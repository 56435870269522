import React, { useState, useEffect, useRef } from 'react'

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';

// child component
import Loader from '../../Ui/BookingModuleLoader'

// packages
// Actions & RTK query or mutations
import { useLazyWebInitiateSessionQuery } from '../../Services/modules/Card';
import { usePaymentBlockAccessMutation } from '../../Services/modules/ReactiveAccount';
import { setSecureData } from '../../Store/BookingModule';
import { setSpinner, setAlert } from '../../Store/UI'
import { setMKey, login, setWalletBalance } from '../../Store/User';


// Constants
import { cardValidationPayment } from '../../Services'
import { NameConstants } from '../../resources/NameConstants'
import { routesName } from '../../resources/RoutesName';
import { placeholders, actions, errors, messages } from '../../resources/en'

const intialState = {
      [NameConstants.CTNameOnCard]:{value:"",error:false,errorMessage:""},
      [NameConstants.CTCardNumber]:{value:"",error:false,errorMessage:""},
      [NameConstants.CTExp]:{value:"",error:false,errorMessage:""},
      expirey_date:{value:"",error:false,errorMessage:""},
      [NameConstants.CTCvc]:{value:"",error:false,errorMessage:""}
}

const PaymentModal = ( { access_id, closePaymentModal, show, amount }) => {

      const [state,setState]                    =     useState(intialState);
      const ui                                  =     useSelector(state=>state.ui);
      const secureData                          =     useSelector(state=>state.bookingModule.secureData);
      const ref                                 =     useRef();
      const dispatch                            =     useDispatch();
      const navigate                            =     useNavigate();

      const [WebInitiateSession, { data : WebData, isSuccess : WebIsSuccess, error : WebError, isLoading : WebIsLoading  }] = useLazyWebInitiateSessionQuery();
      const [paymentBlockAccess, { data : blockAccessData, isSuccess : blockAccessIsSuccess, error : blockAccessError, isLoading : blockAccessIsLoading  }] = usePaymentBlockAccessMutation();

      useEffect(()=>{
            if(WebIsSuccess) {
                  if(WebData.status === "success"){
                        sagePayRequest(WebData.data.merchantSessionKey);
                        dispatch(setMKey({mKey:WebData.data.merchantSessionKey}))
                  } else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:WebData.error || WebData.message || errors.NotFound404}));
                  }
            }
            if(WebError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:WebError.error || WebError.data.error || WebError.data.message || errors.NotFound404}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[WebData, WebIsSuccess, WebError, WebIsLoading, dispatch])

      useEffect(()=>{
            if(blockAccessIsSuccess) {

                  if(blockAccessData.status === "3DAuth"){
                        dispatch(setSecureData({data:blockAccessData}));
                  }
                  else if(blockAccessData.status === "1"){
                        const walletBalance = parseFloat(blockAccessData.data.userInfo.wallet_balance) || 0;
                        dispatch(login(blockAccessData.data));
                        navigate(routesName.CurrentListing);
                        dispatch(setWalletBalance({ct_wallet_balance:walletBalance}))

                  } else if(blockAccessData.status === "0"){
                        dispatch(setSpinner({status : ""}))
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:blockAccessData.errors || blockAccessData.error || blockAccessData.message || errors.NotFound404}));
                  }
            }
            if(blockAccessError){
                  dispatch(setSpinner({status : ""}))
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:blockAccessError.error || blockAccessError.data.error || blockAccessError.data.message || errors.NotFound404}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[blockAccessData, blockAccessIsSuccess, blockAccessError, blockAccessIsLoading, dispatch])

      useEffect(()=>{

            if(secureData !== null ) {
                  if(secureData.status === "3DAuth"){
                        dispatch(setSecureData({data:null}));
                        ref.current.submit();
                  }
            }

      },[secureData, dispatch])

      const clearErrorHandler = ()=>{
            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.CTNameOnCard]:{...prevState[NameConstants.CTNameOnCard],error:false,errorMessage:""},
                        [NameConstants.CTCardNumber]:{...prevState[NameConstants.CTCardNumber],error:false,errorMessage:""},
                        [NameConstants.CTExp]:{...prevState[NameConstants.CTExp],error:false,errorMessage:""},
                        expirey_date:{...prevState.expirey_date,error:false,errorMessage:""},
                        [NameConstants.CTCvc]:{...prevState[NameConstants.CTCvc],error:false,errorMessage:""}
                  }
            })
      }

      const changeValueHandler = (event)=>{
            const type = event.target.name;
            let val  = event.target.value;
            
            if(type === NameConstants.CTExp) {
                  var str = val;
                  if(str.length > 5){
                        return;
                  }
                  const actual_val = str.replace(/\//g, '');
                  // eslint-disable-next-line no-useless-escape
                  const display_val = val.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '')

                  // const checkValidation = cardValidationPayment(val,type); 
                  setState(prevState=>{
                        return {
                              ...prevState,
                              // [type]:{...prevState[type],value:display_val,error:!checkValidation.isValid,message:checkValidation.validatingMessage},
                              [type]:{...prevState[type],value:display_val},
                              expirey_date:{...prevState.expirey_date,value:actual_val}
                        }
                  })

            } else{

                  let actual_val = val;
                  if(type === NameConstants.CTCvc){
                        actual_val = val.replace(/[^0-9]/g, '');;
                  }

                  if(type === NameConstants.CTCardNumber){
                        actual_val = val.replace(/[^0-9]/g, '');;
                  }

                  // const checkValidation = cardValidationPayment(actual_val,type); 
                  setState(prevState=>{
                        return {
                              ...prevState,
                              // [type]:{...prevState[type],value:actual_val,error:!checkValidation.isValid,errorMessage:checkValidation.validatingMessage}
                              [type]:{...prevState[type],value:actual_val}
                        }
                  })
            }
            
      }

      const SendPaymentRequestHandler = (event)=> {

            event.preventDefault();

            let request             =     false;
            const checkedObject     =     {};
            const requestAllow      =     [];
            for(let i in state){
                  const checkValidation   =       cardValidationPayment(state[i].value,i); 
                  checkedObject[i]        =       {...state[i],error:!checkValidation.isValid,errorMessage:checkValidation.validatingMessage}
                  requestAllow.push(!checkValidation.isValid); 

            }

            setState(prevState=>{
                  return {
                        ...checkedObject
                  }
            });

            request   =       requestAllow.includes(true);
            if(!request) {
                  dispatch(setSpinner({status : "request"}))
                  WebInitiateSession();
            }
      }

      const sagePayRequest = (merchantSessionKey)=>{
               
            window.sagepayOwnForm({ merchantSessionKey: merchantSessionKey })
            .tokeniseCardDetails({
                  cardDetails: {
                        cardholderName: state[NameConstants.CTNameOnCard].value,
                        cardNumber: state[NameConstants.CTCardNumber].value,
                        expiryDate: state.expirey_date.value,
                        securityCode: state[NameConstants.CTCvc].value
                  },
                  onTokenised: function(result) {
                              if (result.success) {
                                    const card_identifier = result.cardIdentifier;
                                    paymentBlockAccess({
                                          card_identifier:card_identifier,
                                          amount:parseFloat(amount),
                                          mKey:merchantSessionKey,
                                          access_id:access_id
                                    })

                              } else{
                                    dispatch(setSpinner({status : ""}))
                                    const errorsSag = result.errors;
                                    let ct_card_number = false;
                                    let ct_exp = false;
                                    let ct_cvc = false;
                                    let ct_name_on_card = false;

                                    errorsSag.forEach(error => {
                                          let strng       = error.message;
                                          let incNumber   = strng.includes("number");
                                          let incExp      = strng.includes("date");
                                          let incHold     = strng.includes("cardholder");
                                          let incCvv      = strng.includes("code");
                                          if (incNumber) { ct_card_number = true; }
                                          if (incExp) { ct_exp = true; }
                                          if (incHold) { ct_name_on_card = true; }
                                          if (incCvv) { ct_cvc = true; }
                                    }) 

                                    if (ct_name_on_card) {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTNameOnCard]:{...prevState[NameConstants.CTNameOnCard],error:true,errorMessage:errors.SavedCard[NameConstants.CTNameOnCard]}
                                                }
                                          })
                                    }

                                    if (ct_card_number){	
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTCardNumber]:{...prevState[NameConstants.CTCardNumber],error:true,errorMessage:errors.SavedCard[NameConstants.CTCardNumber]}
                                                }
                                          })							
                                    }

                                    if (ct_exp) {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTExp]:{...prevState[NameConstants.CTExp],error:true,errorMessage:errors.SavedCard[NameConstants.CTExp]}
                                                }
                                          })
                                    }

                                    if(ct_cvc) {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTCvc]:{...prevState[NameConstants.CTCvc],error:true,errorMessage:errors.SavedCard[NameConstants.CTCvc]}
                                                }
                                          })
                                    }

                                    if(!ct_name_on_card && !ct_card_number && !ct_exp && !ct_cvc){
                                          dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:errorsSag[0].message || errors[0].message || errors.sagePayError}))
                                    }
                              }
                    }
            });
      }

      return (
            <>
            {secureData &&
                  <form  ref={ref}  method="POST" style={{"visibility": "hidden","height":"0px"}} action={secureData && secureData.acsUrl}>
                        <input type="hidden" name="creq" value={secureData && secureData.cReq}/>
                        <input type="hidden" name="acsTransID" value={secureData && secureData.acsTransId}/>
                        <input type="hidden" name="threeDSSessionData" value={secureData && secureData.threeDSSessionData}/>
                        <input type="hidden" name="ThreeDSNotificationURL " value={secureData && secureData.ThreeDSNotificationURL} />
                        <button type="submit" style={{"visibility": "hidden"}}></button>
                  </form>
            }
            <div className={["slidein-wrapper show" ,show === "exiting" && "close"].join(" ")}>
                  <div className="slidein slidein__book-tickets reactive-account">
          
                        <div className="content__slidein content-scrollable">
          
                              <form>
          
                                    <div className="content content__checkout">
                                          <div className="content__header border-bottom position-relative">
                                                <button type="button" className="btn-close btn-close__checkout btn-close__custom btn-close__absolute" aria-label="Close" 
                                                      onClick={closePaymentModal}>
                                                </button>
                                                <div className="content my-auto">
                                                      <h4 id="contentTitle" className="content__title my-auto mb-0">
                                                            Payment - £{parseFloat(amount).toFixed(2)}   
                                                      </h4>
                                                </div>
                                          </div>
                                          {/* <!-- /.content__header --> */}
                                          <div id="bookTicketsStep4PayViaCard" className="content content__payment-options border-bottom d-flex">
                                                <div className="content__body">
                                                      {/* <h4 className="heading heading__use-new-card">Use a new card</h4> */}
                                                      <div className="form-groups__use-new-card">
                                                            <div className="form-group">
                                                                  <input type="text" className="form-control" id="bookTicketsInputCardName"
                                                                  name={NameConstants.CTNameOnCard} 
                                                                  placeholder={placeholders[NameConstants.CTNameOnCard]}
                                                                  onFocus={clearErrorHandler}
                                                                  onChange={changeValueHandler} 
                                                                  value={state[NameConstants.CTNameOnCard].value}
                                                                  />
                                                                  {state[NameConstants.CTNameOnCard].error && <p className="form-label-error">{state[NameConstants.CTNameOnCard].errorMessage}</p>}
                                                            </div>
                                                            <div className="form-group">
                                                                  <input type="text" className="form-control"                         id="bookTicketsInputCardNum" 
                                                                  name={NameConstants.CTCardNumber}
                                                                  placeholder={placeholders[NameConstants.CTCardNumber]}
                                                                  onFocus={clearErrorHandler} 
                                                                  onChange={changeValueHandler} 
                                                                  value={state[NameConstants.CTCardNumber].value}
                                                                  />
                                                                  {state[NameConstants.CTCardNumber].error && <p className="form-label-error">{state[NameConstants.CTCardNumber].errorMessage}</p>}
                                                            </div>
                                                            <div className="form-groups__expiry-cvc d-flex">
                                                                  <div className="form-group form-group__card-expiry">
                                                                        <input type="text" className="form-control fc__CardExpiry" name={NameConstants.CTExp} 
                                                                        placeholder={placeholders[NameConstants.CTExp]} 
                                                                        onFocus={clearErrorHandler} 
                                                                        onChange={changeValueHandler} 
                                                                        value={state[NameConstants.CTExp].value}
                                                                        />
                                                                        {state[NameConstants.CTExp].error && <p  className="form-label-error">{state[NameConstants.CTExp].errorMessage}</p>}
                                                                  </div>
                                                                  <div className="form-group form-group__cvc">
                                                                        <input type="text" className="form-control fc__CVC" id="bookTicketsInputCardCVC" 
                                                                        name={NameConstants.CTCvc} 
                                                                        placeholder={placeholders[NameConstants.CTCvc]} 
                                                                        onFocus={clearErrorHandler} 
                                                                        onChange={changeValueHandler} 
                                                                        value={state[NameConstants.CTCvc].value}
                                                                        />
                                                                        {state[NameConstants.CTCvc].error && <p className="form-label-error">{state[NameConstants.CTCvc].errorMessage}</p>}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.form-groups__expiry-cvc --> */}
                                                           
                                                      </div>
                                                      {/* <!-- /.form-groups__use-new-card --> */}
                                                      <br className="d-none d-xxl-block"/>
                                                </div>
                                                {/* <!-- /.content__body --> */}

                                                <div className="content__body content__body--total border-top">
                                                      <div className="subtotal-block">
                                                            <div className="label">Total (VAT inc.):</div>
                                                            <div className="total-amount">£{parseFloat(amount).toFixed(2)}</div>
                                                      </div>
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                                <div className="content__footer border-top">
                                                      <div className="buttons-wrapper gap-0">
                                                            <button id="btnBackToStep3FromPayViaCard" type="button" className="btn__back btn btn-outline-primary w-50" onClick={()=>{}}>
                                                                  {actions.back}
                                                            </button>
                                                            <button id="btnContinueStep5" type="button" className="btn btn-primary w-50" disabled={ui.spinner === "request"} onClick={SendPaymentRequestHandler}>
                                                                  {actions.PayButton} £{parseFloat(amount).toFixed(2)}
                                                            </button>
                                                      </div>
                                                </div>
                                                {/* <!-- /.content__footer --> */}
                                          </div>

                                          <Loader show={ui.spinner === "request"}/>
          
                                    </div>
                                    {/* <!-- /.content__checkout --> */}
          
                              </form>
          
                        </div>
                        {/* <!-- /.content__slidein --> */}
          
                  </div>
                  {/* <!-- /.slidein --> */}
            </div>
            </>
      )
}

export default PaymentModal
