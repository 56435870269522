export const otpVerification =  build =>
      build.mutation({
            query: (body) =>  ({
                  url: '/register/otp_verify',
                  method: 'POST',
                  body: body
            }),
      })


      // Changed with new one