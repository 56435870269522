import React from 'react';
import { Route } from 'react-router-dom';
import { routesName } from "./RoutesName";

import { 
      CurrentListings, 
      EventDetails, MyAccount, 
      MyAccountHome, MyFavourites, 
      MyWallet, ReferFriend, 
      MyBookings, 
      // Home, 
      ReactiveForm,
      AccessNeeds, FeedbackSurvey, MoreInfo, 
      // WebHomePage,
      GiftVouchers
} from '../Pages';

export default <>
            
                  <Route path={routesName.CurrentListing} element={<CurrentListings/>}/>
                  <Route path={routesName.MyFavourites} element={<MyFavourites/>}/>
                  <Route path={routesName.MyBookings} element={<MyBookings/>}/>

                  <Route path={routesName.EventDetails} element={<EventDetails/>}/>
                  <Route path={routesName.SuccessBooking} element={<EventDetails/>}/>
                  <Route path={routesName.FailureBooking} element={<EventDetails/>}/>
                  <Route path={routesName.Event} element={<EventDetails/>}/>

                  <Route path={routesName.MyAccountHome} element={<MyAccountHome/>}/>
                  <Route path={routesName.MyAccount} element={<MyAccount/>}/>
                  <Route path={routesName.SuccessCardStore} element={<MyAccount/>}/>
                  <Route path={routesName.FailureCardStore} element={<MyAccount/>}/>

                  <Route path={routesName.MyWallet} element={<MyWallet/>}/>
                  <Route path={routesName.MyWalletTopUp} element={<MyWallet/>}/>
                  <Route path={routesName.SuccessAddBalance} element={<MyWallet/>}/>
                  <Route path={routesName.FailureAddBalance} element={<MyWallet/>}/>
                  <Route path={routesName.SuccessAddVoucher} element={<GiftVouchers/>}/>
                  <Route path={routesName.FailureAddVoucher} element={<GiftVouchers/>}/>
                  
                  <Route path={routesName.ReferFriend} element={<ReferFriend/>}/>
                  <Route path={routesName.ReactiveForm} element={<ReactiveForm/>}/>
                  <Route path={routesName.AccessNeeds} element={<AccessNeeds/>}/>
                  <Route path={routesName.FeedbackSurvey} element={<FeedbackSurvey/>}/>
                  <Route path={routesName.MoreInfo} element={<MoreInfo />}/>
                  
                  <Route path={routesName.GiftVouchers} element={<GiftVouchers />}/>
                  {/* <Route path={routesName.WebHomePage} element={<WebHomePage />}/>  */}

            </>

