import React from "react";
import SavedCardForm from "../Components/SavedCardForm";

const SavedCards = ()=>{

      return (
                  <div className="tab-pane tab-pane__SavedCards fade show active" id="myAccntSavedCards" role="tabpanel" aria-labelledby="myAccntSavedCards-tab">
                        <div className="tab-pane__body">
                            
                              <SavedCardForm />
                              {/* <!-- /.content --> */}

                        </div>
                        {/* <!-- /.tab-pane__body --> */}

                  </div>
      )
}

export default SavedCards;