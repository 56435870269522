import { validation } from "../resources/en";
import { NameConstants } from "../resources/NameConstants";

const checkValidationPromoCode=(value, type) =>{
      let isValid = true;
      let validatingMessage = "";
      if (!type) {
          return true;
      }

      if(type === NameConstants.promoCode){
            if(value.trim() ==='') {
                  isValid = false;
                  validatingMessage = validation[type].required;

            }else if(value.length < 3) {
                  isValid = false;
                  validatingMessage = validation[type].invalid;
            }
            
      }

      return {isValid,validatingMessage};
}

export default checkValidationPromoCode;
