import { api } from "../../Api";
import { submitRestoreAccessTerms } from "./submitRestoreAccessTerms";
import { restoreBlockAccess } from "./restoreBlockAccess";
import { paymentBlockAccess } from "./paymentBlockAccess";

export const reactiveAccountApi = api.injectEndpoints({
            endpoints: build => ({
            submitRestoreAccessTerms :submitRestoreAccessTerms(build),
            restoreBlockAccess : restoreBlockAccess(build),
            paymentBlockAccess : paymentBlockAccess(build)
      }),
      overrideExisting: true
})

export const { useSubmitRestoreAccessTermsMutation, useLazyRestoreBlockAccessQuery, usePaymentBlockAccessMutation } = reactiveAccountApi