export const fetchMemberPreference =  build =>
      build.query({
            query: (body) =>  ({
                  url: '/memberPreference',
                  method: 'GET',
                  params: body,
            }),
            providesTags: ['MemberPreference']
      })

      // Changed with new one