import { api } from "../../Api";
import { privacy } from "./privacy";
import { faq } from "./faq";
import { terms } from "./terms";
import {flexi} from "./flexi"
import { underrepresentedCommunities } from "./underrepresentedCommunities";
import { TrustpilotScore } from "./TrustpilotScore";
import { getCollectionInstruction } from "./getCollectionInstructions";
import { getFeedbackReport } from "./getFeedbackReport";
import { bookingReleaseSeats } from "./bookingReleaseSeats";

export const othersApi = api.injectEndpoints({
            endpoints: build => ({
            getPrivacy:privacy(build),
            getFaq:faq(build),
            terms : terms(build),
            getFlexi : flexi(build),
            underrepresentedCommunities : underrepresentedCommunities(build),
            trustpilotScore : TrustpilotScore(build),
            getCollectionInstruction : getCollectionInstruction(build),
            getFeedbackReport : getFeedbackReport(build),
            bookingReleaseSeats: bookingReleaseSeats(build),
      }),
      overrideExisting: true
})

export const {  useLazyGetPrivacyQuery, useLazyGetFaqQuery, useLazyTermsQuery,useLazyGetFlexiQuery, useLazyUnderrepresentedCommunitiesQuery, useTrustpilotScoreQuery, 
      useLazyGetCollectionInstructionQuery, useLazyGetFeedbackReportQuery, useLazyBookingReleaseSeatsQuery } = othersApi;