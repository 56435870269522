import React from 'react';

// external css
// redux and react hooks
// child component
// packages
// Actions & RTK query or mutations
// Constants


const OwlCarouselEventsItem = ({afterLoad , image, price,  link  }) => {

      return (
            <div className="item">
                 <div className="card card__event p-0" style={{borderRadius :"32px"}}>
                        <div className="card__event-image">
                             <ul className="list__event-tags">
                                   <li>
                                         <div className="badge badge-red">£{price}</div>
                                   </li>
                              </ul>
                              
                              <a href={link} target="_blank" rel='noreferrer'>
                                    <img className="event-image"  src={`${image}`} srcSet={`${image} 2x`} alt=""/>
                                    {/* <LazyLoadImage className="event-image" src={`${image}`} srcSet={`${image} 2x`} afterLoad={afterLoad} /> */}
                              </a>
                        </div>
                  </div>
            </div>
      )
}

export default OwlCarouselEventsItem
