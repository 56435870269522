import React from 'react';
// external css
// redux and react hooks
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// child component
import Backdrop from '../Backdrop';

import ticketMessageReceivedMustardSm  from "./../../assets/images/tickets/ticket-message-received-mustard-sm.svg"
import ticketMessageReceivedGreenSm from "./../../assets/images/tickets/ticket-message-received-green-sm.svg"
import ticketMessageReceivedYellowSm from "./../../assets/images/tickets/ticket-message-received-yellow-sm.svg"
import ticketMessageReceivedRedSm from "./../../assets/images/tickets/ticket-message-received-red-sm.svg"
import ticketMessageReceivedAquamarineSm from "./../../assets/images/tickets/ticket-message-received-aquamarine-sm.svg"
import ticketMessageReceivedBlueSm from "./../../assets/images/tickets/ticket-message-received-blue-sm.svg"
// packages
// Actions & RTK query or mutations
// Constants
import { routesName } from '../../resources/RoutesName';

const MessageReceivedModal = (props) => {

      const {auth, domain}          =     useSelector(state=>state.user.auth)
      const navigate                =     useNavigate();

      const backToHomePageHandler = ()=> {
            if(auth) {
                  navigate(routesName.CurrentListing)
            } else {
                  navigate(routesName.Home)
            }
      }

      return (
                  <React.Fragment>
                        <div className={["modal modal__form-modal modal__message-received fade",(props.status === "entering" || props.status === "entered") && "modalOpen",(props.status === "exiting" || props.status === "exited") && "modalClosed"].join(" ")} id="modalMessageReceived" style={{"display":"block"}}  tabindex="-1" aria-hidden="true">
                              <div className="modal-dialog">
                                    <div className="modal-content">
                                          <div className="modal-body d-flex align-items-center justify-content-center">
                                                <div className="content">
                                                      <div className="content__header">
                                                            <div className="big-heading">
                                                                  <h1 className="mb-0 text-center">Message<br/>received</h1>
                                                                  {/* <ImageComponent className="ticket ticket-mustard d-md-none" src="ticket-message-received-mustard-sm.svg" type="" tickets/> */}
                                                                  {domain === "" && <>
                                                                  <img className="ticket ticket-mustard d-md-none"  src={ticketMessageReceivedMustardSm} alt=""/>
                                                                  <img className="ticket ticket-green d-md-none"  src={ticketMessageReceivedGreenSm} alt=""/>
                                                                  <img className="ticket ticket-yellow d-md-none"  src={ticketMessageReceivedYellowSm} alt=""/>
                                                                  <img className="ticket ticket-red d-md-none"  src={ticketMessageReceivedRedSm} alt=""/>
                                                                  <img className="ticket ticket-aquamarine d-md-none"  src={ticketMessageReceivedAquamarineSm} alt=""/>
                                                                  <img className="ticket ticket-blue d-md-none"  src={ticketMessageReceivedBlueSm} alt=""/>
                                                                  </>}
                                                            </div>
                                                      </div>
                                                      <div className="content__body text-center">
                                                            <p>
                                                                  Thank you for your message. <br className="d-none d-md-block"/>We’ll get back to you quickly.
                                                            </p>
                                                            <div className="form-group__cta d-flex justify-content-center">
                                                                  <button className="btn btn-xl-lg btn-primary mb-0" onClick={backToHomePageHandler}>
                                                                        {auth ? "Back to listings" :"Back to homepage"}
                                                                  </button>
                                                            </div>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}
                                          </div>
                                          {/* <!-- /.modal-body --> */}
                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>
                        <Backdrop/>
                  </React.Fragment>
      )
}

export default MessageReceivedModal;
