export const addUserVoucher =  build =>
      build.mutation({
            query: (body) =>  ({
                  url: '/voucher/add_user_voucher',
                  method: 'POST',
                  body: body
            }),
            invalidatesTags: ['getUserWalletBalance']
            // invalidatesTags: ['getVoucherHistory']
            
      })


     