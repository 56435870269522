import React, { useState, useEffect } from 'react'

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';

// child component
import { HeadingPictureComponent, TickerText } from '../../Components';
import WalletTopUp from './WalletTopUp/WalletTopUp';
import Footer from "../../Layouts/Footer/Footer";
import WalletTerms from './Modal/WalletTerms';

// packages
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { useLazyGetHistoryQuery } from '../../Services/modules/Wallet';
import { setAlert, updateLocoScroll, setHideWalletTopUpCopy } from '../../Store/UI';
import { isMobile, isAndroid } from 'react-device-detect';


// Constants
import { catsOneText } from '../../resources/TextRollerConstants';
import { setWalletParams, setTermsModal, setRefetchHistory } from '../../Store/Wallet';
import { routesName } from '../../resources/RoutesName';
import { errors, messages } from '../../resources/en';
import { MetaData, MetaDataAj, MetaDataTfm } from '../../resources/MetaData';
import { Config } from '../../Config';

const MyWallet = (props) => {

      const [scroll,setScroll]                  =     useState(false);
      const [height,setHeight]                  =     useState(0);
      const wallet                              =     useSelector(state=>state.wallet);
      const user                                =     useSelector(state=>state.user);
      const hideWalletTopUpCopy                 =     useSelector(state=>state.ui.hideWalletTopUpCopy);

      const [getHistory, { data, isSuccess, error, isLoading }] = useLazyGetHistoryQuery();
      
      const dispatch                      =     useDispatch();
      const navigate                      =     useNavigate();
      const { pathname, search }          =     useLocation();

      useEffect(()=>{  
            document.title = "My Wallet"; 
            const elmnt = document.getElementById("content-id-ref");
            const height = elmnt.offsetHeight ? elmnt.offsetHeight - 41 : 0
            setHeight(height)

            return ()=> {
                  dispatch(setWalletParams({isTopUpOpen : false,step : 'step1',paymentMethod : "",topUpAmount : 0}))
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]) 

      useEffect(()=>{
            if(wallet.refetchHistory){
                  getHistory()
                  dispatch(setRefetchHistory({refetchHistory : false}))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[wallet.refetchHistory])

      useEffect(()=>{
            getHistory()

            if(pathname.includes('top-up')){
                  dispatch(setWalletParams({isTopUpOpen : true,step : 'step1',paymentMethod : "",topUpAmount : 0}))
                  navigate('', { replace : true })
            }

            if(props.webView && !props.paySuccess){
                  dispatch(setWalletParams({isTopUpOpen : props.topUp ? true : false,step : 'step1',paymentMethod : "",topUpAmount : 0}))
                  if(!isAndroid){
                        navigate('', { replace : true })
                  }
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[getHistory, pathname, dispatch])

      // handle Response from 3D secure
      useEffect(()=>{

            if(pathname.includes(routesName.SuccessAddBalance) || pathname.includes(routesName.FailureAddBalance)) {
                  const searchParams = new URLSearchParams(search);
                  const client = searchParams.get('client');
                  const bookingStatus = pathname.includes(routesName.SuccessAddBalance) ? "success" : "failure";
                  const message = bookingStatus === "success" ?  messages.SuccessAddBalance : messages.FailureAddBalance;
                  if(client === "web"){
                        if(bookingStatus === "success"){
                              dispatch(setWalletParams({isTopUpOpen : true,step : 'step3',paymentMethod : "",topUpAmount : 0}))

                              // comment this code bacause using firebase to refetch wallet balance
                              // dispatch(setGetWalletBalance({getWalletBalance:true}))
                              dispatch(setHideWalletTopUpCopy({hideWalletTopUpCopy : true}))
                              // dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:messages.SuccessAddBalance || errors.NotFound404}));
                        } else {
                              dispatch(setWalletParams({isTopUpOpen : false,step : 'step1',paymentMethod : "",topUpAmount : 0}))
                              // comment this code bacause using firebase to refetch wallet balance
                              // dispatch(setGetWalletBalance({getWalletBalance:true}))
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:message || errors.NotFound404}));
                        }
                  }
                  navigate(routesName.MyWallet, { replace: true });
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{
            if(isSuccess) {
                 setScroll(true)
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
      
      },[data, isSuccess, error, isLoading, dispatch])

      useEffect(()=>{
            if(scroll){
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
                  setScroll(false)
            }
      },[scroll, dispatch])

      const topUpHandler = ()=> {
            dispatch(setWalletParams({
                  isTopUpOpen : !wallet.isTopUpOpen,
                  step : 'step1',
                  paymentMethod : "",
                  topUpAmount : 0
            }))
      }

      const termsHandler = ()=> {
            if(user.ct_web_view_session){
                  dispatch(setTermsModal({ termsModal : !wallet.termsModal}))
            } 
            // else {
            //       console.log("here")
            //       navigate(`${routesName.Terms}?wallet`, { target: '_blank', myData: "wallet" });
            // }
      }

      let hideTopUpCopy = false;
      if(isMobile && hideWalletTopUpCopy){
            hideTopUpCopy = true
      }

      let metaDataSite = MetaData
      if(user.domain === Config.DOMAIN_CONSTANT_TFM){
            metaDataSite = MetaDataTfm
      } else if(user.domain === Config.DOMAIN_CONSTANT_AJTIX){
            metaDataSite = MetaDataAj
      }

      return (
                  <React.Fragment>
                        {wallet.termsModal && <WalletTerms closeTermsModal={termsHandler}/>}
                        {/* <!-- Wallet Top-up slidein begins here --> */}
                        {wallet.isTopUpOpen && <WalletTopUp slidUp={wallet.isTopUpOpen} webView={props.webView} />}
                        {/* <!-- /.slidein-wrapper --> */}

                        {/* <!-- REQUIRED wrapper div for parallax --> */}
                        <div className="scroll-animations position-relative" data-scroll-container>
                              <Helmet>
                                    <title>{metaDataSite.Wallet.title}</title>
                                    <meta name="description" content={metaDataSite.Wallet.description} />
                                    <meta name="keywords" content={metaDataSite.Wallet.keywords} />
                              </Helmet>
                              <main>

                                    <section data-scroll-section className="section__my-wallet py-0">
                                          <div className="container-fluid container__block">
                                                <div className="row">
                                                      <div className="col-md-12">
                                                            <div className="content content__my-wallet mx-auto">
                                                                  <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                                                                        <div className="big-heading">
                                                                              <h1 className="mb-0">Your Wallet</h1>
                                                                              {user.domain === "" && <>
                                                                              <HeadingPictureComponent 
                                                                              srcSet="ticket-my-wallet-big-heading1-aquamarine.svg"
                                                                              src="ticket-my-wallet-big-heading1-green-sm.svg"
                                                                              className="ticket-aquamarine"
                                                                              dataScroll/>

                                                                              <HeadingPictureComponent 
                                                                              srcSet="ticket-my-wallet-big-heading1-blue.svg"
                                                                              src="ticket-my-wallet-big-heading1-blue-sm.svg"
                                                                              className="ticket-blue"
                                                                              dataScroll/>

                                                                              <HeadingPictureComponent 
                                                                              srcSet="ticket-my-wallet-big-heading1-yellow.svg"
                                                                              src="ticket-my-wallet-big-heading1-aquamarine-sm.svg"
                                                                              className="ticket-yellow"
                                                                              dataScroll/>
                                                                              </>}

                                                                              
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- .content__header --> */}

                                                                  <div className="content__body border-bottom d-flex flex-column flex-lg-row align-items-lg-stretch">

                                                                        <div className="col__top-up-info border-right-lg">

                                                                              <div className="content ms-lg-auto" id="content-id-ref">
                                                                                    <div className="card card__credit-balance">
                                                                                          <div className="card-body">
                                                                                                <h2 className="h3 heading">Your credit balance</h2>
                                                                                                <h3 className="h2 wallet-balance mb-0">£{parseFloat(user.ct_wallet_balance).toFixed(2)}</h3>
                                                                                          </div>
                                                                                          {/* <!-- .card-body --> */}
                                                                                    </div>
                                                                                    {/* <!-- .card__credit-balance --> */}
                                                                                    {!hideTopUpCopy &&<>
                                                                                    <div className="p-top-up d-flex flex-column flex-sm-row align-items-sm-center">
                                                                                          Are you low on credit? <button type="button" className="btn btn-outline-primary btn-slidein-topup hover-primary" onClick={topUpHandler}>Top up</button>
                                                                                    </div>
                                                                                    <h3 className="heading heading__why-top-up">
                                                                                          Why top up credit?
                                                                                    </h3>
                                                                                    <p>
                                                                                          Save time checking out, by paying for your bookings with account credit, which you can top up at any time via your account wallet.
                                                                                    </p>
                                                                                    <p>
                                                                                          Get £10 extra credit free with your first top-up when you top-up £100 into your wallet.  
                                                                                    </p>
                                                                                    </>}
                                                                                    <p className="p-terms fw-bold">
                                                                                          {/* <Link to={routesName.Terms}  target="_blank" rel="noopener noreferrer" state={{ myData: 'wallet' }}>Read Terms & Conditions</Link> */}
                                                                                          {user.ct_web_view_session ? <span onClick={termsHandler}   style={{color :"var(--bs-red)",textDecoration : 'underline'}}>Read Terms & Conditions</span>:
                                                                                          <Link to={{ pathname: `${routesName.Terms}?wallet`, state: { myData: "wallet" } }}  target="_blank" rel="noopener noreferrer">Read Terms & Conditions</Link>}
                                                                                    </p>
                                                                                    {/* <hr className="d-none d-md-block mt-5"/> */}
                                                                              </div>
                                                                              {/* <!-- .content --> */}

                                                                        </div>
                                                                        {/* <!-- .col__top-up-info --> */}

                                                                        <div className="col__transaction-history me-lg-auto">

                                                                              <div className="content" id="headingHistory">

                                                                                    {(isSuccess && data.data.length > 0) &&<h3 className="heading" >History of transactions</h3>}
                                                                                    {(data) &&
                                                                                    <div id="inside-js-scroll" className={data.data.length > 0 ? "" : "inside-js-scroll-height"} style={{"height":height, minHeight : (user.ct_web_view_session && hideTopUpCopy) ? "350px" : ""}}>

                                                                                          {data.data.map((tran,id)=>{

                                                                                                return      (<div key={`${tran.id}+${id}`} className={["card card__transaction-summary",tran.cr > 0 ? "bg-aqua" : "bg-aqua"].join(" ")} >
                                                                                                                  <div className="card-body">
                                                                                                                        <div className="content">
                                                                                                                              <div className="item-wrapper">
                                                                                                                                    <div className="item d-flex justify-content-between">
                                                                                                                                          <div className="item__name">{tran.nurration}</div>
                                                                                                                                          <div className="item__date flex-shrink-0">{tran.created_date}</div>
                                                                                                                                    </div>
                                                                                                                                    {/* <div className="item-detail">
                                                                                                                                          Card (**** 9456)
                                                                                                                                    </div> */}
                                                                                                                              </div>
                                                                                                                              {/* <!-- .item-wrapper --> */}
                                                                                                                        </div>
                                                                                                                        {/* <!-- .content --> */}
                                                                                                                  </div>
                                                                                                                  {/* <!-- .card-body --> */}
                                                                                                                  <div className="card-footer pt-0">
                                                                                                                        <hr className="card-footer__hr"/>
                                                                                                                        <div className="content">
                                                                                                                              <div className="transaction-status d-flex justify-content-between">
                                                                                                                                    <div className="transaction-amount">{tran.cr > 0 ? "+":"-"} £{tran.cr > 0 ? tran.cr : tran.dr}</div>
                                                                                                                                    <div className="wallet-balance">Balance: <span>£{tran.balance}</span></div>
                                                                                                                              </div>
                                                                                                                              {/* <!-- .transaction-status --> */}
                                                                                                                        </div>
                                                                                                                        {/* <!-- .content --> */}
                                                                                                                  </div>
                                                                                                                  {/* <!-- .card-footer --> */}
                                                                                                            </div>)
                                                                                          })}
                                                                                          
                                                                                          {(data.data.length <= 0 && isSuccess) &&
                                                                                          <div className='col__no-transaction-history'>
                                                                                                <h2>Make your <br/>first top up!</h2>
                                                                                                <p>Get £10 extra credit free with your first top-up when you top-up £100 into your wallet.</p>
                                                                                                <button type="button" className="btn btn-outline-primary btn-slidein-topup hover-primary" onClick={topUpHandler}>Add Credit</button>
                                                                                          </div>
                                                                                          }
                                                                                    </div>}

                                                                              </div>
                                                                              {/* <!-- .content --> */}

                                                                        </div>
                                                                        {/* <!-- .col__transaction-history --> */}

                                                                  </div>
                                                                  {/* <!-- .content__body --> */}
                                                            </div>
                                                            {/* <!-- .content.content__my-wallet --> */}

                                                      </div>
                                                      {/* <!-- .col-md-12 --> */}
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </div>
                                          {/* <!-- /.container-fluid  --> */}
                                    </section>
                                    {/* <!-- /.section__my-wallet --> */}

                                    {!props.webView &&                                              
                                    <div data-scroll-section className="border-bottom">
                                          <TickerText id="tickerShowCatsOne" textData={catsOneText}/>
                                          {/* <!-- /.ticker --> */}
                                    </div>}
                              </main>

                              {!props.webView && <Footer/>}

                        </div>
                        {/* <!-- /div[data-scroll-container] --> */}
                  </React.Fragment>
      )
}


export default MyWallet;
