
export const dateFormatHadler = (start_date, start_time)=>{
      const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

      const timeParts = start_time.split(':');
      const dateParts = start_date.split('-');
      const date = new Date(dateParts[0], parseInt(dateParts[1], 10) - 1, dateParts[2], timeParts[0], timeParts[1],timeParts[2]);

      let day = weekday[date.getDay()];
      let date1 = date.getDate() + nth(date.getDate());
      let month = date.toLocaleString('default', { month: 'short' })

      const time = timeFormatHandler(date)
      return `${day} ${date1} ${month}, ${time}`

}

const timeFormatHandler= (date)=> {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ap = hours >= 12 ? 'pm' : 'am';

      hours = hours % 12;
      hours = hours ? hours : 12;

      minutes = minutes.toLocaleString(2,'0');

      let mergeTime = hours+ ':' + minutes + ':' + ap ;
      return mergeTime;
}

const nth = (d)=> {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
      }
}


export const dateAndTimeFormatHandler = (dateTime,showTime)=>{
      const date0 = dateTime.split(" ")[0].split("-");
      const date0Final = `${date0[2]}/${date0[1]}/${date0[0]}`;
      const date1Final = showTime.split(" ")[3];
      return `${date0Final} at ${date1Final}`;
}