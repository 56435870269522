import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// external css
// redux and react hooks
// child component
// packages
import { osName  } from "react-device-detect";

// Actions & RTK query or mutations
import { setSpinner, setPaymentParams } from '../../Store/WebViewPayment'
import { useAddUserVoucherMutation } from '../../Services/modules/GiftVoucher'
import { setAlert } from '../../Store/UI'

// Constants
import { errors, messages } from '../../resources/en'

const Step1 = (props) => {

      const webViewPayment          =     useSelector(state=>state.webViewPayment)
      const ct_wallet_balance       =     useSelector(state=>state.user.ct_wallet_balance)

      const dispatch                =     useDispatch()

      const [addUserVoucher, { data, error }] = useAddUserVoucherMutation();

      //Handler addUserVoucher using wallet response
      useEffect(()=>{
            if(data) {
                  dispatch(setSpinner({status : ""}))
                  if(data.status === "1") {
                        dispatch(setPaymentParams({
                              step : "step3",
                              paymentMethod : "",
                              amount : "",
                              sessionId : ""
                        }))

                  } else if(data.status === "0") {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404 }))
                  }
            }

            if(error) {
                  dispatch(setSpinner({status : ""}))
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      },[data, error, dispatch])
      


      const selectPaymentMethodHandler = (type)=> {

            if(type ==="wallet" && ct_wallet_balance > webViewPayment.amount) {

                  dispatch(setSpinner({status : "request"}))
                  addUserVoucher({
                        voucher_session_id: webViewPayment.sessionId,
                        wallet_balance_used: true
                  })
                  
            } else {
                  dispatch(setPaymentParams({
                        step : "step2",
                        paymentMethod : "card",
                        amount : webViewPayment.amount,
                        sessionId : webViewPayment.sessionId
                  }))
            }
      }


      return (
            <div id="walletTopupStep1" className="content content__payment-options content__topup-options border-bottom">
                  <div className={["content__body",osName ==="iOS"  ? "ios-body-padding" : "general-body-padding"].join(" ")} >
                        <h4 className="heading">Use your wallet</h4>
                        <button id="btnPaymentMethodWallet" type="button"  className="btn btn__payment-method w-100" onClick={()=>{selectPaymentMethodHandler('wallet')}} disabled={ct_wallet_balance < webViewPayment.amount}>
                              <span className="button__icon button__icon--wallet"></span>
                              <span className="button__text">
                                    <span className="sub-heading__upper">Your current credit</span>
                                    <span className="heading">£{parseFloat(ct_wallet_balance).toFixed(2)}</span>
                              </span>
                              <span className="button__arrow button__arrow--right">
                              </span>
                        </button>
                        <h4 className="heading">Other payment methods</h4>
                        <button id="btnPaymentMethodDebitCredit" type="button" className="btn btn__payment-method w-100" disabled={false} onClick={()=>{selectPaymentMethodHandler('card')}}>
                              <span className="button__icon button__icon--debitcredit"></span>
                              <span className="button__text">
                                    <span className="heading">Debit or Credit Card</span>
                              </span>
                              <span className="button__arrow button__arrow--right"></span>
                        </button>
                        <div className='space-for-apple-pay-button'></div>
                        <span className="bottom-text">
                        1) Account credit can be used against the cost of any show listed via our <a href={`/`} target="_blank" rel='noreferrer'>Shows</a> tab. 2) It cannot be used for shows found on the West End Offers tab of our website which are fulfilled by third-party ticketing partners <a href="https://centraltickets.tixculture.com/" target="_blank" rel='noreferrer'>(tixculture.com)</a> or <a href="https://centraltickets.ticketswitch.com/" target="_blank" rel='noreferrer'>(ticketswitch.com)</a>. 3) Any credit added is non-refundable and cannot be exchanged for cash in part or full.&nbsp;<span style={{"color":"red"}}><span to={`${`routesName.Terms`}?wallet`} onClick={props.termsHandler} style={{"color":"red","textDecoration":"underline","cursor":"pointer"}} rel='noreferrer' target="__blank">Full Terms &amp; Conditions.</span></span>
                        </span>
                        <div className="custom-control custom-checkbox custom-checkbox-sm custom-checkbox__check custom-checkbox-hide-checkbox" >
                              <label className="custom-control-label custom-control-label-hide">
                                    By making payment you agree to our Terms & Conditions
                              </label>
                        </div>
                  </div>
                  {/* <!-- /.content__body --> */}
                  <div className="giftVoucher-lower-footer-body">
                        <div className="content__body content__body--total border-top">
                              <div className="subtotal-block">
                                    <div className="fw-bold">Total (VAT inc.):</div>
                                    <div className="total-amount">£{parseFloat(webViewPayment.amount).toFixed(2)}</div>
                              </div>
                        </div>
                  </div>
                  {/* <!-- /.content__body --> */}
            </div>
      )
}

export default Step1
