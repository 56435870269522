import React from "react";


import iconArrowLeft35X24 from "../assets/images/icon-arrow-left-35x24-black.svg";
import iconArrowLeft23X16 from "../assets/images/icon-arrow-left-23x16-black.svg";

const BackButtonUpdateNumber = () => {
  return (
    <div className="buttons-wrapper buttons-wrapper__nav back-button" style={{width : '100%'}}>
      <button className="btn btn-flat btn-has-icon p-0" style={{marginLeft : 'auto',marginRight : 'auto'}}>
        <picture>
          <source media="(min-width: 1200px)" srcSet={iconArrowLeft35X24} />
          <img className="icon-arrow__left" src={iconArrowLeft23X16} alt=""  />
        </picture>
        <span className="text-wrapper" style={{marginLeft : '5px'}}>Back</span>
      </button>
    </div>
  );
};

export default BackButtonUpdateNumber;
