import { useState } from 'react'

// external css
import iconArrowLeft from "../../assets/images/left_arrow_feedback_report.svg"
import iconArrowRight from "../../assets/images/right_arrow_feedback_report.svg"

// redux and react hooks
// child component
// packages
// Actions & RTK query or mutations
// Constants

const SliderForText = ({ slides }) => {

      const [currentIndex, setCurrentIndex] = useState(1);

      const nextSlide =async (event) => {
            setCurrentIndex((prevIndex) =>{ 
                  return ((prevIndex + 1)  >  slides.length ? prevIndex : (prevIndex + 1))
            });
      };

      const prevSlide = async () => {
            setCurrentIndex((prevIndex) => {
                  return ((prevIndex - 1)  >=  1 ? (prevIndex - 1) : prevIndex) 
            });
      };

      return (
            <article className="report_article">
                  <div className="ct_report_title">
                        <div className="ct_report_sub_title">
                              <h2 className="ct_rt_text">
                                    <strong>Please use the space below to leave a positive quote that the event organisers can use to promote their event on social media: *</strong> 
                              </h2>
                              <h3 className="ct_rt_sub_text">
                                    <span className="out_of">2</span> <span>out of <span className="out_of">2</span> answered</span>
                              </h3>
                        </div>
                  </div>
                  <div className="ct-chart">
                        <div className="ct_custom_slider">
                              <div className="ct_slider">
                                    <div className="ct_slider_review">"<span className="message_content">{slides[currentIndex -1]}</span>"
                                    </div>
                              </div>
                              <div className="slider_custom_btn">
                              <button className="slider_div_left" onClick={prevSlide}>
                                    {/* <svg height="512px" id="Layer_1" style={{"enable-background":"new 0 0 512 512"}} version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "></polygon>
                                    </svg> */}
                                    <img src={iconArrowLeft} alt='' style={{"width" : "100%", "height" : "100%"}}/>
                              </button>
                              <button className="slider_div_right" onClick={nextSlide}>
                                    {/* <svg height="512px" id="Layer_1" style={{"enable-background":"new 0 0 512 512"}} version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 "></polygon>
                                    </svg> */}
                                    <img src={iconArrowRight} alt='' style={{"width" : "100%", "height" : "100%"}} />
                              </button>
                              <div className="ct_slider_counter">
                                    <div className="live_count" style={{"display":"inline"}}>{currentIndex} </div>
                                    of <span className="out_of">{slides.length}</span>
                              </div>
                        </div>
                  </div>
                  </div>
            </article>
      )
}

export default SliderForText
