import React from 'react';
import DeleteAccountContent from '../Components/DeleteAccountContent';

const DeleteAccount = () => {

      return (
                 
            <div className="accordion-item accordion-item__DeleteAccount">
                  <h2 className="accordion-header" id="amasHeading6">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#amasCollapse6" aria-expanded="false"  aria-controls="amasCollapse6"
                        >
                              <span className="btn__text">
                                    Delete Account
                              </span>
                        </button>
                  </h2>
                  <div id="amasCollapse6" className="accordion-collapse collapse" aria-labelledby="amasHeading6">
                        <div className="accordion-body">
                              <DeleteAccountContent />
                              
                              {/* <!-- /.content --> */}
                        </div>
                        {/* <!-- /.accordion-body --> */}

                  </div>
                  {/* <!-- /.accordion-collapse --> */}

            </div>
                        
      )
}

export default DeleteAccount;
