import React from 'react'

// external css
// redux and react hooks
// child component
// packages
// Actions & RTK query or mutations
// Constants


const Step1 = ({ onSubmitToTrustPilot }) => {

      return (
            <div className="card-body p-0 step3">
                  <div className="form-group">
                        {/* <h3 className="form-group__heading">
                              Thank you so much. It's great to know you're happy
                              with the service we providing you.
                        </h3>
                        <br /> */}
                        <h3 className="form-group__heading">
                              Thank you for taking part.  Would you consider leaving us a review on Trustpilot? <span className='brand-color'>(please don’t mention any show or venue names 🙏🏻)</span>
                        </h3>
                        <br />
                        <div className="list__btn-checks flex-column flex-md-row justify-content-center mx-auto">
                              <div className="btn-check-wrapper mb-0">
                                    <button className="btn btn-xl-lg btn-primary" onClick={onSubmitToTrustPilot}>
                                          Sure, let's do it!
                                    </button>
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default Step1
