import React from 'react'

const ImageComponent = (props) => {

      let imageSrcSet = props.srcSet;
      let imageSrc = props.src;

      if(props.type === "tickets") {
            imageSrcSet = props.srcSet ? require(`../assets/images/tickets/${props.srcSet}`) : "";
            imageSrc = require(`../assets/images/tickets/${props.src}`);

      } else if(props.type === "images"){
            imageSrcSet = props.srcSet ? require(`../assets/images/${props.srcSet}`) : "";
            imageSrc    = require(`../assets/images/${props.src}`);
      }

      if(imageSrcSet) {

            return (

                  <img data-scroll={props.dataScroll} className={[props.className].join(" ")}  srcSet={`${imageSrcSet} 2x`} src={imageSrc} alt="" onClick={props.onClick}/>
            )

      } else  {

            return (

                  <img data-scroll={props.dataScroll} className={[props.className].join(" ")} src={imageSrc} alt="" onClick={props.onClick}/>
            )
      }

      
}

export default ImageComponent
