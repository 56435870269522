import React from 'react'

const ArticalQuestions = ({ element, state, feedbackCount }) => {


      const percentageCalculator = (num , totalNum)=> {

            return (parseFloat((num / totalNum) * 100)).toFixed(2)
      }

      return (
            <article className="report_article main_q1">
                  <div className="ct_report_title">
                        <div className="ct_report_sub_title">
                              <h2 className="ct_rt_text"><strong>{element.question}</strong></h2>
                              <h3 className="ct_rt_sub_text"><span className="count_q1">{state.response} </span><span>out of {feedbackCount} answered</span></h3>
                        </div>
                  </div>
                  <div className="ct-chart">
                        <div className="ct-chart-section setval_q1">
                              {element.options.map((opt, index)=> {
                                    const percentage = percentageCalculator(state[opt.key], state.sum)
                                    return (
                                          <div className="ct_bar" key={`${state[opt.key]} ${index} ${percentage}` }>
                                                <div className="ct_bar_section">{index+  1}</div>
                                                <mark className="ct_report_question">{opt.opt}</mark>
                                                <div className="ct_percentage">
                                                      <span className="per_q1_A">{percentage}%</span> 
                                                      <mark className="ct_report_question_sub">
                                                                  / <span className="total_review  review_q1_A">{state[opt.key]}</span> resp.
                                                      </mark>
                                                </div>
                                                <div className="ct_progress">
                                                      <div className="ct_progress_sub progress_q1_A" style={{ width  : percentage + "%"}}>
                                                      </div>
                                                </div>      
                                          </div>
                                    )
                              })}
                        </div>
                  </div>
            </article>
      )
}

export default ArticalQuestions
