import React, { useState, useEffect } from 'react';

// external css
// redux and react hooks
import { useDispatch } from 'react-redux';

// child component
// packages
// Actions & RTK query or mutations
import { useChangePasswordMutation } from "./../../../Services/modules/settings";
import { settingsPasswordChangeValidation } from "./../../../Services";
import { setAlert } from '../../../Store/UI';
import { setIsChangePassword } from '../../../Store/User';

// Constants
import { actions, label, errors, messages, placeholders } from '../../../resources/en';
import { NameConstants } from '../../../resources/NameConstants';

const intialState = {
      [NameConstants.OldPassword]:{value:'',errorMessage:"",error:false},
      [NameConstants.NewPassword]:{value:'',errorMessage:"",error:false},
      [NameConstants.RepeatNewPassword]:{value:'',errorMessage:"",error:false}
}

const ChangePasswordForm = (props) => {

      const [state,setState] = useState(intialState)

      const [submitChangedPassword,{ data, isSuccess, isLoading, isFetching, error }]  =    useChangePasswordMutation();

      const dispatch = useDispatch();

      useEffect(() => {
            
            if(isSuccess){
                  if(data.status === "1"){
                        dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message: data.message || errors.passwordUpdated}));
                        setState(intialState)
                        dispatch(setIsChangePassword({ct_is_change_password : false}))
                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
           
      }, [data, isSuccess, isLoading, isFetching, error, dispatch])
      
      const changeStateHandler= (event)=>{

            const key = event.target.name;
            const val = event.target.value;
            
            setState(prevState=>{
                  return {
                        ...prevState,
                        [key]:{...prevState[key],value:val}
                  }
            })
      }

      const onFocusHandler = ()=>{

            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.OldPassword]:{...prevState[NameConstants.OldPassword],error:false,errorMessage:""},
                        [NameConstants.NewPassword]:{...prevState[NameConstants.NewPassword],error:false,errorMessage:""},
                        [NameConstants.RepeatNewPassword]:{...prevState[NameConstants.RepeatNewPassword],error:false,errorMessage:""},
                  }
            })

      }

      const sumbitChangePasswordHandler = (event)=>{

            event.preventDefault();
            const errorsArray = [];
            const coppyObject = {...state};
            for(let key in state){
                  
                  const res = settingsPasswordChangeValidation(state[key].value,key,state.newPassword.value);

                  if(!res.isValid){
                        errorsArray.push(key);
                        coppyObject[key] = {...coppyObject[key],error:!res.isValid,errorMessage:res.validationMessage}
                  }

            }

            if(errorsArray.length > 0){
                  setState(prevState=>{
                        return {
                              ...prevState,
                              ...coppyObject
                        }
                  })
            } else {
                  const data = {
                        current_password:state.oldPassword.value,
                        new_password:state.newPassword.value,
                        confirm_new_password:state.repeatNewPassword.value
                  }
                  submitChangedPassword(data);
            }
      }
      
      return (
            <form className='settings_change_password'>
                  <div className="form-group">
                        <label htmlFor={`myAccntChangePasswordInputOldPassword${props.type}`} className="form-label">{label[NameConstants.OldPassword]}</label>
                        <input type="password" className="form-control" id={`myAccntChangePasswordInputOldPassword${props.type}`} name={NameConstants.OldPassword} placeholder={placeholders[NameConstants.OldPassword]} value={state[NameConstants.OldPassword].value} onChange={changeStateHandler} onFocus={onFocusHandler}/>
                        {state[NameConstants.OldPassword].error &&
                        <label className="form-label form-label-error">{state[NameConstants.OldPassword].errorMessage}</label>}
                  </div>
                  <div className="form-group">
                        <label htmlFor={`myAccntChangePasswordInputNewPassword${props.type}`} className="form-label">{label[NameConstants.NewPassword]}</label>
                        <input type="password" className="form-control" id={`myAccntChangePasswordInputNewPassword${props.type}`} name={NameConstants.NewPassword} placeholder={placeholders[NameConstants.NewPassword]} value={state[NameConstants.NewPassword].value} onChange={changeStateHandler} onFocus={onFocusHandler}/>
                        {state[NameConstants.NewPassword].error &&
                        <label className="form-label form-label-error">{state[NameConstants.NewPassword].errorMessage}</label>}
                  </div>
                  <div className="form-group">
                        <label htmlFor={`myAccntChangePasswordRepeatNewPassword${props.type}`} className="form-label">{label[NameConstants.RepeatNewPassword]}</label>
                        <input type="password" className="form-control" id={`myAccntChangePasswordRepeatNewPassword${props.type}`} name={NameConstants.RepeatNewPassword} placeholder={placeholders[NameConstants.RepeatNewPassword]} value={state[NameConstants.RepeatNewPassword].value} onChange={changeStateHandler} onFocus={onFocusHandler}/>
                        {state[NameConstants.RepeatNewPassword].error &&
                        <label className="form-label form-label-error">{state[NameConstants.RepeatNewPassword].errorMessage}</label>}
                  </div>
                  <div className="form-group form-group__cta">
                        <button type="button" className="btn btn-xl-lg btn-primary" onClick={sumbitChangePasswordHandler} disabled={isLoading}>
                              {actions.changePassword}
                        </button>
                  </div>
            </form>
      )
}

export default ChangePasswordForm
