export const referralCode =  build =>
      build.mutation({
            query: (body) =>  ({
                  url: '/referralCode',
                  method: 'POST',
                  body: body
            }),
      })


      // Changed with new one