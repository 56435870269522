// //  Saved Card Page
// export const DataObjects = {
//       categories: [
//             { filter_type: "0", id: "0", lable: "All", index: "2", default: true,selected:false },

//             { filter_type: "-2", id: "3", lable: "Online", index: "5",selected:false },
//             { filter_type: "-3", id: "3", lable: "New", index: "5",selected:false },
//             { filter_type: "-2", id: "4", lable: "Offers", index: "6",selected:false },
//             { filter_type: "2", id: "61", lable: "Theatre", index: "7",selected:false },
//             { filter_type: "2", id: "19", lable: "Music", index: "8",selected:false },
//             { filter_type: "2", id: "21", lable: "Walks & Talks", index: "9",selected:false },
//             { filter_type: "2", id: "37", lable: "Performance", index: "10",selected:false },
//             { filter_type: "2", id: "26", lable: "Movies", index: "11",selected:false },
//             { filter_type: "2", id: "60", lable: "Comedy", index: "12",selected:false },
//             { filter_type: "2", id: "9", lable: "Classical Music", index: "13",selected:false },
//             { filter_type: "2", id: "33", lable: "Cabaret", index: "14",selected:false },
//             { filter_type: "2", id: "25", lable: "LGBTQ+", index: "8",selected:false },
//             { filter_type: "3", id: "11", lable: "Today", index: "16",selected:false },
//             { filter_type: "3", id: "12", lable: "Tomorrow", index: "17",selected:false },
//             { filter_type: "3", id: "13", lable: "This Weekend", index: "18",selected:false },
//             { filter_type: "3", id: "15", lable: "Matinee", index: "20",selected:false },
//             { filter_type: "3", id: "16", lable: "Evening", index: "21",selected:false }
//       ],
//       region: [
//             { filter_type: "-1", id: "4", lable: "London", index: "1",selected:false, value : "4"  },
//             { filter_type: "-1", id: "8", lable: "South East England", index: "1",selected:false, value : "8" },
//             { filter_type: "-1", id: "1", lable: "East Anglia", index: "1",selected:false, value : "1" },
//             { filter_type: "-1", id: "9", lable: "South West England", index: "1",selected:false, value : "9" },
//             { filter_type: "-1", id: "10", lable: "West Midlands", index: "1",selected:false, value : "10" },
//             { filter_type: "-1", id: "2", lable: "East Midlands", index: "1",selected:false, value : "2" },
//             { filter_type: "-1", id: "6", lable: "North West England", index: "1",selected:false, value : "6" },
//             { filter_type: "-1", id: "11", lable: "Yorkshire & Humberside", index: "1",selected:false, value : "11" },
//             { filter_type: "-1", id: "5", lable: "North England", index: "1",selected:false, value : "5" },
//             { filter_type: "-1", id: "12", lable: "Wales", index: "1",selected:false, value : "12" },
//             { filter_type: "-1", id: "7", lable: "Scotland", index: "1",selected:false, value : "7" },
//             { filter_type: "-1", id: "3", lable: "Northern Ireland", index: "1",selected:false, value : "3" },
//       ],
//       sortby: [
//             { filter_type: "4", id: "1", lable: "Sort By A-Z", index: "16",selected:false, value : "1" },
//             { filter_type: "4", id: "2", lable: "Sort By Z-A", index: "16",selected:false, value : "2" },
//       ],
// }


// export  default DataObjects;















//  Saved Card Page tenporary change object
export const DataObjects = {
      categories: [
            
      ],
      region: [
      ],
      // sortby: [
      //       { filter_type: "4", id: "1", lable: "Sort By A-Z", index: "16",selected:false, value : "1" },
      //       { filter_type: "4", id: "2", lable: "Sort By Z-A", index: "16",selected:false, value : "2" },
      // ],
      sortby: [
            { filter_type: "4", id: "1", lable: "Sort By A-Z", index: "16",selected:false, value : "1" },
            { filter_type: "4", id: "2", lable: "Sort By Z-A", index: "16",selected:false, value : "2" },
            { filter_type: "4", id: "3", lable: "Price - Low to High", index: "16",selected:false, value : "3" },
            { filter_type: "4", id: "4", lable: "Price - High to Low", index: "16",selected:false, value : "4" },
            { filter_type: "4", id: "5", lable: "Date - Closest", index: "16",selected:false, value : "5" },
            { filter_type: "4", id: "6", lable: "Date - Furthest", index: "16",selected:false, value : "6" },
      ]
}


export  default DataObjects;