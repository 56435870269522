import React from 'react'

const NHSCardBodyDummy = () => {
      return (
            <div className="card card__uptake card__uptake--thank-you border-0 bg-primary border-radius-10 uptakeVenue-nhscardbody__dummy">
            </div>
      )
}

export default NHSCardBodyDummy
