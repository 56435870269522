import React from 'react'
import ChangePasswordForm from '../Components/ChangePasswordForm';
import { useSelector } from 'react-redux';

const ChangePassword = () => {

      const ct_is_change_password = useSelector(state=>state.user.ct_is_change_password)
     
      return (
                  <div className="accordion-item accordion-item__ChangePassword">
                        {/* <!-- amas = accordion My Account Settings --> */}
                        <h2 className="accordion-header" id="amasHeading1">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#amasCollapse1" aria-expanded="false" aria-controls="amasCollapse1" >
                                    <span className="btn__text">Change Password</span>
                              </button>
                        </h2>

                        <div id="amasCollapse1" className="accordion-collapse collapse show" aria-labelledby="amasHeading1">
                              <div className="accordion-body">
                                    {ct_is_change_password && <h2 className="accordion-header is_password_change" id="amasHeading1">
                                          <button className="accordion-button" type="button"  >
                                                Your account security has been enhanced! For your safety, we recommend updating your password to ensure your information remains secure.
                                          </button>
                                    </h2> }                                  
                                    <div className="content">
                                          <ChangePasswordForm type="mobile"/>
                                    </div>
                                    {/* <!-- /.content --> */}

                              </div>
                              {/* <!-- /.accordion-body --> */}

                        </div>
                        {/* <!-- /.accordion-collapse --> */}

                  </div>
      )
}

export default ChangePassword;
