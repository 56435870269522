import React, { useState, useRef  } from 'react';

// external css
// redux and react hooks
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// child component
// import RebrandingAssetsHomePageSlider from "../../../assets/images/Rebranding_assets_home_page_slider.jpg"

import ticketHomeCarouselItem1Yellow from "../../../assets/images/tickets/ticket-home-carousel-item1-yellow.svg"
import ticketHomeCarouselItem4YellowSm from "../../../assets/images/tickets/ticket-home-carousel-item4-yellow-sm.svg"

import ticketHomeCarouselItem2Blue from "../../../assets/images/tickets/ticket-home-carousel-item2-blue.svg"
import ticketHomeCarouselItem4BlueSm from "../../../assets/images/tickets/ticket-home-carousel-item4-blue-sm.svg"

import ticketHomeCarouselItem2Aquamarine from "../../../assets/images/tickets/ticket-home-carousel-item2-aquamarine.svg"
import ticketHomeCarouselItem4AquamarineSm from "../../../assets/images/tickets/ticket-home-carousel-item4-aquamarine-sm.svg"

import ticketHomeCarouselItem2Green from "../../../assets/images/tickets/ticket-home-carousel-item2-green.svg"
import ticketHomeCarouselItem4GreenSm from "../../../assets/images/tickets/ticket-home-carousel-item4-green-sm.svg"

import { PictureComponent } from '../../../Components';
import ItemComponent from './ItemComponent';
import HeroBody from './HeroBody';

// packages
import OwlCarousel from 'react-owl-carousel';

// Actions & RTK query or mutations
// Constants
import { SectionHomeHearoOptions } from '../../../resources/OwlCarouselOptions';
import { routesName } from '../../../resources/RoutesName';
import { URL } from '../../../resources/Url';
import { Config } from '../../../Config';
const SectionHomeHearo = () => {
     
      const [autoPlay, setAutoPlay] = useState(true); // Initial autoplay timeout in milliseconds
      const [currentSlide, setCurrentSlide] = useState(0)
      const domain      =     useSelector(state=>state.user.domain)
      const carouselRef = useRef(null);

      const handleNext = ()=>{      
            if(carouselRef.current){
                  carouselRef.current.next();
                  setAutoPlay(false); // Set autoplay timeout to 0 to stop autoplay temporarily
                  setTimeout(() => {
                        setAutoPlay(true); // Set autoplay timeout back to true to resume autoplay
                  }, 100)
                  // handleSlideChanged({ item: { index: currentSlide + 1 } }, 'next'); // Call handleSlideChanged with action 'prev'
                  handleSlideChanged({ item: { index: currentSlide + 1 } }); // Call handleSlideChanged with action 'prev'

            }
      }

      const handleSlideChanged = (event) => {

            // if (action === 'prev') {
            //       console.log('Previous button clicked');
            // } else if (action === 'next') {
            //       console.log('Next button clicked');
            // }
            setCurrentSlide(event.item.index);
            // console.log(event,action)
            // if (event.property.name === 'position') {
            //       console.log(action)
            //       setCurrentSlide(event.item.index);
            //       // console.log(event)
            // } 
            //     else if (event.property === 'autoplay') {
            //       console.log('Slide changed due to autoplay');
            //     }

      };

      const handlePrev = ()=> {
            if(carouselRef.current){
                  carouselRef.current.prev();
                  setAutoPlay(false); // Set autoplay timeout to 0 to stop autoplay temporarily
                  setTimeout(() => {
                        setAutoPlay(true); // Set autoplay timeout back to true to resume autoplay
                  }, 100)
                  handleSlideChanged({ item: { index: currentSlide - 1 } })
                  // handleSlideChanged({ item: { index: currentSlide - 1 } }, 'prev')
            }
      }

      let siteName = "Central Tickets"
      if(domain === Config.DOMAIN_CONSTANT_TFM) {
            siteName = "Tickets for Medics"
      }

      return (

            <section data-scroll-section className="section__home-hero border-bottom py-0">
            {/* <!--
            NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
            --> */}
                  <div className="container-fluid container__block">
                        <div className="row">
                              <div className="col-md-12">
                                    <div className="content content__carousel-home-hero">
                                          <div className="content__body position-relative">
                                                <OwlCarousel className='owl-carousel owl-carousel__home-hero owl-theme'  {...SectionHomeHearoOptions}
                                                // onChanged={handleSlideChanged}
                                                mergeFit={true} 
                                                startPosition={currentSlide}
                                                autoplay={autoPlay} ref={carouselRef}>
                                                {/* <div className="owl-carousel owl-carousel__home-hero owl-theme"> */}

                                                      {domain === Config.DOMAIN_CONSTANT_TFM &&
                                                      <ItemComponent className="item2" srcSet={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/downlod_the_app.jpg`} src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/downlod_the_app.jpg`}>

                                                            <div className="hero-header">
                                                                  <h1>Cheap Tickets for NHS Staff</h1>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-aquamarine.svg" src="ticket-home-carousel-item2-aquamarine-sm.svg" className="ticket-aquamarine"/>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-blue.svg" src="ticket-home-carousel-item2-blue-sm.svg" className="ticket-blue"/>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-green.svg" src="ticket-home-carousel-item2-green-sm.svg" className="ticket-green"/>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-yellow.svg" src="ticket-home-carousel-item2-yellow-sm.svg" className="ticket-yellow"/>
                                                                  
                                                            </div>
                                                            <HeroBody>
                                                                  Cheap show tickets for NHS Staff & verified Healthcare Key Workers
                                                            </HeroBody>
                                                      </ItemComponent>}

                                                      {/* <!-- item 1 --> */}
                                                      <ItemComponent className="item1" srcSet={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/become_a_seat_filler.jpg`} src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/become_a_seat_filler.jpg`}>
                                                            <div className="hero-header">
                                                                  <div className="big-heading">
                                                                        <h1>Become <br/>a seat filler</h1>
                                                                        <PictureComponent srcSet="ticket-home-carousel-item1-blue.svg" src="ticket-home-carousel-item1-blue-sm.svg" className="ticket-blue"/>

                                                                        <PictureComponent srcSet="ticket-home-carousel-item1-green.svg" src="ticket-home-carousel-item1-green-sm.svg" className="ticket-green"/>

                                                                        <PictureComponent srcSet="ticket-home-carousel-item1-aquamarine.svg" src="ticket-home-carousel-item1-aquamarine-sm.svg"
                                                                        className="ticket-aquamarine"/>

                                                                        <PictureComponent srcSet="ticket-home-carousel-item1-yellow.svg" src="ticket-home-carousel-item1-yellow-sm.svg"
                                                                        className="ticket-yellow"/>
                                                                        
                                                                  </div>
                                                            </div>
                                                            <HeroBody>
                                                                  Enjoy tickets at £4-15 for theatre, music, dance, opera, classical music, talks & movies by becoming a seat filler. It’s free to join – sign up today.
                                                            </HeroBody>
                                                            
                                                            <div className="hero-cta">
                                                                  <div className="buttons-wrapper">
                                                                        <Link className="btn btn-xl-lg btn-primary" to={routesName.Register}>
                                                                              Join us
                                                                        </Link>
                                                                        <Link className="btn btn-xl-lg btn-primary" to={routesName.Login}>
                                                                              Login
                                                                        </Link>
                                                                  </div>
                                                            </div>
                                                      </ItemComponent>

                                                      {/* <!-- item 2 --> */}
                                                      {domain === "" &&
                                                      <ItemComponent className="item2" srcSet={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/downlod_the_app.jpg`} src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/downlod_the_app.jpg`}>

                                                            <div className="hero-header">
                                                                  <h1>Download <br className="d-none d-xl-block"/>the app</h1>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-aquamarine.svg" src="ticket-home-carousel-item2-aquamarine-sm.svg" className="ticket-aquamarine"/>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-blue.svg" src="ticket-home-carousel-item2-blue-sm.svg" className="ticket-blue"/>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-green.svg" src="ticket-home-carousel-item2-green-sm.svg" className="ticket-green"/>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-yellow.svg" src="ticket-home-carousel-item2-yellow-sm.svg" className="ticket-yellow"/>
                                                                  
                                                            </div>
                                                            <HeroBody>
                                                                  Download our App on the App Store or Google Play to book tickets, update your account and manage your bookings easily and quickly.
                                                            </HeroBody>
                                                            
                                                            <div className="hero-cta">
                                                                  <div className="buttons-wrapper two-buttons">
                                                                        <a className="btn btn-xl-lg btn-primary has-icon"  href={URL.APPLE_STORE} rel="noreferrer" target="_blank">
                                                                              <span className="icon-wrapper icon-apple-as-bg"></span>
                                                                              <span className="btn__text">Apple Store</span>
                                                                        </a>
                                                                        <a className="btn btn-xl-lg btn-primary has-icon" href={URL.GOOGLE_PLAY}  rel="noreferrer" target="_blank">
                                                                              <span className="icon-wrapper icon-googleplay-as-bg"></span>
                                                                              <span className="btn__text">Google Play</span>
                                                                        </a>
                                                                  </div>
                                                            </div>
                                                      </ItemComponent>}

                                                      {/* <!-- item 3 --> */}
                                                      <ItemComponent className="item3" srcSet={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/discover_new_show.jpg `} src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/discover_new_show.jpg`}>
                                                            <div className="hero-header">
                                                                  <h1>Discover <br className="d-none d-xl-block"/>new shows</h1>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item1-yellow.svg" src="ticket-home-carousel-item3-yellow-sm.svg" className="ticket-yellow"/>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-blue.svg" src="ticket-home-carousel-item3-blue-sm.svg" className="ticket-blue"/>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-aquamarine.svg" src="ticket-home-carousel-item3-aquamarine-sm.svg" className="ticket-aquamarine"/>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-green.svg" src="ticket-home-carousel-item3-green-sm.svg" className="ticket-green"/>
                                                                  
                                                            </div>
                                                            <HeroBody>
                                                                  You’ll have the opportunity to enjoy hundreds of different shows every week from our wide range of events and ticket partners.
                                                            </HeroBody>
                                                            
                                                            <div className="hero-cta">
                                                                  <div className="buttons-wrapper">
                                                                        <Link className="btn btn-xl-lg btn-primary" to={routesName.Register}>
                                                                              Join us
                                                                        </Link>
                                                                  </div>
                                                            </div>

                                                      </ItemComponent>

                                                      {/* <!-- item 4 --> */}
                                                      <ItemComponent className="item4" srcSet={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/nhs_help.jpg`} src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/nhs_help.jpg`}>
                                                            <div className="hero-header">
                                                                  <h1>Helping <br/>the NHS</h1>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item1-yellow.svg" src="ticket-home-carousel-item4-yellow-sm.svg" className="ticket-yellow"/>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-blue.svg" src="ticket-home-carousel-item4-blue-sm.svg" className="ticket-blue"/>
                                                                  
                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-aquamarine.svg" src="ticket-home-carousel-item4-aquamarine-sm.svg" className="ticket-aquamarine"/>

                                                                  <PictureComponent srcSet="ticket-home-carousel-item2-green.svg" src="ticket-home-carousel-item4-green-sm.svg" className="ticket-green"/>
                                                                  
                                                            </div>
                                                            <HeroBody>
                                                                  {siteName} works in support of NHS Charities Together who support 230+ NHS Charities to help the NHS go further for staff and patients.
                                                            </HeroBody>
                                                           
                                                            <div className="hero-cta">
                                                                  <div className="buttons-wrapper">
                                                                        <Link className="btn btn-xl-lg btn-primary" to={routesName.AboutUs}>
                                                                              Learn more
                                                                        </Link>
                                                                  </div>
                                                            </div>

                                                      </ItemComponent>

                                                      {/* <!-- item 5 --> */}
                                                      <div className="item item5">
                                                            <div className="hero">
                                                                  <div className="hero__content">
                                                                        <div className="hero-header">
                                                                              <h1>Climate <br/>Positive</h1>
                                                                              
                                                                              <picture className="ticket ticket-yellow">
                                                                                    <source media="(min-width: 992px)" 
                                                                                    srcSet={ticketHomeCarouselItem1Yellow} />
                                                                                    <img src={ticketHomeCarouselItem4YellowSm}
                                                                                    alt=""/>
                                                                              </picture>

                                                                              <picture className="ticket ticket-blue">

                                                                                    <source media="(min-width: 992px)" srcSet={ticketHomeCarouselItem2Blue} />
                                                                                    <img src={ticketHomeCarouselItem4BlueSm}
                                                                                    alt=""/>

                                                                              </picture>

                                                                              <picture className="ticket ticket-aquamarine">
                                                                                    <source media="(min-width: 992px)" srcSet={ticketHomeCarouselItem2Aquamarine} />
                                                                                    <img src={ticketHomeCarouselItem4AquamarineSm} alt=""/>
                                                                              </picture>
                                                                              <picture className="ticket ticket-green">
                                                                                    <source media="(min-width: 992px)" srcSet={ticketHomeCarouselItem2Green} />
                                                                                    <img src={ticketHomeCarouselItem4GreenSm}
                                                                                    alt=""/>
                                                                              </picture>
                                                                        </div>
                                                                        <div className="hero-body">
                                                                              <p className="lead">Each time you make a booking, we off-set the carbon emissions for your journey through Gold Standard carbon avoidance and reforestation projects via Ecologi.</p>
                                                                        </div>
                                                                        <div className="hero-cta">
                                                                              <div className="buttons-wrapper">
                                                                                    <Link className="btn btn-xl-lg btn-primary" to={routesName.AboutUs} state={{scroll :"sectionEcologi"}}>
                                                                                          Learn more
                                                                                    </Link>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.hero__content --> */}
                                                                  <div className="hero__image">
                                                                        <img className="carousel-image object-fit" 
                                                                        srcSet={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/climate_postive.jpg 2x`}
                                                                        src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/climate_postive.jpg`}
                                                                        // srcSet=
                                                                        // {`${RebrandingAssetsHomePageSlider} 2x`}
                                                                        // src={RebrandingAssetsHomePageSlider}
                                                                         alt=""/>
                                                                  </div>
                                                                  {/* <!-- /.hero__image --> */}
                                                            </div>
                                                            {/* <!-- /.hero --> */}
                                                      </div>
                                                      {/* <!-- /.item --> */}
                                                      
                                                {/* </div> */}
                                                </OwlCarousel>
                                                {/* <!-- /.owl-carousel__home-hero --> */}

                                                {/* <!--
                                                      - Put owl carousel nav outside outside .owl-carousel wrapper
                                                      - must add "navContainer: '#owlHomeHeroNav'" to customscripts.js
                                                --> */}
                                                {/* <div id="owlHomeHeroNav" className="owl-nav">

                                                
                                                </div> */}
                                                <div id="owlHomeHeroNav" className="owl-nav">
                                                </div>
                                                <div className='custom-nav'>
                                                      <button type="button" role="presentation" className="owl-prev" onClick={()=>handlePrev('prev')}>
                                                            <span aria-label="Previous">‹</span>
                                                      </button>
                                                      <button type="button" role="presentation" className="owl-next" onClick={()=>handleNext('next')}>
                                                            <span aria-label="Next">›</span>
                                                      </button>
                                                </div>

                                          </div>
                                          {/* <!-- /.content__body --> */}
                                    </div>
                                    {/* <!-- /.content --> */}
                              </div>
                              {/* <!-- .col-md-12 --> */}
                        </div>
                        {/* <!-- /.row --> */}
                  </div>
                  {/* <!-- /.container-fluid  --> */}
            </section>
                             
      )
}

export default SectionHomeHearo;
