import React, { useState, useEffect } from  'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
import { 
      BookingSuccess, BookingTimeOut,
      ChooseDate, OrderSummary,
      OrderSummaryPay,
      PaymentMethod,
      PaymentViaCard, PaymentViaWallet,
      PaymentViaWalletPlusCard
} from "./Steps";
import Loader from '../../../Ui/BookingModuleLoader';
import FlexiTerms from './Modal/FlexiTerms';
// packages
import { osName  } from "react-device-detect";

// Actions & RTK query or mutations
import { useTicketReleaseMutation } from '../../../Services/modules/BookingModule';
import { setBookingHandler } from '../../../Store/EventDetail';
import { setBookingStep, setGetBookingDetails, resetForAnimation, setGroupAvailability, setEventId, setTicketTypes } from "../../../Store/BookingModule";
import { setTimer, setSpinner } from '../../../Store/UI';
import { setAlert } from '../../../Store/UI';
import { setSessionBlockId } from '../../../Store/User';

// Constants
import { errors, messages } from '../../../resources/en';
import { NameConstants } from '../../../resources/NameConstants';

const BookingModule = (props) => {

      const [flexiModal,setFlexiModal]          =     useState(false);

      const [seconds,setSeconds]                =     useState(0);
      const [minutes,setMinutes]                =     useState(0);
      const [pauseTimer,setPauseTimer]          =     useState(false);
      const [timerRelease,setTimerRelease]      =     useState(false);
      const bookingModule                       =     useSelector(state=>state.bookingModule);
      const ui                                  =     useSelector(state=>state.ui);
      const user                                =     useSelector(state=>state.user);
      const eventDetail                         =     useSelector(state=>state.eventDetail);

      const dispatch                            =     useDispatch();

      const [ticketRelease, { data, isSuccess, isLoading, isFetching, error }]  =    useTicketReleaseMutation();

      useEffect(()=>{
            
            if(eventDetail.eventInfo && bookingModule.step !== NameConstants.bookingModuleSteps.bookingSuccess){
                  
                  const event_id = props.eventId 
                  const group_availablity = eventDetail.eventInfo.group_availablity;
                  const ticketTypes = eventDetail.eventInfo.group_availablity !==2 ? [eventDetail.eventInfo.tickets_type] : [];
                  dispatch(setEventId({event_id:event_id,title:eventDetail.eventInfo.title}));
                  dispatch(setGroupAvailability({group_availablity:group_availablity,step: NameConstants.bookingModuleSteps.chooseDate ,ticketTypes:ticketTypes}))
                  dispatch(setSpinner({status : ""}));
            }
               

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[dispatch,eventDetail.eventInfo, props.eventId])

      // Clear state and relese tickets while unmount component
      useEffect(()=>{

            return ()=> {
                  
                  closeTimerHandler();
                  // dispatch(reset())
                  // dispatch(setBookingHandler({isBooking : false}));
            }  

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      

      useEffect(()=>{
            if(isSuccess){
                  // Call get User Wallet Balance API  to update wallet balance
                  dispatch(setSessionBlockId({ct_session_block_id: ""}));
                  dispatch(setSpinner({status : ""}));
                  dispatch(resetForAnimation())

                  if(!user.ct_web_view_session){
                        dispatch(setTicketTypes({ticketTypes : []}))
                  }
                  
                  if(timerRelease){
                        setTimerRelease(false)
                        dispatch(setBookingStep({step : NameConstants.bookingModuleSteps.bookingTimeOut }))
                        
                  } else {
                        dispatch(setBookingHandler({isBooking : false}));
                  }
                  closeTimerHandler();
                  resumeTimerHandler()
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error, dispatch])

      
      useEffect(()=>{

            let myInterval = setInterval(() => {
                  if(pauseTimer){
                        return
                  }

                  if (seconds > 0) {
                        setSeconds(seconds-1)
                  }
                  if (seconds === 0) {
                        if (minutes === 0) {
                              clearInterval(myInterval)
                              if(ui.timer === "start"){

                                    // updateWalletBalance();
                                    setTimerRelease(true);
                                    const ct_session_block_id          =     user.ct_session_block_id;
                                    if(ct_session_block_id){
                                          let ticket_type_master_id = getTicketTypeMasterIdHandler();
                                          ticketRelease({block_id:ct_session_block_id,group_availablity:bookingModule.group_availablity,ticket_type_master_id :ticket_type_master_id});
                                    }
                                    else {
                                          dispatch(closeTimerHandler());
                                          dispatch(resetForAnimation());
                                          if(!user.ct_web_view_session){
                                                dispatch(setTicketTypes({ticketTypes : []}))
                                          }

                                          dispatch(setBookingStep({step : NameConstants.bookingModuleSteps.bookingTimeOut }))
                                          dispatch(setSpinner({status:""}))
                                    }
                              }

                        } else {
                              setMinutes(minutes-1)
                              setSeconds(59)
                        }
                  } 
            }, 1000)

            return ()=>{
                  clearInterval(myInterval);
            }
      })

      const startTimerHandler=()=>{
            setMinutes(5);
            setSeconds(0);
            dispatch(setTimer({timer:"start"}));
            resumeTimerHandler()
            // setMinutes(0);
            // setSeconds(20);
      }

      const closeTimerHandler = ()=>{
            setMinutes(0);
            setSeconds(0);
            dispatch(setTimer({timer:null}));
            resumeTimerHandler()
      }

      const pauseTimerHandler = ()=> {
            setPauseTimer(true)
      }

      const resumeTimerHandler = ()=> {
            setPauseTimer(false)
      }

      const closeBookingHanler = ()=>{

            // updateWalletBalance();
            dispatch(setGetBookingDetails({getBookingDetails: null }))
            
            const ct_session_block_id          =     user.ct_session_block_id;
            if(ct_session_block_id){
                  let ticket_type_master_id          =     getTicketTypeMasterIdHandler();
                  ticketRelease({block_id:ct_session_block_id,group_availablity:bookingModule.group_availablity,ticket_type_master_id :ticket_type_master_id});
            } else {
                  closeTimerHandler();
                  dispatch(setSpinner({status : ""}));
                  dispatch(resetForAnimation());
                  if(!user.ct_web_view_session){
                        dispatch(setTicketTypes({ticketTypes : []}))
                  }
                  dispatch(setBookingHandler({isBooking : false}));
            }

      }

      // const updateWalletBalance = ()=> {
      //       if(bookingModule.payment.useWalletCredit > 0){
      //             dispatch(setWalletBalance({ct_wallet_balance : parseFloat(user.ct_wallet_balance) + parseFloat(bookingModule.payment.useWalletCredit)}))
      //       }
      // }

      const getTicketTypeMasterIdHandler = ()=> {
            let ticket_type_master_id = ""
            // if(bookingModule.group_availablity ===2){
            //       bookingModule.ticketTypes.forEach(el=>{
            //             if(el.selected) {
            //                   ticket_type_master_id = el.ticket_type_master_id;
            //             } 
                              
            //       })
            // }
            if(bookingModule.group_availablity ===2){
                  ticket_type_master_id = bookingModule.performanceInfo ? bookingModule.performanceInfo.ticket_type_master_id : ""
            }

            return ticket_type_master_id;
      }

      const flexiModalHandler = ()=> {
            setFlexiModal(!flexiModal)
      }

      let titleText = "Book your tickets";

      if(bookingModule.step === NameConstants.bookingModuleSteps.paymentMethod || bookingModule.step === NameConstants.bookingModuleSteps.paymentViaWallet ||  bookingModule.step === NameConstants.bookingModuleSteps.paymentViaCard || bookingModule.step === NameConstants.bookingModuleSteps.paymentViaWalletPlusCard ){
            titleText = "Payment details"
      } 

      let  subTotalDisplayOnBookingModule = parseFloat(bookingModule.bookingDetails.subTotal).toFixed(2);
      if(bookingModule.bookingDetails.promocode.status === "valid"){
            let charity = 0;
            if(bookingModule.bookingDetails.charity){
                  charity = NameConstants.charity;
            }
            subTotalDisplayOnBookingModule = parseFloat(bookingModule.bookingDetails.promocode.totalAmount + bookingModule.bookingDetails.flexAmount + charity).toFixed(2)
      }


      // asign class to mobile device for androis and ios
      let mobileBottomPadding = "mobile-bottom-padding"
      let IosIssueForApp = ""
      let IphonePadding = ""
      
      
      if(osName ==="iOS") {
            IphonePadding = "iphone-extra-padding"
            mobileBottomPadding = "mobile-bottom-padding-ios"
            IosIssueForApp = "ios-app-mobile-bottom-padding"
      }

      if(osName === "Mac OS") {
            mobileBottomPadding = "mobile-bottom-padding-ios-mac"
      }

      return (
            <>
            {flexiModal && <FlexiTerms closeFlexiModal={flexiModalHandler}/>}
            <div className={["slidein-wrapper show booking-module-slidein-wrapper",props.show === "exiting" && "close",props.show === "exited" && "close"].join(" ")}>
                  <div className={["slidein slidein__book-tickets",user.ct_web_view_session ? IosIssueForApp : ""].join(" ")}>
          
                        <div className={["content__slidein content-scrollable content-scrollable-in-steps",IphonePadding,!user.ct_web_view_session ? mobileBottomPadding : " "].join(" ")} >
          
                              <form>
                                    <div className="content content__checkout">
                                          {(bookingModule.step !== NameConstants.bookingModuleSteps.bookingSuccess &&  bookingModule.step !== NameConstants.bookingModuleSteps.bookingTimeOut) &&
                                          <div className="content__header border-bottom position-relative">
                                                {!user.ct_web_view_session && <button type="button" className="btn-close btn-close__checkout btn-close__custom btn-close__absolute" aria-label="Close" onClick={closeBookingHanler}>
                                                </button>}
                                                <div className="content my-auto">
                                                      <h4 id="contentTitle" className="content__title my-auto mb-0">
                                                            {titleText} - £{subTotalDisplayOnBookingModule}   
                                                            {ui.timer === "start" && <span className="common_timer">{'('+('0' + minutes).slice(-2)+':'+('0' + seconds).slice(-2)+')'}</span>}
                                                      </h4>
                                                </div>
                                          </div>}
                                          {/* <!-- /.content__header --> */}
          
                                          {/* <!-- STEP 1: Choose date --> */}
                                          {bookingModule.step === NameConstants.bookingModuleSteps.chooseDate &&
                                          <ChooseDate startTimer={startTimerHandler} eventId={props.eventId} flexiModalHandler={flexiModalHandler}/>}
          
                                          {/* <!-- STEP 2: Order Summary --> */}
                                          {bookingModule.step === NameConstants.bookingModuleSteps.orderSummary &&
                                          <OrderSummary closeTimerHandler={closeTimerHandler}/>} 

                                          {/* <!-- STEP 3: Payment Methods --> */}
                                          {bookingModule.step === NameConstants.bookingModuleSteps.paymentMethod &&
                                          <PaymentMethod/>}
          
                                          {/* <!-- STEP 4: Payment Via Wallet --> */}
                                          {bookingModule.step === NameConstants.bookingModuleSteps.paymentViaWallet &&
                                          <PaymentViaWallet/>}
          
                                          {/* <!-- STEP 4: Payment Via Card --> */}
                                          {bookingModule.step === NameConstants.bookingModuleSteps.paymentViaCard &&
                                          <PaymentViaCard closeTimerHandler={closeTimerHandler} pauseTimerHandler={pauseTimerHandler} resumeTimerHandler={resumeTimerHandler}/>}
          
                                          {/* <!-- STEP 4: Payment Via Wallet Plus Card --> */}
                                          {bookingModule.step === NameConstants.bookingModuleSteps.paymentViaWalletPlusCard &&
                                          <PaymentViaWalletPlusCard pauseTimerHandler={pauseTimerHandler} resumeTimerHandler={resumeTimerHandler}/>}

                                          {bookingModule.step === NameConstants.bookingModuleSteps.orderSummaryPay &&
                                          <OrderSummaryPay closeTimerHandler={closeTimerHandler} pauseTimerHandler={pauseTimerHandler} resumeTimerHandler={resumeTimerHandler}/>}
          
                                          {/* <!-- LAST STEP : Order Summary #bookTicketsBookingSuccess --> */}
                                          {bookingModule.step === NameConstants.bookingModuleSteps.bookingSuccess &&
                                          <BookingSuccess/>}
          
                                          {/* <!-- BOOKING TIMEOUT : Order Summary #bookTicketsBookingTimeout --> */}
                                          {bookingModule.step === NameConstants.bookingModuleSteps.bookingTimeOut &&
                                          <BookingTimeOut/>}

                                          {/* before omiting this code but loader sometims ugly because of not loaded so now manage property display */}
                                          <Loader show={ui.spinner === "request"}/>
                                          
          
                                    </div>
                                    {/* <!-- /.content__checkout --> */}
          
                              </form>
                             
          
                        </div>
                        {/* <!-- /.content__slidein --> */}
          
                  </div>
                  {/* <!-- /.slidein --> */}
                  
            </div>
            </>
      )
}

export default BookingModule;
