import React, { useState, useEffect } from 'react'

// external css
// redux and react hooks
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// child component
import Backdrop from '../Backdrop'

// packages
// Actions & RTK query or mutations
import { useOtpVerificationMutation } from "./../../Services/modules/Otp"
import { otpVerificationValidationRules } from "./../../Services"
import { setAlert, setOtpModalStatus, setResetPasswordCode, setCtChangingMobileNumber, setOtpEntityId, setSpinner } from './../../Store/UI'
import { logout } from '../../Store/User'
import { api } from '../../Services/Api'

// Constants
import { label, errors, messages } from "./../../resources/en"
import { routesName } from '../../resources/RoutesName'

const OtpMyAccountModal = (props) => {

      const [state,setState] = useState('')
      const [frontError,setFrontError]    =     useState({error:false,errorMessage:""})
      const [seconds,setSeconds]          =     useState(0)
      const [minutes,setMinutes]          =     useState(0)
      const [isSentOtp,setIsSentOtp]      =     useState(true)
      
      const dispatch                      =     useDispatch()
      const ui                            =     useSelector(state=>state.ui)
      const user                          =     useSelector(state=>state.user)
      const navigate                      =     useNavigate()

      const [otpVerification, { data, isSuccess, isLoading, isFetching, error }]    =     useOtpVerificationMutation();


      useEffect(()=>{
            
            setMinutes(1);
            setSeconds(0);
            setIsSentOtp(false)

            return ()=> {
                  resetStates();
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{
            let myInterval
            if(!isSentOtp){

                  myInterval = setInterval(() => {
                        if (seconds > 0) {
                              setSeconds(seconds-1)
                        }
                        if (seconds === 0) {
                              if (minutes === 0) {
                                    clearInterval(myInterval)
                                    setIsSentOtp(true);
                              } else {
                                    setMinutes(minutes-1)
                                    setSeconds(59)
                              }
                        } 
                  }, 1000)
            }

            return ()=>{
                  clearInterval(myInterval);
            }
      })

      // Verify OTP Response Handler
      useEffect(()=>{
            //  Handling verify OTP API Request
            if(isSuccess){
                  dispatch(setSpinner({status : ""}))
                  if(data.status === "1"){

                        dispatch(setOtpEntityId({ otp_entity_id : ""}))
                        resetStates();
                        dispatch(setCtChangingMobileNumber({ct_changing_mobile_number : ""}))
                        const status = ui.ct_otp_modal_status === "otp" ? "mobile_verified" : ""
                        dispatch(setOtpModalStatus({status : status}))

                        if(ui.ct_otp_modal_status !== "otpForgotPassword"){
                              dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:data.message || errors.NotFound404}))
                        }
                        
                        if(ui.ct_otp_modal_status === "otpForgotPassword"){
                              dispatch(setResetPasswordCode({ct_reset_password_code : data.data.code}));
                              navigate(routesName.ResetPassword)
                        } 
                        if(status !== "mobile_verified") {
                              dispatch(api.util.resetApiState());
                              console.log("logout using otp my account modal")
                              dispatch(logout());
                        }
                  }

                  if(data.status === "0"){
                        
                        setFrontError(prevState=>{
                              return {
                                    error:true,
                                    errorMessage:data.message
                              }
                        })
                  }
            } 
           
            if(error){
                  dispatch(setSpinner({status : ""}))
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error, dispatch])

      const resetStates = ()=>{
            setSeconds(0)
            setMinutes(0)
            setIsSentOtp(true)
            setState('')
            setFrontError({error:false,errorMessage:""})
      }

      const focusHandler = ()=>{
            setFrontError(prevState=>{
                  return {
                        error:false,
                        errorMessage:""
                  }
            })
      }

      const closeModalHandler = (event)=>{
            event.preventDefault();
            dispatch(setOtpModalStatus({status : ""}))
      }

      const changeHandler = (event)=> {

            const value = event.target.value.replace(/[^\d]/g, ''); // Only allow digits
            if (value.length <= 5) { // Limit to 5 characters
                  setState(value)
            }

      }

      // const sendOTPHandler = (event)=>{
      //       event.preventDefault();
            
      //       console.log("resend OTP")
      //       // const  type = ui.ct_otp_modal_status === "otp" ? "mobile" : "email"
      //       // sendOTPToVerify({ type:type });
      // }

      const submitOtpHandler = (event)=>{
            event.preventDefault();

            


            let otp = state;

            const validation = otpVerificationValidationRules(otp,ui.ct_otp_modal_status);
            if(!validation.isValid){
                  setFrontError({error:true,errorMessage:validation.validationMessage})
                  return;
            }
            
            if(validation.isValid && otp.length === 5){
                    
                  let type= "";
                  let workflow = ""
                  let email = ""
                  if(ui.ct_otp_modal_status === "otp") {
                        type = "phone"
                        workflow = "change_phonenumber"
                  } else if (ui.ct_otp_modal_status === "otpEmail") {
                        type = "email"
                        workflow = "change_email"
                  } else if(ui.ct_otp_modal_status === "otpForgotPassword") {
                        type = "email"
                        workflow = "forget_password"
                        email = user.ct_access_email ? user.ct_access_email : ""
                  }

                  if(ui.ct_otp_modal_status === "otpForgotPassword") {
                        dispatch(setSpinner({status : "scrolling"}))
                  }
                  
                  otpVerification({
                        "otp_entity_id" : ui.otp_entity_id || "",
                        "otp":otp,
                        "type":type,
                        "workflow":workflow,
                        "email": email
                  })
            }
      }

      const renderInputBox = () => {
            // Fill empty spots with dots
            const otpArray = state.split('');
            for (let i = otpArray.length; i < 5; i++) {
                  otpArray.push('.');
            }
            return otpArray.map((char, index) => (
                  <span key={index} className={["otp-char",char === '.' && "otp-char-asteric"].join(" ")}>
                  {char === '.' ? '*' : char}
                  </span>
            ));
      };

      return (

                  <React.Fragment>
                        <div className={["modal modal__registration-verification show fade",(props.status === "entering" || props.status === "entered") && "modalOpen",(props.status === "exiting" || props.status === "exited") && "modalClosed", props.isAjtix && "ajtix-wrapper", props.isTfm && "tfm-wrapper"].join(" ")} id="modalVerificationCode" tabIndex="-1" aria-hidden="true" style={{"display":"block"}}>
                              <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                          <div className="modal-body">
                                                {(ui.ct_otp_modal_status === "otp" || ui.ct_otp_modal_status === "otpForgotPassword" )&&
                                                <button type="button" className="btn-close btn-close__custom btn-close__absolute" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalHandler}>
                                                </button>}

                                                <div className="content">
                                                      <div className="content__body">
                                                            <form>
                                                                  <p className="mb-0">
                                                                  
                                                                        {ui.ct_otp_modal_status === "otp" ? label.otpVerifyModal.replace('{{mobile}}',ui.ct_changing_mobile_number) : label.otpChangeEmailVerifyModal}
                                                                  </p>
                                                                  <div id="otp" className={["form-group__verification-code d-flex justify-content-center text-center",frontError.error ? "form-group__invalid-verification-code" : ""].join(" ")}>
                                                                        <div className="otp-input-wrapper">
                                                                              <input id="otpFirstChar" name="otpFirstChar" type="text" className="form-control otp-hidden-input" maxLength="5" autoFocus onChange={changeHandler} value={state} onFocus={focusHandler}/>
                                                                              <div className="otp-display">
                                                                                    {renderInputBox()}
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  {frontError.error && <p className="invalid-otp">{frontError.errorMessage}</p>}
                                                                  <div className="form-group__cta d-flex justify-content-center">
                                                                        <button type="submit" className="btn btn-xl-lg btn-primary" disabled={isLoading} onClick={submitOtpHandler}>Verify</button>
                                                                  </div>
                                                                  {/* {(isSentOtp && ui.ct_otp_modal_status === "otp") && 
                                                                        <p className="mb-0 text-center">Didn’t receive it? <Link className="blue" to="" onClick={sendOTPHandler}>Send it again</Link></p>}
                                                                  {(!isSentOtp && ui.ct_otp_modal_status === "otp") && 
                                                                        <p className="mb-0 text-center">Didn’t receive it? <Link className="blue" to="" onClick={(e)=>{e.preventDefault()}}>Send it again in {String(minutes).padStart(2, '0') +":"+ String(seconds).padStart(2, '0')}</Link></p>} */}
                                                            </form>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}

                                          </div>
                                          {/* <!-- /.modal-body --> */}

                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>
                        {/* <!-- modal #modalVerificationCode --> */}
                        <Backdrop />
                  </React.Fragment>
                 
      )
}

export default OtpMyAccountModal;
