import React from 'react'

const UptakeCardCarouselDummy = () => {
      return (
            <div className="card card__uptake card__uptake--carousel border-1 border-radius-10 p-0 uptake-cardcarousel__dummy">
                  
            </div>
      )
}

export default UptakeCardCarouselDummy
