import React from 'react';
import "./CurrentListingItemDummy.css"

const CurrentListingItemDummy = (props) => {

      return (
            <div className="item CurrentListingItemDummy">
                  <div className="card card__event">
                        <div className="card__event-image">
                              <ul className="list__event-tags">
                                    <li>
                                          <div className="badge"></div>
                                    </li>
                                    <li>
                                          <div className="badge"></div>
                                    </li>
                              </ul>
                              <div>
                                    <div  className="event-image"></div>
                              </div>
                        </div>
                        <div className="card-body">
                              <h5 className="card-title fw-bold">
                              </h5>
                              <div className="list-group list-group__event-details list-group-flat">
                                    <div className="list-group-item list-group__location">
                                          <div className="icon-wrapper">
                                          </div>
                                          <p className="mb-0">{props.address}</p>
                                    </div>
                                    <div className="list-group-item list-group__time">
                                          <div className="icon-wrapper">
                                          </div>
                                          <p className="mb-0">{props.event_date_time}</p>
                                    </div>
                                    <div className="list-group-item list-group__price">
                                          <div className="icon-wrapper">
                                          </div>
                                          <p className="mb-0"></p>
                                    </div>
                              </div>
                        </div>
                        {/* <!-- /.card-body --> */}
                        <div className="card-footer">
                              <div className="buttons-wrapper d-flex align-items-center justify-content-between">
                                    <button className="btn btn-outline-primary hover-primary">
                                    </button>
                                    <button className="btn-heart"></button>
                              </div>
                        </div>
                        {/* <!-- /.card-footer --> */}
                  </div>
                  {/* <!-- /.card --> */}
            </div>
      )
}
export default CurrentListingItemDummy;
