import React from 'react'

const UptakeStatusWrapperDummy = () => {
      return (
                  <div className="status-wrapper d-flex flex-shrink-0 align-items-center uptake-statuwrapper__dummy">
                  </div>
      )
}

export default UptakeStatusWrapperDummy
