import React, { useEffect, useRef } from 'react'

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

// child component
import MessageReceivedModal from '../../Ui/Modal/MessageReceivedModal';
import { 
      ContentHeader, ContentBodyQueCat, ContentBodySelectShow, 
      ContentBodyEventDetails, ContentBodyFaq, ContactUsFormUserContainer
} from "./Components";
import Footer from '../../Layouts/Footer/Footer';

// packages
import { Transition } from 'react-transition-group';
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { reset, setStep, setUserType } from '../../Store/ContactUs';
import { updateLocoScroll } from '../../Store/UI';

// Constants
import { NameConstants } from '../../resources/NameConstants';
import { ContentBodyQueCatArray } from "../../resources/ContactUsObject";
import { MetaData, MetaDataAj, MetaDataTfm } from '../../resources/MetaData';
import { Config } from '../../Config';

const ContactUs = () => {

      const contactUs         =     useSelector(state=>state.contactUs)
      const { auth, domain }  =     useSelector(state=>state.user)
      const dispatch          =     useDispatch();
      const { state }         =     useLocation();
      const nodeRef           =     useRef(null);
      const navigate          =     useNavigate()

      useEffect(()=>{

            document.title = "Contact Us";
            if(auth && contactUs.step === NameConstants.UserCat){
                  dispatch(setUserType({userType : NameConstants.Member}))
                  dispatch(setStep({step : NameConstants.MemberBookingQue}))
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])


      useEffect(()=>{

            if(state){
                  dispatch(setStep({ step : NameConstants.OrganizerShowForm }))
                  dispatch(setUserType({ userType : NameConstants.Organizer}))
                  navigate('', { replace : true })
                  dispatch(updateLocoScroll({ locoScroll: { change:true, type:'update' } }))
            }
            // reset contact us redux slice when component unmount
            return ()=>{
                  dispatch(reset());
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[dispatch])

      useEffect(()=>{
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
      },[contactUs.step, dispatch])

      let metaDataSite = MetaData
      if(domain === Config.DOMAIN_CONSTANT_TFM){
            metaDataSite = MetaDataTfm
      } else if(domain === Config.DOMAIN_CONSTANT_AJTIX){
            metaDataSite = MetaDataAj
      }

      return (
                  <>
                        
                        {/* <!-- REQUIRED wrapper div for parallax --> */}
                        <div className={["scroll-animations position-relative"].join(" ")} data-scroll-container>
                              <Helmet>
                                    <title>{metaDataSite.Contact.title}</title>
                                    <meta name="description" content={metaDataSite.Contact.description} />
                                    <meta name="keywords" content={metaDataSite.Contact.keywords} />
                              </Helmet>
                              <main className="main__contact-us">

                                    <section data-scroll-section className="section__contact-us py-0">
                                          {/* <!--
                                                NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                                          --> */}
                                          <div className="container-fluid container__block">
                                                <div className="row">
                                                      <div className="col-md-12">
                                                            <div className="content content__contact-us mx-auto">
                                                                  <ContentHeader />

                                                                  { ContentBodyQueCatArray.includes(contactUs.step) && <ContentBodyQueCat />}

                                                                  {(contactUs.step === NameConstants.MemberSelectShow) && <ContentBodySelectShow/>}

                                                                  {(contactUs.step === NameConstants.MemberEventDetail) && <ContentBodyEventDetails/>}

                                                                  {(contactUs.step === NameConstants.MemberFaq) && <ContentBodyFaq/>}
                                                                  
                                                            </div>
                                                            {/* <!-- .content.content__contact-us --> */}

                                                      </div>
                                                      {/* <!-- .col-md-12 --> */}

                                                </div>
                                                {/* <!-- /.row --> */}

                                          </div>
                                          {/* <!-- /.container-fluid  --> */}

                                          {(contactUs.step === NameConstants.MemberContactForm) && <ContactUsFormUserContainer/>}

                                    </section>
                                    {/* <!-- /.section__contact-us --> */}

                              </main>

                              <Footer/>
                        </div>
                        {/* <!-- /div[data-scroll-container] --> */}
                        
                        <Transition in={contactUs.ct_contact_modal_status === "messageReceived"} timeout={500} mountOnEnter unmountOnExit nodeRef={nodeRef}>
                              {status => {
                                          return <MessageReceivedModal status={status}/> 
                                    }
                              }
                        </Transition>

                  </>
      )
}

export default ContactUs;
