import React, { useEffect, useState } from 'react';

// external css
import "./SelectNHSCityRole.css"
// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';

// child component
import Backdrop from '../Backdrop';

// packages
import TomSelect from 'tom-select';
// Actions & RTK query or mutations
import { setNhsRoleCity } from '../../Store/User';
import { logout } from '../../Store/User';
import { useUpdateTfmDetailsMutation } from '../../Services/modules/Tfm';
import { setAlert } from '../../Store/UI';

// Constants
import { joinValidationRules } from '../../Services/JoinService';
import { NameConstants } from '../../resources/NameConstants';
import { placeholders, messages, errors } from '../../resources/en';

const SelectNHSCityRole = (props) => {

      const [state,setState]= useState({
            // nhsTrust:{value:"",error:false,errorMessage:"Please select NHS trust you work for."},
            [NameConstants.NearestCity]:{value:"",error:false,errorMessage:"Please select your nearest city"},
            [NameConstants.Role]:{value:"",error:false,errorMessage:"Please enter your role"}
      })

      const user        =     useSelector(state=>state.user)
      const dispatch    =     useDispatch();

      const [UpdateTfmDetails, {data, isLoading, isSuccess, isFetching, error}] = useUpdateTfmDetailsMutation()

      useEffect(()=>{
            setState(prevState=> {

                  return {
                        ...prevState,
                        [NameConstants.NearestCity]: {...prevState[NameConstants.NearestCity],value : user.nhs_city},
                        [NameConstants.Role]: {...prevState[NameConstants.Role],value : user.nhs_role}
                  }
            })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{
            new TomSelect('#SelectNearestCity',{
                  plugins: ['remove_button'],
                  create: false,
                  maxOptions:150,
                  sortField: {
                        field: "text"
                  },
                  items:[user.auth && user.nhs_city],
                  openOnFocus:false,
                  onType:(str)=>{
                        if(str.length > 1){
                             document.querySelector("#SelectNearestCity + .ts-wrapper .ts-dropdown").style.display = "block"
                        }
                        else{
                              document.querySelector("#SelectNearestCity + .ts-wrapper .ts-dropdown").style.display = "none"
                        }
                  },	
                  render:{
                        no_results:function(data,escape){
                              document.querySelector("#SelectNearestCity + .ts-wrapper .ts-dropdown").style.border = "0px"
                        }
                  }	
            });
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      //Handler response of update Tfm Details API
      useEffect(()=>{
            if(isSuccess){
                  dispatch(setNhsRoleCity({nhs_city : state[NameConstants.NearestCity].value,nhs_role : state[NameConstants.Role].value}))
                  dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:data.error || data.errors || data.message}))
            }

            if(error) {
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.errors || error.data.message || errors.NotFound404}))

            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error])

      const changeHandler = (event)=>{
           
            const name = event.target.name;
            let value = event.target.value;

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:{...prevState[name],value:value}
                  }
            })
      }

      const focusHandler = ()=>{

            setState(prevState=>{

                  return {
                        ...prevState,
                        // nhsTrust:{...prevState.nhsTrust,error:false},
                        [NameConstants.NearestCity]:{...prevState[NameConstants.NearestCity],error:false},
                        [NameConstants.Role]:{...prevState[NameConstants.Role],error:false},
                  }
            })
      }

      const closePopHandler = ()=>{
            dispatch(logout())
      }



      const submitSelectedOptionsHandler = (event)=>{
            event.preventDefault();
           
            const errorStore = [];
            const copy = {

            }
            for(let key in state)
            {
                  const errorCheck = joinValidationRules(state[key].value,key,);

                  if(!errorCheck.isValid)
                  {
                        errorStore.push(key);
                  }       
                  copy[key] = {...state[key],error:!errorCheck.isValid,errorMessage:errorCheck.validationMessage}
                        
            }
           
           
            if(errorStore.length > 0)
            {
                  setState(prevState=>{
                        return {
                              ...prevState,
                              ...copy
                        }
                  })
            }
            else
            {
                  const data = {
                        role:state[NameConstants.Role].value,
                        city:state[NameConstants.NearestCity].value,
                        nhs_trust:state.nhsTrust ? state.nhsTrust.value : user.nhs_trust
                  }

                  UpdateTfmDetails(data)
            }
      }

      return (

                  <React.Fragment>
                        <div className={["modal modal__registration-verification show fade modal__select-nhs-city-role",(props.status === "entering" || props.status === "entered") && "modalOpen",(props.status === "exiting" || props.status === "exited") && "modalClosed"].join(" ")} id="modalVerificationCode" tabIndex="-1" aria-hidden="true" style={{"display":"block"}}>
                              <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                          <div className="modal-body">
                                                
                                                <button type="button" className="btn-close btn-close__custom btn-close__absolute" data-bs-dismiss="modal" aria-label="Close" onClick={closePopHandler}>
                                                </button>

                                                <div className="content">
                                                      <div className="content__body">
                                                                  <h1 className="fw-bold">Your NHS Role</h1>
                                                            <form onSubmit={submitSelectedOptionsHandler}>
                                                                  <div className="row">
                                                                        <div className="col-lg-12">
                                                                              <div className="form-group">
                                                                                    <label htmlFor="joinInputRole" className="form-label fw-normal">Role</label>
                                                                                    <input type="text" className="form-control form-control-xl-lg" id="joinInputRole" name={NameConstants.Role} value={state[NameConstants.Role].value} onChange={changeHandler} onFocus={focusHandler} disabled={false} readOnly={false} placeholder={placeholders[NameConstants.Role]}/>
                                                                                    {state[NameConstants.Role].error && <div className="invalid-feedback" style={{"visibility": state[NameConstants.Role].error && "visible"}}>{state[NameConstants.Role].errorMessage}</div>}
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                        <div className="col-lg-121">
                                                                              <div className="form-group" onFocus={focusHandler}>
                                                                                    <label htmlFor="SelectNearestCity" className="form-label fw-normal">Nearest City</label>
                                                                                    <select className="form-control tomselect tomselect-xl-lg" id="SelectNearestCity"  autoComplete="off"  name={NameConstants.NearestCity} value={state[NameConstants.NearestCity].value}  onChange={changeHandler} disabled={false} placeholder={placeholders[NameConstants.NearestCity]}>
                                                                                          {/* <option value="0" hidden>Select City</option> */}
                                                                                          <option value="1">Aberdeen</option>
                                                                                          <option value="2">Armagh</option>
                                                                                          <option value="3">Bangor</option>
                                                                                          <option value="4">Barnsley</option>
                                                                                          <option value="5">Basildon</option>
                                                                                          <option value="6">Basingstoke</option>
                                                                                          <option value="7">Bath</option>
                                                                                          <option value="8">Bedford</option>
                                                                                          <option value="9">Belfast</option>
                                                                                          <option value="10">Birkenhead</option>
                                                                                          <option value="11">Birmingham</option>
                                                                                          <option value="12">Blackburn</option>
                                                                                          <option value="13">Blackpool</option>
                                                                                          <option value="14">Bolton</option>
                                                                                          <option value="15">Bournemouth</option>
                                                                                          <option value="16">Bradford</option>
                                                                                          <option value="17">Brighton</option>
                                                                                          <option value="18">Brighton and Hove</option>
                                                                                          <option value="19">Bristol</option>
                                                                                          <option value="20">Cambridge</option>
                                                                                          <option value="21">Canterbury</option>
                                                                                          <option value="22">Cardiff</option>
                                                                                          <option value="23">Carlisle</option>
                                                                                          <option value="24">Chelmsford</option>
                                                                                          <option value="25">Cheltenham</option>
                                                                                          <option value="26">Chester</option>
                                                                                          <option value="27">Chesterfield</option>
                                                                                          <option value="28">Chichester</option>
                                                                                          <option value="29">Colchester</option>
                                                                                          <option value="30">Coventry</option>
                                                                                          <option value="31">Crawley</option>
                                                                                          <option value="32">Darlington</option>
                                                                                          <option value="33">Derby</option>
                                                                                          <option value="34">Doncaster</option>
                                                                                          <option value="35">Dundee</option>
                                                                                          <option value="36">Durham</option>
                                                                                          <option value="37">Eastbourne</option>
                                                                                          <option value="38">Edinburgh</option>
                                                                                          <option value="39">Ely</option>
                                                                                          <option value="40">Exeter</option>
                                                                                          <option value="41">Gateshead</option>
                                                                                          <option value="42">Gillingham</option>
                                                                                          <option value="43">Glasgow</option>
                                                                                          <option value="44">Gloucester</option>
                                                                                          <option value="45">Grimsby</option>
                                                                                          <option value="46">Halifax</option>
                                                                                          <option value="47">Hartlepool</option>
                                                                                          <option value="48">Hastings</option>
                                                                                          <option value="49">Hemel Hempstead</option>
                                                                                          <option value="50">Hereford</option>
                                                                                          <option value="51">High Wycombe</option>
                                                                                          <option value="52">Huddersfield</option>
                                                                                          <option value="53">Inverness</option>
                                                                                          <option value="54">Ipswich</option>
                                                                                          <option value="55">Kingston upon Hull</option>
                                                                                          <option value="56">Lancaster</option>
                                                                                          <option value="57">Leeds</option>
                                                                                          <option value="58">Leicester</option>
                                                                                          <option value="59">Lichfield</option>
                                                                                          <option value="60">Lincoln</option>
                                                                                          <option value="61">Lisburn</option>
                                                                                          <option value="62">Liverpool</option>
                                                                                          <option value="63">London</option>
                                                                                          <option value="64">Londonderry</option>
                                                                                          <option value="65">Luton</option>
                                                                                          <option value="66">Maidstone</option>
                                                                                          <option value="67">Manchester</option>
                                                                                          <option value="68">Middlesbrough</option>
                                                                                          <option value="69">Milton Keynes</option>
                                                                                          <option value="70">Newcastle upon Tyne</option>
                                                                                          <option value="71">Newport</option>
                                                                                          <option value="72">Newry</option>
                                                                                          <option value="73">Northampton</option>
                                                                                          <option value="74">Norwich</option>
                                                                                          <option value="75">Nottingham</option>
                                                                                          <option value="76">Nuneaton</option>
                                                                                          <option value="77">Oldham</option>
                                                                                          <option value="78">Oxford</option>
                                                                                          <option value="79">Peterborough</option>
                                                                                          <option value="80">Plymouth</option>
                                                                                          <option value="81">Poole</option>
                                                                                          <option value="82">Portsmouth</option>
                                                                                          <option value="83">Preston</option>
                                                                                          <option value="84">Raleigh</option>
                                                                                          <option value="85">Reading</option>
                                                                                          <option value="86">Ripon</option>
                                                                                          <option value="87">Rochdale</option>
                                                                                          <option value="88">Rotherham</option>
                                                                                          <option value="89">Sale</option>
                                                                                          <option value="90">Salford</option>
                                                                                          <option value="91">Salisbury</option>
                                                                                          <option value="92">Sheffield</option>
                                                                                          <option value="93">Slough</option>
                                                                                          <option value="94">Solihull</option>
                                                                                          <option value="95">Southampton</option>
                                                                                          <option value="96">Southend-on-Sea</option>
                                                                                          <option value="97">Southport</option>
                                                                                          <option value="98">St Albans</option>
                                                                                          <option value="99">St Davids</option>
                                                                                          <option value="100">St Helens</option>
                                                                                          <option value="101">Stevenage</option>
                                                                                          <option value="102">Stirling</option>
                                                                                          <option value="103">Stockport</option>
                                                                                          <option value="104">Stoke-on-Trent</option>
                                                                                          <option value="105">Sunderland</option>
                                                                                          <option value="106">Sutton Coldfield</option>
                                                                                          <option value="107">Swansea</option>
                                                                                          <option value="108">Swindon</option>
                                                                                          <option value="109">Telford</option>
                                                                                          <option value="110">Truro</option>
                                                                                          <option value="111">Wakefield</option>
                                                                                          <option value="112">Warrington</option>
                                                                                          <option value="113">Watford</option>
                                                                                          <option value="114">Wells</option>
                                                                                          <option value="115">Westminster</option>
                                                                                          <option value="116">Weston-super-Mare</option>
                                                                                          <option value="117">Wigan</option>
                                                                                          <option value="118">Winchester</option>
                                                                                          <option value="119">Woking</option>
                                                                                          <option value="120">Wolverhampton</option>
                                                                                          <option value="121">Worcester</option>
                                                                                          <option value="122">Worthing</option>
                                                                                          <option value="123">York</option>
                                                                                    </select>
                                                                                    {state[NameConstants.NearestCity].error && <div className="invalid-feedback">{state[NameConstants.NearestCity].errorMessage}</div>}
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>

                                                                  </div> 
                                                                  
                                                                  <div className="row">
                                                                        <div className="col-md-12">
                                                                              <div className="form-group form-group__buttons d-flex flex-column flex-xl-row">
                                                                                    <button type="submit" className="btn btn-primary fw-bold w-xl-50" onClick={submitSelectedOptionsHandler} disabled={isLoading ||  isFetching}>
                                                                                          Submit
                                                                                    </button>
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                  </div>
                                                                  
                                                            </form>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}

                                          </div>
                                          {/* <!-- /.modal-body --> */}

                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>
                        {/* <!-- modal #modalVerificationCode --> */}
                        <Backdrop />
                  </React.Fragment>
                 
      )
}

export default SelectNHSCityRole;