import React from 'react';
import "./EventDetailImageDummy.css";

const EventDetailCollectionInstructionDummy = () => {
      return (
            <div className='collection-instruction-dummy'>
                  
            </div>
      )
}

export default EventDetailCollectionInstructionDummy
