import React from 'react';
import "./OwlCarouselEventsItemDummy.css";

const OwlCarouselEventsItemDummy = () => {

      return (

            <div className="item OwlCarouselEventsItemDummy">
                 <div className="card card__event p-0">
                        <div className="card__event-image">
                             <ul className="list__event-tags">
                                   <li>
                                         <div className="badge badge-red"></div>
                                   </li>
                              </ul>
                              <div>
                                    <div className="event-image" src=""></div>
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default OwlCarouselEventsItemDummy;
