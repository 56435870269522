import { api } from "./../../../Services/Api";
import { getPerformanceInfo } from "./getPerformanceInfo";
import { applyPromocode } from "./applyPromocode";
import { ticketBlock } from "./ticketBlock";
import { ticketRelease } from "./ticketRelease";
import { findTicketType } from "./findTicketType";
import { getTicketTypePerformance } from "./getTicketTypePerformance";
import { getDetailsOfTicketTypePerf } from "./getDetailsOfTicketTypePerf";
import { cardIdentify } from "./cardIidentify";
import { getBookingDetails } from "./getBookingDetails";
import { getSessionKeyDetails } from "./getSessionKeyDetails";
import { widgetSessionForWebView } from "./widgetSessionForWebView";

export const BookingModuleApi = api.injectEndpoints({
      endpoints: build => ({
            getPerformanceInfo:getPerformanceInfo(build), 
            applyPromocode : applyPromocode(build), /* correct */
            ticketBlock : ticketBlock(build), /* correct */
            ticketRelease : ticketRelease(build), /* correct */
            findTicketType : findTicketType(build), /* correct to fetch ticket types for particular event call when choosedate mout */
            getTicketTypePerformance : getTicketTypePerformance(build), /* correct to fetch schedules for selected ticket type */
            getDetailsOfTicketTypePerf : getDetailsOfTicketTypePerf(build), /* to fetch performance for selected schedule for ticket type is same as getPerformanceInfo */
            cardIdentify : cardIdentify(build), /*correct to submit final booking */
            getBookingDetails : getBookingDetails(build), /*correct to get booking details */
            getSessionKeyDetails : getSessionKeyDetails(build), /*correct to get web view details */
            widgetSessionForWebView : widgetSessionForWebView(build)
      }),
      overrideExisting: true
})

export const {  
      useGetPerformanceInfoMutation, useApplyPromocodeMutation, 
      useTicketBlockMutation, useTicketReleaseMutation, 
      useFindTicketTypeMutation, useGetTicketTypePerformanceMutation ,
      useGetDetailsOfTicketTypePerfMutation, 
      useCardIdentifyMutation, useGetBookingDetailsMutation,
      useLazyGetSessionKeyDetailsQuery,
      useWidgetSessionForWebViewMutation
} = BookingModuleApi;