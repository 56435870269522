import { api } from "./../../../Services/Api";
import { setMemberData } from "./setMemberData"

export const moreinfowApi = api.injectEndpoints({
            endpoints: build => ({
                  setMemberData : setMemberData(build),

      }),
      overrideExisting: true
})

export const {  useSetMemberDataMutation } = moreinfowApi