
import { validation } from "../resources/en";
import { NameConstants } from "../resources/NameConstants";

const settingsChangeMobileNumberValidation=(value,type,oldNumber) =>{
      let isValid = true;
      let validationMessage = "";
      
      const textMessage = validation;
     
      if(type === NameConstants.ChangeMobileNumber){

            let isnum = /^\d+$/.test(value);
            if(value.trim()=== "" || !value) {
                  isValid =false;
                  validationMessage =  textMessage[type].required;
                  return {isValid,validationMessage};
                  
            }
            if(value.trim()=== "07"){
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }

            if(value.length !== 11 || !isnum ){
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }

            if(value.substring(0, 3) === "070") {
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }

            if(value === oldNumber) {
                  isValid =false;
                  validationMessage =  textMessage[type].match;
                  return {isValid,validationMessage};
            }


             
      }

      return {isValid,validationMessage};
}

export default settingsChangeMobileNumberValidation;