import React from 'react';
import Backdrop from '../Backdrop';

const AlmostDoneModal = (props) => {

      return (
                  <React.Fragment>
                        <div className={["modal modal__form-modal modal__almost-done show fade",(props.status === "entering" || props.status === "entered") && "modalOpen",(props.status === "exiting" || props.status === "exited") && "modalClosed"].join(" ")} id="modalAlmostDone" style={{"display":"block"}} tabIndex="-1" aria-hidden="true">
                              <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                          <div className="modal-body d-flex align-items-center justify-content-center">
                                                <div className="content">
                                                      <div className="content__body text-center">
                                                            <h2>Almost<br/>done!</h2>
                                                            <p>Check your email and click<br/> on the link to confirm your account.</p>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}

                                          </div>
                                          {/* <!-- /.modal-body --> */}
                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>
                        <Backdrop/>
                  </React.Fragment>
      )
}

export default AlmostDoneModal;
