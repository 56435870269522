/* eslint-disable */
import React, { useState, useEffect } from 'react';

// external css
// redux and react hooks
import  { useSelector, useDispatch } from "react-redux";

// child component
// packages
// Actions & RTK query or mutations♦
// import { useChangeEmailMutation } from "./../../../Services/modules/settings";
import { useUpdateProfileMutation } from '../../../Services/modules/settings';

import { setAlert, setOtpEntityId, setOtpModalStatus } from '../../../Store/UI';

// Constants
import { settingsChangeEmailValidation } from "../../../Services";
import { actions, errors, label, messages, placeholders } from '../../../resources/en';
import { NameConstants } from '../../../resources/NameConstants';

const intialstate = {
      [NameConstants.OldEmail]:{value:"",error:false,errorMessage:""},
      [NameConstants.NewEmail]:{value:"",error:false,errorMessage:""},
      [NameConstants.RepeatNewEmail]:{value:"",error:false,errorMessage:""},
}

const ChangeEmailForm = () => {

      const [state,setState]                    =     useState(intialstate);
      const user                                =     useSelector(state=>state.user);
      const dispatch                            =     useDispatch();

      // const [submitChangedEmail,{ data, isSuccess, isLoading, isFetching, error }]  =    useChangeEmailMutation();
      const [updateProfile,{ data, isSuccess, isLoading, isFetching, error }]  =    useUpdateProfileMutation();
      
      useEffect(()=>{
            setState(prevState=>{
                  return {
                        ...prevState,
                        [NameConstants.OldEmail]:{...prevState[NameConstants.OldEmail],value:user.ct_access_email}
                  }
            })

      },[user.ct_access_email])


      useEffect(()=>{

            if(isSuccess){
                  if(data.status === "1"){
                        setState(prevState=>{
                              return {
                                    ...prevState,
                                    [NameConstants.NewEmail]:{value:"",error:false,errorMessage:""},
                                    [NameConstants.RepeatNewEmail]:{value:"",error:false,errorMessage:""},
                              }
                        })
                        dispatch(setOtpEntityId({ otp_entity_id : data?.data?.otp_entity_id || "" }))
                        dispatch(setOtpModalStatus({status : "otpEmail"}))

                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }

      },[data, isSuccess, isLoading, isFetching, error, dispatch])


      const onChangeHandler = (event)=>{

            const type = event.target.name;
            const value = event.target.value;

            setState(prevState=>{
                  return {
                        ...prevState,
                        [type]:{...prevState[type],value:value}
                  }
            })
      }

      const onFocusHandler = ()=>{
            setState(prevState=>{
                  return {
                        ...prevState,
                        [NameConstants.OldEmail]: {...prevState[NameConstants.OldEmail],error:false,errorMessage:""},
                        [NameConstants.NewEmail]: {...prevState[NameConstants.NewEmail],error:false,errorMessage:""},
                        [NameConstants.RepeatNewEmail]:{...prevState[NameConstants.RepeatNewEmail],error:false,errorMessage:""}
                  }
            })
      }

      const submitHandler = (event)=>{
            event.preventDefault();

            const errorsArray = [];
            const coppyObject = {...state};
            for(let key in state){
                  
                  const res = settingsChangeEmailValidation(state[key].value,key,state.oldEmail.value,state.newEmail.value);
                  if(!res.isValid){
                        errorsArray.push(key);
                        coppyObject[key] = {...coppyObject[key],error:!res.isValid,errorMessage:res.validationMessage}
                  }
            }

            if(errorsArray.length > 0) {

                  setState(prevState=>{
                        return {
                              ...prevState,
                              ...coppyObject
                        }
                  })

            } else {
                  const names = user.ct_access_name.split(" ")

                  updateProfile({
                        // mobilenumber : user.ct_access_mobilenumber,
                        // fname : names[0],
                        // lname : names[1],
                        email : state[NameConstants.NewEmail].value
                  })
            }
            
      }

      const handleCopy = (e) => {
            e.preventDefault();
      };
        
      const handlePaste = (e) => {
            e.preventDefault();
      };
        
      const handleCut = (e) => {
            e.preventDefault();
      };

      return (
            <form className='settings_change_email'>
                  <div className="form-group">
                        <label htmlFor="myAccntChangeEmailInputOldEmail" className="form-label">{label[NameConstants.OldEmail]}</label>
                        <input type="email" className="form-control" id="myAccntChangeEmailInputOldEmail"  name={NameConstants.OldEmail} value={state.oldEmail.value} onChange={onChangeHandler} onFocus={onFocusHandler} disabled readOnly
                        onCopy={handleCopy}
                        onPaste={handlePaste}
                        onCut={handleCut}
                        />
                  </div>

                  <div className="form-group">
                        <label htmlFor="myAccntChangeEmailInputNewEmail" className="form-label">{label[NameConstants.NewEmail]}</label>
                        <input type="email" className="form-control" id="myAccntChangeEmailInputNewEmail" placeholder={placeholders[NameConstants.NewEmail]} name={NameConstants.NewEmail} value={state.newEmail.value} onChange={onChangeHandler} onFocus={onFocusHandler} disabled={isLoading}
                              onCopy={handleCopy}
                              onPaste={handlePaste}
                              onCut={handleCut}
                        />
                        {state.newEmail.error &&
                        <label className="form-label form-label-error">{state.newEmail.errorMessage}</label>}
                  </div>
                  <div className="form-group">
                        <label htmlFor="myAccntChangeEmailRepeatNewEmail" className="form-label">{label[NameConstants.RepeatNewEmail]}</label>
                        <input type="email" className="form-control" id="myAccntChangeEmailRepeatNewEmail" name={NameConstants.RepeatNewEmail}  placeholder={placeholders[NameConstants.RepeatNewEmail]} value={state.repeatNewEmail.value} onChange={onChangeHandler} onFocus={onFocusHandler} disabled={isLoading}
                        onCopy={handleCopy}
                        onPaste={handlePaste}
                        onCut={handleCut}/>
                        {state.repeatNewEmail.error && 
                        <label className="form-label form-label-error">{state.repeatNewEmail.errorMessage}</label>}
                  </div>
                  <div className="form-group form-group__cta">
                        <div className="form-group form-group__cta">
                              <button type="submit" className="btn btn-xl-lg btn-primary" onClick={submitHandler} disabled={isLoading}>{actions.changeEmail}</button>
                        </div>
                  </div>
            </form>
      )
}

export default ChangeEmailForm
