import DeleteAccountContent from "../Components/DeleteAccountContent";


const DeleteAccount = ()=>{

      return (
                  <div className="tab-pane tab-pane__DeleteAccount fade show active" id="myAccntDeleteAccount" role="tabpanel" aria-labelledby="myAccntDeleteAccount-tab">
                        <div className="tab-pane__body">
                              <DeleteAccountContent />
                        </div>
                        {/* <!-- /.tab-pane__body --> */}
                  </div>
      )
}

export default DeleteAccount;