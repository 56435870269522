import React from 'react';

// external css
// redux and react hooks
import { Link } from 'react-router-dom';

// child component
import { HeadingPictureComponent } from '../../../Components';
import { ImageComponent } from '../../../Components';

// packages
// Actions & RTK query or mutations
// Constants
import { routesName } from '../../../resources/RoutesName';
// import { URL } from '../../../resources/Url';


const ForVenues = () => {

      return (
            <div className="container-fluid container__for-venues">
                  <div className="row">
                        <div className="col-md-6">

                              <div className="content content__image">
                                    <div className="content__body">
                                          <ImageComponent dataScroll className="image1" srcSet="" src="ticket-nhs-charities-together.png" type="images"/>
                                          <ImageComponent dataScroll className="image2" srcSet={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/working_in_partnership_2.jpg`} src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/working_in_partnership_2.jpg`} />
                                          <ImageComponent dataScroll className="image3" 
                                          srcSet={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/working_in_partnership.jpg`} src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/working_in_partnership.jpg`}/>
                                    </div>
                                    {/* <!-- .content__body --> */}
                              </div>
                              {/* <!-- .content --> */}
            
                        </div>
                        {/* <!-- .col-md-6 --> */}

                        <div className="col-md-6">

                              <div className="content content__for-venues">
                                    <div className="content__header">
                                          <div className="big-heading">
                                                <h6 className="sub-heading sub-heading-upper">For our event organisers</h6>
                                                <h2 className="heading mb-0">Working in partnership to generate social impact<br className="d-none d-xxl-block"/></h2>
                                          </div>
                                    </div>
                                    {/* <!-- .content__header --> */}

                                    <div className="content__body">
                                          <p>
                                                Since 2017 we have provided a free seat-filling service that connects productions with new audiences - that includes underserved groups - in a way that is socially and climate responsible.
                                          </p>
                                    </div>
                                    {/* <!-- .content__body --> */}

                                    <div className="content__cta">
                                          <Link className="btn btn-xl-lg btn-primary" to={routesName.AboutUs}>
                                                learn more
                                          </Link>
                                    </div>
                                    {/* <!-- .content__cta --> */}

                                    <ImageComponent dataScroll className="ticket ticket-blue d-lg-none" srcSet="" src="ticket-for-venues-blue-sm.svg" type="tickets"/>
                                    {/* <img data-scroll className="ticket ticket-blue d-lg-none" src={ticketForVenuesBlueSm} alt=""/> */}

                                    <HeadingPictureComponent dataScroll className="ticket-yellow" srcSet="ticket-for-venues-yellow.svg" src="ticket-for-venues-yellow-sm.svg"/>


                                    <HeadingPictureComponent dataScroll className="ticket-aquamarine" srcSet="ticket-for-venues-aquamarine.svg" src="ticket-for-venues-aquamarine-sm.svg"/>

                                    <HeadingPictureComponent dataScroll className="ticket-green" srcSet="ticket-for-venues-green.svg" src="ticket-for-venues-green-sm.svg"/>

                              </div>
                              {/* <!-- .content --> */}

                        </div>
                        {/* <!-- .col-md-6 --> */}
                  </div>
                  {/* <!-- /.row --> */}
            </div>
      )
}

export default ForVenues;
