/* eslint-disable */
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header/Header';
import { Config } from '../Config';
import { useSelector } from 'react-redux';

const MainLayout = (props) => {

      const domain =  useSelector(state=>state.user.domain)
      let classWrapper = ""
      if(domain != props.domainTemp) {
            if(props.domainTemp === Config.DOMAIN_CONSTANT_AJTIX) {
                  classWrapper = "ajtix-wrapper"
            } else if(props.domainTemp === Config.DOMAIN_CONSTANT_TFM) {
                  classWrapper = "tfm-wrapper"
            }
      } else {
            if(domain === Config.DOMAIN_CONSTANT_AJTIX) {
                  classWrapper = "ajtix-wrapper"
            } else if(domain === Config.DOMAIN_CONSTANT_TFM) {
                  classWrapper = "tfm-wrapper"
            }
      }

      return (
            // <div className={[domain === Config.DOMAIN_CONSTANT_AJTIX && "ajtix-wrapper", domain === Config.DOMAIN_CONSTANT_TFM && "tfm-wrapper"].join(" ")}>
            <div className={classWrapper}>
                  <Header/>
                  <Outlet/>
            </div>
      )
}

export default MainLayout
