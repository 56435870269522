import React from 'react'

// external css
// redux and react hooks
// child component
// packages
// Actions & RTK query or mutations
// Constants
const ratingsArray = [
      "1","2","3","4","5","6","7","8","9","10"
]

const Step2 = ({ ratingChange, rating, isLoading }) => {
      
      return (
                  <div className="card-body p-0">
                        <div className="form-group">
                              <h3 className="form-group__heading">
                                    How likely are you to recommend us to your friends
                                    or colleagues?
                              </h3>
                              <div className="list__btn-checks list__btn-checks-circle justify-content-center mx-auto">
                                    {ratingsArray.map((el,index)=>{

                                          return (
                                                <div className="btn-check-wrapper mb-0" key={`${el}${index}`} style={{ margin: "0 2px" }} onClick={()=>ratingChange(el)}>
                                                      <input className="form-check-input btn-check" type="radio" id={`radioEmotionalResponse${el}`} checked={el === rating} value={el} onChange={()=>{}} disabled={isLoading}/>
                                                      <label className="btn btn-sm btn-outline-primary rounded-circle hover-primary" htmlFor={`radioEmotionalResponse${el}`}>
                                                      {el}
                                                      </label>
                                                </div>
                                          )
                                    })}
                              </div>

                              <div className="list__btn-checks flex-column flex-md-row justify-content-center mx-auto gap_beetween_likely_and_non_likly">
                                    <div className="btn-check-wrapper mb-0 ">
                                          <p className="success-exists">
                                                Not likely at all
                                          </p>
                                    </div>
                                    <div className="btn-check-wrapper mb-0">
                                          <p className="success-exists">
                                                Extremely likely
                                          </p>
                                    </div>
                              </div>
                        </div>
                  </div>
      )
}

export default Step2
