import React, { useEffect, useState } from 'react'

// external css
// redux and react hooks
import { useDispatch } from 'react-redux';

// child component
import { SingleListBooking } from "./index";
import { BackArrow } from './index';
// packages
// Actions & RTK query or mutations
import { useLazyFetchBookingsQuery } from '../../../Services/modules/Bookings';
import { setStep } from '../../../Store/ContactUs';
import { setAlert } from '../../../Store/UI';
import { updateLocoScroll } from '../../../Store/UI';

// Constants
import { errors, messages } from '../../../resources/en';
import { NameConstants } from '../../../resources/NameConstants';

const ContentBodySelectShow = () => {

      const [scroll,setScroll]            =     useState(false);
      const [fetchBookings, { data, isSuccess, error, isLoading }]  =    useLazyFetchBookingsQuery();
      const dispatch = useDispatch();

      useEffect(()=>{
            fetchBookings({type:'contact'});
      },[fetchBookings])

      const afterLoadHandler = ()=>{
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
      }

      useEffect(()=>{
            if(isSuccess) {
                  if(data.status === "1"){
                        // console.log(data)
                  } else if(data.status === "0"){
                        setScroll(true)
                        dispatch(setStep({step : NameConstants.MemberContactForm}))
                        // dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
      
      },[data, isSuccess, error, isLoading, dispatch])

      useEffect(()=>{
            if(scroll){
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
                  setScroll(false)
            }
      },[scroll, dispatch])

      return (
            <div className="content__body">

                  <div className="content content__help-with-show mx-auto">

                        <div className="content__header border-bottom">

                              {/* Added wrapper div and backArrow */}
                              {/* <div className='content__header-logo-wrapper'> */}
                                    {/* <BackArrow /> */}
                              <h3 className="heading text-lg-center red content__header-logo-wrapper">
                                    <BackArrow /> 
                                    Select the show you need help with
                              </h3>
                              {/* </div> */}
                        </div>
                        {/* <!-- .content__header --> */}

                        <div className="content__body">

                              <ul className="list__my-bookings list-unstyled">
                                    {data && data.data.current_bookings.map(el=>{
                                          return <SingleListBooking key={el.booking_id} {...el} afterLoad={afterLoadHandler}/>
                                    })}
                                    {(isSuccess && data.data.current_bookings.length === 0) && 

                                    <div className="section__no-my-bookings">
                                          <h1>No Bookings</h1>
                                    </div>}
                              </ul>
                        </div>
                        {/* <!-- .content__body --> */}

                  </div>
                  {/* <!-- .content.content__help-with-show --> */}

            </div>
      )
}

export default ContentBodySelectShow
