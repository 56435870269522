import React from 'react'

import alterImage from "./../assets/images/alterImage.png"


const CurrentListingDummyImage = () => {

      return (
            <img className="event-image" src={alterImage} alt=''/>
      )
}

export default CurrentListingDummyImage
