import React, { useState, useEffect, useRef } from 'react'

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

// child component
import CardDeletedModal from "../../../Ui/Modal/CardDeletedModal";
import SavedCardForm from '../Components/SavedCardForm';
import Loader  from "../../../Ui/BookingModuleLoader"

// packages
import { Transition } from 'react-transition-group';

// Actions & RTK query or mutations
import { useLazyGetSessionKeyDetailsQuery } from '../../../Services/modules/BookingModule';
import { setDeteleCardModal } from '../../../Store/UI';
import { setAlert, setSpinner } from '../../../Store/UI';
import { setWebViewSession, changeCtAcessRefreshToken, setTomMember, setDarkMode } from '../../../Store/User';

// Constants
// import { routesName } from '../../../resources/RoutesName';
import { errors, messages } from '../../../resources/en';
// import { routesName } from '../../../resources/RoutesName';

const SaveCard = () => {

      const [showSaveCard,setShowSaveCard]      =     useState(false)
      const ui                                  =     useSelector(state=>state.ui)
      const user                                =     useSelector(state=>state.user)
      const { pathname,search }                 =     useLocation();
      const { token }                           =     useParams()
      const dispatch                            =     useDispatch()
      const navigate                            =     useNavigate()
      const nodeRef                             =     useRef(null)

      const [getSessionKeyDetails, { data, isSuccess, isLoading, isFetching, error }]  =    useLazyGetSessionKeyDetailsQuery();
      
      // const { pathname } = useLocation();

      useEffect(()=>{

            const searchParams = new URLSearchParams(search);  
            const darkMode = searchParams.get('darkMode')?.toString()
            console.log(darkMode, 'here')
            if(darkMode === "true") {
                  document.body.classList.add('dark-mode');
                  dispatch(setDarkMode({ct_app_dark_mode : true}))
            } else {
                  document.body.classList.remove('dark-mode');
                  dispatch(setDarkMode({ct_app_dark_mode : false}))
            }

            document.title = "Save Card";
            if(pathname.includes("payment-success") || pathname.includes("payment-failure")){

                  const searchParams = new URLSearchParams(search);
                  const client = searchParams.get('client');
                  const bookingStatus = pathname.includes("payment-success") ? "success" : "failure";
                  const message = bookingStatus === "success" ?  messages.SuccessCardStore : messages.FailureCardStore;
                  if(client === "app") {
                        // if(window.ReactNativeWebView){
                        //       window.ReactNativeWebView.postMessage(JSON.stringify({
                        //             "stay": true,
                        //             "redirect": false,
                        //             "redirectTo": "",
                        //             "status": bookingStatus === "success" ? 1 : 0,
                        //             "message": message
                        //       }));
                        // } else {
                              dispatch(setAlert({model:true,title:bookingStatus === "success" ? messages.modalSuccessTitle : messages.modalAlertTitle,message: message}))
                        // }
                  }

                  setShowSaveCard(true)
                  navigate(`/save-card/${user.ct_web_view_session}`, { replace: true });
                  dispatch(setSpinner({status : ""}));
            }
            else {
                  dispatch(setSpinner({status : "request"}));
                  getSessionKeyDetails({ session_id : token })
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[getSessionKeyDetails, token, pathname])

      useEffect(()=>{
            if(isSuccess){
                  // Call get User Wallet Balance API  to update wallet balance
                  if(data.status === "1"){
                        if(data.data.is_set_cookie === "0" || user.ct_web_view_session === token){
                              dispatch(setWebViewSession({ct_web_view_session : token }))
                              // dispatch(changeToken({ct_access_token : data.data.token})) 
                              dispatch(changeCtAcessRefreshToken({ ct_access_token : data.data.token, ct_refresh_token : data.data.refresh_token}))
                              dispatch(setTomMember({ct_is_top_member : data.data.is_top_member || 0}))  
                              dispatch(setSpinner({status : ""}));
                              setShowSaveCard(true)
                        }
                  }
                  else if(data.status === "0"){
                        // dispatch(setSpinner({status : ""}));
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.errors || data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error, dispatch])

      // useEffect(()=>{
            
            // if(pathname === routesName.SuccessSavedCard) {
            //       dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:messages.SuccessCardStore}));
            // } else if(pathname === routesName.FailureSavedCard) {
            //       dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:messages.FailureCardStore}));
            // }

      // },[pathname, dispatch])
      // },[])

      const deleteCardModalHandler = ()=> {
            dispatch(setDeteleCardModal({card:false}))
      }

      return (
                  <React.Fragment>
                        {/* <!-- REQUIRED wrapper div for parallax --> */}
                        <div className="scroll-animations position-relative" id="webViewSaveCard" data-scroll-container>

                              <main style={{paddingTop:'0px',"minHeight" : "40vh"}}>

                                    <section data-scroll-section className="section__my-account  pt-0">
                                          <div className="container-fluid container__block">
                                                <div className="row">
                                                      <div className="col-md-12">
                                                            <div className="content content__my-account mx-auto">
                                                                  <div className="content__header">
                                                                        <div className="big-heading">
                                                                              <h1 className="h4 mb-0">Save Card</h1>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- .content__header --> */}
                                                                  <div className="content__body">
                                                                        <div className="accordion accordion__my-account-settings accordion__circle-arrow d-md-none">
                                                                              <div className="accordion-item accordion-item__SavedCards" style={{border : 'none'}}>
                                                                                    <div id="amasCollapse5" className="accordion-collapse collapse show" aria-labelledby="amasHeading5">
                                                                                          <div className="accordion-body">
                                                                                                {showSaveCard &&  <SavedCardForm mobile/> }
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- .content__body --> */}
                                                            </div>
                                                            {/* <!-- .content__my-account --> */}
                                                      </div>
                                                      {/* <!-- .col-md-12 --> */}
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </div>
                                          {/* <!-- /.container-fluid  --> */}
                                    </section>
                                    {/* <!-- /.section__my-account --> */}

                              </main>
                        </div>
                        <div className='section__web-view-save-card' style={{display : ui.spinner === "request" ? "" :"none"}}>
                              <Loader show={ui.spinner === "request"}/>
                        </div>
                        {/* <!-- /div[data-scroll-container] --> */}
                        <Transition in={ui.deleteCardModal} timeout={500} mountOnEnter unmountOnExit nodeRef={nodeRef}>
                              {status => {
                                          return <CardDeletedModal status={status} deleteCardModalHandler={deleteCardModalHandler}/> 
                                    }
                              }
                        </Transition>
                        
                  </React.Fragment>
      )
}

export default SaveCard;



