import React from 'react'

// external css
import iconArrowLeft35X24 from "../../../assets/images/icon-arrow-left-35x24-black.svg";
import iconArrowLeft23X16 from "../../../assets/images/icon-arrow-left-23x16-black.svg";

// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
// packages
// Actions & RTK query or mutations
import { setStep } from '../../../Store/ContactUs';

// Constants
import { ContactUsObject } from '../../../resources/ContactUsObject';
import { actions } from '../../../resources/en';
import { NameConstants } from '../../../resources/NameConstants';


const BackArrow = () => {

      const contactUs   =     useSelector(state=>state.contactUs);
      const dispatch    =     useDispatch();

      const backArrowHandler = ()=>{
            let back_on = ContactUsObject[contactUs.step].back_on

            //  Condition added for MemberSelectShow page to back on MemberBookingQue
            if(back_on === NameConstants.MemberLogin && contactUs.step === NameConstants.MemberSelectShow){
                  back_on = NameConstants.MemberBookingQue
            }
            dispatch(setStep({ step : back_on }))
      }

      return (

            <div className="buttons-wrapper buttons-wrapper__nav">
            {/* replace button with tag a */}
                  <button className="btn btn-flat btn-has-icon p-0" onClick={backArrowHandler}>
                        <picture>
                              <source media="(min-width: 1200px)" srcSet={iconArrowLeft35X24}/>
                              <img className="icon-arrow__left" src={iconArrowLeft23X16} alt=""/>
                        </picture>
                        <span className="text-wrapper">{actions.back}</span>
                  </button>
            </div>
      )
}

export default BackArrow
