import React from 'react';

// external css
// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';
// child component
import { HeadingPictureComponent } from '../../../../Components';

// packages
// Actions & RTK query or mutations
import { reset } from '../../../../Store/CurruntBookings';

// Constants
import { actions } from '../../../../resources/en';
// import { Config } from '../../../../Config';

const Step5 = () => {

      const domain  = useSelector(state=>state.user.domain)
      const dispatch = useDispatch();

      const resetCancelBookingHandler = ()=> {
            dispatch(reset());
      }

      return (

            <div id="manageBookingOtherRequestsReceived" className="content content__manage-booking-other-requests-received tickets-drop-down text-center d-flex">

                  <button type="button" className="btn-close btn-close__custom btn-close__absolute" aria-label="Close" onClick={resetCancelBookingHandler}>
                  </button>

                  <div className="content__header">
                        <div className="big-heading">
                              <h1 className="mb-0">
                                    We received<br/>your request!
                              </h1>
                              {domain === "" &&  
                              <>
                              <HeadingPictureComponent media="(min-width: 768px)" srcSet="ticket-other-requests-received-green.svg"
                              src="ticket-other-requests-received-green-sm.svg"
                              dataScroll
                              className="ticket-green"/>

                              <HeadingPictureComponent media="(min-width: 768px)" srcSet="ticket-other-requests-received-red.svg"
                              src="ticket-other-requests-received-red-sm.svg"
                              dataScroll
                              className="ticket-red"/>

                              <HeadingPictureComponent media="(min-width: 768px)" srcSet="ticket-other-requests-received-blue.svg"
                              src="ticket-other-requests-received-blue-sm.svg"
                              dataScroll
                              className="ticket-blue"/>

                              <HeadingPictureComponent media="(min-width: 768px)" srcSet="ticket-other-requests-received-aquamarine.svg"
                              src="ticket-other-requests-received-aquamarine-sm.svg"
                              dataScroll
                              className="ticket-aquamarine"/>

                              <HeadingPictureComponent media="(min-width: 768px)" srcSet="ticket-other-requests-received-orange.svg"
                              src="ticket-other-requests-received-orange-sm.svg"
                              dataScroll
                              className="ticket-orange"/>
                              </>}
                             
                        </div>
                  </div>

                  <div className="content__body min-height-0">
                        <p>
                              We will be in touch with you as soon as possible!
                        </p>
                        <div className="buttons-wrapper">
                              <button type="button" id="btnBackToStep2" className="btn btn-primary mx-auto" onClick={resetCancelBookingHandler}>{actions.goBack}</button>
                        </div>
                  </div>
                  {/* <!-- /.content__body --> */}
            </div>
      )
}

export default Step5;
