export const UpdateTfmDetails =  build =>
    build.mutation({
          query: (body) =>  ({
                url: '/update_tfm_details',
                method: 'POST',
                body: body
          }),
    })


    