import { api } from "./../../../Services/Api";
import { fetchBookings } from "./fetchBookings";
import { cancelBooking } from "./cancelBookin";
import { contactWebBookings } from "./contactWebBookings";
import { getTicketTypeBookingInfo } from "./getTicketTypeBookingInfo";
import { downloadTickets } from "./downloadTickets";
import { getFlexiMessage } from "./getFlexiMessage";

export const bookingApi = api.injectEndpoints({
      endpoints: build => ({
            fetchBookings:fetchBookings(build),
            cancelBooking:cancelBooking(build),
            contactWebBookings:contactWebBookings(build),
            getTicketTypeBookingInfo:getTicketTypeBookingInfo(build),
            downloadTickets : downloadTickets(build),
            getFlexiMessage : getFlexiMessage(build)
      }),
      overrideExisting: true
})

export const {  useLazyFetchBookingsQuery, useCancelBookingMutation, useContactWebBookingsMutation, useGetTicketTypeBookingInfoMutation, useLazyDownloadTicketsQuery, useLazyGetFlexiMessageQuery } = bookingApi