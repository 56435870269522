
export const flexiHandler = (price,tickets)=>{

      let flexi_price_per_tix = 0;

      if(price<=4) {

          flexi_price_per_tix=1.25;
      }
      else if(price<=5) {

          flexi_price_per_tix=1.50;
      }
      else if(price<=6.50) {

          flexi_price_per_tix=1.75;
      }
      else if(price<=15) {

          flexi_price_per_tix=3.50;
      }
      else if(price > 15) {
          flexi_price_per_tix=5;
      }

      return flexi_price_per_tix*tickets;

}