import React from "react";

const ItemDummy = () => {
      return (
            <div className="row uptakeVenue-item__dummy">
                  <div className="col-md-12">
                        <div className="content content__uptake content__uptake-venues content__uptake-event mx-auto">
                              <div className="content__body">
                                    <div className="card card__event border-1 border-radius-10 flex-lg-row mb-0">
                                          <div className="card__event-image flex-shrink-0">
                                                <div className="event-image"></div>
                                          </div>
                                          <div className="card-body">
                                                <div className="card-body__row row-event-title-status">
                                                      <div className="card-title fw-bold"></div>
                                                </div>

                                                <div className="card-body__row row-uptake-list-progress">
                                                      <div className="col col__left">
                                                            <ul className="list__uptake-stat list-unstyled"></ul>
                                                      </div>

                                                      <div className="col col__right flex-shrink-0">
                                                            <div className="uptake-progress-wrapper">
                                                                  <div className="heading"></div>
                                                                  <div className="progress progress-bar-overlay">
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>

                                                <div className="card-body__row row-notice-buttons">
                                                      <div className="col col__left">
                                                            <div className="buttons-wrapper mt-lg-auto">
                                                                  <a className="btn btn-primary" href="/" rel="noreferrer">
                                                                  </a>
                                                            </div>
                                                      </div>

                                                      <div className="col col__right d-lg-flex flex-shrink-0">
                                                            <div className="buttons-wrapper mt-lg-auto">
                                                                  <a className="btn btn-primary" href="/" rel="noreferrer">
                                                                  </a>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
  );
};

export default ItemDummy;
