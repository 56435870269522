export const ticketTypes = {
      /* 0 -normal event
      1 - group without contacts
      2 - Ticket Type
      3 - group contact
      4 - contact normal */
      reduceTickets : ["0","2","4"],
      allTicketTypes : ["0","1","2","3","4"]
}
      

export const onLineTicketTypes = ['5','6','7']