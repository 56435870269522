import React, { useEffect } from 'react';

// external css
import AjtixContactIcon from "../../assets/images/ajtix_contact_icon.png"
import AjtixSearchLogo from "../../assets/images/ajtix_search_logo.png"
import AjtixLocationIcon from "../../assets/images/ajtix_location_icon.png"
import AjtixTicketIcon from "../../assets/images/ajtix_tickets_icon.png"

// redux and react hooks
import { Link } from 'react-router-dom';
// child component
import Footer from "../../Layouts/Footer/Footer";

// packages
import { Helmet } from 'react-helmet';
// Actions & RTK query or mutations
// Constants
import { routesName } from '../../resources/RoutesName';
import { URL } from '../../resources/Url';
import { MetaDataAj } from '../../resources/MetaData';

const AjtixHome = () => {

      useEffect(()=>{
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      let metaDataSite = MetaDataAj
      
      return (
            
            <React.Fragment>
                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <div className="scroll-animations position-relative" data-scroll-container>
                        <Helmet>
                              <title>{metaDataSite.Home.title}</title>
                              <meta name="description" content={metaDataSite.Home.description} />
                              <meta name="keywords" content={metaDataSite.Home.keywords} />
                        </Helmet>
                        <main className="main__home ajtix-main__home">
                              <section data-scroll-section className="ajtix-main__home-seat-filler">
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content">
                                                            <div className="content__body position-relative">

                                                                        <div className="hero">
                                                                              <div className="hero__content">
                                                                                    <div className="hero-header">
                                                                                          <div className="big-heading">
                                                                                                <h2>Become a seat-filler and get ticket offers for 100+ shows each week.</h2>
                                                                                          </div>
                                                                                    </div>
                                                                                    <div className="hero-body">
                                                                                          <p className="lead">
                                                                                                Get tickets for £4-15 for theatre, music, dance, opera, classical music, talks & movies by becoming a seat fillers with AJ Tickets. It’s free to join – sign up today.
                                                                                          </p>
                                                                                    </div>
                                                                                    
                                                                                    <div className="hero-cta">
                                                                                          <div className="buttons-wrapper">
                                                                                                <Link className="btn btn-xl-lg btn-primary" to={routesName.Register}>
                                                                                                      Register
                                                                                                </Link>
                                                                                                
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                              {/* <!-- /.hero__content --> */}
                                                                              <div className="hero__image">
                                                                                    <img src={`${URL.Image_Base_Url}AjticketsHomePage-dancers.jpg`} alt='' />
                                                                              </div>
                                                                              {/* <!-- /.hero__image --> */}
                                                                        </div>
                                                                        {/* <!-- /.hero --> */}

                                                            </div>
                                                            {/* <!-- /.content__body --> */}
                                                      </div>
                                                      {/* <!-- /.content --> */}
                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                              </section>

                              <section data-scroll-section className="ajtix-join-our-community">
                                    <div className="container-fluid container__block position-relative">
                                          <div className="row" >
                                                <div className="col-12 header-wrapper">
                                                      <h1>Join our community</h1>
                                                </div>
                                                <hr />
                                          </div>
                                          <div className="row" >
                                                <div className="col-12">
                                                      <div className="hero">
                                                            <div className="hero__image">
                                                                  <img src={`${URL.Image_Base_Url}AjticketsHomePage-musician.jpg`} alt='' />
                                                            </div>
                                                            <div className="hero__content">

                                                                  <div className="hero-header">
                                                                        <div className="big-heading">
                                                                              <h2>Join as a member <br/> or an event partner</h2>
                                                                        </div>
                                                                  </div>
                                                                  <div className="hero-body">
                                                                        <p className="lead">
                                                                              AJ Tickets members enjoy discounted tickets for 100+ shows each week in exchange for being a discreet seat-filler. You can book up to six tickets and bring along friends or family. You'll also get access to exclusive third-party and West End discounts.
                                                                              <br/>
                                                                              <br/>
                                                                              If you'd like to partner with us, we provide a free seat-filling service that connects productions with new audiences - that include underserved communities - in a way that is socially and climate responsible.
                                                                        </p>
                                                                  </div>
                                                                  <div className="hero-cta">
                                                                        <div className="buttons-wrapper">
                                                                              <Link className="btn btn-xl-lg btn-primary" to={routesName.Register}>
                                                                                    Register
                                                                              </Link>
                                                                        </div>
                                                                  </div>
                                                                  
                                                            </div>
                                                           
                                                            
                                                      </div>
                                                </div>
                                                
                                          </div>
                                    </div>
                              </section>

                              <section data-scroll-section className="membership-free">
                                    <div className="container-fluid container__block">
                                          <div className="row" >
                                                <h1>Membership</h1>
                                                <div></div>
                                          </div>
                                          <div className="row" >
                                                <div></div>
                                                <h1>is free</h1>
                                          </div>
                                          <div className="row">

                                                <div className="hero">
                                                      <div className="hero__content">
                                                            <div className="hero-body">
                                                                  <p className="lead">
                                                                        It's completely free to join our mailing list. Seat-filling shows range between £4-15 in cost and our members also have access to exclusive third-party West End shows.
                                                                  </p>
                                                            </div>
                                                                                    
                                                            <div className="hero-cta">
                                                                  <div className="buttons-wrapper">
                                                                        <Link className="btn btn-xl-lg btn-primary" to={routesName.Register}>
                                                                              Join Us
                                                                        </Link>
                                                                        
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>

                                          </div>
                                    </div>
                              </section>


                              <section data-scroll-section className="ajtix-supporting">
                                    <div className="container-fluid container__block">
                                          <div className="row" >
                                                <div className="col-12">
                                                      <div className="hero">
                                                            <div className="hero__image">
                                                                  <img src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/nhs_help.jpg`} alt='' />
                                                            </div>
                                                            <div className="hero__content">

                                                                  <div className="hero-header">
                                                                        <div className="big-heading">
                                                                              <h2>Supporting <br/> good causes</h2>
                                                                        </div>
                                                                  </div>
                                                                  <div className="hero-body">
                                                                        <p className="lead">
                                                                              Alongside our friends at Central Tickets we work in support of NHS Charities Together who help our health service to do more: from supporting research and development, to brightening up hospital environments, to donating state-of-the-art technologies and eqipment, the charities raise funds and mobilise volunteers, touching lives and making a huge diffrence to millions of the people when they are at their most vulnerable.
                                                                        </p>
                                                                  </div>
                                                                  <div className="hero-cta">
                                                                        <div className="buttons-wrapper">
                                                                              <a className="btn btn-xl-lg btn-primary" target='_blank' rel="noreferrer" href={URL.TICKETS_FOR_MEDICS_SITE_URL}>
                                                                                    Learn more
                                                                              </a>
                                                                        </div>
                                                                  </div>
                                                                  
                                                            </div>
                                                           
                                                            
                                                      </div>
                                                </div>
                                                
                                          </div>
                                    </div>
                              </section>

                              <section data-scroll-section className="ajtix-getting-started" >
                                    <div className="container-fluid container__block">
                                          <div className="row" >
                                                <div className="col-12">
                                                      <div className="content" >
                                                            <div className="content__body position-relative">
                                                                  <div className='item'>
                                                                        <div className='item-header'>
                                                                              <div className='item-image-wrapper'>
                                                                                    <img src={AjtixContactIcon} alt='' />
                                                                              </div>
                                                                              <h2>Open account</h2>
                                                                        </div>
                                                                        <div className='item-body'>
                                                                              <p className='lead'>

                                                                                    It's free to open an account and it only <br/>
                                                                                    takes a few minutes to get access to  <br/>
                                                                                    100+ seat-filling shows and exclusive  <br/>
                                                                                    West End pre-sales and offers. 
                                                                              </p>
                                                                        </div>
                                                                  </div>

                                                                  <div className='item'>
                                                                        <div className='item-header'>
                                                                              <div className='item-image-wrapper'>
                                                                                    <img src={AjtixSearchLogo} alt='' />
                                                                              </div>
                                                                              <h2>Search listings</h2>
                                                                        </div>
                                                                        <div className='item-body'>
                                                                              <p className='lead'>
                                                                                    Browse the seat-filling listings <br/>
                                                                                    available to you. We add new <br/>
                                                                                    shows every day and you can use <br/>
                                                                                    search filters to find your favourites.
                                                                              </p>
                                                                        </div>
                                                                  </div>

                                                                  <div className='item'>
                                                                        <div className='item-header'>
                                                                              <div className='item-image-wrapper'>
                                                                                    <img src={AjtixTicketIcon} alt='' />
                                                                              </div>
                                                                              <h2>Book tickets</h2>
                                                                        </div>
                                                                        <div className='item-body'>
                                                                              <p className='lead'>
                                                                                    You can take your friends and <br/>
                                                                                    family along. Your free membership <br/>
                                                                                    allows you to book up to six tickets <br/>
                                                                                    for any seat-filling show.
                                                                              </p>
                                                                        </div>
                                                                  </div>
                                                                  <div className='item'>
                                                                        <div className='item-header'>
                                                                              <div className='item-image-wrapper'>
                                                                                    <img src={AjtixLocationIcon} alt='' />
                                                                              </div>
                                                                              <h2>
                                                                                    Turn up & be discreet
                                                                              </h2>
                                                                        </div>
                                                                        <div className='item-body'>
                                                                              <p className='lead'>
                                                                                    Arrive early, turn up and be discreet. <br />
                                                                                    Make sure you read the collection <br/>
                                                                                    instructions (which can differ show <br/>
                                                                                    to show) 
                                                                              </p>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="row" >
                                                <div className="col-12">
                                                      <div className="hero">
                                                            <div className="hero__content">
                                                                  <div className="hero-cta">
                                                                        <div className="buttons-wrapper">
                                                                              <a className="btn btn-xl-lg btn-primary" href={routesName.MembershipGuidlines}>
                                                                                    Read the Membership Guidelines 
                                                                              </a>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                                
                                          </div>
                                    </div>
                              </section>


                              <section data-scroll-section className="ajtix-about-us">
                                    <div className="container-fluid container__block">
                                          <div className="row" >
                                                <div className="col-12">
                                                      <div className="hero">
                                                            
                                                            <div className="hero__content">

                                                                  <div className="hero-header">
                                                                        <div className="big-heading">
                                                                              <h2>About us</h2>
                                                                        </div>
                                                                  </div>
                                                                  <div className="hero-body">
                                                                        <p className="lead">
                                                                              AJ Tickets is an independent seat-filling agency for event organisers in the UK. We provide a discreet, reliable audiences and our appreciative members enjoy being offered a wide range of seat-filling events to attend. 
                                                                              <br/>
                                                                              <br/>
                                                                              Our appreciative members have the opportunity to attend events listed directly with AJ Tickets. They also have the opportunity to book seat-filling opportunities and discounted West End offers for third-party event partners we have affiliate agreements with including Central Tickets and Encore (within the AJ Tickets platform).
                                                                              <br/>
                                                                              <br/>
                                                                              Event organisers list their events with us because we have a range of fullfillment options including COBO, etickets and data-sharing. Our members turn up, spend well and our team know how to connect your event with the people most likely to attend.
                                                                        </p>
                                                                  </div>
                                                            </div>
                                                            <div className="hero__image">
                                                                  <img src={`${URL.Image_Base_Url}AjticketsHomePage-audience.jpg`} alt='' />
                                                            </div>
                                                           
                                                      </div>
                                                </div>
                                                
                                          </div>
                                    </div>
                              </section>
                        </main>

                        <Footer/>
                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}
            </React.Fragment>
      )
}

export default AjtixHome;
