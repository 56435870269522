export const bookingReleaseSeats =  build =>
    build.query({
            query: (body) =>  ({
                url: `/booking/booking_release_seats/${body.entity_id}`,
                method: 'GET',
                params: body
            }),
    })
    // Changed with new one

