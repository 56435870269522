

import { validation } from "./../resources/en";
import { NameConstants } from "../resources/NameConstants";

const reactiveAccountValidationRules =(value, type) =>{

      const textMessage = validation;

      let isValid = true;
      let validationMessage = "";

      if (!type) {
          return true;
      }

      if(type === NameConstants.Name){
            if(value.trim() ==="")
            {
                  isValid = false;
                  validationMessage = textMessage[type].required;
            }
            else if(value.length <2)
            {
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type === NameConstants.Email){

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!value){
                  isValid = false;
                  validationMessage = textMessage[type].required;
            }
            else if(!re.test(String(value).toLowerCase()) || !value){
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type === NameConstants.Attendance){
            if(!value) 
            {
                  isValid=false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type === NameConstants.CancellingBooking){
            if(!value) 
            {
                  isValid=false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type === NameConstants.Punctuality){
            if(!value) 
            {
                  isValid=false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type === NameConstants.Etiquette){
            if(!value) 
            {
                  isValid=false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type === NameConstants.TicketOffers){
            if(!value) 
            {
                  isValid=false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      return {isValid,validationMessage};
}



export default reactiveAccountValidationRules;
