import React, { useEffect } from 'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
// packages
// Actions & RTK query or mutations
import { useCancelBookingMutation, useLazyGetFlexiMessageQuery } from '../../../../Services/modules/Bookings';

import { reset } from '../../../../Store/CurruntBookings';
import { logout } from '../../../../Store/User';
import { setAlert } from "../../../../Store/UI";
import { api } from '../../../../Services/Api';

// Constants
import  { errors, actions, messages } from "../../../../resources/en";

const Step3 = () => {

      const curruntBookings = useSelector(state=>state.curruntBookings);
      const dispatch = useDispatch();

      const [cancelBooking, { data, isSuccess, isLoading, isFetching, error}] = useCancelBookingMutation();
      const [fetchFlexiMessage, { data : flexiData, isSuccess : flexiIsSuccess, isLoading : flexiIsLoading, isFetching : flexiIsFetching, error : flexiError}] = useLazyGetFlexiMessageQuery();

      useEffect(()=>{
            fetchFlexiMessage({
                  booking_id:curruntBookings.booking_id,
                  tickets:curruntBookings.numberOfTickets
            })

      },[fetchFlexiMessage, curruntBookings.booking_id, curruntBookings.numberOfTickets])

      useEffect(()=>{
            if(isSuccess){
                  if(data.block) {
                        console.log("logout using manage booking stpe3")
                        dispatch(logout());
                        dispatch(api.util.resetApiState());
                  } 

                  if(data.wallet_refresh){
                        // commment this code beucase using firebase to refetch wallet balance
                        // dispatch(setGetWalletBalance({getWalletBalance : true}));
                  }
                  dispatch(reset());
                  if(data.status === "1"){

                        dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:data.error || data.message }));
                  } else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message }));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
                  dispatch(reset())
            }

      },[ data, isSuccess, isLoading, isFetching, error, dispatch])

      useEffect(()=>{
            if(flexiData){
                  if(flexiData.status === "0") {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:flexiData.error || flexiData.errors || flexiData.message || errors.NotFound404 }))
                  }
            }

            if(flexiError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:flexiError.error || flexiError.data.error || flexiError.data.message || errors.NotFound404 }))
                  dispatch(reset())
            }

      },[flexiData, flexiIsSuccess, flexiIsLoading, flexiIsFetching, flexiError, dispatch])

      const confirmCancelhandler = ()=> {

            let data = null;
            if(curruntBookings.eventType === "2" || curruntBookings.eventType === 2){
                  const ticketsType = curruntBookings.ticketsType.map(tic=>{
                                        
                        return {tickets_type_management_id:tic.tickets_type_management_id,id:tic.id,quantity:tic.cancelqty}
                  })

                  data = {
                        booking_id:curruntBookings.booking_id,
                        tickets:curruntBookings.numberOfTickets,
                        contacts:[],
                        ticketsType:ticketsType
                  }
            }else {
                  data = {booking_id:curruntBookings.booking_id,tickets:curruntBookings.numberOfTickets}
            }
            cancelBooking(data);
      }

      return (

            <div id="manageBookingCancelBooking" className="content content__manage-booking-options border-bottom d-flex">
                  <div className="content__body">
                        <h4 className="heading">
                              {/* {title.cancelBookingConfirmation} */}
                              {flexiData ? flexiData.title : ""}
                        </h4>
                        <p>
                              {/* {messages.cancelBookingConfirmation} */}
                              {flexiData ? flexiData.message : ""}
                        </p>
                        <div className="buttons-wrapper">
                              <button type="button" className="btn btn-primary" onClick={confirmCancelhandler} disabled={isLoading || !flexiIsSuccess}>{actions.confirmCancellation}</button>
                        </div>
                  </div>
                  {/* <!-- /.content__body --> */}
            </div>
      )
}

export default Step3;
