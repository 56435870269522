import { api } from "./../../../Services/Api";
import { changePassword } from "./changePassword";
import {  changeEmail } from "./changeEmail";
import { deleteAccount } from "./deleteAccount";
import { updateProfile  } from "./updateProfile";

export const settingsApi = api.injectEndpoints({
            endpoints: build => ({
            changePassword:changePassword(build),
            changeEmail:changeEmail(build),
            deleteAccount:deleteAccount(build),
            updateProfile : updateProfile(build)
      }),
      overrideExisting: true
})

export const {  useChangePasswordMutation, useChangeEmailMutation, useDeleteAccountMutation, useUpdateProfileMutation } = settingsApi