import React from 'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux'; 

// child component
import Step1 from './Step1';
import Loader from '../../../Ui/BookingModuleLoader';
import Step2 from './Step2';
import Step3 from "./Step3"
import Step4 from './Step4';

// packages
// Actions & RTK query or mutations
import { setGiftParams } from '../../../Store/GiftVoucher';
// Constants

const GiftTopUp = ({ webView }) => {


      const giftVoucher                         =     useSelector(state=>state.giftVoucher);
      const dispatch                            =     useDispatch()
      

      const closeTopUpHandler = ()=> {
            dispatch(setGiftParams({
                  isTopUpOpen: false,
                  step : "step1",
                  paymentMethod : "",
                  giftAmount : "",
                  userDetails : null
            }))
      }

      return (
            <div className="slidein-wrapper show">
                  <div className="slidein slidein__wallet-topup slidein__gift-voucher">

                        <div className="content__slidein content-scrollable">

                              <form>

                                    <div className="content content__checkout content__wallet-topup">

                                          <div className="content__header border-bottom position-relative">
                                                <button type="button" className="btn-close btn-close__custom btn-close__absolute" aria-label="Close" onClick={closeTopUpHandler}>

                                                </button>
                                                <div className="content my-auto">
                                                      <h4 className="content__title my-auto mb-0">
                                                            {giftVoucher.step === "step4" ? "Success - gift credit purchase confirmed" : "Gift wallet credit"}
                                                      </h4>
                                                </div>
                                          </div>
                                          {/* <!-- /.content__header --> */}

                                          {giftVoucher.step === "step1" && <Step1 />}
                                          {giftVoucher.step === "step2" &&  <Step2 />}
                                          {giftVoucher.step === "step3" && <Step3 />}
                                          {giftVoucher.step === "step4" && <Step4 webView={webView}/>}
                                          <Loader show={giftVoucher.spinner}/>
                                    </div>
                                    {/* <!-- /.content__wallet-topup --> */}

                              </form>

                        </div>
                        {/* <!-- /.content__slidein --> */}

                  </div>
                  {/* <!-- /.slidein --> */}
            </div>

      )
}

export default GiftTopUp;
