import React from 'react';
import EmailPrefrencesForm from '../Components/EmailPrefrencesForm';

const EmailPreferences = () => {
      
      return (
                  <div className="accordion-item accordion-item__EmailPreferences">
                        <h2 className="accordion-header" id="amasHeading4">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#amasCollapse4" aria-expanded="false" aria-controls="amasCollapse4" >
                                    <span className="btn__text">
                                          Email Preferences
                                    </span>
                              </button>
                        </h2>
                        <div id="amasCollapse4" className="accordion-collapse collapse" aria-labelledby="amasHeading4">

                              <div className="accordion-body">
                                    <div className="content">
                                          <h4 className="heading">Your area</h4>
                                          <EmailPrefrencesForm mobile/>
                                    </div>
                                    {/* <!-- /.content --> */}

                              </div>
                              {/* <!-- /.accordion-body --> */}
                        </div>
                        {/* <!-- /.accordion-collapse --> */}
                  </div>
      )
}

export default EmailPreferences;
