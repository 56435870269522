import React, { useEffect, useState } from 'react';

// external css
import ticket1starRed from "../../assets/images/ticket-1star-red.svg";
import ticket1starYellow from "../../assets/images/ticket-1star-red-ajtix.svg";
import ticket1starRedTfm from "../../assets/images/ticket-1star-red-tfm.svg";

// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

// child component
// packages
import TomSelect from 'tom-select';

// Actions & RTK query or mutations
import { routesName } from '../../resources/RoutesName';
import { joinValidationRules } from '../../Services/JoinService';
import { useRegistrationWithoutNhsEmailMutation } from '../../Services/modules/Tfm';
import { setAlert, setOtpEntityId } from '../../Store/UI';
import { setTfmRegister, setIsRegister, updateRegistrationState ,setExistingRegisterNumber} from '../../Store/Register';
import { setRegistrationType, setInfoPartialRegistration, setReferral, logout } from '../../Store/User';

// Constants
import { Config } from '../../Config';
import { actions, label, placeholders, messages, errors } from '../../resources/en';
import { NameConstants } from '../../resources/NameConstants';

const JoinAltStep1 = () => {

      const [preview,setPreview] = useState('')
      const [state,setState]= useState({

            [NameConstants.FirstName]:{value:"",error:false,errorMessage:""},
            [NameConstants.LastName]:{value:"",error:false ,errorMessage:""},
            [NameConstants.PassWord]:{value:"",error:false,errorMessage:""},
            [NameConstants.Role]:{value:"",error:false,errorMessage:""},
            [NameConstants.NearestCity]:{value:"",error:false,errorMessage:""},
            [NameConstants.EmailTfm]:{value:"",error:false,errorMessage:""},
            [NameConstants.EmailConfitmTfm]:{value:"",error:false,errorMessage:""},
            [NameConstants.Comments] : {value:"",error:false,errorMessage:""},
            [NameConstants.WorkIDCard] : { value:"",error:false,errorMessage:"" },
            [NameConstants.PhoneNumber]:{value:"",error:false,errorMessage:""},
            [NameConstants.CheckReceiveAlerts]:{value:false,error:false,errorMessage:""},
            [NameConstants.CheckAgreeTOS]:{value:false,error:false,errorMessage:""},

      })


      const user                    =     useSelector(state=>state.user);
      const register                =     useSelector(state=>state.register);
      const navigate                =     useNavigate()
      const dispatch                =     useDispatch()

      const [RegistrationWithoutNhsEmail, { data : regData, isSuccess, isFetching,error, isLoading }] = useRegistrationWithoutNhsEmailMutation();


      useEffect(()=>{
            if(user.auth){
                  navigate(routesName.Home)
            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{

            window.scrollTo({top: 0, behavior: 'smooth'});

            if(register.tfmRegister.state){   

                  setState(prevState=>{
                        return {
                              ...prevState,
                              [NameConstants.FirstName]:{...prevState[NameConstants.FirstName],value:register.tfmRegister.state[NameConstants.FirstName]},
                              [NameConstants.LastName]:{...prevState[NameConstants.LastName],value:register.tfmRegister.state[NameConstants.LastName]},
                              [NameConstants.PassWord]:{...prevState[NameConstants.PassWord],value:register.tfmRegister.state[NameConstants.PassWord]},
                              [NameConstants.EmailTfm]:{...prevState[NameConstants.EmailTfm],value:register.tfmRegister.state[NameConstants.NHSEmail]},
                              [NameConstants.Role]:{...prevState[NameConstants.Role],value:register.tfmRegister.state[NameConstants.Role]},
                              [NameConstants.NearestCity]:{...prevState[NameConstants.NearestCity],value:register.tfmRegister.state[NameConstants.NearestCity]},
                              [NameConstants.PhoneNumber]:{...prevState[NameConstants.PhoneNumber],value:register.tfmRegister.state[NameConstants.PhoneNumber]}
                        }
                  })
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{

            window.scrollTo({top: 0, behavior: 'smooth'});
            const city = new TomSelect('#joinInputNearestCity',{
                  plugins: ['remove_button'],
                  create: false,
                  maxOptions:150,
                  sortField: {
                        field: "text"
                  },
                  items:[register.tfmRegister.state && register.tfmRegister.state[NameConstants.NearestCity] ],
                  openOnFocus:false,
                  onType:(str)=>{
                        if(str.length > 1){
                             document.querySelector("#joinInputNearestCity + .ts-wrapper .ts-dropdown").style.display = "block"
                        }
                        else{
                              document.querySelector("#joinInputNearestCity + .ts-wrapper .ts-dropdown").style.display = "none"
                        }
                  },	
                  render:{
                        no_results:function(data,escape){
                              document.querySelector("#joinInputNearestCity + .ts-wrapper .ts-dropdown").style.border = "0px"
                        }
                  }
            });
      
            return ()=>{
                  city.clear();
            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(() => {
            
            if (!state[NameConstants.WorkIDCard].value) {
                setPreview("")
                return
            }
    
            const objectUrl = URL.createObjectURL(state[NameConstants.WorkIDCard].value)
            setPreview(objectUrl)
    
            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl)

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [state])

      // Handle Rsponse of RegistrationWithoutNhsEmail
      useEffect(()=>{
            if(isSuccess){
                  
                  if(regData.status === "1"){ 
                        localStorage.removeItem('nhs_ajtix');
                        localStorage.removeItem('nhs_ragistration_type');
                        dispatch(setReferral({referral : ""}))
                        dispatch(setRegistrationType({ct_registration_type : ""}))
                        dispatch(setOtpEntityId({otp_entity_id  :regData?.data?.otp_entity_id || ""}))
                        dispatch(setInfoPartialRegistration({
                              auth : false,
                              ct_access_token : regData.data.token,
                              ct_refresh_token : regData.data.refresh_token,
                              ct_register_token : regData.data.token,
                              ct_access_name : regData.data.userInfo.firstName +" "+ regData.data.userInfo.lastName,
                              ct_access_email : regData.data.userInfo.email || "",
                              ct_access_mobilenumber : regData.data.userInfo.mobilenumber || "",
                              ct_is_verified : 'false',
                              ct_firebase_key : regData.data.userInfo.firebase_key,
                              ct_reactive : "0",
                              ct_is_top_member : 0,
                              ct_wallet_balance : 0
                        }))
                        dispatch(setIsRegister({isRegister : true}))
                        dispatch(updateRegistrationState({
                              ct_otp_modal_status : "otpEmail",
                              step : "step2",
                              registration_step : register.registration_step
                        }))

                        // To remember if user change number while verifiying it
                        dispatch(setExistingRegisterNumber({existing_register_number : state[NameConstants.PhoneNumber].value}))

                  }
            } 

           
            if(isSuccess && !isFetching && !isLoading) {

                  if(regData.status === "0"){

                        if(regData.data && regData.data.length > 0){
                              const copy =    {
                                    ...state
                              }
      
                              let message = ""
                              regData.data.errors.forEach(el => {
                                    
                                    if(el.field_name === "fname"){
                                          copy[NameConstants.FirstName] = {...copy[NameConstants.FirstName],error:true,errorMessage:el.message}
                                    } 
                                    else if(el.field_name === "lname"){
                                          copy[NameConstants.LastName] = {...copy[NameConstants.LastName],error:true,errorMessage:el.message}
                                    } 
                                    else if(el.field_name === "email"){
                                          copy[NameConstants.EmailTfm] = {...copy[NameConstants.EmailTfm],error:true,errorMessage:el.message}
                                    } 
                                    else if(el.field_name === "mobilenumber"){
                                          copy[NameConstants.PhoneNumber] = {...copy[NameConstants.PhoneNumber],error:true,errorMessage:el.message}
                                    }
                                    else if(el.field_name === "password"){
                                          copy[NameConstants.PassWord] = {...copy[NameConstants.PassWord],error:true,errorMessage:el.message}
                                    }
                                    else if(el.field_name === "Role"){
                                          copy[NameConstants.Role] = {...copy[NameConstants.Role],error:true,errorMessage:el.message}
                                    }
                                    else if(el.field_name === "Comments"){
                                          copy[NameConstants.Comments] = {...copy[NameConstants.Comments],error:true,errorMessage:el.message}
                                    }
                                    else if(el.field_name === "NearestCity"){
                                          copy[NameConstants.NearestCity] = {...copy[NameConstants.NearestCity],error:true,errorMessage:el.message}
                                    }
                                    else {
                                          message = el.message
                                    }
                              })
      
                              setState(prevState=>{
                                    return {
                                          ...prevState,
                                          ...copy
                                    }
                              })
                              if(message !== ""){
                                    dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:message || errors.NotFound404 }))
                              }
                        } else {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:regData.error || regData.errors || regData.message || errors.NotFound404 }))
                        }

                  } 
            }

            if(error) {
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[regData, isSuccess, error, isLoading, isFetching, dispatch])


      const changeHandler = (event)=>{
            
            const name = event.target.name;
            let value = event.target.value;
            let radioButton = false;

          
            if(name === NameConstants.FirstName || name === NameConstants.LastName){
                  value = capitalizeFirstLetter(value);
            }

            if(event.target.name === NameConstants.WorkIDCard){
                  value = event.target.files[0];
            }

            if(name === NameConstants.CheckReceiveAlerts || name=== NameConstants.CheckAgreeTOS){
                  value = event.target.checked;
                  radioButton = true;
            }

            if(name === NameConstants.PhoneNumber){
                  value = event.target.value.replace(/\D/g, '');
            }

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:{...prevState[name],value:value}
                  }
            })

            if(radioButton){
                  onfocusHandler();
            }
      }

      const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
      }

      const checkForNhsEmailHandler = ()=>{
            const value = state[NameConstants.EmailTfm].value;
            const errorCheck = joinValidationRules(value,"email");
            if(errorCheck.isValid){
                  if((value.indexOf("@nhs.net") !== -1) || (value.indexOf("nhs.uk") !== -1) || (value.indexOf("nhs.scot") !== -1)){
                        const data = {
                              [NameConstants.FirstName]:state[NameConstants.FirstName].value,
                              [NameConstants.LastName]:state[NameConstants.LastName].value,
                              [NameConstants.PassWord]:state[NameConstants.PassWord].value,
                              [NameConstants.PhoneNumber]:state[NameConstants.PhoneNumber].value,
                              [NameConstants.EmailTfm]:state[NameConstants.EmailTfm].value,
                              [NameConstants.Role]:state[NameConstants.Role].value,
                              [NameConstants.NearestCity]:state[NameConstants.NearestCity].value
                        }

                        dispatch(setTfmRegister({nhs: true,state : data}));
                        return;
                  }
            }

      }

      const onfocusHandler = ()=>{

            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.FirstName]:{...prevState[NameConstants.FirstName],error:false},
                        [NameConstants.LastName]:{...prevState[NameConstants.LastName],error:false},
                        [NameConstants.PassWord]:{...prevState[NameConstants.PassWord],error:false},
                        [NameConstants.EmailTfm]:{...prevState[NameConstants.EmailTfm],error:false},
                        [NameConstants.EmailConfitmTfm]:{...prevState[NameConstants.EmailConfitmTfm],error:false},
                        [NameConstants.Role]:{...prevState[NameConstants.Role],error:false},
                        [NameConstants.NearestCity]:{...prevState[NameConstants.NearestCity],error:false},
                        [NameConstants.PhoneNumber]:{...prevState[NameConstants.PhoneNumber],error:false},
                        [NameConstants.Comments]:{...prevState[NameConstants.Comments],error:false},
                        [NameConstants.WorkIDCard]:{...prevState[NameConstants.WorkIDCard],error:false},
                        [NameConstants.CheckReceiveAlerts]:{...prevState[NameConstants.CheckReceiveAlerts],error:false},
                        [NameConstants.CheckAgreeTOS]:{...prevState[NameConstants.CheckAgreeTOS],error:false},
                  }
                 
            })
      }

      const backHandler = (event)=>{
            event.preventDefault()
            dispatch(setTfmRegister({nhs: true,state : register.tfmRegister.state}));
      }

      const submitJoinDataHandler = (event) => {
            event.preventDefault();
            const errorStore = [];
            const copy = {

            }

            for(let key in state)
            {
                  const errorCheck = joinValidationRules(state[key].value,key,state[NameConstants.EmailTfm].value);
                  
                  if(!errorCheck.isValid)
                  {
                        errorStore.push(key);
                  }       
                  copy[key] = {...state[key],error:!errorCheck.isValid,errorMessage:errorCheck.validationMessage}
                        
            }
           

            if(errorStore.length > 0)
            {
                  setState(prevState=>{
                        return {
                              ...prevState,
                              ...copy
                        }
                  })
            }
            else
            {
                  // const data = {
                  //       fname:state.fname.value,
                  //       lname:state.lname.value,
                  //       password:state.password.value,
                  //       work_email:state.email.value,
                  //       role:state.role.value,
                  //       city:state.city.value,
                  //       // organisation:state.organisation.value,
                  //       comments:state.comments.value,
                  //       image:state.image.value,
                  //       agree_tos:state.checkAgreeTOS.value,
                  //       receive_alerts:state.checkReceiveAlerts.value
                  // }

                  dispatch(logout())

                  const ragistration_special = localStorage.getItem('nhs_ragistration_type');
                  const ragistration_type = (ragistration_special !== "undefined" && ragistration_special) ? ragistration_special  : "";

                  let formData = new FormData();
                  formData.append('image',state[NameConstants.WorkIDCard].value,state[NameConstants.WorkIDCard].value.name);
                  formData.append('fname',state[NameConstants.FirstName].value);
                  formData.append('lname',state[NameConstants.LastName].value);
                  formData.append('password',state[NameConstants.PassWord].value);
                  formData.append('work_email',state[NameConstants.EmailTfm].value);
                  formData.append('role',state[NameConstants.Role].value);
                  formData.append('city',state[NameConstants.NearestCity].value);
                  formData.append('mobile_number',state[NameConstants.PhoneNumber].value);
                  // formData.append('organization',state.organisation.value);
                  formData.append('comments',state[NameConstants.Comments].value);
                  formData.append('agree_tos',state[NameConstants.CheckAgreeTOS].value);
                  formData.append('receive_alerts',state[NameConstants.CheckReceiveAlerts].value);
                  formData.append('nhs_ajtix',localStorage.getItem('nhs_ajtix') || false);
                  formData.append('ragistration_type',ragistration_type);

                  formData.append('code',user.ct_old_user_verification_code);
                  formData.append('type',user.ct_registration_type || "");
                  formData.append('gift_voucher_id',user.ct_gift_voucher || "");
                  formData.append('referral_code',user.ct_referral || "");
                      
                  // for (var pair of formData.entries()) {
                  //       console.log(pair[0]+ ', ' + pair[1]); 
                  // }
                  // return;

                  RegistrationWithoutNhsEmail(formData)
            }
      }
      
      return (
                  <React.Fragment>
                       
                        <div className="col-md-12">

                              <div className="content content__register mx-auto">
                                    <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                                          <div className="big-heading">
                                                <h1 className="h2 mb-0">
                                                      Register
                                                </h1>
                                                {/* <HeadingPictureComponent 
                                                dataScroll=""
                                                src="ticket-register-big-heading1-orange-sm.svg"
                                                srcSet="ticket-register-big-heading1-orange.svg" className="ticket-orange" />

                                                <HeadingPictureComponent 
                                                dataScroll=""
                                                src="ticket-register-big-heading1-aquamarine-sm.svg"
                                                srcSet="ticket-register-big-heading1-aquamarine.svg" className="ticket-aquamarine" />

                                                <HeadingPictureComponent 
                                                dataScroll=""
                                                src="ticket-register-big-heading1-yellow-sm.svg"
                                                srcSet="ticket-register-big-heading1-yellow.svg" className="ticket-yellow" /> */}

                                          </div>
                                    </div>
                                    {/* <!-- .content__header --> */}

                                    <div className="content__body p-0 d-flex flex-column flex-lg-row">
                                          <div className="col__left border-right-lg d-none d-lg-block">

                                                <div className="content">

                                                      <div className="content__body">
                                                            <div className="wrapper d-flex flex-column flex-md-row flex-lg-column">
                                                                  <div className="wrapper">
                                                                        <h3 className="heading">
                                                                              Why become a member?
                                                                        </h3>
                                                                        <ul className="list list-square-style">
                                                                              <li>
                                                                                    Save money on show tickets
                                                                              </li>
                                                                              <li>
                                                                                    Seat-filling offers for £4-6.50 per ticket
                                                                              </li>
                                                                              <li>
                                                                                    Discounted offers at £15 or under
                                                                              </li>
                                                                              <li>
                                                                                    Hundreds of shows each week
                                                                              </li>
                                                                              <li>
                                                                                    Discounted West End tickets
                                                                              </li>
                                                                              <li>
                                                                                    Exclusive pre-sale access
                                                                              </li>
                                                                              <li>
                                                                                    Receive hot show alerts
                                                                              </li>
                                                                              <li>
                                                                                    <span className="red fw-bold">It’s free to join!</span>
                                                                              </li>
                                                                        </ul>
                                                                  </div>
                                                                  <div className="feature flex-shrink-md-0">
                                                                        <img className="ticket-1-star" src={user.domain === Config.DOMAIN_CONSTANT_AJTIX ? ticket1starYellow :  ( user.domain === Config.DOMAIN_CONSTANT_TFM ? ticket1starRedTfm : ticket1starRed)} alt=""/>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}

                                          </div>
                                          {/* <!-- /.col__left --> */}

                                          <div className="col__right">

                                                <div className="content">
                                                      <div className="content__body">
                                                            <form>

                                                                  <div className="row row__form-fields">

                                                                        <div className="col-md-6">
                                                                              <div className="form-group">
                                                                                    <label htmlFor="registerInputFirstName" className="form-label">
                                                                                          {label[NameConstants.FirstName]}
                                                                                    </label>
                                                                                    <input type="text" className="form-control" id="registerInputFirstName" name={NameConstants.FirstName} placeholder={placeholders[NameConstants.FirstName]} onChange={changeHandler} value={state[NameConstants.FirstName].value} onFocus={onfocusHandler} disabled={false}
                                                                                    />
                                                                                    {state[NameConstants.FirstName].error &&
                                                                                    <div className="form-label-error">
                                                                                          {state[NameConstants.FirstName].errorMessage}
                                                                                    </div>}
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                              <div className="form-group">
                                                                                    <label htmlFor="registerInputLastName" className="form-label">
                                                                                          {label[NameConstants.LastName]}
                                                                                    </label>
                                                                                    <input type="text" className="form-control" id="registerInputLastName" placeholder={placeholders.lastName} name={NameConstants.LastName} onChange={changeHandler} value={state[NameConstants.LastName].value} onFocus={onfocusHandler} disabled={false}
                                                                                    />
                                                                                    {state.lastName.error && 
                                                                                    <div className="form-label-error">
                                                                                          {state[NameConstants.LastName].errorMessage}
                                                                                    </div>}
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>

                                                                  </div>
                                                                  {/* <!-- /.row --> */}

                                                                  <div className="row row__form-fields">
                                                                        <div className="col-md-12">
                                                                              <div className="form-group">
                                                                                    <label htmlFor="registerInputPassword" className="form-label">
                                                                                          {label[NameConstants.PassWord]}
                                                                                    </label>
                                                                                    <input type="password" className="form-control" id="registerInputPassword" name={NameConstants.PassWord} placeholder={placeholders.password} onChange={changeHandler} value={state[NameConstants.PassWord].value} onFocus={onfocusHandler} disabled={false}/>
                                                                                    {state[NameConstants.PassWord].error &&
                                                                                    <div className="form-label-error">
                                                                                    {state[NameConstants.PassWord].errorMessage}
                                                                                    </div>}
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                        
                                                                  </div>
                                                                  {/* <!-- /.row --> */}

                                                                  <div className="row row__form-fields">
                                                                        <div className="col-md-12">
                                                                              <div className="form-group">
                                                                                    <label htmlFor="registerInputEmail" className="form-label">
                                                                                          {label[NameConstants.EmailTfm]}
                                                                                    </label>
                                                                                    <input type="email" className="form-control" id="registerInputEmail" name={NameConstants.EmailTfm} placeholder={placeholders[NameConstants.EmailTfm]} onChange={changeHandler} value={state[NameConstants.EmailTfm].value} onFocus={onfocusHandler} onBlur={checkForNhsEmailHandler} disabled={false}/>
                                                                                    {state[NameConstants.EmailTfm].error &&  
                                                                                    <div className="form-label-error">
                                                                                          {state[NameConstants.EmailTfm].errorMessage}
                                                                                    </div>}
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                              <div className="form-group">
                                                                                    <label htmlFor="registerInputPhoneNumber" className="form-label">
                                                                                          {label[NameConstants.EmailConfitmTfm]}
                                                                                    </label>
                                                                                    <input type="text" className="form-control" id="registerInputEmailConfirm" name={NameConstants.EmailConfitmTfm} placeholder={placeholders[NameConstants.EmailConfitmTfm]} onChange={changeHandler} value={state[NameConstants.EmailConfitmTfm].value} onFocus={onfocusHandler} disabled={false}/>
                                                                                    {state[NameConstants.EmailConfitmTfm].error && 
                                                                                    <div  className="form-label-error">
                                                                                          {state[NameConstants.EmailConfitmTfm].errorMessage}
                                                                                    </div>}
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.row --> */}

                                                                  
                                                                  <div className="row row__form-fields">

                                                                        <div className="col-md-6">
                                                                              <div className="form-group">
                                                                                    <label htmlFor="registerInputFirstName" className="form-label">
                                                                                          
                                                                                          {label[NameConstants.Role]}
                                                                                    </label>
                                                                                    <input type="text" className="form-control" id="registerInputRole" name={NameConstants.Role} placeholder={placeholders[NameConstants.Role]} onChange={changeHandler} value={state[NameConstants.Role].value} onFocus={()=>{}} disabled={false}
                                                                                    onBlur={onfocusHandler}
                                                                                    />
                                                                                    {state[NameConstants.Role].error &&
                                                                                    <div className="form-label-error">
                                                                                          {state[NameConstants.Role].errorMessage}
                                                                                    </div>}
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                              <div className="form-group">
                                                                                    <label htmlFor="joinInputNearestCity" className="form-label">
                                                                                                {label[NameConstants.NearestCity]}
                                                                                    </label>
                                                                                    <select className="form-control tomselect tomselect-xl-lg" id="joinInputNearestCity" aria-label="Nearest City" autoComplete="off"  name={NameConstants.NearestCity} value={state[NameConstants.NearestCity].value} onChange={changeHandler}  disabled={false === true ? true :false} readOnly={false === true ? true :null} placeholder={placeholders[NameConstants.NearestCity]}>
                                                                                                {/* <option value="0" hidden>Select City</option> */}
                                                                                                <option value="1">Aberdeen</option>
                                                                                                <option value="2">Armagh</option>
                                                                                                <option value="3">Bangor</option>
                                                                                                <option value="4">Barnsley</option>
                                                                                                <option value="5">Basildon</option>
                                                                                                <option value="6">Basingstoke</option>
                                                                                                <option value="7">Bath</option>
                                                                                                <option value="8">Bedford</option>
                                                                                                <option value="9">Belfast</option>
                                                                                                <option value="10">Birkenhead</option>
                                                                                                <option value="11">Birmingham</option>
                                                                                                <option value="12">Blackburn</option>
                                                                                                <option value="13">Blackpool</option>
                                                                                                <option value="14">Bolton</option>
                                                                                                <option value="15">Bournemouth</option>
                                                                                                <option value="16">Bradford</option>
                                                                                                <option value="17">Brighton</option>
                                                                                                <option value="18">Brighton and Hove</option>
                                                                                                <option value="19">Bristol</option>
                                                                                                <option value="20">Cambridge</option>
                                                                                                <option value="21">Canterbury</option>
                                                                                                <option value="22">Cardiff</option>
                                                                                                <option value="23">Carlisle</option>
                                                                                                <option value="24">Chelmsford</option>
                                                                                                <option value="25">Cheltenham</option>
                                                                                                <option value="26">Chester</option>
                                                                                                <option value="27">Chesterfield</option>
                                                                                                <option value="28">Chichester</option>
                                                                                                <option value="29">Colchester</option>
                                                                                                <option value="30">Coventry</option>
                                                                                                <option value="31">Crawley</option>
                                                                                                <option value="32">Darlington</option>
                                                                                                <option value="33">Derby</option>
                                                                                                <option value="34">Doncaster</option>
                                                                                                <option value="35">Dundee</option>
                                                                                                <option value="36">Durham</option>
                                                                                                <option value="37">Eastbourne</option>
                                                                                                <option value="38">Edinburgh</option>
                                                                                                <option value="39">Ely</option>
                                                                                                <option value="40">Exeter</option>
                                                                                                <option value="41">Gateshead</option>
                                                                                                <option value="42">Gillingham</option>
                                                                                                <option value="43">Glasgow</option>
                                                                                                <option value="44">Gloucester</option>
                                                                                                <option value="45">Grimsby</option>
                                                                                                <option value="46">Halifax</option>
                                                                                                <option value="47">Hartlepool</option>
                                                                                                <option value="48">Hastings</option>
                                                                                                <option value="49">Hemel Hempstead</option>
                                                                                                <option value="50">Hereford</option>
                                                                                                <option value="51">High Wycombe</option>
                                                                                                <option value="52">Huddersfield</option>
                                                                                                <option value="53">Inverness</option>
                                                                                                <option value="54">Ipswich</option>
                                                                                                <option value="55">Kingston upon Hull</option>
                                                                                                <option value="56">Lancaster</option>
                                                                                                <option value="57">Leeds</option>
                                                                                                <option value="58">Leicester</option>
                                                                                                <option value="59">Lichfield</option>
                                                                                                <option value="60">Lincoln</option>
                                                                                                <option value="61">Lisburn</option>
                                                                                                <option value="62">Liverpool</option>
                                                                                                <option value="63">London</option>
                                                                                                <option value="64">Londonderry</option>
                                                                                                <option value="65">Luton</option>
                                                                                                <option value="66">Maidstone</option>
                                                                                                <option value="67">Manchester</option>
                                                                                                <option value="68">Middlesbrough</option>
                                                                                                <option value="69">Milton Keynes</option>
                                                                                                <option value="70">Newcastle upon Tyne</option>
                                                                                                <option value="71">Newport</option>
                                                                                                <option value="72">Newry</option>
                                                                                                <option value="73">Northampton</option>
                                                                                                <option value="74">Norwich</option>
                                                                                                <option value="75">Nottingham</option>
                                                                                                <option value="76">Nuneaton</option>
                                                                                                <option value="77">Oldham</option>
                                                                                                <option value="78">Oxford</option>
                                                                                                <option value="79">Peterborough</option>
                                                                                                <option value="80">Plymouth</option>
                                                                                                <option value="81">Poole</option>
                                                                                                <option value="82">Portsmouth</option>
                                                                                                <option value="83">Preston</option>
                                                                                                <option value="84">Raleigh</option>
                                                                                                <option value="85">Reading</option>
                                                                                                <option value="86">Ripon</option>
                                                                                                <option value="87">Rochdale</option>
                                                                                                <option value="88">Rotherham</option>
                                                                                                <option value="89">Sale</option>
                                                                                                <option value="90">Salford</option>
                                                                                                <option value="91">Salisbury</option>
                                                                                                <option value="92">Sheffield</option>
                                                                                                <option value="93">Slough</option>
                                                                                                <option value="94">Solihull</option>
                                                                                                <option value="95">Southampton</option>
                                                                                                <option value="96">Southend-on-Sea</option>
                                                                                                <option value="97">Southport</option>
                                                                                                <option value="98">St Albans</option>
                                                                                                <option value="99">St Davids</option>
                                                                                                <option value="100">St Helens</option>
                                                                                                <option value="101">Stevenage</option>
                                                                                                <option value="102">Stirling</option>
                                                                                                <option value="103">Stockport</option>
                                                                                                <option value="104">Stoke-on-Trent</option>
                                                                                                <option value="105">Sunderland</option>
                                                                                                <option value="106">Sutton Coldfield</option>
                                                                                                <option value="107">Swansea</option>
                                                                                                <option value="108">Swindon</option>
                                                                                                <option value="109">Telford</option>
                                                                                                <option value="110">Truro</option>
                                                                                                <option value="111">Wakefield</option>
                                                                                                <option value="112">Warrington</option>
                                                                                                <option value="113">Watford</option>
                                                                                                <option value="114">Wells</option>
                                                                                                <option value="115">Westminster</option>
                                                                                                <option value="116">Weston-super-Mare</option>
                                                                                                <option value="117">Wigan</option>
                                                                                                <option value="118">Winchester</option>
                                                                                                <option value="119">Woking</option>
                                                                                                <option value="120">Wolverhampton</option>
                                                                                                <option value="121">Worcester</option>
                                                                                                <option value="122">Worthing</option>
                                                                                                <option value="123">York</option>
                                                                                    </select>
                                                                                    {state[NameConstants.NearestCity].error && 
                                                                                    <div className="form-label-error">
                                                                                          {state[NameConstants.NearestCity].errorMessage}
                                                                                    </div>}
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>

                                                                  </div>

                                                                  <div className="row row__form-fields">
                                                                        <div className="col-md-12">
                                                                              <div className="form-group">
                                                                                    <label htmlFor="registerInputPhoneNumber" className="form-label">
                                                                                          {label[NameConstants.PhoneNumber]}
                                                                                    </label>
                                                                                    <input type="text" className="form-control" id="registerInputPhoneNumber" name={NameConstants.PhoneNumber} placeholder={placeholders[NameConstants.PhoneNumber]} onChange={changeHandler} value={state[NameConstants.PhoneNumber].value} onFocus={onfocusHandler} disabled={false}/>
                                                                                    {state[NameConstants.PhoneNumber].error && 
                                                                                    <div  className="form-label-error">
                                                                                          {state[NameConstants.PhoneNumber].errorMessage}
                                                                                    </div>}
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.row --> */}

                                                                  <div className="row">
                                                                        <div className="col-xxl-7 order-xxl-2">
                                                                              <div className="form-group">
                                                                                    <label htmlFor="joinInputComments" className="form-label fw-normal">{label[NameConstants.Comments]}</label>
                                                                                    <textarea className="form-control form-control-xl-lg" id="joinInputComments" name={NameConstants.Comments} value={state[NameConstants.Comments].value} onChange={changeHandler} onFocus={onfocusHandler} disabled={false}></textarea>
                                                                                    {state[NameConstants.Comments].error && 
                                                                                    <div className="form-label-error">
                                                                                          {state[NameConstants.Comments].errorMessage}
                                                                                    </div>}
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                        <div className="col-xxl-5 order-xxl-1">
                                                                              <div className="form-group form-group__file">
                                                                                    <label className="form-label">{label[NameConstants.WorkIDCard]}</label>
                                                                                    <div className="d-flex flex-row flex-xxl-column">
                                                                                          <div className="form-file-wrapper">
                                                                                                <div className="custom-form-file">
                                                                                                      <button className="btn btn__custom-file-upload" disabled={false}>Choose Image</button>
                                                                                                      <input type="file" name={NameConstants.WorkIDCard} accept="image/*" onChange={changeHandler} onFocus={onfocusHandler}  disabled={false}/>
                                                                                                      {state[NameConstants.WorkIDCard].value && <img className='image-preview' src={preview}  alt=""/>} 
                                                                                                      {!state[NameConstants.WorkIDCard].value &&<div className='dummy-image-preview' src=""  alt=""></div>}
                                                                                                      
                                                                                                </div>
                                                                                          </div>
                                                                                          <div>
                                                                                                <p className="p-asterisk mb-0">
                                                                                                      *Please upload your work ID card or something that indicates that you work with, or provide services to the NHS.
                                                                                                </p>
                                                                                          </div>
                                                                                    </div>
                                                                                    {state[NameConstants.WorkIDCard].error && 
                                                                                          <div className="form-label-error">
                                                                                                {state[NameConstants.WorkIDCard].errorMessage}
                                                                                          </div>}
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.row --> */}


                                                                  <div className="row row__checkboxes">
                                                                        <div className="col-md-12">

                                                                              <div className="form-group form-group__custom-controls">
                                                                                    <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                                          <input type="checkbox" className="custom-control-input" id="checkReceiveAlerts" name={NameConstants.CheckReceiveAlerts} onChange={changeHandler} value={state[NameConstants.CheckReceiveAlerts].value} checked={state[NameConstants.CheckReceiveAlerts].value} onFocus={onfocusHandler} disabled={false}/>
                                                                                          <label className="custom-control-label" htmlFor="checkReceiveAlerts">
                                                                                                I provide my consent to receive marketing communications from Tickets For Medics. 
                                                                                          </label>

                                                                                          {state[NameConstants.CheckReceiveAlerts].error && 
                                                                                          <div className="form-label-error">
                                                                                                {state[NameConstants.CheckReceiveAlerts].errorMessage}
                                                                                          </div>}
                                                                                    </div>
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}

                                                                              <div className="form-group form-group__custom-controls">
                                                                                    <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                                          <input type="checkbox" className="custom-control-input" id="checkAgreeTerms" name={NameConstants.CheckAgreeTOS} onChange={changeHandler} value={state[NameConstants.CheckAgreeTOS].value} checked={state[NameConstants.CheckAgreeTOS].value} onFocus={onfocusHandler} disabled={false}/>
                                                                                          <label className="custom-control-label" htmlFor="checkAgreeTerms">
                                                                                                I understand that joining Tickets For Medics is subject to its <Link className="blue text-decoration-none" to="/terms" target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> and <Link className="blue text-decoration-none" to="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy.</Link>
                                                                                          </label>
                                                                                          {state[NameConstants.CheckAgreeTOS].error &&
                                                                                          <div className="form-label-error ">
                                                                                                {state[NameConstants.CheckAgreeTOS].errorMessage}
                                                                                          </div>}
                                                                                    </div>
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}

                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.row --> */}

                                                                  <div className="row row__cta">
                                                                        <div className="col-md-12">
                                                                              <div className="form-group form-group__cta">
                                                                                    <button type="button" className="btn btn-xl-lg btn-primary" onClick={submitJoinDataHandler}
                                                                                    disabled={false}>
                                                                                          {actions.createAccount}
                                                                                    </button>
                                                                                    <a href="/" className="btn btn__back d-flex align-items-center px-3 ps-lg-2 pe-lg-0" onClick={backHandler}>{actions.back}</a>
                                                                              </div>
                                                                              {/* <!-- /.form-group --> */}
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- /.row --> */}
                                                            </form>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}

                                          </div>
                                          {/* <!-- /.col__right --> */}

                                    </div>
                                    {/* <!-- /.content__body --> */}

                              </div>
                        </div>
                                                
                                          
                  </React.Fragment>
      )
}

export default JoinAltStep1;
