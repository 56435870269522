export const Config = {
    // API_URL: 'https://cooking.centraltickets.co.uk/frontend/',
      API_URL: 'https://x9s1p7-service.centraltickets.co.uk/frontend/',
     //API_URL: 'https://reactapi.centraltickets.co/',
     //  API_URL: 'https://stage.centraltickets.co/',
     // API_URL: 'https://api.centraltickets.co.uk/',
     RECAPTCHA_SITE_KEY : process.env.REACT_APP_RECAPTCHA_SITE_KEY,
     // AJTIX_URL_CHECK : "localhost",
     // TFM_URL_CHECK : "localhost",   
     DOMAIN_CONSTANT_AJTIX : "ajtix",
     DOMAIN_CONSTANT_TFM : "ticketsformedics",
     AJTIX_URL_CHECK : "ajtix",
     TFM_URL_CHECK : "ticketsformedics",
     
}
