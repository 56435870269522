import { api } from "./../../../Services/Api";
import { submitAccessNeeds } from "./submitAccessNeeds";

export const accessNeedsApi = api.injectEndpoints({
      endpoints: build => ({
            submitAccessNeeds:submitAccessNeeds(build),
      }),
      overrideExisting: true
})

export const {  useSubmitAccessNeedsMutation } = accessNeedsApi