import { api } from "./../../../Services/Api";
import { otpVerification } from "./otpVerification";
import { sendOTP } from "./sendOtp";

export const otpApi = api.injectEndpoints({
            endpoints: build => ({
            otpVerification :otpVerification(build),
            sendOTP: sendOTP(build)

      }),
      overrideExisting: true
})

export const { useOtpVerificationMutation, useSendOTPMutation } = otpApi