export const getHistory =  build =>
      build.query({
            query: (body) =>  ({
                  url: '/voucher/history',
                  method: 'GET',
                  params: body
            }),
            providesTags: ['getVoucherHistory']
      })

     
      // Changed with new one