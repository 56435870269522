import React, { useState, useEffect } from  'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
// packages
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';

// Actions & RTK query or mutations
import { useFetchApplePayIntentMutation, useWalletWebhookConfirmationMutation } from '../../../../Services/modules/Wallet';
import { setPaymentMethod, setBookingStep, setGetBookingDetails, resetForAnimation } from '../../../../Store/BookingModule';
import { setWalletBalance } from '../../../../Store/User';
import { setSpinner, setAlert,setTimer } from '../../../../Store/UI';
// Constants
import { NameConstants } from '../../../../resources/NameConstants';
import { dateAndTimeFormatHandler } from "../../../../resources/DateHandler";
import { errors, messages } from '../../../../resources/en';

let spinnerTimeOut;

const PaymentMethods = () => {

      const [paymentRequest, setPaymentRequest] =     useState(null);

      const bookingModule                       =     useSelector(state=>state.bookingModule);
      const user                                =     useSelector(state => state.user)
      const eventDetail                         =     useSelector(state=>state.eventDetail);

      const stripe                              =     useStripe();
      const dispatch                            =     useDispatch();

      const [fetchApplePayIntent, { data : IntData, isSuccess : IntIsSuccess, error : IntError, isLoading : IntIsLoading  }] = useFetchApplePayIntentMutation();
      const [walletWebhookConfirmation, { data : WebData, isSuccess : WebIsSuccess, error : WebError, isLoading : WebIsLoading  }] = useWalletWebhookConfirmationMutation();

      useEffect(()=>{

            if(paymentRequest) {

                  const data = prepareDataObject(false,"","",false)
                  dispatch(setSpinner({status:"request"}));
                  fetchApplePayIntent({
                        amount : parseFloat(data.totalAmount * 100),
                        data : data,
                        IntentId : null,
                        source:"widget"
                  })
            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[paymentRequest])

      useEffect(()=>{
            if(IntIsSuccess) {
                  clearTimeout(spinnerTimeOut)
                  spinnerTimeOut = setTimeout(()=>{
                        dispatch(setSpinner({status:""}));
                  },[1000])
            }

            if(IntError){
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:IntError.error || IntError.data.error || IntError.data.message || errors.NotFound404}));
            }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[IntData, IntIsSuccess, IntError, IntIsLoading, dispatch])

      useEffect(()=>{
            
            if(WebIsSuccess) {
                  
                  if(WebData.status === false) {

                        if(WebData.attempt === 4) {
                              dispatch(setTimer({timer:null}));
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:messages.FailureApplePay}));
                              dispatch(setSpinner({status:""}));
                              dispatch(resetForAnimation())
                        }else {
                              const timer = WebData.attempt === 2 ? 4000 : 5000;
                              setTimeout(()=>{
                                    walletWebhookConfirmation({ paymentIntent : WebData.paymentIntent, attempt : WebData.attempt, sessionid: IntData.session_id })
                              },timer)
                        }
                  } 
                  else if(WebData.status === true) {
                        dispatch(setTimer({timer:null}));
                        dispatch(setSpinner({status:""}));
                        dispatch(setWalletBalance({ct_wallet_balance:WebData.wallet_balance}))
                        dispatch(resetForAnimation());
                        dispatch(setGetBookingDetails({getBookingDetails: WebData.getBookingDetails }))
                        dispatch(setBookingStep({step: NameConstants.bookingModuleSteps.bookingSuccess }))
                  }
            }

            if(WebError){

                  dispatch(resetForAnimation())
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:WebError.error || WebError.data.error || WebError.data.message || errors.NotFound404}));
            }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[WebData, WebIsSuccess, WebError, WebIsLoading, dispatch])

      useEffect(() => {

            if (stripe) {
                  let  totalAmountToBePaid =  parseFloat(calculateTotalAmountToBePaid());

                  const pr = stripe.paymentRequest({
                        country: 'GB',
                        currency: 'gbp',
                        total: {
                              label: bookingModule.title ? bookingModule.title : 'Central Tickets Event Booking',
                              amount: Math.trunc(totalAmountToBePaid*100),
                        },
                        disableWallets: ['browserCard','link'],
                        requestPayerName: true,
                        requestPayerEmail: true,
                  });
    
                  // Check the availability of the Payment Request API.
                  pr.canMakePayment().then(result => {
                        if (result) {
                              setPaymentRequest(pr);
                        }
                  })

                  pr.on('cancel', () =>{
                        dispatch(setSpinner({status:""}));
                  });

                  pr.on('loaderror', function(event) {
                        dispatch(setSpinner({status:""}));
                  });   
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [stripe]);

      const onPaymentSubmit = () => {
            dispatch(setSpinner({status:"request"}));
            paymentRequest.on("paymentmethod", handlePaymentRequest);
      };

      const handlePaymentRequest = async (event) => {
           
            const  clientSecret  = IntData.intent;
            // const  session_id  = IntData.session_id;

            if (!clientSecret) {         
                  event.complete("fail");
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:'Invalid Client Secret'}));
            }        
            
        
            const { error, paymentIntent } = await stripe.confirmCardPayment(
                  clientSecret,
                  {
                        payment_method: event.paymentMethod.id,
                  },
                  {
                        handleActions: false,
                  }
            );
        
            if (error) {
                  event.complete("fail");
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:'Invalid Client Secret'}));
            }
        
            if(paymentIntent.status === "requires_action") {

                  stripe.confirmCardPayment(clientSecret);
            }
           
            //    Show a success message to your customer
            //                   There's a risk of the customer closing the window before callback
            //                   execution. Set up a webhook or plugin to listen for the
            //                   payment_intent.succeeded event that handles any business critical
            //                   post-payment actions.

            setTimeout(()=>{
                  walletWebhookConfirmation({ paymentIntent : paymentIntent, attempt : 1, sessionid: IntData.session_id})
            },2000);

            event.complete("success");
      };

      const changePaymentMethodHandler = (name)=> {
            
            let step = "";  
            let useWalletCredit = 0;
            let useCardCredit = 0;
            let useApplePayCredit = 0;
            let subTotal = parseFloat(bookingModule.bookingDetails.subTotal);
            
            if(bookingModule.bookingDetails.promocode.status === "valid") {
                  subTotal = parseFloat(bookingModule.bookingDetails.promocode.totalAmount);
                  
                  if(bookingModule.bookingDetails.isFlexi){
                        subTotal = parseFloat(subTotal + bookingModule.bookingDetails.flexAmount);
                  }

                  if(bookingModule.bookingDetails.charity) {
                        subTotal = parseFloat(subTotal + NameConstants.charity);
                  }
            }
            
            if(name === NameConstants.wallet) {
                  step = NameConstants.bookingModuleSteps.paymentViaWallet;
            } else if(name === NameConstants.card) {
                  step = NameConstants.bookingModuleSteps.orderSummaryPay;
                  useCardCredit = subTotal;
            } else if(name === NameConstants.applepay) {
                  step = NameConstants.bookingModuleSteps.orderSummaryPay;
                  useApplePayCredit = subTotal;

                  console.log('here applye pay method')
                  return;
            }

            dispatch(setPaymentMethod({paymentMethod : name, useWalletCredit : useWalletCredit, useCardCredit : useCardCredit, useApplePayCredit : useApplePayCredit}));
            dispatch(setBookingStep({step : step}));
      }


      const options = {
            paymentRequest,
            style: {
              paymentRequestButton: {
                type: 'default',
                // One of 'default', 'book', 'buy', or 'donate'
                // Defaults to 'default'
          
                theme: 'light-outline',
                border: '10px solid red',
                // One of 'dark', 'light', or 'light-outline'
                // Defaults to 'dark'
          
                height: '53px',
                // Defaults to '40px'. The width is always '100%'.
              },
            }
      }

      const backOnPaymentMethod = ()=> {
            dispatch(setPaymentMethod({paymentMethod : "", useWalletCredit : 0, useCardCredit : 0, useApplePayCredit : 0}));
            dispatch(setBookingStep({step : NameConstants.bookingModuleSteps.orderSummary }));
      }

      const calculateTotalAmountToBePaid = ()=>{
            let  totalAmountToBePaid = parseFloat(bookingModule.bookingDetails.subTotal).toFixed(2);
            if(bookingModule.bookingDetails.promocode.status === "valid"){
                  let charity = 0;
                  if(bookingModule.bookingDetails.charity){
                        charity = NameConstants.charity;
                  }
                  totalAmountToBePaid = parseFloat(bookingModule.bookingDetails.promocode.totalAmount + bookingModule.bookingDetails.flexAmount + charity).toFixed(2)
            }

            return totalAmountToBePaid;
      }

      const prepareDataObject = (reusable, card_identifier, mKey,saveCardFuture) => {

            
           
            let payment_method = "";

            // calculate total amount to be paid
            let  totalAmountToBePaid = calculateTotalAmountToBePaid();

            let ticket_type_master_id = bookingModule.performanceInfo.ticket_type_master_id
            let ticket_type_id = bookingModule.performanceInfo.ticket_type_id

            const date = dateAndTimeFormatHandler(bookingModule.performanceInfo.date_time,bookingModule.performanceInfo.show_time).split(" ");
            payment_method = bookingModule.payment.paymentMethod === "apple_pay";

            const data = {
                  event_id: bookingModule.event_id,
                  token: user.ct_access_token,
                  client: user.ct_web_view_session ? "app" : "web",
                  request_client : user.ct_web_view_session ? "app" : "web",
                  coupan_code_id : bookingModule.bookingDetails.promocode.coupan_code_id || "",
                  promocode: bookingModule.bookingDetails.promocode.promocode,
                  performance_id: bookingModule.bookingDetails.showId,
                  discount_amount: bookingModule.bookingDetails.promocode.discount,
                  price: bookingModule.bookingDetails.price,
                  wallet_balance: parseFloat(user.ct_wallet_balance),
                  amount_to_be_paid_by_card: parseFloat(totalAmountToBePaid),
                  wallet_balance_used: parseFloat(bookingModule.payment.useWalletCredit),
                  pennies_amount: bookingModule.bookingDetails.charity ? NameConstants.charity : 0,
                  totalAmount: parseFloat(totalAmountToBePaid),
                  flexi_price: bookingModule.bookingDetails.flexAmount,
                  is_flexi: bookingModule.bookingDetails.isFlexi,
                  tickets: bookingModule.bookingDetails.quantity,
                  group_availablity: bookingModule.group_availablity,
                  ticket_type_master_id: ticket_type_master_id,
                  ticket_type_id: ticket_type_id,
                  block_id: user.ct_session_block_id,
                  event_name: bookingModule.title,
                  address: eventDetail.eventInfo.address,
                  performance_date_time: bookingModule.performanceInfo.show_time,
                  merchantSessionKey: mKey,
                  card_identifier: card_identifier,
                  reusable_payment: reusable,
                  reusable: saveCardFuture,
                  get_current_id: "order_summary_view",
                  get_next_id: "card_details_view",
                  apple_pay_id: true,
                  raw_date: date[0],
                  start_time: date[2],
                  ticket_type_name: "",
                  payment_method: payment_method,
                  timer: "",
                  session_id : user.ct_web_view_session ? user.ct_web_view_session : "",
            }

            return data;
      }

      let displayTotalOnPaymentMethod = bookingModule.bookingDetails.subTotal.toFixed(2);

      if(bookingModule.bookingDetails.promocode.status === "valid") {
            let charity = 0;
            if(bookingModule.bookingDetails.charity){
                  charity = NameConstants.charity;
            }
            displayTotalOnPaymentMethod = parseFloat(bookingModule.bookingDetails.promocode.totalAmount + bookingModule.bookingDetails.flexAmount + charity).toFixed(2);
      }

      return (
            <div className="bookTicketsStep3__wrapper">
                  <div id="bookTicketsStep3" className="content content__payment-options d-flex">
                        <div className="content__body">
      
                              <h4 className="heading">Use your wallet</h4>
                              <button id="btnPaymentMethodWallet" type="button"  className="btn btn__payment-method w-100"  onClick={()=>changePaymentMethodHandler(NameConstants.wallet)}>
                                    <span className="button__icon button__icon--wallet"></span>
                                    <span className="button__text">
                                          <span className="sub-heading__upper">Your current credit</span>
                                          <span className="heading">£ {parseFloat(user.ct_wallet_balance).toFixed(2)}</span>
                                    </span>
                                    <span className="button__arrow button__arrow--right"></span>
                              </button>
      
                              <h4 className="heading">Other payment methods</h4>
                              <button id="btnPaymentMethodDebitCredit" type="button" className="btn btn__payment-method w-100" onClick={()=>changePaymentMethodHandler(NameConstants.card)}>
                                    <span className="button__icon button__icon--debitcredit"></span>
                                    <span className="button__text">
                                          <span className="heading">Debit or Credit Card</span>
                                    </span>
                                    <span className="button__arrow button__arrow--right"></span>
                              </button>
                              {(paymentRequest && !IntIsLoading) &&<div className="payment-request-button"> <PaymentRequestButtonElement  options={options} onClick={onPaymentSubmit} /></div>}

                              {/* <button id="btnPaymentMethodApplePay" type="button" className="btn btn__payment-method w-100" onClick={()=>changePaymentMethodHandler(NameConstants.applepay)}>
                                    <span className="button__icon button__icon--poundsterling"></span>
                                    <span className="button__text">
                                          <span className="heading">Apple <span className="icon__apple"></span>Pay</span>
                                    </span>
                                    <span className="button__arrow button__arrow--right"></span>
                              </button> */}
      
                        </div>
                        {/* <!-- /.content__body --> */}

                       
                  </div>

                  <div className="content__footer__wrapper">
                        <div className="content__body content__body--total border-top">
                              <div className="subtotal-block">
                                    <div className="label">Total (VAT inc.):</div>
                                    <div className="total-amount">£{displayTotalOnPaymentMethod}</div>
                              </div>
                        </div>
                        {/* <!-- /.content__body --> */}

                        <div className="content__footer border-top">
                              <div className="buttons-wrapper gap-0">
                                    <button id="btnBackToStep2" type="button" className="btn__back btn btn-outline-primary w-50" onClick={backOnPaymentMethod}>
                                          Back
                                    </button>
                                    <button type="button" className="btn btn-primary w-50" style={{"cursor":"not-allowed"}}>
                                          Book now
                                    </button>
                              </div>
                        </div>
                        {/* <!-- /.content__footer --> */}
                  </div>
             </div>
      )
}

export default PaymentMethods;
