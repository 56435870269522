export const favUnFav =  build =>
      build.mutation({
            query: (id) =>  ({
                  url: '/event/favoriteAndUnfavourite',
                  method: 'POST',
                  body: { event_id: id }
            }),
            // invalidatesTags: ['MemberFavorites']
      })

      // Changed with new one