import React from 'react';
import "./EventDetailImageDummy.css"

const EventDetailTagsDummy = (props) => {

      return <li className={['list__event-dummyTags',props.tabInfo && "tabInfo"].join(" ")}>
                  <div className="badge badge-outline fw-normal m-1">
                  </div>
            </li>
}
export default EventDetailTagsDummy;
