import React from 'react';

// external css
// redux and react hooks
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// child component
import { HeadingPictureComponent } from '../../../Components';

// packages
// Actions & RTK query or mutations
// Constants
import { routesName } from '../../../resources/RoutesName';
import { Config } from '../../../Config';

const ContainerBigHeading = () => {

      const domain      =     useSelector(state=>state.user.domain)



      return (

            <div className="container-fluid container__block container__big-heading">
                  <div className="row">
                        <div className="col-md-12">

                              <div className="content content__membership-is-free text-center">
                                    <div className="content__header">
                                          <h6 className="sub-heading sub-heading-upper">Did we mention</h6>
                                          <div className="big-heading">
                                                <h2 className="heading h1 mb-0">Membership is free?</h2>

                                                <HeadingPictureComponent dataScroll className="ticket-aquamarine" srcSet="ticket-home-big-heading2-aquamarine.svg" src="ticket-home-big-heading2-aquamarine-sm.svg"/>

                                                <HeadingPictureComponent dataScroll className="ticket-green" srcSet="ticket-home-big-heading2-green.svg" src="ticket-home-big-heading2-green-sm.svg"/>

                                                <HeadingPictureComponent dataScroll className="ticket-blue" srcSet="ticket-home-big-heading2-blue.svg" src="ticket-home-big-heading2-blue-sm.svg"/>

                                                <HeadingPictureComponent dataScroll className="ticket-orange" srcSet="ticket-home-big-heading2-orange.svg" src="ticket-home-big-heading2-orange-sm.svg"/>

                                                <HeadingPictureComponent dataScroll className="ticket-yellow" srcSet="ticket-home-big-heading2-yellow.svg" src="ticket-home-big-heading2-yellow-sm.svg"/>
                                                
                                          </div>
                                    </div>
                                    {/* <!-- .content__header --> */}

                                    <div className="content__body">
                                          <p className="lead">
                                                Membership with {domain === Config.DOMAIN_CONSTANT_TFM ? "Tickets For Medics"  : "Central"} is totally free.  Seat-filling tickets range from £4 to £15 and you’ll have access to special offers for West End shows via our ticketing partners.
                                          </p>
                                    </div>
                                    {/* <!-- .content__body --> */}

                                    <div className="content__cta">
                                          <Link className="btn btn-xl-lg btn-primary" to={routesName.Register}>
                                                Join us
                                          </Link>
                                    </div>
                                    {/* <!-- .content__cta --> */}
                              </div>
                              {/* <!-- .content --> */}
                        </div>
                        {/* <!-- .col-md-12 --> */}
                  </div>
                  {/* <!-- /.row --> */}
            </div>
                  
      )
}

export default ContainerBigHeading;
