import React from 'react'

import { useSelector } from 'react-redux';


import ChangeEmail from './ChangeEmail';
import ChangeMobileNumber from './ChangeMobileNumber';
import ChangePassword from './ChangePassword';
import DeleteAccount from './DeleteAccount';
import EmailPreferences from './EmailPreferences';
import SavedCards from "./SavedCards";

const AccountSettingsMobile = () => {


      const {ct_is_top_member, ct_is_change_password} = useSelector(state=>state.user)

      return (
                  <div className="accordion accordion__my-account-settings accordion__circle-arrow d-md-none">
                        {/* <!-- item 1 --> */}
                        <ChangePassword/>

                        {/* <!-- item 2 --> */}
                        {!ct_is_change_password && <ChangeEmail/>}

                        {/* <!-- item 3 --> */}
                        {!ct_is_change_password && <ChangeMobileNumber/>}
                       
                        {/* <!-- item 4 --> */}
                        {!ct_is_change_password && <EmailPreferences/>}

                        {/* <!-- item 5 --> */}
                        {(ct_is_top_member === 1 && !ct_is_change_password) && 
                        <SavedCards />}

                        {/* <!-- item 6 --> */}
                        {!ct_is_change_password && <DeleteAccount/>}
                  </div>
      )
}

export default AccountSettingsMobile;
