import { api } from "./../../../Services/Api";
import { getFeedback } from "./getFeedback";
import { postFeedback } from "./postFeedback";

export const feedbackApi = api.injectEndpoints({
            endpoints: build => ({
            getFeedback:getFeedback(build),
            postFeedback:postFeedback(build)
      }),
      overrideExisting: true
})

export const {  useLazyGetFeedbackQuery, usePostFeedbackMutation } = feedbackApi;