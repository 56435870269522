import React, {  useEffect } from 'react'

// external css
import "./WebViewPayment.css"

// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation,useNavigate } from 'react-router-dom';

// child component
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from "./Step4"
import Loader from '../../Ui/BookingModuleLoader';
import GiftVoucherTerms from './Modal/WalletTerms';

// packages
// Actions & RTK query or mutations
import { useLazyGetSessionKeyDetailsQuery } from '../../Services/modules/BookingModule';
import { setPaymentParams, setSpinner, setGiftVoucherTerms } from '../../Store/WebViewPayment';
import { setAlert } from '../../Store/UI';
import { changeCtAcessRefreshToken, setTomMember, setWalletBalance, setWebViewSession, setDarkMode } from '../../Store/User';


// Constants
import { messages, errors } from '../../resources/en';

const WebViewPayment  = (props) => {

      const webViewPayment                      =     useSelector(state=>state.webViewPayment)
      const dispatch                            =     useDispatch()
      const { token }                           =     useParams()
      const { pathname, search }                =     useLocation();
      const navigate                            =     useNavigate()

      const [getSessionKeyDetails, { data, isSuccess, error }] = useLazyGetSessionKeyDetailsQuery()

      useEffect(()=>{
            const searchParams = new URLSearchParams(search);  
            const darkMode = searchParams.get('darkMode')?.toString()
            if(darkMode === "true") {
                  document.body.classList.add('dark-mode');
                  dispatch(setDarkMode({ct_app_dark_mode : true}))
            } else {
                  document.body.classList.remove('dark-mode');
                  dispatch(setDarkMode({ct_app_dark_mode : false}))
            }

            if(pathname.includes("payment-success") || pathname.includes("payment-failure")){

                  const searchParams = new URLSearchParams(search);
                  const client = searchParams.get('client');
                  const paymentStatus = pathname.includes("payment-success") ? "success" : "failure";
                  // const message = paymentStatus === "success" ?  messages.SuccessAddVoucher : messages.FailureAddVoucher;
                  if(client === "app"){
                        // if(window.ReactNativeWebView){
                        //       if(paymentStatus === "success"){
                        //             dispatch(setPaymentParams({
                        //                   step : "step3",
                        //                   paymentMethod : "",
                        //                   amount : "",
                        //                   sessionId : token || ""
                        //             }))
                        //       } 
                        //       else {

                        //             dispatch(setPaymentParams({
                        //                   step : "step4",
                        //                   paymentMethod : "",
                        //                   amount : "",
                        //                   sessionId : token || ""
                        //             }))
                        //             // dispatch(setAlert({model:true,title:paymentStatus === "success" ? messages.modalSuccessTitle :messages.modalAlertTitle,message: message}))
                        //       }
                        //       // window.ReactNativeWebView.postMessage(JSON.stringify({
                        //       //       "stay": true,
                        //       //       "redirect": false,
                        //       //       "redirectTo": "",
                        //       //       "status": paymentStatus === "success" ? 1 : 0,
                        //       //       "message": message
                        //       // }));
                        // } 
                        // else {
                              if(paymentStatus === "success"){
                                    dispatch(setPaymentParams({
                                          step : "step3",
                                          paymentMethod : "",
                                          amount : "",
                                          sessionId : token || ""
                                    }))
                              } 
                              else {

                                    dispatch(setPaymentParams({
                                          step : "step4",
                                          paymentMethod : "",
                                          amount : "",
                                          sessionId : token || ""
                                    }))
                                    // dispatch(setAlert({model:true,title:paymentStatus === "success" ? messages.modalSuccessTitle :messages.modalAlertTitle,message: message}))
                              }
                        // }
                  }
                  navigate(`/vouchers-for-gift/${webViewPayment.sessionId || token}`, { replace : true });
                  dispatch(setSpinner({status : ""}));
            } else {
                  dispatch(setSpinner({status : "request"}));
                  getSessionKeyDetails({ session_id : token })
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[getSessionKeyDetails, token, dispatch])

      useEffect(()=>{
            if(data) {
                  if(data.status === "1") {      
                        dispatch(setSpinner({status : ""}))
                        dispatch(setWebViewSession({ct_web_view_session : token }))
                        dispatch(setPaymentParams({
                              step : "step1",
                              paymentMethod : "",
                              amount : data.data.voucher_amount,
                              sessionId : token
                        }))
                        dispatch(changeCtAcessRefreshToken({ ct_access_token : data.data.token, ct_refresh_token : data.data.refresh_token || ""}))
                        dispatch(setTomMember({ct_is_top_member : data.data.is_top_member || 0}))  
                        dispatch(setWalletBalance({ ct_wallet_balance : parseFloat(data.data.wallet_balance)}))
                  } else {
                       
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404 }))
                  }
            }

            if(error) {
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, error, isSuccess])

      // const closeOrResetHandler = ()=> {
      //       dispatch(setPaymentParams({
      //             step : "step1",
      //             paymentMethod : "",
      //             amount : "",
      //             sessionId : token || ""
      //       }))

      //       dispatch(setMKey({mKey: ""}))
      // }

      const termsHandler = ()=> {
            dispatch(setGiftVoucherTerms({ GiftVoucherTerms : !webViewPayment.GiftVoucherTerms}))
      }


      return (
                  <React.Fragment>
                        {webViewPayment.GiftVoucherTerms && <GiftVoucherTerms termsHandler={termsHandler}/> }
                        <div id="WebViewPayment">

                              <div className="slidein-wrapper show">
                                    <div className="slidein slidein__wallet-topup slidein__gift-voucher">

                                          <div className="content__slidein content-scrollable">

                                                <form>
                                                      <div className="content content__checkout content__wallet-topup">

                                                            <div className="content__header border-bottom position-relative">
                                                                  {/* <button type="button" className="btn-close btn-close__custom btn-close__absolute" aria-label="Close" onClick={closeOrResetHandler}>

                                                                  </button> */}
                                                                  <div className="content my-auto">
                                                                        <h4 className="content__title my-auto mb-0">
                                                                              {webViewPayment.step === "step3" ? "Success - gift credit purchase confirmed" : "Top up your wallet to Gift wallet credit"}
                                                                        </h4>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.content__header --> */}

                                                            {webViewPayment.step === "step1" && <Step1 termsHandler={termsHandler} />}
                                                            {webViewPayment.step === "step2" && <Step2/>}
                                                            {webViewPayment.step === "step3" && <Step3/>}
                                                            {webViewPayment.step === "step4" && <Step4/>}
                                                            {webViewPayment.spinner === "request"  && <Loader show={true}/>}
                                                      </div>
                                                      {/* <!-- /.content__wallet-topup --> */}

                                                </form>

                                          </div>
                                          {/* <!-- /.content__slidein --> */}

                                    </div>
                                    {/* <!-- /.slidein --> */}
                              </div>
                        </div>
                  </React.Fragment>
      )
}

export default WebViewPayment;
