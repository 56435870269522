import React, { useState, useEffect } from 'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
import { SelectComponent } from '../../../../Components';

// packages
// Actions & RTK query or mutations
import { useGetTicketTypeBookingInfoMutation } from '../../../../Services/modules/Bookings';
import { setTicketsHandler, setStepHandler, setTicketsType, reset } from '../../../../Store/CurruntBookings';
import { setAlert } from '../../../../Store/UI';

// Constants
import { errors, messages } from '../../../../resources/en';

const Step2 = () => {

      const [tickets,setTickets] = useState([]);
      const [ticError,setTicError] = useState({error:false,errorMessage:""});
      const {booking_id, totalTickets, numberOfTickets, eventType, ticketsType} = useSelector(state=>state.curruntBookings);

      const dispatch = useDispatch();

      const [getTicketTypeBookingInfo, { data, isSuccess, isLoading, isFetching, error}] = useGetTicketTypeBookingInfoMutation();

      useEffect(()=>{
            if((eventType === "0") || (eventType === 0)) {
                  const ticketsArray = [];
                 
                  for (let i = 1; i <= totalTickets; i++) {
                        ticketsArray.push(i)
                  }
                  setTickets(ticketsArray)
            }

            if((eventType ==="2") || (eventType === 2)){
                  // ticket type handler
                  getTicketTypeBookingInfo({booking_id:booking_id});
            }
           
      },[totalTickets,eventType,booking_id,getTicketTypeBookingInfo])

      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1" || data.status === 1){
                        
                        const ticketTypesArray = data.data.ticket_types.map(el=>{
                              return {...el,cancelqty:0};
                              // return {...el,cancelqty:parseInt(el.purchased)};
                        });
                        dispatch(setTicketsType({ticketsType:ticketTypesArray}));
                  }else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message }));
                        dispatch(reset());
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
                  dispatch(reset())
            }

      },[ data, isSuccess, isLoading, isFetching, error, dispatch])


      const changeTicketsHandler =(event) => {
            const updateTickets = parseInt(event.target.value) || totalTickets;
            if(updateTickets <= totalTickets) {
                  dispatch(setTicketsHandler({tickets:updateTickets}));
            }
      }

      const confirmReduceBookingHandler = ()=>{

            if(eventType === "2" || eventType === 2) {
                  if(numberOfTickets > 0) {
                        dispatch(setStepHandler({step:'step3'}));
                  } else {
                        setTicError({error:true,errorMessage :errors.selectTicketToCancel});
                  }
            } else {
                  dispatch(setStepHandler({step:'step3'}));
            }
      }

      const changeTicketsTypeHandler = (event) => {
            clearErrorHander();
            const name = event.target.name;
            const value = parseInt(event.target.value);
            let totalNumberTickets = 0;

            const copyTicketTypes = [...ticketsType];
            const findIndex = copyTicketTypes.findIndex(el=>el.name === name);
            if(copyTicketTypes[findIndex].purchased >= value){
                  copyTicketTypes[findIndex] = {...copyTicketTypes[findIndex],cancelqty : value};
            }

            copyTicketTypes.forEach(el=>totalNumberTickets+=el.cancelqty);
            dispatch(setTicketsType({ticketsType:copyTicketTypes}));
            dispatch(setTicketsHandler({tickets:totalNumberTickets}));
      }

      const clearErrorHander = ()=> {
            setTicError({error:false,errorMessage:""});
      }

      let items = []; 
      if(numberOfTickets){
            items.push(numberOfTickets);
      }

      // calculate toalnumber of tickets remain for ticket type
      let remainTickets = totalTickets - numberOfTickets
      if(eventType === "2" || eventType ===2){
            let totalNumberTickets = 0; 
            let totalCancelQty = 0;
            ticketsType.forEach(el=>{
                  totalNumberTickets+=parseFloat(el.purchased)
                  totalCancelQty+=parseFloat(el.cancelqty)
            })
            remainTickets = parseFloat(totalNumberTickets - totalCancelQty)
      }

      return (

            <div id="manageBookingReduceTickets" className="content content__manage-booking-options border-bottom d-flex">
                  <div className="content__body">
                        <h4 className="heading">
                              How many tickets do you want to remove?
                        </h4>
                        { (tickets.length > 0 && eventType !== "2") && <SelectComponent id="selectTicketsTotal" placeholder="Select Ticket Number" items={items} onChange={changeTicketsHandler}>
                              {tickets.length > 0 && tickets.map((item,index)=>{
                                    return <option key={`tickets${index}`} value={item}>{item}</option>
                              })}
                        </SelectComponent>}
                        {(eventType === "2" || eventType ===2) && ticketsType.map((el,index)=>{
                                    let items = []; 
                                    const ticketsArray = [];
                                    for (let i = 1; i <= el.purchased; i++) {
                                          ticketsArray.push(i)
                                    }
                                    if(el.purchased){
                                          items.push(el.cancelqty);
                                    }

                              return (<div className='select-ticket-type-wrapper' key={el.id}>
                                          <h4 className="heading heading__remaining-tickets">
                                                <span className="red">{el.name}</span>
                                          </h4>
                                          <SelectComponent id={`selectTicketsTotal${index}`} placeholder="Select Ticket Number" items={items} name={el.name}  onChange={changeTicketsTypeHandler}>
                                                {ticketsArray.length > 0 && ticketsArray.map((item,index)=>{
                                                      return <option key={`tickets${index}`} value={item}>{item}</option>
                                                })}
                                          </SelectComponent>
                                    </div>)
                        })}
                        {ticError && <p className='form-label-error'>{ticError.errorMessage}</p>}
                        <h4 className="heading heading__remaining-tickets">
                              You’ll then have <span className="red">{remainTickets}</span> tickets.
                        </h4>
                        <div className="buttons-wrapper">
                              <button type="button" className="btn btn-primary" onClick={confirmReduceBookingHandler}>Confirm</button>
                        </div>
                  </div>
                  {/* <!-- /.content__body --> */}
            </div>

      )
}

export default Step2;
