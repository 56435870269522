import React from 'react';

// external css
// redux and react hooks
import { useSelector } from 'react-redux';

// child component

import { 
      ContentUserCat, ContentContactClues, 
      ContactLogin, OrganizerShowForm, 
      OrganizerSimpleForm
      
} from "./index";

// packages
// Actions & RTK query or mutations
// Constants
import { NameConstants } from '../../../resources/NameConstants';
import { ContentUserCatArray, ContentContactCluesArray } from "../../../resources/ContactUsObject";

const ContentBodyQueCat = () => {

      const contactUs = useSelector(state=>state.contactUs);

      return (
            <div className="content__body p-0 d-flex flex-column flex-lg-row">

                  { ContentUserCatArray.includes(contactUs.step) && <ContentUserCat />}

                  { ContentContactCluesArray.includes(contactUs.step) && <ContentContactClues/>}

                  {(contactUs.step === NameConstants.MemberLogin) &&<ContactLogin/>}

                  {(contactUs.step === NameConstants.OrganizerShowForm) && <OrganizerShowForm/>}

                  {(contactUs.step === NameConstants.OrganizerSimpleForm) && <OrganizerSimpleForm/>}
            </div>
      )
}

export default ContentBodyQueCat
