import React from 'react';
import "./MyBookingItemDummy.css"

const MyBookingItemDummy = (props) => {

      return (
            <li className='MyBookingItemDummy'>
                  <div className="card card__event flex-row flex-wrap mx-auto">
                        <div className="card__event-image">
                              <div>
                                    <div className="event-image"></div>
                              </div>
                        </div>
                        <div className="card-body py-0">
                              <h5 className="card-title fw-bold">
                              </h5>
                              <ul className="list__event-details list-unstyled">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                              </ul>
                        </div>
                        {/* <!-- /.card-body --> */}
                        <div className="card-footer ms-lg-auto">
                              <div className={["buttons-wrapper flex-wrap",props.type === "past" ? "my-lg-auto" : ""].join(" ")}>
                                    {props.type !== "past" ?
                                    <>
                                          <button type="button" className="btn btn-primary btn-view-ticket order-1">
                                                
                                          </button>
                                          <button type="button" className="btn btn-primary btn-manage-booking order-2 order-lg-3">
                                                
                                          </button>
                                          <button type="button" className="btn btn-primary order-3 order-lg-2">
                                                
                                          </button>
                                    </> :
                                    <div className="buttons-wrapper flex-wrap my-lg-auto">
                                          <button className="btn btn-primary">
                                          </button>
                                    </div>}
                              </div>
                        </div>
                        {/* <!-- /.event-cta --> */}
                  </div>
                  {/* <!-- /.card --> */}
            </li>
      )
}

export default MyBookingItemDummy;
