import React from 'react';
import { ImageComponent } from '../../../Components';

const ItemComponent = (props) => {
      return (
            <div className={["item",props.className].join(" ")}>
                  <div className="hero">
                        <div className="hero__content">
                              {props.children}
                        </div>
                        {/* <!-- /.hero__content --> */}
                        <div className="hero__image">
                              <ImageComponent className="carousel-image object-fit" srcSet={props.srcSet} src={props.src} alt=""/>
                        </div>
                        {/* <!-- /.hero__image --> */}
                  </div>
                  {/* <!-- /.hero --> */}
            </div>
      )
}

export default ItemComponent
