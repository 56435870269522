import { NameConstants } from "./NameConstants";

const text = {
      messages:{
            modalAlertTitle : "Attention!",
            modalSuccessTitle : "Success!",
            joinWaitingList:"This event is fully booked. Would you like to join the waiting list & be notified if more tickets become available?",
            removeFromwaitingList:"Are you sure you would like to remove yourself from the waiting list? You will not be notified if more tickets become available.",
            deleteCard : '<p>Card deleted<br/>successfully</p>',
            cancelBookingOtherRequest :"Please let us know how we can help you and our team will check and respond (don’t worry, we’ll prioritise urgent bookings).",
            cancelBookingConfirmation : "If you cancel a booking in error, our team may not be able to reinstate if the tickets are booked by another member, or the event is taken off sale to send names to the venue.",
            ticAlertMessage : "You have already confirmed a booking for {{x}} ticket(s) for this performance.  Are you sure you want to add more?  (please check your current bookings).",
            FailureTicketBooking : "Sorry, we weren't able to complete your booking request.  Please refresh and try again.",
            SuccessTicketBooking : "Ticket booking request has been successful.",
            SuccessCardStore : "Your card has been saved successfully and the £1 charge has been refunded.",
            FailureCardStore : "Sorry, we weren't able to save your card.  Please refresh and try again.",
            SuccessAddBalance : "We've credited your wallet with the funds you've added.",
            FailureAddBalance : "Sorry, we weren't able to top-up your wallet.  No funds have been taken from your account.  Please refresh and try again.",
            SuccessAddVoucher : "We've credited your wallet with the voucher you've added.",
            FailureAddVoucher : "Failed - gift credit purchase not confirmed",
            // FailureApplePay : "Sorry, we weren't able to complete the payment request via Apple Pay.  Please refresh and try again.",
            FailureApplePay : "Your payment for this booking has already been received.  Please check your current bookings.  If there's a problem send us an email to enquiries@centraltickets.co.uk and we'll check your booking status.",
            UntickCaptcha : "Please complete captcha to send your message.",
            SuccessReactiveAccount : "Your account has been restored - you can book tickets again.",
            FailureReactiveAccount : "Sorry, our payment merchant was unable to process this order.  Please email us at enquiries@centraltickets.co.uk and a member of the team willl assist you"
      },
      actions: {
          continue: 'Continue',
          back: 'Back',
          signUp: "Sign up",
          login: "Log in",
          submit:"Submit",
          next:"Next",
          createAccount:"Create Account",
          completeRegistration:"Complete registration",
          stratExploring:"Start exploring",
          sendLink:"Send Link",
          changePassword:"Change Password",
          changeEmail : "Change Email",
          updateCard:"Update Card",
          deleteSavedCard:"Delete saved card",
          sendConfirmationCode : "Send Confirmation Code",
          topUp:"Top up",
          buyMoreTickets:"Buy more tickets",
          reduceTickets:"Reduce tickets",
          cancelBooking:"Cancel booking",
          otherRequests:"Other requests",
          sendRequest:"Send request",
          goBack:"Go back",
          confirmCancellation:"Confirm cancellation",
          sendMessage : "Send message",
          PayButton : "Pay"
      },
      validation: {
            [NameConstants.Name]: {
                  required:"Please enter your name.",
                  invalid: "Name is invalid."
            },
            [NameConstants.FirstName]: {
                  required:"Please enter your first name.",
                  invalid: "First name is invalid."
            },
            [NameConstants.LastName]: {
                  required:"Please enter your last name.",
                  invalid: "Last name is invalid."
            },
            [NameConstants.Email]: {
                  required: "Please enter your email address.",
                  invalid: "Email address is invalid."
            },
            [NameConstants.NewEmail]: {
                  required: "Please enter your email address.",
                  invalid: "Email address is invalid.",
                  diffEmail :"Please choose a different email than your current email."
            },
            [NameConstants.RepeatNewEmail]: {
                  required: "Please enter your email address.",
                  // invalid: "That’s not a match.  Please review your entries to ensure they match.",
                  invalid: "The email address and repeat email address must match.",
            },
            [NameConstants.PassWord]: {
                  required: "Please enter a password.",
                  minLength: "Your password should be at least 8 characters long."
            },
            [NameConstants.NewPassword] :{
                  required: "Please enter a password.",
                  invalid: "Your password should be at least 8 characters long."
            },
            [NameConstants.ConfirmPassword]: {
                  invalid: "Password and confirm password should match."
            },
            [NameConstants.RepeatNewPassword]: {
                  required: "Please enter a password.",
                  invalid: "Password and confirm password should match."
            },
            [NameConstants.OldPassword] :{
                  required: "Please enter your old password.",
                  invalid: "Password should be at least 8 character long."
            },
            [NameConstants.PhoneNumber]: {
                  required: "Please enter a UK mobile number.",
                  invalid : "Please enter a valid UK mobile number."
                  // invalid : "That doesn't look right."
            },
            [NameConstants.CheckReceiveAlerts]:{
                  invalid: "Please provide your consent to receive marketing communications."
            },
            [NameConstants.CheckAgreeTOS]:{
                  invalid: "Please agree to the Terms & Conditions and Privacy Policy to proceed."
            },
            [NameConstants.CheckAgreeMG] : {
                  invalid: "Please provide your confirmation that you read and agree to follow the Membership Guidelines."
            },
            [NameConstants.OTP]:{
                  required:"Please enter the 5-digit code we sent you.",
                  invalid: "That doesn't look right.  Please enter the code we've sent you via SMS."
            },
            [NameConstants.OTPEmail] : {
                  required:"Please enter the 5-digit code we sent you.",
                  invalid: "That doesn't look right.  Please enter the code we've sent you via Email."
            },
            [NameConstants.OTPForgotPassword] : {
                  required:"Please enter the 5-digit code we sent you.",
                  invalid: "That doesn't look right.  Please enter the code we've sent you via Email."
            },
            [NameConstants.CTNameOnCard] : {
                  required:"Card holder’s name is empty.",
                  invalid: "Please enter your name as printed on the card."
            },
            [NameConstants.CTCardNumber] : {
                  required: "Card number is empty."
            },
            [NameConstants.CTExp] :{
                  required : "Expiry date is empty."
            },
            [NameConstants.CTCvc]:{ 
                  required : "CVC is empty."
            },
            [NameConstants.ChangeMobileNumber] : {
                  required: "Please enter a UK mobile number.",
                  invalid: "Please enter a valid UK mobile number.",
                  // invalid: "That doesn't look right.",
                  match: "Please choose a different mobile number than your current mobile number."
            },
            [NameConstants.bookingRequestMessage] : {
                  required:"Please let us know how we can help you.",
                  invalid: "Tell us more."
            },
            [NameConstants.promoCode] : {
                  required:"Please enter a promo code.",
                  invalid: "Incorrect promocode."
            },
            [NameConstants.partPayment] : {
                  invalid : "Please enter the amount you'd like to part-pay with credit.",
                  more : "Amount exceeds booking value.  Please double check.",
                  enoughBalance : "Insufficient funds to complete your request, please top-up."
            },
            [NameConstants.Problem] : {
                  required:"Please enter the problem you're facing.",
                  invalid: "Tell us more."
            },
            [NameConstants.IdealSolution] : {
                  required:"Please help us understand how you'd like us to help.",
                  invalid: "Tell us more."
            },
            [NameConstants.Message] : {
                  required:"Please help us understand how you'd like us to help.",
                  invalid: "Tell us more."
            },
            [NameConstants.SurveyAnswer] : {
                  required:"Please let us know your answer.",
                  invalid: "Tell us more."
            },
            [NameConstants.AccessNeedMessage] : {
                  required:"Please let us know how we can help you.",
                  invalid: "Tell us more."
            },
            [NameConstants.EventTitle] : {
                  required:"Please tell us about the show title.",
                  invalid: "Please tell us about the show title."
            },
            [NameConstants.EventDetails] : {
                  required:"Please tell us about the show and how we can help.",
                  invalid: "Please tell us about the show and how we can help."
            },
            [NameConstants.WebLink] : {
                  required:"Please enter a WebLink.",
                  invalid: "Please enter valid Web Link."
            },
            [NameConstants.Attendance] : {
                  invalid : "You must agree to the Terms & Conditions."
            },
            [NameConstants.CancellingBooking] : {
                  invalid : "You must agree to the Terms & Conditions."
            },
            [NameConstants.Punctuality] : {
                  invalid : "You must agree to the Terms & Conditions."
            },
            [NameConstants.Etiquette] : {
                  invalid : "You must agree to the Terms & Conditions."
            },
            [NameConstants.TicketOffers] : {
                  invalid : "You must agree to the Terms & Conditions."
            },

            [NameConstants.TheirFirstName]: {
                  required:"Please enter their first name.",
                  invalid: "First name is invalid."
            },
            [NameConstants.TheirLastName]: {
                  required:"Please enter their last name.",
                  invalid: "Last name is invalid."
            },
            [NameConstants.TheirEmail]: {
                  required: "Please enter their email address.",
                  invalid: "Email address is invalid."
            },
            [NameConstants.TheirMessage] : {
                  required:"Please enter the message you’d like us to send with your gift.",
                  invalid: "Tell us more."
            },
            [NameConstants.NHSTrust] : {
                  invalid : "Please select NHS trust you work for."
            },
            [NameConstants.Role] : {
                  required :  "Please enter your role",
                  invalid : "Please enter your role"
            },
            [NameConstants.NearestCity] : {
                  required : "Please select your nearest city",
            },
            [NameConstants.EmailTfm]: {
                  required: "Please enter your email address.",
                  invalid: "Email address is invalid."
            },
            [NameConstants.NHSEmail]: {
                  required: "Please enter a valid NHS email address.",
                  invalid:  "Please enter a valid email address.",
                  invalidNHS : "Please enter a valid NHS email address."
            },
            [NameConstants.EmailConfitmTfm]: {
                  required: "That’s not a match. Please check the email addresses entered.",
                  invalid: "That’s not a match. Please check the email addresses entered."
            },
            [NameConstants.NHSEmailConfirm]: {
                  required: "That’s not a match. Please check the email addresses entered.",
                  invalid: "That’s not a match. Please check the email addresses entered."
            },
            [NameConstants.Comments] : {
                  invalid : "Please share with us some information regarding your Keyworker status."
            },
            [NameConstants.WorkIDCard] : {
                  invalid  : "Please upload documentation that verifies your Keyworker status."
            }
      },
      placeholders: {
          [NameConstants.Name]: "Name",
          [NameConstants.Email]: "Email Address",
          [NameConstants.PassWord]: "Password",
          [NameConstants.ConfirmPassword]: "Re-Enter Password",
          [NameConstants.FirstName]: "Enter First Name",
          [NameConstants.LastName]:"Enter Last Name",
          [NameConstants.PhoneNumber]:"Enter Mobile Number",
          [NameConstants.ContactNumber]:"Enter Contact Number",
          DidnotLike:'write here..',
          DoLike:'write here..',
          partyAmount:"0.00",
          [NameConstants.CTNameOnCard]:"Name on card",
          [NameConstants.CTCardNumber]:"Card Number",
          [NameConstants.CTExp]:"Exp (mm/yy)",
          [NameConstants.CTCvc]:"CVC",
          [NameConstants.ChangeMobileNumber] : "Enter new mobile number",
          [NameConstants.NewEmail] : "New Email",
          [NameConstants.RepeatNewEmail] : "Repeat New Email",
          [NameConstants.OldPassword] : "Old Password",
          [NameConstants.NewPassword] : "New Password",
          [NameConstants.RepeatNewPassword] : "Repeat New Password",
          [NameConstants.EventTitle] : "Enter Event Title",
          [NameConstants.WebLink] : "Enter Web Link",
          [NameConstants.EventDetails] : "Enter Event Details",

          [NameConstants.TheirFirstName]: "Enter Recipient First Name",
          [NameConstants.TheirLastName]: "Enter Recipient Last Name",
          [NameConstants.TheirEmail]: "Enter Recipient Email Address",
          [NameConstants.TheirMessage]: "Message",

          [NameConstants.NHSTrust] : "Enter an NHS Trust",
          [NameConstants.Role] : "Enter a Role",
          [NameConstants.NearestCity]: "Enter your nearest City",
          [NameConstants.EmailTfm] :"Enter Email Address",
          [NameConstants.NHSEmail] :"Enter Email Address",
          [NameConstants.EmailConfitmTfm]: "Re-Enter Email Address",
          [NameConstants.NHSEmailConfirm]: "Re-Enter Email Address",
          [NameConstants.Comments] : "Comments",
          

      },
      label:{
            [NameConstants.Name]:"Name",
            [NameConstants.Email]:"Email",
            [NameConstants.PassWord]:"Password",
            [NameConstants.ConfirmPassword]:"Confirm Password",
            [NameConstants.OldPassword]:"Old Password",
            [NameConstants.NewPassword]:"New Password",
            [NameConstants.RepeatNewPassword]:"Repeat New Password",
            [NameConstants.OTPEmail]:"Old Email",
            [NameConstants.NewEmail]:"New Email",
            [NameConstants.OldEmail] :"Existing Email",
            [NameConstants.RepeatNewEmail]:"Repeat New Email",
            [NameConstants.FirstName]:"First Name",
            [NameConstants.LastName]:"Last Name",
            [NameConstants.PhoneNumber]:"Mobile Number",
            [NameConstants.ContactNumber]:"Contact Number",
            otpVerifyModal :"Please enter the 5-digit code we’ve sent to {{mobile}} to verify your number.",
            updateMobileNumber :"Please enter your mobile number again below.",
            // updateMobileNumber :"Your mobile number is already registered and valid in our system. Thank you for being a valued member",
            // otpVerifyModal :"Please enter the 5-digit code we’ve sent to your mobile to verify your number.",
            otpEmailVerifyModal:"Please enter the code we’ve emailed you to verify your email address.",
            otpChangeEmailVerifyModal:"We just sent you a verification code to your email. enter it here:",
            [NameConstants.SavedMobileNumber] : "Saved Mobile number",
            [NameConstants.ChangeMobileNumber] : "Change your mobile number",
            [NameConstants.Problem] : "Problem",
            [NameConstants.IdealSolution] : "Ideal solution",
            [NameConstants.Message] : "Message",
            [NameConstants.EventTitle] : "Event Title",
            [NameConstants.WebLink] : "Web Link",
            [NameConstants.EventDetails] : "Event Details",
            [NameConstants.Attendance] : "Attendance",
            [NameConstants.CancellingBooking] : "Cancelling a Booking",
            [NameConstants.Punctuality] : "Punctuality",
            [NameConstants.Etiquette] : "Etiquette",
            [NameConstants.TicketOffers] : "Free Ticket Offers",

            [NameConstants.TheirFirstName]: "Recipient First Name",
            [NameConstants.TheirLastName]: "Recipient Last Name",
            [NameConstants.TheirEmail]: "Recipient Email",
            [NameConstants.TheirMessage]: "Message",

            [NameConstants.NHSTrust] : "NHS Trust",
            [NameConstants.Role] : "Role",
            [NameConstants.NearestCity] : "Nearest City",
            [NameConstants.EmailTfm] : "Key Worker Email",
            [NameConstants.NHSEmail] : "Key Worker Email",
            [NameConstants.EmailConfitmTfm] : "Key Worker Email Confirmation",
            [NameConstants.NHSEmailConfirm] : "Key Worker Email Confirmation",
            [NameConstants.Comments] : "Comments",
            [NameConstants.WorkIDCard] : "Work ID Card*",
      },
      title: {
            Login:"Login",
            wallet: "Wallet",
            tickets: "Tickets",
            account: "Account",
            faqs: "FAQs",
            contactUs: "Contact Us",
            termsAndConditions: "T&C",
            logout: "Log Out",
            changePreferences: "Change Preferences",
            editDetails: "Edit Details",
            changePassword: "Change Password",
            changeEmail:"Change Email",
            changeMobileNUmber:"Change Mobile Number",
            emailPrefrences:"Email Preferences",
            savedCards: "Save Card",
            deleteAccount:"Delete Account",
            notifications: "Notifications",
            saveChanges:"Save changes",
            yourBookings:"Bookings",
            yourAccount:"Account Settings",
            yourFavourites:"Favourites",
            yourMobileNumber : "Your Mobile Number",
            cancelBookingConfirmation :"Are you sure you want to cancel your booking?",
            AccessNeeds : "Your access requirements",

            // Ajtix
            AjtixRegister : "Register"
            
      },
      errors:{
            NotFound404:"Sorry, something went wrong.  Please refresh and try again.",
            // sagePayError :"Something's gone wrong.  It looks as though your internet connection is blocking security elements on our site.  Try a different connection, or email help@ticketsformedics.co.uk for support, quoting error code 75-1.",
            sagePayError :"Sorry, our payment merchant was unable to process this order.  Please email us at enquiries@centraltickets.co.uk and a member of the team willl assist you.",
            passwordUpdated:"Password updated successfully.",
            emailPrefrences:{
                  notification_preferences:"Please tell us which show alerts you want to receive?",
                  categories:"Please select some categories of interest.",
                  regions:"Please select the area you reside.",
                  sub_locations:"Please let us know your location preferences."
            },
            leaveFeedback:{
                  0:"Please select a rating and then press next.",
                  1:"Please select your choice from above.",
                  3:"Please select one of the above.",
                  2:"Please enter a comment.",
                  4:"Please select an answer.",
                  5:"Please select one of the above.",
                  6:"Please select one of the above.",
                  7:"Please enter the amount your group spent at the venue.",
                  8:"Please select at least one option.",
                  9:"Please select at least one option.",
            },
            SavedCard : {
                  [NameConstants.CTNameOnCard] : 'Card holder name is invalid.',
                  [NameConstants.CTCardNumber] : 'Card number is invalid.',
                  [NameConstants.CTExp] :'Expiry date is invalid.',
                  [NameConstants.CTCvc]:'CVC is invalid.'
            },
            WalletTopUp : {
                  enterAmount :"Please enter a valid amount.",
                  excedAmountLimit : "The top-up amount must be below £1000."
            },
            giftAmount : {
                  enterAmount :"Please enter a valid amount.",
                  excedAmountLimit : "The gift amount must be below £1000.",
            },
            selectDate :"Please select a date.",
            selectTicketToCancel : "Please select atleast 1 tickets to cancel."
      }
}

export const { actions, validation, placeholders, label, title, errors, messages, ticketTypes } = text;