import React from 'react'

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
// packages
// Actions & RTK query or mutations
import { setStep, setUserType } from '../../../Store/ContactUs';

// Constants
import { ContactUsObject, backButtonShowArray } from '../../../resources/ContactUsObject';
import { NameConstants } from '../../../resources/NameConstants';
import { actions } from '../../../resources/en';


const ContentContactClues = (props) => {

      const contactUs   =     useSelector(state=>state.contactUs);
      const user        =     useSelector(state=>state.user);
      const dispatch    =     useDispatch();

      const selectOptionHandler =(opt)=>{
            
            let jump_on = opt.jump_on;            
            let userType = "";

            if(contactUs.step === NameConstants.UserCat){

                  if(jump_on === NameConstants.OrganizerShowQue){
                        userType = NameConstants.Organizer;
                  } else {
                        userType = NameConstants.Member;
                  }
                  dispatch(setUserType({ userType: userType }))
            }

            if(contactUs.step === NameConstants.MemberBookingQue){

                  if(user.auth && user.ct_access_token && opt.option_id === "1"){
                        jump_on = opt.auth_jump_on;
                  }
            }

            dispatch(setStep({ step: jump_on }))
      }     

      const backButtonHandler = ()=>{
            let back_on = ContactUsObject[contactUs.step].back_on;
            let userType = contactUs.userType;
            if(back_on === NameConstants.UserCat){
                  userType = "";
                  dispatch(setUserType({ userType: userType }))
            }

            dispatch(setStep({step:back_on}))
      }


      return (
            <div className="col__right">

                  <div className="content content__contact-clues">
                        <div className="content__body">
                              <h3 className="heading fw-bold">
                                    {ContactUsObject[contactUs.step].question}
                              </h3>
                              <div className="contact-clues">
                                    {ContactUsObject[contactUs.step].options.map(opt=>{

                                          // const lableClass = `${contactUs.step}${opt.option_id}`

                                          return (<div className="btn-check-wrapper" key={`${contactUs.step}${opt.option_id}`} onClick={()=>selectOptionHandler(opt)}>
                                                      <input className="form-check-input btn-check" type="radio" name={contactUs.step} id={`${contactUs.step}${opt.option_id}`}/>
                                                      <label className={["btn btn-outline-primary hover-primary w-100"].join(" ")} htmlFor={`${contactUs.step}${opt.option_id}`}>
                                                            {opt.content}
                                                      </label>
                                                </div>)
                                    })}
                              </div>
                              {/* <!-- /.contact-clues --> */}

                              {(backButtonShowArray.includes(contactUs.step) && !user.auth) &&
                              <div className="form-group form-group__cta d-flex flex-column flex-sm-row">
                                    {/* replace button with a tag */}
                                    <button className="btn btn-xl-lg btn-primary align-self-start align-self-lg-auto" onClick={backButtonHandler}>
                                          {actions.back}
                                    </button>
                              </div>}

                        </div>
                        {/* <!-- .content__body --> */}

                  </div>
                  {/* <!-- .content --> */}

            </div>
      )
}

export default ContentContactClues
