

//  Saved Card Page
export const NameConstants = {

      // Common , :Login , Regisrtation, Chnage Password, settings Change Password
      Email: 'email',
      PhoneNumber : 'phoneNumber',
      ContactNumber : 'contactNumber',
      PassWord : 'password',
      ConfirmPassword : 'confirmPassword',
      NewPassword :'newPassword',
      
      // Saved Card Page && Wallet TopUp
      CTCardNumber : "ct_card_number",
      CTNameOnCard : "ct_name_on_card",
      CTExp : "ct_exp",
      CTCvc : "ct_cvc",

      // OTP Verification
      OTP : 'otp',
      OTPEmail : 'otpEmail',
      OTPForgotPassword : "otpForgotPassword",

      // Regisration
      FirstName : 'firstName',
      LastName : 'lastName',
      CheckReceiveAlerts : 'checkReceiveAlerts',
      CheckAgreeTOS : 'checkAgreeTOS',
      CheckAgreeMG : 'checkAgreeMG',

      // GiftVoucher
      TheirFirstName : 'FirstName',
      TheirLastName : 'LastName',
      TheirEmail: 'Email',
      TheirMessage : "TheirMessage",

      // Settings Change Email 
      OldEmail :'oldEmail',
      NewEmail : 'newEmail',
      RepeatNewEmail : 'repeatNewEmail',

      // Settings Change Password
      OldPassword : 'oldPassword',
      RepeatNewPassword : 'repeatNewPassword',

      // Settings Change Mobile Number
      SavedMobileNumber : 'SavedMobileNumber',
      ChangeMobileNumber : 'ChangeMobileNumber',

      // Cancel Booking Other Request
      bookingRequestMessage : "bookingRequestMessage",

      // Pormo code Other Request
      promoCode : "promoCode",

      // bookingModule steps
      bookingModuleSteps : {
            chooseDate : "chooseDate",
            orderSummary : "orderSummary",
            paymentMethod : "paymentMethod",
            paymentViaWallet : "paymentViaWallet",
            paymentViaCard : "paymentViaCard",
            paymentViaWalletPlusCard : "paymentViaWalletPlusCard",
            orderSummaryPay : "orderSummaryPay",
            bookingSuccess : "bookingSuccess",
            bookingTimeOut : "bookingTimeOut"
      },

      //  bookingModule Names for validations
      partPayment : "partPayment",

      // charity amount
      charity : 0.50,
      
      // payment method constants
      wallet : "wallet",
      card : "card",
      walletPlusCard : "walletPlusCard",
      walletPlusApplePay : "walletPlusApplePay",
      applepay : "applePay",

      // Contact Us Steps Constants & access Needs
      Member : "member", 
      Organizer : "organizer",
      UserCat : "userCat",
      MemberBookingQue : "memberBookingQue",
      MemberLogin : "memberLogin",
      MemberSelectShow : "memberSelectShow",
      MemberEventDetail : "memberEventDetail",
      MemberFaq : "memberFaq",
      MemberContactForm : "memberContactForm",
      OrganizerShowQue : "OrganizerShowQue",
      OrganizerShowForm : "OrganizerShowForm",
      OrganizerSimpleForm : "OrganizerSimpleForm",
      Problem :  "Problem",
      IdealSolution : "IdealSolution",
      Name : "Name",
      Message : "Message",
      AccessNeedMessage : "Message",
      EventTitle : "EventTitle",
      WebLink : "WebLink",
      EventDetails : "EventDetails",

      // React  Account
      Attendance : "Attendance",
      CancellingBooking : "cancellingBooking",
      Punctuality : "Punctuality",
      Etiquette : "Etiquette",
      TicketOffers : "TicketOffers",

      // Negative Review Survey
      SurveyAnswer : "SurveyAnswer",

      // TFM
      EmailTfm :"EmailTfm",
      EmailConfitmTfm : "EmailConfirmTfm",
      NHSEmail : "NHSEmail",
      NHSEmailConfirm : "NHSEmailConfirm",
      Role : "Role",
      NearestCity : "NearestCity",
      WorkIDCard  :"WorkIDCard",
      Comments : "Comments",
      NHSTrust : "NHSTrust",

}


