import React, { useEffect } from 'react'

// external css
// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// child component
import { ImageComponent } from '../../Components';

// packages
// Actions & RTK query or mutations
import { updateRegistrationState } from '../../Store/Register';

// Constants
import { actions } from '../../resources/en';
import { routesName } from '../../resources/RoutesName';

const Step3 = () => {

      const domain      =     useSelector(state=>state.user.domain)
      const navigate    =     useNavigate();
      const dispatch    =     useDispatch();

      useEffect(()=>{
            window.scrollTo({top: 0, behavior: 'smooth'});
      },[])

      const exlporeHandler = ()=>{
            dispatch(updateRegistrationState({
                  ct_otp_modal_status : "",
                  step : "step1",
                  registration_step : null
            }))
            navigate(routesName.CurrentListing);
      }
      
      return (
            <div className="content content__register content__register-last-part mx-auto">
                  <div className="content__header text-center overflow-hidden">
                        <div className="big-heading">
                              <h1 className="mb-0">Congratulations!</h1>
                              {domain === "" && <>
                              <ImageComponent dataScroll className="ticket ticket-aquamarine1" src="ticket-register-congratulations-big-heading1-aquamarine1.svg" type="tickets"/>

                              <ImageComponent dataScroll className="ticket ticket-red" src="ticket-register-congratulations-big-heading1-red.svg" type="tickets"/>

                              <ImageComponent dataScroll className="ticket ticket-orange1" src="ticket-register-congratulations-big-heading1-orange1.svg" type="tickets"/>

                              <ImageComponent dataScroll className="ticket ticket-blue" src="ticket-register-congratulations-big-heading1-blue.svg" type="tickets"/>

                              <ImageComponent dataScroll className="ticket ticket-aquamarine2" src="ticket-register-congratulations-big-heading1-aquamarine2.svg" type="tickets"/>

                              <ImageComponent dataScroll className="ticket ticket-yellow" src="ticket-register-congratulations-big-heading1-yellow.svg" type="tickets"/>

                              <ImageComponent dataScroll className="ticket ticket-orange2" src="ticket-register-congratulations-big-heading1-orange2.svg" type="tickets"/>
                              
                              </>}
                        </div>
                        <p className="p-account-created fw-bold">
                              Your account <br className="d-lg-none"/>has been created
                        </p>
                        <div className="buttons-wrapper justify-content-center">
                              <button className="btn btn-xl-lg btn-primary" onClick={exlporeHandler}>
                                    {actions.stratExploring}
                              </button>
                              {/* <a className="btn btn-xl-lg btn-primary" href="#">Start exploring</a> */}
                        </div>
                  </div>
                  {/* <!-- .content__header --> */}
            </div>
      )
}

export default Step3
