import { validation } from "../resources/en";
import { NameConstants } from "../resources/NameConstants";

const settingsPasswordChangeValidation=(value, type, password) =>{
      let isValid = true;
      let validationMessage = "";
      if (!type) {
          return true;
      }

      if(type === NameConstants.OldPassword){
            if(value.trim()=== ""){
                  isValid =false;
                  validationMessage = validation[type].required;
            }
            // else if(value.length < 6) {
            //       isValid=false;
            //       validationMessage = validation[type].invalid;
            // }
      }

      if(type === NameConstants.NewPassword){
            if(value.trim()=== "") {
                  isValid =false;
                  validationMessage = validation[type].required;
            }
            else if(value.length < 8) {
                  isValid=false;
                  validationMessage = validation[type].invalid;
            }
      }

      if(type === NameConstants.RepeatNewPassword){
            if(value.trim()=== "") {
                  isValid =false;
                  validationMessage = validation[type].required;
            }
            else if(value !== password) {
                  isValid=false;
                  validationMessage = validation[type].invalid;
            }
      }
      
      return {isValid,validationMessage};
}

export default settingsPasswordChangeValidation;