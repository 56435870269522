import React from 'react'

const UptakeTitleDummy = () => {
      return (
            // eslint-disable-next-line jsx-a11y/heading-has-content
            <h5 className="card-title fw-bold uptake-title__dummy"></h5>
      )
}

export default UptakeTitleDummy
