import React, { useState, useEffect } from  'react';

// external css
// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';

// child component
import ContentHeader from "./ContentHeader/ContentHeader";
import ListingFilters from "./ListingFilters/ListingFilters";
import { TickerText, CurrentListingItem, CurrentListingItemDummy, OwlCarouselEventsItem, OwlCarouselEventsItemDummy } from '../../Components';
import Footer from "../../Layouts/Footer/Footer";
import CurrentListingLoader from '../../Ui/CurrentListingLoader';

// packages
import OwlCarousel from 'react-owl-carousel';
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { useGetCurrentListingMutation } from "./../../Services/modules/currentlisting";
import { setEventsData } from "./../../Store/CurrentListing/index";
import { setAlert, updateLocoScroll, setSpinner, setRefreshListing } from '../../Store/UI';
import { setFilterEventsData, setFetchListing } from "../../Store/CurrentListing";
import { setShowUpdateTimestamp } from "../../Store/User"

// Constants
import { errors, messages } from '../../resources/en';
import { catsOneText } from '../../resources/TextRollerConstants';
import { CurrentListingOptions } from "../../resources/OwlCarouselOptions";
import { MetaData, MetaDataAj, MetaDataTfm } from '../../resources/MetaData';
import { Config } from '../../Config';

let timeoutId = null;


const CurrentListings = () => {

      const currentListing                            = useSelector(state=>state.currentListing);
      const ui                                        = useSelector(state=>state.ui);
      const {ct_show_update_timestamp, domain }       = useSelector(state=>state.user);
      const [getCurrentListing, { data, isSuccess, isLoading, isFetching, error }]  =    useGetCurrentListingMutation();
      const dispatch = useDispatch();

      const [collapse,setCollapse] = useState(true);
      const [showCollapse,setShowCollapse] = useState(false);

      useEffect(()=>{
            document.title = "Shows";     
            
            if(window.innerWidth < 1199){
                  setCollapse(false)
                  setShowCollapse(false)
            } else {
                  setShowCollapse(true)
            }

            const eventId = localStorage.getItem('eventId')
            if(eventId){
                  dispatch(setSpinner({status : "scrolling"}))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{
            // Handle Fetch Current Listing Data API Request
            if(isSuccess){
                  if(data.status === "1"){
                        if(ct_show_update_timestamp === 0) {
                              dispatch(setShowUpdateTimestamp({ct_show_update_timestamp : data.timestamp}))
                        }
                        dispatch(setEventsData(data))
                  }
            }
            
            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || errors.NotFound404}))
            }     

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error,dispatch])


      useEffect(()=>{
            // Call Fetch Current Listing Data API on Comonent Mount

            if(currentListing.eventsData.length === 0 || currentListing.fetchListing) {

                  getCurrentListing();
                  dispatch(setFetchListing({fetchListing : false}))
                  dispatch(setRefreshListing({refreshListing : false}))
            }

      },[getCurrentListing, dispatch, currentListing.eventsData, currentListing.fetchListing])

      const collapseHandler = ()=>{
            setCollapse(!collapse);
            if(collapse){
                  dispatch(setFilterEventsData({filteredEvents:currentListing.eventsData,isFilteredApplied:false}));
            }
      }

      
      const afterLoadHandler = ()=>{
            if (timeoutId) {
                  clearTimeout(timeoutId);
            }
            // Schedule the update to occur after a certain delay
            timeoutId = setTimeout(updateLocomotiveScroll, 700);
      }

      const updateLocomotiveScroll = ()=> {
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
      }

      let listingItems = [];
      if(currentListing.eventsData.length > 0) {
            listingItems = currentListing.eventsData;
            if(currentListing.isFilteredApplied) {
                  listingItems = currentListing.filteredEvents
            }
      }

      let metaDataSite = MetaData
      if(domain === Config.DOMAIN_CONSTANT_TFM){
            metaDataSite = MetaDataTfm
      } else if(domain === Config.DOMAIN_CONSTANT_AJTIX){
            metaDataSite = MetaDataAj
      }
      

      return (
            
            <React.Fragment>
                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <CurrentListingLoader show={ui.spinner === "scrolling"}/>
                  <div className={["scroll-animations position-relative",ui.refreshListing ? "padding-for-sticky-header" :""].join(" ")}>
                        <Helmet>
                              <title>{metaDataSite.Shows.title}</title>
                              <meta name="description" content={metaDataSite.Shows.description} />
                              <meta name="keywords" content={metaDataSite.Shows.keywords} />
                        </Helmet>
                        <div id="tagetScroll"></div>
                        <main>
                        
                              <section className="section__current-listings py-0">
                              
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__current-listings mx-auto">
                                                            
                                                            <ContentHeader/>
                                                            {/* <!-- /.content__header --> */}
                                                            <div className="content__body">
                                                                  <div className="listing-filters-wrapper">

                                                                        <button className="btn btn__listings-filter has-icon justify-content-between w-100 d-xl-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseListingFilters" aria-expanded="false"
                                                                              aria-controls="collapseListingFilters" onClick={collapseHandler}>
                                                                              <span className="btn__text">Filters</span>
                                                                              <span className="icon__plus"></span>
                                                                        </button>

                                                                        <ListingFilters collapse={collapse} showCollapse={showCollapse}/>
                                                                        {/* <!-- /#collapseListingFilters --> */}
                                                                  </div>
                                                                  
                                                                  <div className="current-listings flex-md-row align-items-md-stretch flex-md-wrap two-cols-md three-cols-xl">
                                                                        {(listingItems.length <=0 && !currentListing.isFilteredApplied) && <>
                                                                              <CurrentListingItemDummy />
                                                                              <CurrentListingItemDummy />
                                                                              <CurrentListingItemDummy />
                                                                              <CurrentListingItemDummy />
                                                                        </>}
                                                                        {listingItems.length > 0 ? listingItems.map(item=>{
                                                                              if(item.is_soldout !== "1"){
                                                                                    return (
                                                                                          <CurrentListingItem 
                                                                                                key={item.id} 
                                                                                                {...item}
                                                                                                is_favorite={item.is_favorite}
                                                                                                afterLoad={afterLoadHandler}
                                                                                          />
                                                                                    )
                                                                              }else {
                                                                                    return null
                                                                              }
                                                                        }): (
                                                                              <></>
                                                                              // <div className='results-not-found'>
                                                                              //       <h5>No Results Found</h5>
                                                                              // </div>
                                                                        )}
                                                                        
                                                                  </div>
                                                                  {/* <!-- /.current-listings --> */}

                                                                  {/* --------------------------------------------------------------------- */}
                                                                  {/* ----------------------------OwlCarousel Logic------------------------- */}
                                                                  {/* --------------------------------------------------------------------- */}
                                                                  {currentListing.eventsData.length <=0  && 
                                                                  <OwlCarousel className='owl-carousel owl-carousel__events border-bottom' loop {...CurrentListingOptions}
                                                                  // key={`carousel_${currentListing.eventsData.length}`} 
                                                                  >
                                                                        <OwlCarouselEventsItemDummy/>
                                                                  </OwlCarousel>}

                                                                  {currentListing.encore_json.length > 0 && 
                                                                  <OwlCarousel className='owl-carousel owl-carousel__events border-bottom' loop {...CurrentListingOptions}
                                                                  // key={`carousel_${currentListing.encore_json.length}`}
                                                                  >
                                                                        {currentListing.encore_json.map((el,index)=>{
                                                                              return <OwlCarouselEventsItem key={`${el.id}${index}`} {...el} />
                                                                        })}
                                                                  </OwlCarousel>}

                                                                  {/* --------------------------------------------------------------------- */}
                                                                  {/* ------------------------End OwlCarousel Logic------------------------- */}
                                                                  {/* --------------------------------------------------------------------- */}

                                                                  <div className="current-listings flex-md-row align-items-md-stretch flex-md-wrap two-cols-md three-cols-xl">
                                                                        { (listingItems.length <=0 && !currentListing.isFilteredApplied)&& <>
                                                                              <CurrentListingItemDummy />
                                                                              <CurrentListingItemDummy />
                                                                              <CurrentListingItemDummy />
                                                                              <CurrentListingItemDummy />
                                                                        </>}
                                                                        {listingItems.length > 0 && listingItems.map(item=>{
                                                                              if(item.is_soldout === "1"){
                                                                                    
                                                                                    return (
                                                                                          <CurrentListingItem 
                                                                                                key={item.id} 
                                                                                                {...item}
                                                                                                is_favorite={item.is_favorite}
                                                                                                afterLoad={afterLoadHandler}
                                                                                          />
                                                                                    )
                                                                              }else {
                                                                                    return null
                                                                              }
                                                                        })}
                                                                  </div>
                                                                  {/* <!-- /.waiting-listings --> */}
                                                            </div>
                                                            {/* <!-- /.content__body --> */}

                                                      </div>
                                                      {/* <!-- /.content.content__current-listings --> */}
                                                </div>
                                                {/* <!-- /.col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /.section__current-listings --> */}

                              <div className="border-bottom">
                                    <TickerText id="tickerShowCatsOne" textData={catsOneText}/>
                                    {/* <!-- /.ticker --> */}
                              </div>
                              {/* <CatTicketText/> */}
                             
                        </main>

                        <Footer/>
                                                                                          
                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}
            </React.Fragment>
      )
}

export default CurrentListings;
