import { api } from "./../../../Services/Api";
import { RegistrationWithNhsEmail } from "./RegistrationWithNhsEmail";
import { RegistrationWithoutNhsEmail } from "./RegistrationWithoutNhsEmail";
import { UpdateTfmDetails } from "./UpdateTfmDetails";

export const tfmApi = api.injectEndpoints({
    endpoints: build => ({
        RegistrationWithNhsEmail : RegistrationWithNhsEmail(build),
        RegistrationWithoutNhsEmail : RegistrationWithoutNhsEmail(build),
        UpdateTfmDetails : UpdateTfmDetails(build)
    }),
    overrideExisting: true
})

export const {  useRegistrationWithNhsEmailMutation, useRegistrationWithoutNhsEmailMutation, useUpdateTfmDetailsMutation  } = tfmApi