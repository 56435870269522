import { validation } from "./../resources/en";
import { NameConstants } from "../resources/NameConstants"

 const contactUsValidationRules=(value, type) =>{

      const textMessage = validation;

      let isValid = true;
      let validationMessage = "";

      if (!type) {
            return true;
      }
      
      if(type=== NameConstants.Problem || type === NameConstants.IdealSolution ||  type === NameConstants.Message || type === NameConstants.EventDetails || type === NameConstants.EventTitle){
            if(value.trim()==='') {
                  isValid = false;
                  validationMessage = textMessage[type].required;
            } 
            else if(value.length < 3) {
                  isValid=false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      
      if(type=== NameConstants.Name){

            if(value.trim() === ""){
                  isValid = false;
                  validationMessage = textMessage[type].required;
            }
            else if(value.length <2)
            {
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }


      if(type === NameConstants.Email){

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!value){
                  isValid = false;
                  validationMessage = textMessage[type].required;
            }
            else if(!re.test(String(value).toLowerCase()) || !value){
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type === NameConstants.PhoneNumber){

            let isnum = /^\d+$/.test(value);
            if(value.trim() === "" || !value){
                  isValid =false;
                  validationMessage =  textMessage[type].required;
                  return {isValid,validationMessage};
            }
            if(value.trim()=== "07"){
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
            if(value.length !== 11 || !isnum ){
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
            if(value.substring(0, 3) === "070") {
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
             
      }

      // if(type === NameConstants.WebLink) {
      //       const webLinkPattern = /^(ftp|http|https):\/\/[^ "]+$/;

      //       if(!value){
      //             isValid = false;
      //             validationMessage = textMessage[type].required;
      //       }
      //       else if(!webLinkPattern.test(value)){
      //             isValid = false;
      //             validationMessage = textMessage[type].invalid;
      //       }
      // }

      return {isValid,validationMessage};
}

export default contactUsValidationRules;
