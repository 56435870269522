import React, { useState } from 'react'

// external css
// redux and react hooks
// child component
// packages
// Actions & RTK query or mutations
// Constants
import { NameConstants } from '../../../resources/NameConstants'
import { surveyValidationRules } from '../../../Services'

const Step4 = ({ rating, submitHandler, isLoading }) => {
      
      const [state,setState]= useState({
            [NameConstants.SurveyAnswer]:{value:"",error:false,errorMessage:""},
      })

      const messageChangeHandler = (event) => {
            setState(prevState=> {
                  return {
                        ...prevState,
                        [NameConstants.SurveyAnswer] : {...prevState[NameConstants.SurveyAnswer],value : event.target.value}
                  }
            })
      }

      const submitAnswerHandler = (event)=> {
                  event.preventDefault();
                  const copy  = {
                        ...state
                  }
                  const errorStore = [];
      
                  for(let key in state) {
                        const errorCheck = surveyValidationRules(state[key].value,key);
                        if(!errorCheck.isValid){
                              copy[key] = {...copy[key],error:true,errorMessage:errorCheck.validationMessage}
                              errorStore.push(key);
                        }
                  }
      
                  if(errorStore.length > 0) {
                        setState(prevState=>{
                              return {
                                    ...prevState,
                                    ...copy
                              }
                        })
      
                  } else {
                        submitHandler(state[NameConstants.SurveyAnswer].value)
                  }
      }

      const onfocusHandler = ()=>{

            setState(prevState=> {
                  return {
                        ...prevState,
                        [NameConstants.SurveyAnswer] : {...prevState[NameConstants.SurveyAnswer],error : false, errorMessage : ''}
                  }
            })

      }

      return (
                  <div className="card-body p-0">
                        <div className="form-group">
                              <h3 className="form-group__heading">
                                    Why did you choose {rating}? What could we do better?*
                              </h3>

                              <div className="form-group">
                                    {/* <label for="eventOrgContactEventDetails" className="form-label">
                                    Event Details
                                    </label> */}
                                    <textarea className="form-control" id="eventOrgContactEventDetails" spellCheck="false"
                                    data-ms-editor="true" placeholder="Type your answer here..." value={state[NameConstants.SurveyAnswer].value} onFocus={onfocusHandler} onChange={messageChangeHandler} disabled={isLoading}>
                                    </textarea>
                                    {state[NameConstants.SurveyAnswer].error && <label className='form-label-error'>{state[NameConstants.SurveyAnswer].errorMessage}</label>}
                              </div>
                              <div className="list__btn-checks flex-column flex-md-row justify-content-center mx-auto">
                                    <div className="btn-check-wrapper mb-0">
                                          <button className="btn btn-xl-lg btn-primary" onClick={submitAnswerHandler} disabled={isLoading}>
                                                Submit
                                          </button>
                                    </div>
                              </div>
                        </div>
                  </div>
      )
}

export default Step4
