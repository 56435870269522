import React from "react";
import ChangePasswordForm from "../Components/ChangePasswordForm";
import { useSelector } from "react-redux";

const ChangePassword = ()=>{

      const ct_is_change_password = useSelector(state=>state.user.ct_is_change_password)

      return (
            <div className="tab-pane tab-pane__ChangePassword fade show active" id="myAccntChangePassword" role="tabpanel" aria-labelledby="myAccntChangePassword-tab">

                  <div className="tab-pane__body">
                        <div className="content">
                              {ct_is_change_password && <h4 className="heading is_change_password">
                                    Your account security has been enhanced! For your safety, we recommend updating your password to ensure your information remains secure.
                              </h4>}
                              <h4 className="heading">Change Password</h4>
                              <ChangePasswordForm type="desktop"/>
                        </div>
                        {/* <!-- /.content --> */}

                  </div>
                  {/* <!-- /.tab-pane__body --> */}

            </div>
      )
}

export default ChangePassword;