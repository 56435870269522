import React from 'react'

import 'font-awesome/css/font-awesome.min.css';


import Backdrop from "../Backdrop/index";


const CurrentListingLoader = ({show}) => {

      return (
            <>
                  {show && <Backdrop />}
                  <span className="fa fa-refresh ct_widget_spinner fa-spin ct_widget_spinner__alone current-listing-spinner" style={{"display":show ? "" :"none"}}></span>
            </>
      )
}

export default CurrentListingLoader;