import React from 'react';
import { Route } from 'react-router-dom';
import { routesName } from './RoutesName';

import { 
      AboutUs,
      Faq,
      Terms,
      Login, 
      Register, 
      //  Join,
      //  JoinAlt,
      MembershipGuidelines, 
      ResetPassword,
      LeaveFeedBack, 
      UptakePageV2, UptakePageVenues, 
      NoWorkingPage, Privacy,
      ContactUs,
      ReactiveAccount,
      InstantLogout,
      Home,
      NewLookPage,
      Flexi,
      UnderRepresentedCommunity,
      LandingPage,
      AjtixHome,
      GetFeedbackReport,
      ETickets

} from './../Pages';

export default <>
                  <Route path={routesName.ContactUs} element={<ContactUs/>}/>
                  <Route path={routesName.Login} element={<Login/>}/>
                  <Route path={routesName.Register} element={<Register/>}/>
                  <Route path={routesName.joinAjtix} element={<Register/>}/> 
                  <Route path={routesName.joinParam} element={<Register/>}/> 
                  {/* <Route path={routesName.joinAlt} element={<JoinAlt />}/> */}
                  <Route path={routesName.RegisterWithVoucher} element={<Register/>}/>
                  <Route path={routesName.RegisterType} element={<Home/>}/>
                  <Route path={routesName.OldRegisterVerification} element={<Register/>}/>
                  <Route path={routesName.MembershipGuidlines} element={<MembershipGuidelines/>}/>
                  <Route path={routesName.LeaveFeedback} element={<LeaveFeedBack/>}/>
                  <Route path={routesName.LeaveFeedbackAnother} element={<LeaveFeedBack/>}/>
                  <Route path={routesName.ResetPassword} element={<ResetPassword/>} />
                  <Route path={routesName.NoWorkingPage} element={<NoWorkingPage/>}/>

                  <Route path={routesName.UptakePageV2} element={<UptakePageV2/>}/>
                  <Route path={routesName.UptakePageVenues} element={<UptakePageVenues/>}/>
                  <Route path={routesName.UptakePageAllEvents} element={<UptakePageVenues/>}/>
                  <Route path={routesName.UnderRepresentedCommunity} element={<UnderRepresentedCommunity />}/> 
                  <Route path={routesName.LandingPage} element={<LandingPage />}/> 
                  
                  
                  <Route path={routesName.AboutUs} element={<AboutUs/>}/>
                  <Route path={routesName.Faq} element={<Faq/>}/>
                  <Route path={routesName.Terms} element={<Terms/>}/>
                  <Route path={routesName.Privacy} element={<Privacy/>}/>
                  <Route path={routesName.PrivacyPolicy} element={<Privacy/>}/>
                  <Route path={routesName.Referral} element={<Register/>}/>
                  <Route path={routesName.ReactiveAccount} element={<ReactiveAccount/>}/>
                  <Route path={routesName.SuccessReactiveAccount} element={<Home/>}/>
                  <Route path={routesName.FailureReactiveAccount} element={<Home/>}/>
                  
                  <Route path={routesName.NewLookPage} element={<NewLookPage/>}/>
                  <Route path={routesName.FlexiTerms} element={<Flexi/>}/>
                  <Route path={routesName.InstantLogout} element={<InstantLogout/>}/>

                  <Route path={routesName.AjtixHome} element={<AjtixHome/>}/>
                  <Route path={routesName.GetFeedbackReport} element={<GetFeedbackReport/>}/>
                  <Route path={routesName.ETickets} element={<ETickets />}/>
                  <Route path={routesName.ETicketsWithService} element={<ETickets />}/>
            </>

