import React from 'react';
import ChangeMobileNumberForm from '../Components/ChangeMobileNumberForm';

const ChangeMobileNumber = () => {

    

      return (
                  <div className="accordion-item accordion-item__SavedMobileNum">
                        <h2 className="accordion-header" id="amasHeading3">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#amasCollapse3" aria-expanded="false" aria-controls="amasCollapse3">
                                    <span className="btn__text" >
                                          Change Mobile Number
                                    </span>
                              </button>
                        </h2>

                        <div id="amasCollapse3" className="accordion-collapse collapse" aria-labelledby="amasHeading3">

                              <div className="accordion-body">
                                   
                                    <ChangeMobileNumberForm />
                                    {/* <!-- /.content --> */}

                              </div>
                              {/* <!-- /.accordion-body --> */}

                        </div>
                        {/* <!-- /.accordion-collapse --> */}

                  </div>
      )
}

export default ChangeMobileNumber;
