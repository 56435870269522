import { createSlice } from '@reduxjs/toolkit'
import { NameConstants } from '../../resources/NameConstants';
const initialState = {
      step : NameConstants.UserCat,
      userType : '', //member or organizer
      showDetails : null,
      ct_contact_modal_status : ""
}

const contactUsSlice = createSlice({
      name: 'contactUs',
      initialState:initialState,
      reducers: {
            setStep(state, { payload }) {
                  state.step        =       payload.step;
            },
            setUserType(state, { payload }) {
                  state.userType    =     payload.userType;
            },
            setShowDetails (state, { payload }) {
                  state.showDetails = payload.showDetails
            },
            setModalStatus (state,  { payload }) {
                  state.ct_contact_modal_status = payload.status
            },
            reset: ()=> initialState
      },
      
})


export const { setStep, setUserType, setShowDetails, setModalStatus, reset } = contactUsSlice.actions

export default contactUsSlice.reducer


