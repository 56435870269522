import React from "react";
import ChangeMobileNumberForm from "../Components/ChangeMobileNumberForm";

const ChangeMobileNumber = ()=>{


      return (
                  <div className="tab-pane tab-pane__SavedMobileNum fade show active" id="myAccntChangeMobileNum" role="tabpanel" aria-labelledby="myAccntChangeMobileNum-tab">
                        <div className="tab-pane__body">
                              
                              <ChangeMobileNumberForm/>
                              {/* <!-- /.content --> */}

                        </div>
                        {/* <!-- /.tab-pane__body --> */}
                  </div>
      )
}

export default ChangeMobileNumber;