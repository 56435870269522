export const getUserWalletBalance =  build =>
      build.query({
            query: (body) =>  ({
                  url: '/wallet/get_user_wallet_balance',
                  method: 'GET',
                  body: body
            }),
            providesTags: ['getUserWalletBalance']
      })

      // Changed with new one