import React from "react";
import EmailPrefrencesForm from "../Components/EmailPrefrencesForm";

const EmailPreferences = ()=>{


      return (
                  <div className="tab-pane tab-pane__EmailPreferences fade show active" id="myAccntEmailPreferences" role="tabpanel" aria-labelledby="myAccntEmailPreferences-tab">
                        <div className="tab-pane__body">

                              <div className="content">

                                    <h4 className="heading">Your area</h4>
                                    <EmailPrefrencesForm/>

                              </div>
                              {/* <!-- /.content --> */}

                        </div>
                        {/* <!-- /.tab-pane__body --> */}
                  </div>
      )
}

export default EmailPreferences;