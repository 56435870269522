import React, { useEffect } from 'react'

// external css
// redux and react hooks
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// child component
import { HeadingPictureComponent } from '../../Components';
import { ImageComponent } from '../../Components';
import Footer from '../../Layouts/Footer/Footer';

// packages
// Actions & RTK query or mutations
import { useGetLogoutMutation } from "./../../Services/modules/users"
import { logout } from '../../Store/User';
import { setAlert } from '../../Store/UI';
import { reset as resetCurrentBookings } from '../../Store/CurruntBookings';
import { reset as resetBookingModule } from "../../Store/BookingModule"
import { reset as resetWallet } from "../../Store/Wallet"
import { reset as resetRegistration } from "../../Store/Register"

// Constants
import { routesName } from '../../resources/RoutesName';
import { errors, title, messages } from '../../resources/en';
import { api } from '../../Services/Api';
import { persistor } from "../../Store"

const MyAccountHome = () => {

      const browserDetect     =     useSelector(state=>state.ui.browserDetect);
      const domain            =     useSelector(state=>state.user.domain);

      const [getLogout, { data, isSuccess, isLoading, isFetching, error }] = useGetLogoutMutation();
      const dispatch = useDispatch();
      const navigate = useNavigate();

      useEffect(()=>{
            document.title = "My Account Home";
      },[])

      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1"){
                        console.log("logout using my account")
                        dispatch(api.util.resetApiState());
                        dispatch(logout(data.data));
                        navigate(routesName.Home)
                        // reset all redux slices
                        dispatch(resetCurrentBookings())
                        dispatch(resetBookingModule())
                        dispatch(resetRegistration())
                        dispatch(resetWallet())

                        persistor.purge(); // Clear the persisted state
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || errors.NotFound404}))
            }

      },[data, isSuccess, isLoading, isFetching, error, dispatch, navigate])

      const logOutHandler = (e)=>{
            e.preventDefault();
            getLogout({});
            
      }

      return (
                  <div className={["scroll-animations position-relative"].join(" ")} data-scroll-container>

                        <main className="overflow-hidden">
        
                              <section data-scroll-section className="section__my-account pt-0">
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__my-account content__my-account-home mx-auto">
                                                            <div className="content__header text-center">
                                                                  <div className="big-heading">
                                                                        <h1 className="h4 mb-0">What are you looking for?</h1>
                                                                        {domain === "" && <>
                                                                        
                                                                        <HeadingPictureComponent media="(min-width: 768px)" srcSet="ticket-account-home-big-heading1-blue.svg" src="ticket-account-home-big-heading1-blue-sm.svg" className="ticket-blue" dataScroll/>

                                                                        <HeadingPictureComponent media="(min-width: 768px)" srcSet="ticket-account-home-big-heading1-yellow.svg" src="ticket-account-home-big-heading1-aquamarine-sm.svg" className="ticket-yellow" dataScroll/>

                                                                        <ImageComponent dataScroll className="ticket ticket-blue" src="ticket-account-home-big-heading1-blue.svg" type="tickets"/>

                                                                        <ImageComponent dataScroll className="ticket ticket-yellow d-none d-md-block" src="ticket-account-home-big-heading1-yellow.svg" type="tickets"/>

                                                                        <ImageComponent dataScroll className="ticket ticket-green d-none d-md-block" src="ticket-account-home-big-heading1-green.svg" type="tickets"/>
                                                                        </>}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="content__body">
                                                                  <ul className="list-nav-link-card align-items-md-stretch flex-md-row flex-md-wrap justify-content-center">
                                                                        <li style={{padding:browserDetect ? "20px":""}}>
                                                                              <Link className="nav-link__card" to="/my-bookings">
                                                                                    <span className="nav-link__heading">{title.yourBookings}</span>
                                                                                    {/* <span className="nav-link__sub-heading">Past & Present</span> */}
                                                                              </Link>
                                                                        </li>
                                                                        <li style={{padding:browserDetect ? "20px":""}}>
                                                                              <Link className="nav-link__card" to="/my-account">
                                                                                    <span className="nav-link__heading">{title.yourAccount}</span>
                                                                                    {/* <span className="nav-link__sub-heading">Manage password, email & preferences</span> */}
                                                                              </Link>
                                                                        </li>
                                                                        <li style={{padding:browserDetect ? "20px":""}}>
                                                                              <Link className="nav-link__card" to="/my-favourites">
                                                                                    <span className="nav-link__heading">{title.yourFavourites}</span>
                                                                                    {/* <span className="nav-link__sub-heading">Shows to keep in mind</span> */}
                                                                              </Link>
                                                                        </li>
                                                                        <li style={{padding:browserDetect ? "20px":""}}>
                                                                              <Link className="nav-link__card" to="/" onClick={logOutHandler}>
                                                                                    <span className="nav-link__heading">{title.logout}</span>
                                                                                    {/* <span className="nav-link__sub-heading">See you later!</span> */}
                                                                              </Link>
                                                                        </li>
                                                                        
                                                                  </ul>
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                            {domain === "" && <>
                                                            <HeadingPictureComponent media="(min-width: 768px)" srcSet="ticket-account-home-big-heading1-orange.svg" src="ticket-account-home-big-heading1-orange-sm.svg" className="ticket-orange" dataScroll/>

                                                            <HeadingPictureComponent media="(min-width: 768px)" srcSet="ticket-account-home-big-heading1-aquamarine.svg" src="ticket-account-home-big-heading1-green-sm.svg" className="ticket-aquamarine" dataScroll/>

                                                            <ImageComponent dataScroll className="ticket ticket-yellow2 d-md-none" src="ticket-account-home-big-heading1-yellow.svg" type="tickets"/>
                                                            </>}
                                                            
                                                      </div>
                                                      {/* <!-- .content__my-account --> */}
                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /.section__my-account --> */}
                        </main>

                        <Footer/>
                  </div>
      )
}

export default MyAccountHome;



