import React from 'react'

// external css
import messageBubbleRed from "../../../assets/images/message-bubble-red.svg";
import messageBubbleRedAjtix from "../../../assets/images/message-bubble-red-ajtix.svg";
import messageBubbleRedTfm from "../../../assets/images/message-bubble-red-tfm.svg";

// redux and react hooks
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// child component
// packages
// Actions & RTK query or mutations
// Constants
import { NameConstants } from '../../../resources/NameConstants';
import { userCatTextArray } from "../../../resources/ContactUsObject"
import { routesName } from '../../../resources/RoutesName';
import { Config } from '../../../Config';

const ContentUserCat = () => {

      const contactUs = useSelector(state=>state.contactUs);
      const domain = useSelector(state=>state.user.domain)

      let organizer = false;
      if(userCatTextArray.includes(contactUs.step)){
            organizer = true;
      }
      
      return (
            <div className="col__left border-bottom border-bottom-lg-0 border-right-lg">

                  <div className="content">
                        <div className="content__body">
                              {/* {contactUs.step !== NameConstants.UserCat &&  */}
                              {false && 
                              <div className="user-cat">
                                    <h3 className="heading mb-0">I am a:</h3>
                                    <div className="user-cat__buttons ps-0">
                                          {/* replace span with a tags */}
                                          <span className={["btn-prefix-arrow",contactUs.userType === NameConstants.Member && "active"].join(" ")}>
                                                Member
                                          </span>
                                          <span className={["btn-prefix-arrow",contactUs.userType === NameConstants.Organizer && "active"].join(" ")}>
                                                Event Organiser
                                          </span>
                                    </div>
                              </div>}
                              <div className={["wrapper d-flex flex-column flex-md-row flex-lg-column",organizer && "d-none d-xl-flex"].join(" ")}>
                                    <div className="wrapper">
                                          <p>
                                                Need some help? If our <Link to={routesName.Faq}><span className="fw-bold">FAQs</span></Link> page doesn’t answer your question, send us a message and our team will get back to you within 24 hours. Don’t worry, our team prioritise urgent messages and respond to those first.
                                          </p>
                                    </div>
                                    <div className="feature flex-shrink-md-0 d-none d-lg-flex">
                                          <img className="message-bubble" src={domain === Config.DOMAIN_CONSTANT_AJTIX ? messageBubbleRedAjtix : ( domain === Config.DOMAIN_CONSTANT_TFM ? messageBubbleRedTfm : messageBubbleRed)} alt=""/>
                                    </div>
                              </div>

                        </div>
                        {/* <!-- .content__body --> */}
                  </div>
                  {/* <!-- .content --> */}
            </div>
      )
}

export default ContentUserCat
