import React from 'react';

// external css
// redux and react hooks
// child component
import { HeadingPictureComponent } from '../../../Components';
import { ImageComponent } from '../../../Components';

// packages
// Actions & RTK query or mutations
// Constants

const BigHeading = () => {

      return (
          
                  <div className="container-fluid container__big-heading">
                        <div className="row">
                              <div className="col-md-12">
                                    <div className="content content__what-we-do">
                                          <div className="content__header">
                                                <div className="big-heading text-center">
                                                      <h1 className="mb-0">What we do</h1>
                                                      {/* <img data-scroll className="ticket ticket-red" src={ticketHomeBigHeading1Red} type="tickets" alt=""/> */}
                                                      <ImageComponent dataScroll className="ticket ticket-red" srcSet="" src="ticket-home-big-heading1-red.svg" type="tickets"/>
                                                      <HeadingPictureComponent dataScroll className="ticket-yellow" srcSet="ticket-home-big-heading1-yellow.svg" src="ticket-home-big-heading1-yellow-sm.svg"/>

                                                      <HeadingPictureComponent dataScroll className="ticket-green" srcSet="ticket-home-big-heading1-green.svg" src="ticket-home-big-heading1-green-sm.svg"/>

                                                      <HeadingPictureComponent  dataScroll className="ticket-aquamarine" srcSet="ticket-home-big-heading1-aquamarine.svg" src="ticket-home-big-heading1-aquamarine-sm.svg"/>

                                                      <HeadingPictureComponent dataScroll className="ticket-blue" srcSet="ticket-home-big-heading1-blue.svg" src="ticket-home-big-heading1-blue-sm.svg"/>
                                                </div>
                                          </div>
                                          {/* <!-- .content__header --> */}
                                    </div>
                                    {/* <!-- .content --> */}
                              </div>
                              {/* <!-- .col-md-12 --> */}
                        </div>
                        {/* <!-- /.row --> */}
                  </div>
      )
}

export default BigHeading;
