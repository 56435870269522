/* eslint-disable */
import React, { useState, useEffect } from  'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Navigate  } from 'react-router-dom';

// child component
// packages
// Actions & RTK query or mutations
import { setPaymentMethod, setBookingStep } from '../../../../Store/BookingModule';
import { useWidgetSessionForWebViewMutation } from '../../../../Services/modules/BookingModule';
import { setAlert } from '../../../../Store/UI';
// Constants
import { checkValidationBookingModule } from "../../../../Services"
import { NameConstants } from '../../../../resources/NameConstants';
import { messages, errors } from '../../../../resources/en';

const PaymentViaWallet = () => {

      const [partPayAmount,setPartPayAmount]    =     useState({value:"",error:false,errorMessage:""});
      const [redirect,setRedirect]              =     useState("");
      const browserDetect                       =     useSelector(state=>state.ui.browserDetect);
      const user                                =     useSelector(state=>state.user);
      const bookingModule                       =     useSelector(state=>state.bookingModule);

      const dispatch                            =     useDispatch();
      const navigate                            =     useNavigate();

      const [widgetSessionForWebView, { data, isSuccess, error, isLoading }] = useWidgetSessionForWebViewMutation();

      useEffect(()=>{
            if(isSuccess) {
                  if(data.status === "1"){
                        // navigate(`/wallet-topup/${data.data.session_id}`, { state : {topUp : true}})
                        setRedirect(`/wallet-topup/${data.data.session_id}`)
                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
      
      },[data, isSuccess, error, isLoading, dispatch, navigate])

      if(redirect){
            return <Navigate to={redirect} state={{ topUp: true }} />;
      }

      const backOnPaymentViaWallet = ()=> {
            dispatch(setPaymentMethod({paymentMethod : "", useWalletCredit : 0, useCardCredit : 0, useApplePayCredit : 0}));
            dispatch(setBookingStep({step : NameConstants.bookingModuleSteps.paymentMethod }));
      }

      const redirectToTopUpHandler = ()=>{
            if(user.ct_web_view_session){
                  widgetSessionForWebView({"client":"app","action":"wallet"})
            } else {
                  window.open("/my-wallet/top-up", "_blank");
            }
      }

      const useCrediHandler = ()=> {
            if(displayTotalOnPaymentMethod <= user.ct_wallet_balance) {

                  dispatch(setPaymentMethod({paymentMethod : NameConstants.wallet, useWalletCredit : parseFloat(displayTotalOnPaymentMethod), useCardCredit : 0, useApplePayCredit : 0}));
                  dispatch(setBookingStep({step : NameConstants.bookingModuleSteps.orderSummaryPay }));
                  // dispatch(setWalletBalance({ct_wallet_balance : parseFloat(user.ct_wallet_balance - displayTotalOnPaymentMethod)}))
            }
      }

      const changeNumberHandler = (event)=>{
            event.preventDefault();
            let val = validate(event.target.value) !== null  ? validate(event.target.value).input : "";

            val = val.toString(); // "0.012"

            if (val.charAt(0) === '.') {
                  val = val.replace('.', '0.');
            }
            
            // Remove all digits except for two digits after the decimal point
            if(val.includes(".")){
                  let index = val.indexOf('.');
                  if (index !== -1) {
                        val = val.substring(0, index + 3); // str is now "2.22"
                  }
            }
           
            setPartPayAmount(prevState=>{
                  return {
                        ...prevState,
                        value : val
                  }
            });
      }

      const clearPartPaymentError = ()=> {
            setPartPayAmount(prevState=>{
                  return {
                        ...prevState,
                        error : false,
                        errorMessage : ""
                  }
            })
      }

      const validate = (s) => {
            var rgx = /^[0-9]*\.?[0-9]*$/;
            return s.match(rgx);
      }

      const partPayWithCreditHandler = ()=> {

            const res = checkValidationBookingModule(partPayAmount.value,NameConstants.partPayment,parseFloat(displayTotalOnPaymentMethod),user.ct_wallet_balance);
            
            if(!res.isValid){
                  setPartPayAmount(prevState=>{
                              return {
                                    ...prevState,
                                    error : true,
                                    errorMessage : res.validatingMessage
                              }
                        })
            } else {

                  const remainPayment = parseFloat(displayTotalOnPaymentMethod)- parseFloat(partPayAmount.value)
                  let paymentMethod = "";
                  if(remainPayment === 0){
                        paymentMethod = NameConstants.wallet;
                  }

                  dispatch(setPaymentMethod({paymentMethod : paymentMethod, useWalletCredit : parseFloat(partPayAmount.value), useCardCredit : 0, useApplePayCredit : 0}));
                  dispatch(setBookingStep({step : remainPayment === 0 ? NameConstants.bookingModuleSteps.orderSummaryPay : NameConstants.bookingModuleSteps.paymentViaWalletPlusCard}));
                  // dispatch(setWalletBalance({ct_wallet_balance : parseFloat(user.ct_wallet_balance - parseFloat(partPayAmount.value))}))
            }
      }

      let displayTotalOnPaymentMethod = parseFloat(bookingModule.bookingDetails.subTotal).toFixed(2);
      if(bookingModule.bookingDetails.promocode.status === "valid") {
            let charity = 0;
            if(bookingModule.bookingDetails.charity){
                  charity = NameConstants.charity;
            }
            displayTotalOnPaymentMethod = parseFloat(bookingModule.bookingDetails.promocode.totalAmount + bookingModule.bookingDetails.flexAmount + charity);
      }
      

      // Disbale payment via full wallet credit button if total amount is greter than wallet credit
      const disableFullWalletCredit = parseFloat(displayTotalOnPaymentMethod).toFixed(2) > parseFloat(user.ct_wallet_balance.toFixed(2))


      return (
            <div className="bookTicketsStep4PayViaWallet__wrapper">
            
                  <div id="bookTicketsStep4PayViaWallet" className="content content__payment content__payment--walletd-flex">
                        <div className="content__body">

                              <h4 className="heading">Use your wallet</h4>
                              <div className="wallet-balance-wrapper d-flex align-items-center justify-content-between border-top border-bottom w-100">
                                    <div className="wallet-balance__text">
                                          <div className="sub-heading__upper">Your current credit</div>
                                          <div className="wallet-balance">£ {parseFloat(user.ct_wallet_balance).toFixed(2)}</div>
                                    </div>
                                    {/* <button type="button" className="btn btn-xxs btn-outline-primary hover-primary" onClick={redirectToTopUpHandler} disabled={isLoading}>
                                          Top up
                                    </button> */}
                              </div>
                              <div className="buttons-wrapper">
                                    <button id="btnPaymentMethodFullWalletCredit" type="button" className="btn btn-primary w-100" 
                                          disabled={disableFullWalletCredit}
                                          onClick={useCrediHandler}
                                          >
                                          Use Credit for full amount
                                    </button>
                              </div>

                              {disableFullWalletCredit &&
                              <>
                              
                              <h4 className="heading">
                                    {/* Or enter the amount you’d like to use: */}
                                    Or top up your wallet
                                    </h4>
                              <div className="form-group-wrapper d-flex flex-column flex-md-row gap-4">
                                    {/* <div className="input-group input-group__amount input-group__as-form-control">
                                          <span className="input-group-text fw-bold">£</span>
                                          <input type="text" className="form-control fc__amount fw-bold" placeholder="0.00" 
                                          value={partPayAmount.value} 
                                          onChange={changeNumberHandler}
                                          onFocus={clearPartPaymentError}/>
                                          
                                    </div> */}
                                    <button id="btnPaymentMethodPartWalletCredit" type="button" className="btn btn-primary align-self-md-center" style={{marginLeft:browserDetect ? "20px":"", width: "100%"}} onClick={redirectToTopUpHandler} disabled={isLoading}>
                                          Top up
                                    </button>
                              </div>
                              </>}
                              {/* {partPayAmount.error &&<p className='form-label-error'>{partPayAmount.errorMessage}</p> }  */}
                              
                        </div>
                        {/* <!-- /.content__body --> */}

                        
                  </div>
                  <div className="content__footer__wrapper">
                        <div className="content__body content__body--total border-top">
                              <div className="subtotal-block">
                                    <div className="label">Total (VAT inc.):</div>
                                    <div className="total-amount">£{parseFloat(displayTotalOnPaymentMethod).toFixed(2)}</div>
                              </div>
                        </div>
                        {/* <!-- /.content__body --> */}
                        <div className="content__footer border-top">
                              <div className="buttons-wrapper gap-0 web-view-changes">
                                    <button id="btnBackToStep3FromPayViaWallet" type="button" className="btn__back btn btn-outline-primary w-50" onClick={backOnPaymentViaWallet}>
                                          Back
                                    </button>
                                    <button type="button" className="btn btn-primary w-50" disabled>
                                          Book now
                                    </button>
                              </div>
                        </div>
                        {/* <!-- /.content__footer --> */}
                  </div>
            </div>
      )
}

export default PaymentViaWallet;
