import { createSlice } from '@reduxjs/toolkit'

const initialState = {
      isRequesting:false,
      event_id:'',
      booking_id:'',
      bookingRef:'',
      isCancling:false,
      eventType:0,
      numberOfTickets:0,
      totalTickets:0,
      step:'step1',
      actionType:'',
      start_time:"",
      start_date:"",
      event_title:"",
      contacts:[],
      ticketsType:[],
      groups:[]
}


const currentBookingSlice = createSlice({
      name: 'currentBooking',
      initialState:initialState,
      reducers: {
            setCancleBookings(state,{ payload }){

                  state.isCancling = payload.isCancling;
                  state.eventType = payload.eventType;
                  state.numberOfTickets = payload.tickets;
                  state.totalTickets = payload.tickets;
                  state.step = payload.step;
                  state.booking_id = payload.booking_id;
                  state.bookingRef = payload.bookingRef;
                  state.event_id = payload.event_id;
                  state.start_time = payload.start_time;
                  state.start_date = payload.start_date;
                  state.event_title = payload.event_title;
            },
            setStepHandler(state,{ payload }){
                  state.step = payload.step;
            },
            setActionType(state, { payload }) {
                  state.actionType =  payload.actionType;
                  state.step = payload.step;
            },
            cancelBookingSuccess (state, { payload }) {
                  state.event_id          =     '';
                  state.booking_id        =     '';
                  state.bookingRef        =     '';
                  state.eventType         =     0;
                  state.numberOfTickets   =     0;
                  state.totalTickets      =     0;
                  state.step              =     payload.step;
                  state.actionType        =     '';
                  state.contacts          =     [];
                  state.ticketsType       =     [];
                  state.groups            =     [];
                  state.start_time        =     "";
                  state.start_date        =     "";
                  state.event_title       =     "";
            },
            setTicketsHandler (state, { payload }) {
                  state.numberOfTickets = payload.tickets;
            },
            setTicketsType (state, { payload }) {
                  state.ticketsType = payload.ticketsType;

            },
            reset: ()=> initialState
      },
      
})



export const { setStepHandler, setCancleBookings, setActionType, cancelBookingSuccess, setTicketsHandler, setTicketsType, reset } = currentBookingSlice.actions

export default currentBookingSlice.reducer
