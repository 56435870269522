import { createSlice } from '@reduxjs/toolkit'

const initialState = {
      eventsData:[],
      favorite_ids:[],
      filteredEvents:[],
      encore_json : [],
      filters_json : [],
      beforeSearchEventsData : [],
      isFilteredApplied:false,
      appliedFilterData : null,
      fetchListing : false
}

const currentListingSlice = createSlice({
      name: 'currentlisting',
      initialState:initialState,
      reducers: {
            setEventsData(state, { payload }) {
                  state.eventsData        =       payload.data;
                  state.favorite_ids      =       payload.favorite_ids;
                  state.encore_json       =       payload.encore_json
                  state.filters_json      =       payload.filters_json
            },
            setFilterEventsData (state, { payload }) {
                  state.filteredEvents = payload.filteredEvents;
                  state.isFilteredApplied = payload.isFilteredApplied;
            },
            setBeforeSearchData(state, { payload }) {
                  state.beforeSearchEventsData = payload.beforeSearchEventsData
            },
            setAppliedFilterData (state, { payload }) {
                  state.appliedFilterData = payload.appliedFilterData
            },
            setFetchListing(state, { payload }) {
                  state.fetchListing = payload.fetchListing
            },
            reset: ()=> initialState
            
      },
      
})



export const { setEventsData, setFilterEventsData, setAppliedFilterData, setBeforeSearchData, setFetchListing, reset } = currentListingSlice.actions

export default currentListingSlice.reducer


