import React, { useState, useEffect, useRef } from 'react';

// external css
import "react-datepicker/dist/react-datepicker.css";
import "./../../ReactDatepicker.css"

// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// child component
import { ImageComponent } from '../../../Components';

// packages
import { browserName } from "react-device-detect";

import OwlCarousel from 'react-owl-carousel';
import DatePicker from "react-datepicker";
import TomSelect from 'tom-select';

// Actions
import { useGetCurrentListingMutation } from '../../../Services/modules/currentlisting';
import { setFilterEventsData, setAppliedFilterData, setBeforeSearchData } from "../../../Store/CurrentListing";
import { updateLocoScroll,setAlert } from '../../../Store/UI';

// Constants
import DataObjects from '../../../resources/DataObjects';
import { errors, messages } from '../../../resources/en';
import { routesName } from '../../../resources/RoutesName';
let timerForSearch;

const ListingFilters = (props) => {
      
      const selectSortRef           = useRef(null);
      const selectLocationRef       = useRef(null);
      const location                = useLocation()

      // const [isPending, startTransition]      =     useTransition();
      const allevents                           =     useSelector(state => state.currentListing)
      const spinner                             =     useSelector(state => state.ui.spinner)
      const [sortState,setSortState]            =     useState({categories : [], region : [], sortby : []});
      const dispatch                            =     useDispatch();

      const [filter,setFilter]                  =     useState(false);
      const [currIndex,setCurrIndex]            =     useState(0);
      const [updateLocSel,setUpdateLocSel]      =     useState(false);
      
      const [startDate, setStartDate]           =     useState(null);
      const [endDate, setEndDate]               =     useState(null);
      const [search,setSearch]                  =     useState('');

      const [preventFilterAPI,setPreventFilterAPI]                  =     useState(false);

      const [scroll,setScroll] = useState(false);

      const { collapse, showCollapse } = props

      const [eventFilters, { data, isSuccess, isLoading, isFetching, error }]  =    useGetCurrentListingMutation();

      // asign filter_json to state once we have filter_json object
      useEffect(()=>{
            if(allevents.appliedFilterData) {

                  setSortState((prevState)=>{
                        return {
                              ...prevState,
                              categories : allevents.appliedFilterData.categories,
                              region : allevents.appliedFilterData.region,
                              sortby : allevents.appliedFilterData.sortby
                        }
                  })
                  if(allevents.appliedFilterData.startDate){
                        // setStartDate(new Date(allevents.appliedFilterData.startDate))
                        let startParts =  allevents.appliedFilterData.startDate.split("/");
                        let startYear = startParts[2];
                        let startMonth = startParts[1] - 1; // Months are zero-based (0 for January, 1 for February, etc.)
                        let startDay = startParts[0];
                        let startDate = new Date(startYear, startMonth, startDay);
                        setStartDate(startDate)
                  }

                  if(allevents.appliedFilterData.endDate){

                        let endParts =  allevents.appliedFilterData.endDate.split("/");
                        let endYear = endParts[2];
                        let endMonth = endParts[1] - 1; // Months are zero-based (0 for January, 1 for February, etc.)
                        let endDay = endParts[0];
                        let endDate = new Date(endYear, endMonth, endDay);
                        setEndDate(endDate)
                  }

                  if(allevents.appliedFilterData.search) {
                        setSearch(allevents.appliedFilterData.search)
                  }

                  setUpdateLocSel(true)

            }
            else if(allevents.filters_json){
                  setSortState((prevState)=>{
                        return {
                              ...prevState,
                              categories : allevents.filters_json.filters || [],
                              region : allevents.filters_json.location || [],
                              sortby : DataObjects.sortby 
                        }
                  })
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[allevents.filters_json])

      useEffect(()=>{   
            let toSetPreventApi = false
            if(allevents.appliedFilterData) {
                  const locationArray = allevents.appliedFilterData.region || []
                  locationArray.forEach(el=>{
                        if(el.selected) {
                              toSetPreventApi = true  
                        }
                  })
            }
            if(allevents.appliedFilterData && toSetPreventApi){
                  setPreventFilterAPI(true)
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{
            if(!location.pathname.includes(routesName.CurrentListing) && allevents.appliedFilterData !== null){
                  // console.log(location.pathname, 'listing filters')
                  clearFilterData()
            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[location.pathname])


      // Handle Fetch Current Listing Data API Request
      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1"){
                        dispatch(setFilterEventsData({filteredEvents:data.data,isFilteredApplied:true}));
                        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

                        dispatch(setAppliedFilterData({
                              appliedFilterData : { 
                                    region : sortState.region, 
                                    sortby : sortState.sortby, 
                                    categories : sortState.categories, 
                                    startDate : startDate && startDate.toLocaleDateString('en-GB', options), 
                                    endDate: endDate && endDate.toLocaleDateString('en-GB', options),
                                    search : search
                              }
                        }))
                        setScroll(true);
                  }
                  if(data.status === "0") {
                        dispatch(setFilterEventsData({filteredEvents:[],isFilteredApplied:true}));
                        setScroll(true);
                  }
            }
            
            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || errors.NotFound404}))
            }     

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error,dispatch])

      useEffect(()=>{
            if(filter) {
                  checkFilterHandler();
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[filter])

      useEffect(()=>{
            if(!collapse){

                  setSearch("");
                  setSortState(prevState=>{

                        return {
                              ...prevState,
                              categories : allevents.filters_json.filters || []
                        }
                  })
            }


      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[collapse])

      useEffect(()=>{
            if(scroll){
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
                  setScroll(false)
            }
      },[scroll, dispatch])

      // Set Tom Select For SortBy And Region
      useEffect(()=>{

            if(selectSortRef.current) {

                  const selectSort = new TomSelect(selectSortRef.current,{
                        onFocus:onFocusHandler,onBlur:onBlurHandler,
                        allowEmptyOption : true
      
                  });
                  selectSortRef.current = selectSort;
                  if(!allevents.appliedFilterData){
                        selectSortRef.current.setValue(null)
                  }
            }
           
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[sortState.sortby.length])

      useEffect(()=>{
            if(selectLocationRef.current) {

                  const selectLocation = new TomSelect(selectLocationRef.current,{
                        onFocus:onFocusHandler,onBlur:onBlurHandler,
                        allowEmptyOption : true,
                        plugins: ['checkbox_options'],
                        onChange : (value)=>regionChangeHandler(value)
      
                  })
                  selectLocationRef.current = selectLocation;
                  if(!allevents.appliedFilterData){
                        selectLocationRef.current.setValue(null)
                  }
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[sortState.region.length])
      
      // Check Filter on change in serach text
      useEffect(()=>{

            if(search.length >= 2 || allevents.beforeSearchEventsData.length > 1) {

                  // console.log('here')
                  clearTimeout(timerForSearch);
                  timerForSearch = setTimeout(() => {
                       checkFilterHandler()
                  }, 1000);
            }
          
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[search])

      // To hide more than 1 selected location and add number instead while user came from another page and filters are applied
      useEffect(()=>{
            if(updateLocSel) {
                  setUpdateLocSel(false)
                  hideMorethan1SelectedOptionsHandler()
            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[updateLocSel])

      // for Scrolling issue while we selected select Location fields and came back from event details page
      useEffect(()=>{
            // selectLocationRef.current.setValue(null)
            
            if(spinner!== "scrolling" && allevents.appliedFilterData){
                  
                  const regionItems = []
                  const sortArray = allevents.appliedFilterData.region

                  sortArray.forEach(el=>{
                        if(el.selected){
                              regionItems.push(el.id)
                        }     
                  })
                  
                  if(regionItems.length > 0 && selectLocationRef.current){
                        selectLocationRef.current.setValue(regionItems) 
                  }
            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[spinner])

      // code for set values of selectLocation after we came back from another routes and filters is applied
      useEffect(()=>{
            if(spinner!== "scrolling" && allevents.appliedFilterData && selectLocationRef.current){
                  const regionItems = []
                  const sortArray = allevents.appliedFilterData.region

                  sortArray.forEach(el=>{
                        if(el.selected){
                              regionItems.push(el.id)
                        }     
                  })

                  if(regionItems.length > 0 && selectLocationRef.current){
                        selectLocationRef.current.setValue(regionItems) 
                  }
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[selectLocationRef.current])

      // pause Locomotive scroll on focus select component 
      const onFocusHandler =()=>{
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'stop'}}))
      }

      // start Locomotive scroll on blur select component 
      const onBlurHandler =()=>{
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'start'}}))
      }

      const chnageStartDateHandler = (date)=>{
            if(endDate !==null){

                  if(date > endDate){
                        setStartDate(date)
                        setEndDate(null)
                        setFilter(true)
                  }
                  else if(date <= endDate){
                        setStartDate(date)
                        setFilter(true)
                  }
            }
            else {
                  setStartDate(date)
                  setFilter(true)
            }
            
      }

      const chnageEndDateHandler = (date)=>{
            console.log(date)
            if(date >= startDate || startDate ===null){
                  setEndDate(date)
            }
            setFilter(true)
      }

      const searchChangeHandler = (event)=> {
           
            setSearch(event.target.value)

            // setFilter(true);
            // startTransition(()=>{
            //       setSearch(event.target.value)
            //       setFilter(true);
            // })

      }

      const categoriesChangeHandler = (event)=> {

            const currentEleIndex = document.querySelector('.list__event-tags-wrapper .list__event-tags .owl-item.center span').getAttribute('crindex')
            // alert(JSON.stringify(currentEleIndex+'-'+'here'))

            const key = event.target.getAttribute('dataid');
           
            if(!key) {
                  return;
            }
            const index = sortState.categories.findIndex(cat=>cat.key === key)

            const updateCategories = [...sortState.categories]

            const selected =updateCategories[index].selected

            // console.log(selected,id ,'index....')

            updateCategories[index] = { ...updateCategories[index],selected:!selected}

            setSortState(prevState=>{

                  return {
                        ...prevState,
                        categories : updateCategories
                  }
            })

            setCurrIndex(currentEleIndex)
            setFilter(true);
      }

      const regionOrShortChangeHandler = (event)=> {

            const name  = event.target.name;
            const value = event.target.value;
           
            const index = sortState[name].findIndex(cat=>cat.id === value)

            const copyState = [...sortState[name]]

            const updateState = copyState.map((el,ind)=>{
                  if(ind === index){
                        return {...el,selected:true}
                  }else {
                        return {...el,selected:false}
                  }
            })

            setSortState(prevState=>{

                  return {
                        ...prevState,
                        [name] : updateState
                  }
            })
            setFilter(true);
      }

      const regionChangeHandler = (values)=> {

            const copyState = [...sortState.region]

            const updateState = copyState.map(el=>{
                  if(values.includes(el.id)){
                        return {...el,selected : true }
                  }else {
                        return {...el,selected:false}
                  }
            })
            setSortState(prevState=>{
                  return {
                        ...prevState,
                        region : updateState
                  }
            })

            setFilter(true)
            setUpdateLocSel(true)
      }
      
      const capitalizeFirstLetter = (string) =>{
            return string.charAt(0).toUpperCase() + string.slice(1);
      }

      const checkFilterHandler = ()=>{
            if(preventFilterAPI){
                  setPreventFilterAPI(false)
                  setFilter(false)
                  return
            }
           
            if(filter){
                  setFilter(false)
                  const filters = [];
                  // Filters object of sorting
                  sortState.sortby.forEach(el=>{
                        if(el.selected){
                              filters.push(el)
                        }
                  })
                  
                  // Filters object of Region
                  sortState.region.forEach(el=>{
                        if(el.selected){
                              filters.push(el)
                        }
                  })
                  
                  // Filters object of categories
                  sortState.categories.forEach(el=>{
                        if(el.selected){
                              filters.push(el)
                        }
                  })


                  // Filter object of dates
                  if(startDate != null || endDate!==null){
                        let finalStartDate = "";
                        let finalEndDate = "";
                        
                        if(startDate !==null){
                              
                              const start_dd        = ("0"+ startDate.getDate()).slice(-2);
                              const start_mm        = ("0"+(startDate.getMonth() + 1)).slice(-2);
                              const start_yyyy      = startDate.getFullYear();

                              finalStartDate = start_yyyy+"-"+start_mm+"-"+start_dd;
                        }

                        if(endDate !==null){
                              const end_dd        = ("0"+ endDate.getDate()).slice(-2);
                              const end_mm        = ("0"+(endDate.getMonth() + 1)).slice(-2);
                              const end_yyyy      = endDate.getFullYear();
                              finalEndDate = end_yyyy+"-"+end_mm+"-"+end_dd;
                        }

                        filters.push({
                              filter_type: "3",
                              id: "14",
                              lable: "Specific Date",
                              index: "19",
                              selected: true,
                              from_date: finalStartDate,
                              to_date: finalEndDate
                        })
                 
                  } 


                  if(filters.length > 0){
                        eventFilters({filters : filters})
                  } else {
                        dispatch(setFilterEventsData({filteredEvents:[],isFilteredApplied:false}));
                  }
                  
            } else {
                  
                  let isFilteredApplied=allevents.isFilteredApplied;
                  let filteredEventsData = [];
                  
                  // Filter BY Search
                  if(search.length === 0 && allevents.beforeSearchEventsData.length === allevents.eventsData.length) {
                        clearFilterData();
                        return;
                  }

                  if(search.length > 0 || allevents.beforeSearchEventsData.length > 0) { 
                        // now we check filterapplied then we took filtered data and store filtred data in beforeSearchData and second time if filter and search applied then we took beforeSearchEventsData
                        let intialSeachData;
                        if(!isFilteredApplied){
                              intialSeachData = allevents.eventsData
                        } else {
                              intialSeachData = allevents.beforeSearchEventsData.length > 0 ? allevents.beforeSearchEventsData : allevents.filteredEvents
                        }

                        intialSeachData.forEach(event=>{
                              const eventName1 = capitalizeFirstLetter(search);
                              const eventName2 = search.toLowerCase();
                              
                              if((event.event_name.indexOf(search)!==-1) || (event.event_name.indexOf(eventName1)!==-1) || (event.event_name.indexOf(eventName2)!==-1) || event.event_name.toLowerCase().indexOf(eventName2) !==-1){
                                    filteredEventsData.push(event)
                              }
                                    
                        })

                        dispatch(setBeforeSearchData({ beforeSearchEventsData : search.length === 0 ? [] : intialSeachData }))
                        dispatch(setFilterEventsData({filteredEvents:filteredEventsData,isFilteredApplied:true}));
                        setScroll(true)
                        dispatch(setAppliedFilterData({
                              appliedFilterData : { 
                                    region : sortState.region, 
                                    sortby : sortState.sortby, 
                                    categories : sortState.categories, 
                                    startDate : startDate && startDate.toLocaleDateString(), 
                                    endDate: endDate && endDate.toLocaleDateString(),
                                    search : search
                              }
                        }))
                  }
            }
      }

      const searchByNameHandler = ()=>{
            checkFilterHandler()
      }

      const clearFilterData = ()=> {
            dispatch(setFilterEventsData({filteredEvents:[],isFilteredApplied:false}));
            dispatch(setAppliedFilterData({appliedFilterData : null}))
            dispatch(setBeforeSearchData({ beforeSearchEventsData : [] }))
            setFilter(false)
            setSearch("");
            setStartDate(null);
            setEndDate(null);
            setSortState((prevState)=>{
                  return {
                        ...DataObjects,
                        categories : allevents.filters_json.filters || [],
                        region : allevents.filters_json.location || [] 
                  }
            });
            setScroll(true)

            selectSortRef.current !== null ?? selectSortRef.current.setValue(null)
            selectLocationRef.current !== null  ?? selectLocationRef.current.setValue(null)

            // To remove number of item selected for select location while clear filters
            let childnode;
            childnode = document.querySelector("#selectLocation + .ts-wrapper .ts-control")?.childNodes

            if(!childnode) {
                  return
            }
            
            if(typeof(childnode[childnode.length-1].data) === "string"){
                  childnode[childnode.length-1].textContent = "";
            }

      }

      // This is javascript function to display number if more than 1 item selected for select region
      const hideMorethan1SelectedOptionsHandler = ()=>{

            let items;
            items = document.querySelectorAll("#selectLocation + .ts-wrapper .ts-control .item")
            const amendItem  = items.length || "0";

            items.forEach((item, index)=>{
                  if(index > 0){
                        item.style.setProperty('display', 'none', 'important');

                        let childnode;
                        childnode = document.querySelector("#selectLocation + .ts-wrapper .ts-control").childNodes

                        
                        if(typeof(childnode[childnode.length-1].data) === "string"){
                              childnode[childnode.length-1].textContent = "("+amendItem  +")";
                        }
                        else {
                              document.querySelector("#selectLocation + .ts-wrapper .ts-control").append("("+amendItem  +")")
                        }
                       
                  }
                  else {
                        
                        let childnodes;
                        childnodes = document.querySelector("#selectLocation + .ts-wrapper .ts-control").childNodes
                        if(typeof(childnodes[childnodes.length-1].data) === "string"){
                              // childnode[childnode.length-1]
                              childnodes[childnodes.length-1].textContent = "";
                        }
                        item.style.display = "inline-flex"
                        item.style.display = "inline-flex"
                        
                        if(items.length > 1) {
                              childnodes[0].textContent = "Multiple selections";
                        } else {
                              const sortRegionArray = sortState.region.filter(el=>el.selected)
                              childnodes[0].textContent = sortRegionArray[0].lable || "";
                        }
                  }
            })
      }

      const options = {
            // autoplay: true,
            // autoplayTimeout: 2000,
            center: true,
            loop: true,
            dots: false,
            nav: true,
            //EXTERNAL nav container
            navContainer: '#owlCarouselEventTagsNav',
            autoWidth: true,
            animateIn:true,
            animateOut:true,
            onClick : categoriesChangeHandler,
            responsive:{
                  0:{
                        items: 3,
                        margin: 12,
                  },
                  1200:{
                        items: 8,
                        margin: 25,
                  },
            }
      }
      

      let regionItems = []
      let sortItems = null
      let filterButtonStyle = ""

      // const regionArray = allevents.appliedFilterData ? allevents.appliedFilterData.region : sortState.region
      const sortArray = allevents.appliedFilterData ? allevents.appliedFilterData.sortby : sortState.sortby

      sortArray.forEach(el=>{
            if(el.selected){
                  sortItems = el.id;
            }     
      })

      // Check browser if it's Firefox make clear filter button bottom position 6px
      if(browserName.includes('Firefox')){
            filterButtonStyle = "6px"
      }
      let keyCarousel = sortState.categories.filter(cat=>cat.selected === true).length


      return (    
            
            <div className={["collapse",showCollapse ? "show" :" "].join(" ")} id="collapseListingFilters">
                  <div className="collapse__listing-filters">

                        <div className="listing-filters">
                              <form>
                                    <div className="row__form-groups d-flex flex-column flex-xl-row justify-content-xxl-between">
                                          <div className="form-groups-wrapper form-groups-wrapper-4 d-flex flex-column flex-md-row flex-md-wrap mb-md-0">

                                                {sortState.sortby.length > 0 &&
                                                <div className="form-group mb-md-0">
                                                      <select id="selectSortBy" className="form-control tomselect tomselect-xl-lg" autoComplete="off" onChange={regionOrShortChangeHandler} name='sortby'  placeholder="Sort By" defaultValue={sortItems} ref={selectSortRef}>
                                                            {sortState && sortState.sortby.map(el=>{
                                                                  return <option  value={el.id} key={`sortby${el.id}`}>{el.lable}</option>
                                                            })}
                                                      </select>
                                                </div>}

                                                {sortState.region.length > 0 &&
                                                <div className="form-group mb-md-0">
                                                      <select id="selectLocation" className="form-control tomselect tomselect-xl-lg" autoComplete="off" multiple={true}  name='region'  placeholder="Select Location" defaultValue={regionItems} ref={selectLocationRef}>
                                                            {sortState && sortState.region.map(el=>{
                                                                  return <option value={el.id} key={`region${el.id}${el.lable}`}>{el.lable}</option>
                                                            })}
                                                      </select>
                                                </div>}

                                                {/* <!-- /.form-group --> */}
                                                <div className="form-group mb-md-0">
                                                      <div className="form-datepicker">
                                                            {/* <input id="EventStartDate" className="form-control__datepicker datepicker-xl-lg" placeholder="From date"/> */}
                                                            <DatePicker selected={startDate} className="form-control__datepicker datepicker-xl-lg"  onChange={(date:Date) => chnageStartDateHandler(date)} dateFormat="dd-MM-yyyy" placeholderText="From Date" minDate={new Date()}/>
                                                      </div>
                                                </div>
                                                {/* <!-- /.form-group --> */}
                                                <div className="form-group mb-md-0">
                                                      <div className="form-datepicker">
                                                            {/* <input id="EventEndDate" className="form-control__datepicker form-control__datepicker-xl-lg" placeholder="To date"/> */}
                                                            <DatePicker selected={endDate} className="form-control__datepicker form-control__datepicker-xl-lg" onChange={(date:Date) => chnageEndDateHandler(date)} dateFormat="dd-MM-yyyy" placeholderText="To Date" minDate={new Date()}/>

                                                      </div>
                                                </div>
                                                {/* <!-- /.form-group --> */}
                                          </div>
                                                
                                                {/* <!-- /.form-groups-wrapper --> */}
                                                <div className="form-groups-wrapper form-groups-wrapper__search mb-md-0">
                                                      <div className="form-group position-relative mb-md-0">
                                                            <input type="text" className="form-control form-control-line form-control__search form-control-xl-lg"
                                                            value={search} onChange={searchChangeHandler}/>
                                                            <ImageComponent className="icon" src="icon-search-black-sm.svg" type="images" onClick={searchByNameHandler}/>
                                                      </div>
                                                      {/* <!-- /.form-group --> */}
                                                </div>
                                                {/* <!-- /.form-groups-wrapper --> */}
                                    </div>
                        </form>
                        </div>
                        {/* <!-- /.listing-filters --> */}
                        {sortState.categories.length > 0 &&
                        <div className="list__event-tags-wrapper border-top">
                              <OwlCarousel className='list__event-tags owl-carousel owl-carousel__event-tags' 
                              loop {...options}
                              startPosition={currIndex}
                              key={`carousel_${keyCarousel}`} 
                              >
                                    
                                    {sortState.categories.filter(el=>el.filter_type !=="-1").map((el,index)=>{

                                                let addclass = ''
                                                if(el.selected) {
                                                      addclass = 'active'
                                                }
                                                return (    
                                                      <div className="item" key={`categories${index}${el.key}`} >
                                                            {/* replace a tag with div by rushit*/}
                                                            <div>
                                                                  <span className={["badge badge-outline badge-xl-lg",addclass].join(" ")} dataid={el.key} crindex={index}>
                                                                        {el.lable}
                                                                  </span>
                                                            </div>
                                                      </div>
                                                )

                                    })}

                              </OwlCarousel>
                              {/* <!-- /.list__event-tags --> */}

                              {/* <!-- EXTERNAL nav for .owl-carousel__event-tags  --> */}
                              <div id="owlCarouselEventTagsNav" className="owl-nav"></div>

                              {/* <!-- Search results__count --> */}
                              {(allevents.isFilteredApplied ) &&  <div className="search-results-wrapper text-center">
                                    <div>
                                          <span className="search-results__count">{allevents.filteredEvents.length}</span> {allevents.filteredEvents.length > 1 ? "results" : "result"}
                                          <button type="button" className="btn-close btn-close__clearfilters btn-close__custom btn-close__absolute" style={{bottom : filterButtonStyle}} onClick={clearFilterData}>
                                          </button>
                                    </div>
                              </div> }

                        </div>}
                        {/* <!-- /.list__event-tags-wrapper --> */}

                  </div>
                  {/* <!-- /.collapse__listing-filters --> */}
            </div>
      )
}

export default ListingFilters;
