import React from 'react';

// external css
// redux and react hooks
// child component
import { HeadingPictureComponent } from '../../Components';
import { useSelector } from 'react-redux';

// packages
// Actions & RTK query or mutations
// Constants

const Step3 = () => {


      const domain = useSelector(state=>state.user.domain)
      // const dispatch = useDispatch();

      // const backHandler = ()=> {

      //       if(window.ReactNativeWebView){
      //             window.ReactNativeWebView.postMessage(JSON.stringify({
      //                   "stay": false,
      //                   "redirect": true,
      //                   "redirectTo": "",
      //                   "status": 1,
      //                   "message": messages.SuccessAddVoucher
      //             }));
      //       } 
      // }
      
      return (
            <div id="walletTopupSuccess" className="content content__wallet-topup-success tickets-drop-down text-center d-flex step4">

                  <div className="content__header">
                        <div className="big-heading">
                              <h1 className="mb-0">
                                    {/* We'll<br/>Topped<br/>&nbsp;Up */}
                                    Voucher <br/>Purchase<br/> failed!
                              </h1>
                              {domain === "" && 
                              <>
                              <HeadingPictureComponent media="(min-width: 768px)" className="ticket-blue"
                              dataScroll
                              srcSet="ticket-topup-success-blue.svg"
                              src="ticket-topup-success-blue-sm.svg"
                              />

                              <HeadingPictureComponent media="(min-width: 768px)" className="ticket-red"
                              dataScroll
                              srcSet="ticket-topup-success-red.svg"
                              src="ticket-topup-success-red-sm.svg"
                              />

                              {/* <HeadingPictureComponent media="(min-width: 768px)" className="ticket-yellow"
                              dataScroll
                              srcSet="ticket-topup-success-yellow.svg"
                              src="ticket-topup-success-yellow-sm.svg"
                              /> */}

                              <HeadingPictureComponent media="(min-width: 768px)" className="ticket-green"
                              dataScroll
                              srcSet="ticket-topup-success-green.svg"
                              src="ticket-topup-success-green-sm.svg"
                              />

                              <HeadingPictureComponent media="(min-width: 768px)" className="ticket-orange"
                              dataScroll
                              srcSet="ticket-topup-success-orange.svg"
                              src="ticket-topup-success-orange-sm.svg"
                              />

                              <HeadingPictureComponent media="(min-width: 768px)" className="ticket-aquamarine"
                              dataScroll
                              srcSet="ticket-topup-success-aquamarine.svg"
                              src="ticket-topup-success-aquamarine-sm.svg"
                              />
                              </>}


                        </div>
                  </div>
                   {/* <div className="content__body min-height-0">
                        <br/>
                        <div className="buttons-wrapper">
                              <button type="button" id="btnBackToStep2" className="btn btn-primary mx-auto" onClick={backHandler}>Go back</button>
                        </div>
                  </div>  */}
                  {/* <!-- /.content__body --> */}
            </div>
      )
}

export default Step3
