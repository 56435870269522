import React from 'react';

// external css
// redux and react hooks
import { Link } from 'react-router-dom';

// child component
import { HeadingPictureComponent } from '../../../Components';
import { ImageComponent } from '../../../Components';

// packages
// Actions & RTK query or mutations
// Constants
import { routesName } from '../../../resources/RoutesName';
// import { URL } from '../../../resources/Url';

const ForMembers = () => {

      return (

            <div className="container-fluid container__for-members">
                  <div className="row">
                        <div className="col-md-6 order-md-2">
                              <div className="content content__image">
                                    <div className="content__body d-flex flex-column">
                                          {/* <ImageComponent dataScroll className="image1" srcSet="" src="ticket-uk-wide.png" type="images"/> */}
                                          <ImageComponent dataScroll className="image1" srcSet="" src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/ticket-uk-wide.webp`}/>
                                          <ImageComponent dataScroll className="image2" srcSet={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/theator-music.jpg`} src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/theator-music.jpg`} />
                                          <ImageComponent dataScroll className="image3 ms-auto" 
                                          srcSet={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/theator-music_one.jpg`} src={`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/theator-music_one.jpg`}
                                          />

                                          {/* <img data-scroll className="image1" src={ticketInAllUkGreen} alt=""/>
                                          <img data-scroll className="image2" srcSet={`${stockImage07a2x} 2x`} src={stockImage07} alt=""/>
                                          <img data-scroll className="image3 ms-auto" srcSet={`${stockImage08a2x} 2x`} src={stockImage08} alt=""/> */}
                                    </div>
                                    {/* <!-- .content__body --> */}
                              </div>
                              {/* <!-- .content --> */}
                        </div>
                        {/* <!-- .col-md-6 --> */}

                        <div className="col-md-6 order-md-1">
                              <div className="content content__for-members">
                                    <div className="content__header">
                                          <div className="big-heading">
                                                <h6 className="sub-heading sub-heading-upper">For our members</h6>
                                                <h2 className="heading mb-0">Theatre, <br className="d-sm-none"/>music <br className="d-none d-xxl-block"/>and so much more!</h2>
                                          </div>
                                    </div>
                                    {/* <!-- .content__header --> */}
                                    <div className="content__body">
                                          <p>
                                                Our members have access to a wide variety of different shows for an affordable price in exchange for being discreet and providing a valuable service to event organisers.
                                                <br />
                                                <br />
                                                Members can book up to six tickets so you can take friends and family along with you (you’ll always be sat together). 
                                                <br/><br/>
                                                By registering as a seat-filler you’ll also have access to exclusive pre-sales and West End discounts via our third-party ticketing partners.
                                          </p>
                                    </div>
                                    {/* <!-- .content__body --> */}
                                    <div className="content__cta">
                                          <Link className="btn btn-xl-lg btn-primary" to={routesName.Register}>
                                                Sign Up
                                          </Link>
                                    </div>
                                    {/* <!-- .content__cta --> */}

                                    <HeadingPictureComponent dataScroll className="ticket-aquamarine" srcSet="ticket-for-members-aquamarine.svg" src="ticket-for-members-aquamarine-sm.svg"/>

                                    <HeadingPictureComponent dataScroll className="ticket-green" srcSet="ticket-for-members-green.svg" src="ticket-for-members-green-sm.svg"/>

                                    <HeadingPictureComponent dataScroll className="ticket-yellow" srcSet="ticket-for-members-yellow.svg" src="ticket-for-members-yellow-sm.svg"/>

                                    <HeadingPictureComponent dataScroll className="ticket-blue" srcSet="ticket-for-members-blue.svg" src="ticket-for-members-blue-sm.svg"/>
                                   
                              </div>
                              {/* <!-- .content --> */}

                        </div>
                        {/* <!-- .col-md-6 --> */}
                  </div>
                  {/* <!-- /.row --> */}
            </div>
      )
}

export default ForMembers;
