import React from 'react'
import SavedCardForm from '../Components/SavedCardForm';

import { title } from '../../../resources/en';

const SavedCards = () => {
     

      return (
                 
            <div className="accordion-item accordion-item__SavedCards">
                  <h2 className="accordion-header" id="amasHeading5">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#amasCollapse5" aria-expanded="false" aria-controls="amasCollapse5">
                              <span className="btn__text">
                                    {title.savedCards}
                              </span>
                        </button>
                  </h2>
                  <div id="amasCollapse5" className="accordion-collapse collapse" aria-labelledby="amasHeading5">
                        <div className="accordion-body">
                              <SavedCardForm mobile/>
                              {/* <!-- /.content --> */}

                        </div>
                        {/* <!-- /.accordion-body --> */}
                  </div>
                  {/* <!-- /.accordion-collapse --> */}
            </div>
                       
      )
}

export default SavedCards;
