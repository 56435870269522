import React, { useState, useEffect } from 'react';

// external css
import ticket1starRed from "./../../assets/images/ticket-1star-red.svg";
import ticket1starYellow from "./../../assets/images/ticket-1star-red-ajtix.svg";
import ticket1starRedTfm from "./../../assets/images/ticket-1star-red-tfm.svg";



// redux and react hooks
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// child component
import { HeadingPictureComponent } from '../../Components';

// packages
// Actions & RTK query or mutations
import { registerValidationRules } from "./../../Services";
import { useSubmitRegisterDataMutation } from "./../../Services/modules/Register";
import { setInfoPartialRegistration, setReferral, setRegistrationType, logout } from "./../../Store/User";
import { updateRegistrationState, setIsRegister, setExistingRegisterNumber } from "../../Store/Register"
import { setAlert, setOtpEntityId } from "./../../Store/UI";

// Constants
import { actions, placeholders, label } from "./../../resources/en";
import { errors, messages } from './../../resources/en';
import { Config } from '../../Config';
import { NameConstants } from '../../resources/NameConstants';

const Step1 = () => {

      const [state,setState]= useState({
            [NameConstants.FirstName]:{value:"",error:false,errorMessage:""},
            [NameConstants.LastName]:{value:"",error:false,errorMessage:""},
            [NameConstants.Email]:{value:"",error:false,errorMessage:""},
            [NameConstants.PhoneNumber]:{value:"",error:false,errorMessage:""},
            [NameConstants.PassWord]:{value:"",error:false,errorMessage:""},
            [NameConstants.ConfirmPassword]:{value:"",error:false,errorMessage:""},
            [NameConstants.CheckReceiveAlerts]:{value:false,error:false,errorMessage:""},
            [NameConstants.CheckAgreeTOS]:{value:false,error:false,errorMessage:""},
            [NameConstants.CheckAgreeMG]:{value:false,error:false,errorMessage:""},
      })

      const user              =     useSelector(state => state.user)
      const register          =     useSelector(state => state.register)
      const dispatch          =     useDispatch();
      const [submitRegister, { data, isSuccess, isLoading, isFetching, error }] = useSubmitRegisterDataMutation();

      useEffect(()=>{
            if(user.ct_old_user_verification_code){

                  let fname = ""
                  let lname = ""
                  if(user.ct_access_name){
                        const nameArray = user.ct_access_name.split(" ")
                        fname = nameArray[0] || ""
                        lname = nameArray[1] || ""
                  }
                  
                  setState(prevState=>{
                        return {
                              ...prevState,
                              [NameConstants.FirstName] : {...state[NameConstants.FirstName], value : fname},
                              [NameConstants.LastName] : {...state[NameConstants.LastName], value : lname},
                              [NameConstants.Email] : {...state[NameConstants.Email], value : user.ct_access_email},
                              [NameConstants.PhoneNumber] : {...state[NameConstants.PhoneNumber], value : user.ct_access_mobilenumber},
                        }
                  })
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[user])

      useEffect(() => {
            
            if(isSuccess){
                  
                  if(data.status === "1"){ 
                        dispatch(setRegistrationType({ct_registration_type : ""}))
                        dispatch(setInfoPartialRegistration({
                              auth : false,
                              ct_access_token : data.data.token,
                              ct_refresh_token : data.data.refresh_token,
                              ct_register_token : data.data.token,
                              ct_access_name : state[NameConstants.FirstName].value +" "+ state[NameConstants.LastName].value,
                              ct_access_email : state[NameConstants.Email].value,
                              ct_access_mobilenumber : state[NameConstants.PhoneNumber].value,
                              ct_is_verified : 'false',
                              ct_firebase_key : data.data.userInfo.firebase_key,
                              ct_reactive : "0",
                              ct_is_top_member : 0,
                              ct_wallet_balance : 0
                        }))
                        dispatch(setOtpEntityId({ otp_entity_id : data.data.otp_entity_id }))
                        dispatch(setReferral({referral : ""}))
                        dispatch(setIsRegister({isRegister : true}))
                        dispatch(updateRegistrationState({
                              ct_otp_modal_status : "otpEmail",
                              step : "step2",
                              registration_step : register.registration_step
                        }))

                        // To remember if user change number while verifiying it
                        dispatch(setExistingRegisterNumber({existing_register_number : state[NameConstants.PhoneNumber].value}))

                  }
            } 

            if(isSuccess && !isFetching && !isLoading) {

                  if(data.status === "0"){

                        if(data.data){
                              const copy = {
                                    ...state
                              }
      
                              let message = ""
                              data.data.errors.forEach(el => {
                                    
                                    if(el.field_name === "fname"){
                                          copy[NameConstants.FirstName] = {...copy[NameConstants.FirstName],error:true,errorMessage:el.message}
                                    } 
                                    else if(el.field_name === "lname"){
                                          copy[NameConstants.LastName] = {...copy[NameConstants.LastName],error:true,errorMessage:el.message}
                                    } 
                                    else if(el.field_name === "email"){
                                          copy[NameConstants.Email] = {...copy[NameConstants.Email],error:true,errorMessage:el.message}
                                    } 
                                    else if(el.field_name === "mobilenumber"){
                                          copy[NameConstants.PhoneNumber] = {...copy[NameConstants.PhoneNumber],error:true,errorMessage:el.message}
                                    }
                                    else if(el.field_name === "password"){
                                          copy[NameConstants.PassWord] = {...copy[NameConstants.PassWord],error:true,errorMessage:el.message}
                                    }
                                    else if(el.field_name === "confirm_password"){
                                          copy[NameConstants.ConfirmPassword] = {...copy[NameConstants.ConfirmPassword],error:true,errorMessage:el.message}
                                    }
                                    else {
                                          message = el.message
                                    }
                              })
      
                              setState(prevState=>{
                                    return {
                                          ...prevState,
                                          ...copy
                                    }
                              })
                              if(message !== ""){
                                    dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:message || errors.NotFound404 }))
                              }
                        } else {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.errors || data.message || errors.NotFound404 }))
                        }


                  } 
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [data, isSuccess, isLoading, isFetching, error, dispatch])

      const changeHandler = (event)=>{
           
            const name = event.target.name;
            let value = event.target.value;
            let radioButton = false;

            if(name === NameConstants.FirstName || name === NameConstants.LastName){
                  value = capitalizeFirstLetter(value).trim();
            }

            if(name === NameConstants.CheckReceiveAlerts || name === NameConstants.CheckAgreeTOS || name === NameConstants.CheckAgreeMG){
                  value = event.target.checked;
                  radioButton=true;
            }

            if(name === NameConstants.PhoneNumber){
                  value = event.target.value.replace(/\D/g, '');
            }

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:{...prevState[name],value:value}
                  }
            })

            if(radioButton){
                  onfocusHandler();
            }
      }

      const onBlueNameFiledHandler = (event)=> {

            const name = event.target.name;
            let value = event.target.value;
            
            let prefixes = ["Mr", "Mrs", "Miss", "Ms", "Mx", "Dr", "Reverend", "Rev", "Revd Doctor"];
            if(prefixes.includes(value)){
                  value = ""
            }  

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:{...prevState[name],value:value}
                  }
            })
      } 

      function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
      }

      const onfocusHandler = ()=>{

            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.FirstName]:{...prevState[NameConstants.FirstName],error:false},
                        [NameConstants.LastName]:{...prevState[NameConstants.LastName],error:false},
                        [NameConstants.Email]:{...prevState[NameConstants.Email],error:false},
                        [NameConstants.PhoneNumber]:{...prevState[NameConstants.PhoneNumber],error:false},
                        [NameConstants.PassWord]:{...prevState[NameConstants.PassWord],error:false},
                        [NameConstants.ConfirmPassword]:{...prevState[NameConstants.ConfirmPassword],error:false},
                        [NameConstants.CheckReceiveAlerts]:{...prevState[NameConstants.CheckReceiveAlerts],error:false},
                        [NameConstants.CheckAgreeTOS]:{...prevState[NameConstants.CheckAgreeTOS],error:false},
                        [NameConstants.CheckAgreeMG]:{...prevState[NameConstants.CheckAgreeMG],error:false},
                  }
            })
      }

      const submitRegister12DataHandler = (event)=>{

            event.preventDefault();
            const errorStore = [];
            const copy = {
            }
            
            for(let key in state){
                  const errorCheck = registerValidationRules(state[key].value,key,state.password.value);

                  if(!errorCheck.isValid) {
                        errorStore.push(key);
                  }       
                  copy[key] = {...state[key],error:!errorCheck.isValid,errorMessage:errorCheck.validationMessage}
                        
            }

            if(errorStore.length > 0)
            {
                  // copy["errorExists"] = true;
                  setState(prevState=>{
                        return {
                              ...prevState,
                              ...copy
                        }
                  })
                  
            }
            else  {

                  dispatch(logout())
                  const data = {
                        fname: state.firstName.value,
                        lname: state.lastName.value,
                        email: state.email.value,
                        password: state.password.value,
                        confirm_password: state.confirmPassword.value,
                        mobilenumber: state.phoneNumber.value,
                        // referral_code: referralCode,
                        referral_code: user.ct_referral,
                        terms_agreed: state.checkAgreeTOS.value ? "1" :"0",
                        rules_agreed: state.checkReceiveAlerts.value ? "1" :"0",
                        membership_rules: state.checkAgreeMG.value ? "1" :"0",
                        code  : user.ct_old_user_verification_code,
                        type :  user.ct_registration_type || "",
                        gift_voucher_id : user.ct_gift_voucher || ""
                  }

                  submitRegister(data)
            }
                
      }
      
      let siteText = "Central Tickets"
      if(user.domain === Config.DOMAIN_CONSTANT_AJTIX){
            siteText = "AJ Tickets"
      }

      return (

            <div className="content content__register mx-auto">
                  <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                        <div className="big-heading">
                              <h1 className="h2 mb-0">
                                    Register
                              </h1>
                              {user.domain === "" && <>
                              
                              <HeadingPictureComponent 
                              dataScroll=""
                              src="ticket-register-big-heading1-orange-sm.svg"
                              srcSet="ticket-register-big-heading1-orange.svg" className="ticket-orange" />

                              <HeadingPictureComponent 
                              dataScroll=""
                              src="ticket-register-big-heading1-aquamarine-sm.svg"
                              srcSet="ticket-register-big-heading1-aquamarine.svg" className="ticket-aquamarine" />

                              <HeadingPictureComponent 
                              dataScroll=""
                              src="ticket-register-big-heading1-yellow-sm.svg"
                              srcSet="ticket-register-big-heading1-yellow.svg" className="ticket-yellow" />
                              </>}

                        </div>
                  </div>
                  {/* <!-- .content__header --> */}

                  <div className="content__body p-0 d-flex flex-column flex-lg-row">
                        <div className="col__left border-right-lg d-none d-lg-block">

                              <div className="content">

                                    <div className="content__body">
                                          <div className="wrapper d-flex flex-column flex-md-row flex-lg-column">
                                                <div className="wrapper">
                                                      <h3 className="heading">
                                                            Why become a member?
                                                      </h3>
                                                      <ul className="list list-square-style">
                                                            <li>
                                                                  Save money on show tickets
                                                            </li>
                                                            <li>
                                                                  Seat-filling offers for £4-6.50 per ticket
                                                            </li>
                                                            <li>
                                                                  Discounted offers at £15 or under
                                                            </li>
                                                            <li>
                                                                  Hundreds of shows each week
                                                            </li>
                                                            <li>
                                                                  Discounted West End tickets
                                                            </li>
                                                            <li>
                                                                  Exclusive pre-sale access
                                                            </li>
                                                            <li>
                                                                  Receive hot show alerts
                                                            </li>
                                                            <li>
                                                                  <span className="red fw-bold">It’s free to join!</span>
                                                            </li>
                                                      </ul>
                                                </div>
                                                <div className="feature flex-shrink-md-0">
                                                      <img className="ticket-1-star" src={user.domain === Config.DOMAIN_CONSTANT_AJTIX ? ticket1starYellow :  ( user.domain === Config.DOMAIN_CONSTANT_TFM ? ticket1starRedTfm : ticket1starRed)} alt=""/>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.content__body --> */}
                              </div>
                              {/* <!-- /.content --> */}

                        </div>
                        {/* <!-- /.col__left --> */}

                        <div className="col__right">

                              <div className="content">
                                    <div className="content__body">
                                          <form>

                                                <div className="row row__form-fields">

                                                      <div className="col-md-6">
                                                            <div className="form-group">
                                                                  <label htmlFor="registerInputFirstName" className="form-label">
                                                                        {label[NameConstants.FirstName]}
                                                                  </label>
                                                                  <input type="text" className="form-control" id="registerInputFirstName" name={NameConstants.FirstName} placeholder={placeholders.firstName} onChange={changeHandler} value={state.firstName.value} onFocus={onfocusHandler} disabled={isLoading}
                                                                  onBlur={onBlueNameFiledHandler}
                                                                  />
                                                                  {state.firstName.error &&
                                                                  <div className="form-label-error">
                                                                        {state[NameConstants.FirstName].errorMessage}
                                                                  </div>}
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>
                                                      <div className="col-md-6">
                                                            <div className="form-group">
                                                                  <label htmlFor="registerInputLastName" className="form-label">
                                                                        {label[NameConstants.LastName]}
                                                                  </label>
                                                                  <input type="text" className="form-control" id="registerInputLastName" placeholder={placeholders.lastName} name={NameConstants.LastName} onChange={changeHandler} value={state.lastName.value} onFocus={onfocusHandler} disabled={isLoading}
                                                                  onBlur={onBlueNameFiledHandler}/>
                                                                  {state.lastName.error && 
                                                                  <div className="form-label-error">
                                                                        {state[NameConstants.LastName].errorMessage}
                                                                  </div>}
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>

                                                </div>
                                                {/* <!-- /.row --> */}

                                                <div className="row row__form-fields">
                                                      <div className="col-md-6">
                                                            <div className="form-group">
                                                                  <label htmlFor="registerInputEmail" className="form-label">
                                                                        {label[NameConstants.Email]}
                                                                  </label>
                                                                  <input type="email" className="form-control" id="registerInputEmail" name={NameConstants.Email} placeholder={placeholders.email} onChange={changeHandler} value={state.email.value} onFocus={onfocusHandler} disabled={isLoading}/>
                                                                  {state.email.error &&  
                                                                  <div className="form-label-error">
                                                                        {state[NameConstants.Email].errorMessage}
                                                                  </div>}
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>
                                                      <div className="col-md-6">
                                                            <div className="form-group">
                                                                  <label htmlFor="registerInputPhoneNumber" className="form-label">
                                                                        {label[NameConstants.PhoneNumber]}
                                                                  </label>
                                                                  <input type="text" className="form-control" id="registerInputPhoneNumber" name={NameConstants.PhoneNumber} placeholder={placeholders.phoneNumber} onChange={changeHandler} value={state.phoneNumber.value} onFocus={onfocusHandler} disabled={isLoading}/>
                                                                  {state.phoneNumber.error && 
                                                                  <div  className="form-label-error">
                                                                        {state[NameConstants.PhoneNumber].errorMessage}
                                                                  </div>}
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>
                                                </div>
                                                {/* <!-- /.row --> */}

                                                <div className="row row__form-fields">
                                                      <div className="col-md-6">
                                                            <div className="form-group">
                                                                  <label htmlFor="registerInputPassword" className="form-label">
                                                                        {label[NameConstants.PassWord]}
                                                                  </label>
                                                                  <input type="password" className="form-control" id="registerInputPassword" name={NameConstants.PassWord} placeholder={placeholders.password} onChange={changeHandler} value={state.password.value} onFocus={onfocusHandler} disabled={isLoading}/>
                                                                  {state.password.error &&
                                                                  <div className="form-label-error">
                                                                  {state[NameConstants.PassWord].errorMessage}
                                                                  </div>}
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>
                                                      <div className="col-md-6">
                                                            <div className="form-group">
                                                                  <label htmlFor="registerInputConfirmPassword" className="form-label">
                                                                        {label[NameConstants.ConfirmPassword]}
                                                                  </label>
                                                                  <input type="password" className="form-control" id="registerInputConfirmPassword" name={NameConstants.ConfirmPassword} placeholder={placeholders.confirmPassword} onChange={changeHandler} value={state.confirmPassword.value} onFocus={onfocusHandler} disabled={isLoading}/>
                                                                  {state.confirmPassword.error && 
                                                                  <div className="form-label-error">
                                                                        {state[NameConstants.ConfirmPassword].errorMessage}
                                                                  </div>}
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>
                                                </div>
                                                {/* <!-- /.row --> */}

                                                <div className="row row__checkboxes">
                                                      <div className="col-md-12">

                                                            <div className="form-group form-group__custom-controls">
                                                                  <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                        <input type="checkbox" className="custom-control-input" id="checkReceiveAlerts" name={NameConstants.CheckReceiveAlerts} onChange={changeHandler} value={state.checkReceiveAlerts.value} checked={state.checkReceiveAlerts.value} onFocus={onfocusHandler} disabled={isLoading}/>
                                                                        <label className="custom-control-label" htmlFor="checkReceiveAlerts">
                                                                              I provide my consent to receive marketing communications from {siteText}. 
                                                                        </label>

                                                                        {state.checkReceiveAlerts.error && 
                                                                        <div className="form-label-error">
                                                                              {state.checkReceiveAlerts.errorMessage}
                                                                        </div>}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.form-group --> */}

                                                            <div className="form-group form-group__custom-controls">
                                                                  <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                        <input type="checkbox" className="custom-control-input" id="checkAgreeTerms" name={NameConstants.CheckAgreeTOS} onChange={changeHandler} value={state.checkAgreeTOS.value} checked={state.checkAgreeTOS.value} onFocus={onfocusHandler} disabled={isLoading}/>
                                                                        <label className="custom-control-label" htmlFor="checkAgreeTerms">
                                                                              I understand that joining {siteText} is subject to its <Link className="blue text-decoration-none" to="/terms" target="_blank" rel="noopener noreferrer">Terms & Conditions</Link> and <Link className="blue text-decoration-none" to="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy.</Link>
                                                                        </label>
                                                                        {state.checkAgreeTOS.error &&
                                                                        <div className="form-label-error ">
                                                                              {state.checkAgreeTOS.errorMessage}
                                                                        </div>}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.form-group --> */}

                                                            <div className="form-group form-group__custom-controls">
                                                                  <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                        <input type="checkbox" className="custom-control-input" id="checkReadAgreeGuidelines" name={NameConstants.CheckAgreeMG} onChange={changeHandler} value={state.checkAgreeMG.value} checked={state.checkAgreeMG.value} onFocus={onfocusHandler} disabled={isLoading}/>
                                                                        <label className="custom-control-label" htmlFor="checkReadAgreeGuidelines">
                                                                              I read and agree to follow the <Link className="blue text-decoration-none" to="/membership-guidelines" target="_blank" rel="noopener noreferrer">Membership Guidelines.</Link>
                                                                        </label>
                                                                        {state.checkAgreeMG.error &&
                                                                        <div className="form-label-error" >
                                                                              {state.checkAgreeMG.errorMessage}
                                                                        </div>}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                            
                                                      </div>
                                                </div>
                                                {/* <!-- /.row --> */}

                                                <div className="row row__cta">
                                                      <div className="col-md-12">
                                                            <div className="form-group form-group__cta">
                                                                  <button type="button" className="btn btn-xl-lg btn-primary" onClick={submitRegister12DataHandler}/*data-bs-toggle="modal" data-bs-target="#modalVerificationCode"*/
                                                                  disabled={isLoading}>
                                                                        {actions.createAccount}
                                                                  </button>
                                                            </div>
                                                            {/* <!-- /.form-group --> */}
                                                      </div>
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </form>
                                    </div>
                                    {/* <!-- /.content__body --> */}
                              </div>
                              {/* <!-- /.content --> */}

                        </div>
                        {/* <!-- /.col__right --> */}

                  </div>
                  {/* <!-- /.content__body --> */}

            </div>

      )
}

export default Step1
