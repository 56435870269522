import React, { useEffect } from 'react'

// external css
// redux and react hooks
import { useSelector } from 'react-redux'

// child component
import { HeadingPictureComponent } from '../../Components'
import Footer from '../../Layouts/Footer/Footer'

// packages
import { Helmet } from 'react-helmet'

// Actions & RTK query or mutations
// Constants
import { MetaData, MetaDataAj, MetaDataTfm } from '../../resources/MetaData'
import { Config } from '../../Config'

const InstantLogout = () => {

      const domain      =     useSelector(state=>state.user.domain)
      useEffect(()=>{
            document.title = "Temporary Account Blocked"
      },[])

      let domainText = "Team Central"
      if(domain === Config.DOMAIN_CONSTANT_TFM) {
            domainText = "Team Tickets For Medics"
      } else if(domain === Config.DOMAIN_CONSTANT_AJTIX) {
            domainText = "Team AJ Tickets"
      }

      let metaDataSite = MetaData
      if(domain === Config.DOMAIN_CONSTANT_TFM){
            metaDataSite = MetaDataTfm
      } else if(domain === Config.DOMAIN_CONSTANT_AJTIX){
            metaDataSite = MetaDataAj
      }
      
      
      return (
                  <div className="scroll-animations position-relative" data-scroll-container>
                        <Helmet>
                              <title>{metaDataSite.AccountStatus.title}</title>
                              <meta name="description" content={metaDataSite.AccountStatus.description} />
                              <meta name="keywords" content={metaDataSite.AccountStatus.keywords} />
                        </Helmet>
                        <main>
                              <section data-scroll-section className="section__faq section__terms pt-0">
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__faq mx-auto">
                                                            <div className="content__header text-center overflow-hidden">
                                                                  <div className="big-heading">
                                                                        <h1 className="mb-0">Temporary Account Blocked</h1>
                                                                        {domain === "" && <>
                                                                        <HeadingPictureComponent dataScroll className="ticket-orange" srcSet="ticket-faq-big-heading1-orange.svg" src="ticket-faq-big-heading1-green-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-blue" srcSet="ticket-faq-big-heading1-blue.svg" src="ticket-faq-big-heading1-blue-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-aquamarine" srcSet="ticket-faq-big-heading1-aquamarine.svg" src="ticket-faq-big-heading1-orange-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-yellow" srcSet="ticket-faq-big-heading1-yellow.svg" src="ticket-faq-big-heading1-aquamarine-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-red" srcSet="ticket-faq-big-heading1-red.svg" src="ticket-faq-big-heading1-red-sm.svg"/>
                                                                        </>}

                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="content__body" style={{"minHeight": "40vh"}}>
                                                                  
                                                                  <p className="desc font-white">Dear Member,</p>

                                                                  <p className="desc font-white">As you are likely aware, the&nbsp;<a target='_blank' rel="noreferrer" href='https://www.gov.uk/government/topical-events/coronavirus-covid-19-uk-government-response/'>UK Government</a>&nbsp;has announced that we should restrict any non-essential contact or travel, including visits to Pubs, Clubs or Theatres, effective immediately.</p>

                                                                  <p className="desc font-white"><a target='_blank' rel="noreferrer" href='https://officiallondontheatre.com/news/solt-uk-theatre-member-venues-close/'>The Society of London Theatre (SOLT)</a> has announced that West End venues are closed until further notice, as are many smaller venues.&nbsp;</p>

                                                                  <p className="desc font-white">As a temporary measure to help prevent the transmission of the virus, we will cease to take any bookings for seat-filling opportunities for the time being.</p>

                                                                  <p className="desc font-white">Access to our shows is now restricted and we are in the process of cancelling and refunding all bookings for seat-filling events taking place on or before the 31st March 2020. &nbsp;&nbsp;</p>

                                                                  <p className="desc font-white">We will reassess the situation next week to consider events taking place after this date and we will honour any cancellation requests and offer full refunds.&nbsp;</p>

                                                                  <p className="desc font-white">Please do not worry about minimum booking requirements &ndash; these rules will be relaxed in light of the above.</p>

                                                                  <p className="desc font-white">Your health and safety are very important to us and we urge you to look after yourselves and one another, throughout these uncertain times.</p>

                                                                  <p className="desc font-white">We look forward to welcoming you back and thank for your continued support and patience.</p>

                                                                  <p className="desc font-white">Best<br />
                                                                  {domainText}</p>
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content.content__faq --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /.section__faq --> */}
                        </main>

                        <Footer/>

                  </div>

            
      )
}

export default InstantLogout
