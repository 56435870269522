import { api } from "./../../../Services/Api";
import { getUptake } from "./getUptake";
import { getUptakeVenue } from "./getUptakeVenue";

export const uptakeApi = api.injectEndpoints({
            endpoints: build => ({
            getUptake:getUptake(build),
            getUptakeVenue:getUptakeVenue(build),
      }),
      overrideExisting: true
})

export const {  useLazyGetUptakeQuery, useLazyGetUptakeVenueQuery } = uptakeApi