import { api } from "./../../../Services/Api";
import { getLogin } from "./getLogin";
import { getLogout } from "./getLogout";
import { forgotPassword } from "./forgotPassword";
import { passwordChangeSubmit } from "./passwordChangeSubmit";
import { verifyEmail } from "./verifyEmail"

export const userApi = api.injectEndpoints({
            endpoints: build => ({
            getLogin :getLogin(build),
            getLogout: getLogout(build),
            forgotPassword: forgotPassword(build),
            passwordChangeSubmit:passwordChangeSubmit(build),
            verifyEmail:verifyEmail(build)
      }),
      overrideExisting: true
})

export const { useGetLoginMutation, useGetLogoutMutation, useForgotPasswordMutation, usePasswordChangeSubmitMutation, useVerifyEmailMutation } = userApi