import React from 'react'

const EventDetailButtonDummy = () => {
      return (
            <button type="button" className="btn btn-primary btn-book-show dummyButton">
            </button>
      )
}

export default EventDetailButtonDummy
