import React from 'react'
import ChangeEmailForm from '../Components/ChangeEmailForm';

const ChangeEmail = () => {

      
      return (
                  <div className="accordion-item accordion-item__ChangeEmail">
                        <h2 className="accordion-header" id="amasHeading2">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#amasCollapse2" aria-expanded="false" aria-controls="amasCollapse2">
                                    <span className="btn__text">
                                          Change Email
                                    </span>
                              </button>
                        </h2>
                        <div id="amasCollapse2" className="accordion-collapse collapse" aria-labelledby="amasHeading2">

                              <div className="accordion-body">

                                    <div className="content">
                                          <ChangeEmailForm />
                                          
                                    </div>
                                    {/* <!-- /.content --> */}

                              </div>
                              {/* <!-- /.accordion-body --> */}
                        </div>
                        {/* <!-- /.accordion-collapse --> */}
                  </div>
      )
}

export default ChangeEmail;
