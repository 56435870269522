import React from 'react';
import "./EventDetailImageDummy.css"

const EventDetailDescriptionDummy = () => {

      return <div className='event__body-dummyDescription'>
                  <p></p>
            </div>
}
export default EventDetailDescriptionDummy;
