import React from 'react'

const UptakeProgressBarDummy = () => {
      return (
            <div className="uptake-progressbar_dummy">
            </div>
      )
}

export default UptakeProgressBarDummy
