import React,{useState, useEffect} from "react";

// external css
// redux and react hooks
import { useSelector,useDispatch } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";

// child component
import Footer from "../../Layouts/Footer/Footer";
import { HeadingPictureComponent } from "../../Components";

// packages
// Actions & RTK query or mutations
import { useSubmitRestoreAccessTermsMutation } from "../../Services/modules/ReactiveAccount";
import { setAlert } from "../../Store/UI";
import { setReactiveAccount } from "../../Store/User";

// Constants
import { NameConstants } from "../../resources/NameConstants";
import { routesName } from "../../resources/RoutesName";
import {  placeholders, label, errors, messages } from "../../resources/en";
import { reactiveAccountValidationRules } from "../../Services"
import { Config } from "../../Config";

const initialstate = {
      [NameConstants.Name] : {value:"",error:false,errorMessage : ""},
      [NameConstants.Email] : {value:"",error:false,errorMessage : ""},
      [NameConstants.Attendance] : {value:false,error:false,errorMessage : ""},
      [NameConstants.CancellingBooking] : {value:false,error:false,errorMessage : ""},
      [NameConstants.Punctuality] : {value:false,error:false,errorMessage : ""},
      [NameConstants.Etiquette] : {value:false,error:false,errorMessage : ""},
      [NameConstants.TicketOffers] : {value:false,error:false,errorMessage : ""},
}

const ReactiveForm = () => {
      
      const [state,setState]  =     useState(initialstate)
      const user              =     useSelector(state=>state.user)

      const dispatch          =     useDispatch();
      const navigate          =     useNavigate();

      const [submitRestoreAccessTerms,{ data, isSuccess, error, isLoading }] = useSubmitRestoreAccessTermsMutation();

      useEffect(()=>{

            document.title = "Reactive Page";

            const name        =     user.ct_access_name || "";
            const email       =     user.ct_access_email || "";

            setState(prevState=>{
                  return {
                        ...prevState,
                        [NameConstants.Name]:{
                              ...prevState.fullname,
                              value:name
                        },
                        [NameConstants.Email]:{
                              ...prevState.email,
                              value:email
                        }
                  }
            })

      },[user.ct_access_name, user.ct_access_email])

      useEffect(()=>{
            if(isSuccess) {
                  if(data.status === "1"){
                        dispatch(setReactiveAccount({reactive:"0"}))
                        navigate(routesName.CurrentListing)
                  } 
                  else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.errors || data.error || data.message || errors.NotFound404}))
                  }
            }
            
            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }


      },[data, isSuccess, error, isLoading, dispatch, navigate])

      const changeValueHandler = (event)=>{
            event.preventDefault();
            const name= event.target.name;

            let value;
            if(name === NameConstants.Name ||  name === NameConstants.Email){
                  value = event.target.value;
            }
            else {
                  value = !(event.target.value === "true" ? true : false);
            }

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:{
                              ...prevState[name],
                              value:value
                        }
                  }
            })

      }

      const submitFormHandler = (event)=>{

            event.preventDefault()

            const checkedObject     =       {};
            const requestAllow      =       [];

            for(let i in state){
                  
                  const checkValidation   =       reactiveAccountValidationRules(state[i].value,i); 
                  checkedObject[i]        =       {...state[i],error:!checkValidation.isValid,errorMessage:checkValidation.validationMessage}

                  if(!checkValidation.isValid){
                        requestAllow.push(i); 
                  }
            }

            setState(prevState=>{
                  return {
                        ...checkedObject
                  }
            });
                
            if(requestAllow.length === 0) {
                  
                  const data = {
                        fullname:state[NameConstants.Name].value,
                        email:state[NameConstants.Email].value,
                        attendance:state[NameConstants.Attendance].value,
                        cancellingbooking:state[NameConstants.CancellingBooking].value,
                        Punctuality:state[NameConstants.Punctuality].value,
                        Etiquette:state[NameConstants.Etiquette].value,
                        TicketOffers :state[NameConstants.TicketOffers].value,
                        reactive_token:user.ct_access_token
                  }

                  submitRestoreAccessTerms(data)
            }

      }

      // Redirect to Home Page if user does not require to reactive account th
      if(user.ct_reactive === "0" ||  !user.ct_reactive) {
            return <Navigate to={routesName.Home}/>
      }
      let siteText = "Central Tickets"
      if(user.domain === Config.DOMAIN_CONSTANT_TFM){
            siteText = "Tickets For Medics"
      } else if(user.domain === Config.DOMAIN_CONSTANT_AJTIX) {
            siteText = "AJ Tickets"
      }

      return (
            <>
                  <div className="scroll-animations position-relative" data-scroll-container>
                        <main>
                              <section data-scroll-section className="section__register section__reactive-account py-0 my-auto">

                                    {/* <!--
                                          NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                                    --> */}
                                    <div className="container container-fluid container__block p-5">
                                                <div className="row">
                                                      <div className="col-xs-12 col-sm-12 col-md-12">
                                                            <div className="content content__register mx-auto">
                                                                  <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                                                                        <div className="big-heading">
                                                                              <h1 className="h2 mb-0">
                                                                                    Reactive Form
                                                                              </h1>
                                                                              {user.domain === "" && <>
                                                                              <HeadingPictureComponent 
                                                                              dataScroll=""
                                                                              src="ticket-register-big-heading1-orange-sm.svg"
                                                                              srcSet="ticket-register-big-heading1-orange.svg" className="ticket-orange" />

                                                                              <HeadingPictureComponent 
                                                                              dataScroll=""
                                                                              src="ticket-register-big-heading1-aquamarine-sm.svg"
                                                                              srcSet="ticket-register-big-heading1-aquamarine.svg" className="ticket-aquamarine" />

                                                                              <HeadingPictureComponent 
                                                                              dataScroll=""
                                                                              src="ticket-register-big-heading1-yellow-sm.svg"
                                                                              srcSet="ticket-register-big-heading1-yellow.svg" className="ticket-yellow" />
                                                                              </>}

                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- .content__header --> */}

                                                                  <div className="content__body p-0 d-flex flex-column flex-lg-row" style={{marginTop: "30px"}}>
                                                                        
                                                                        <form  method="post">
                                                                              <div className={["form-group","col-md-12 col-sm-12 col-xs-12"].join(" ")}>
                                                                                    <h3 className="heading">
                                                                                          To unblock your account you'll be asked a short series of questions which will allow you to demonstrate that you have read and understood what is expected of you as one of our members.
                                                                                    </h3>
                                                                              </div>
                                                                              <div className={["form-group","col-md-12 col-sm-12 col-xs-12"].join(" ")} >
                                                                                    <label htmlFor="inputName" className="control-label">
                                                                                          <h3 className="heading"><p>1) &nbsp; {label[NameConstants.Name]}</p></h3>
                                                                                    </label>
                                                                                    <input style={{"height": "47px"}} type="text"
                                                                                    name={NameConstants.Name}  className="form-control form-control-xl-lg" 
                                                                                    value={state[NameConstants.Name].value}  
                                                                                    placeholder={placeholders[NameConstants.Name]} 
                                                                                    onChange={changeValueHandler} 
                                                                                    disabled={isLoading}
                                                                                    />
                                                                                    {state[NameConstants.Name].error && 
                                                                                    <div className="form-label-error">
                                                                                          {state[NameConstants.Name].errorMessage}
                                                                                    </div>}
                                                                              </div>
                                                                              <div className={["form-group","col-md-12 col-sm-12 col-xs-12"].join(" ")} >
                                                                                    <hr/>
                                                                                    <label htmlFor="inputName" className="control-label">
                                                                                          <h3 className="heading"><p>2) &nbsp; Registered Account Email Address</p></h3>
                                                                                    </label>
                                                                                    <input style={{"height": "47px"}} 
                                                                                    name={NameConstants.Email} 
                                                                                    className="form-control form-control-xl-lg"  
                                                                                    value={state[NameConstants.Email].value} 
                                                                                    placeholder={placeholders[NameConstants.Email]}
                                                                                    onChange={changeValueHandler}
                                                                                    disabled={isLoading}
                                                                                    />
                                                                                    {state[NameConstants.Email].error && 
                                                                                    <div className="form-label-error">
                                                                                          {state[NameConstants.Email].errorMessage}
                                                                                    </div>}

                                                                              </div>
                                                                              <div className={["form-group","col-md-12 col-sm-12 col-xs-12"].join(" ")}>
                                                                                    <label htmlFor="inputName" className="control-label">
                                                                                          <hr/>
                                                                                          <h3 className="heading"><p>3) &nbsp; {label[NameConstants.Attendance]}</p></h3>
                                                                                          <p>{siteText} members must always attend those events that they book, with no exceptions.</p>
                                                                                          <p>You are given access to these seats for a nominal booking fee in return for providing a service - filling a seat. If you do no attend, we fail to provide the service that we offer to event organisers and they may choose to use a different seat filling service in the future.</p>
                                                                                          <p>By clicking 'I accept' you agree to attend all events that you book with {siteText}.*</p>
                                                                                    </label>
                                                                                    <div className={["input-group"].join(" ")}>
                                                                                          <button className={["col-lg-4 col-sm-12 col-md-5 btn btn-xl-lg btn-primary fw-bold mt-2",state[NameConstants.Attendance].value ? "buttonClicked" : ""].join(" ")} onClick={changeValueHandler} name={NameConstants.Attendance} value={state[NameConstants.Attendance].value} disabled={isLoading}>
                                                                                                {state[NameConstants.Attendance].value ? "accepted" :"click to accept"}
                                                                                          </button>
                                                                                    </div>
                                                                                    {state[NameConstants.Attendance].error && 
                                                                                    <div className="form-label-error">
                                                                                          {state[NameConstants.Attendance].errorMessage}
                                                                                    </div>}
                                                                              </div>
                                                                              <div className={["form-group","col-md-12 col-sm-12 col-xs-12"].join(" ")}>
                                                                                    <label className="control-label">
                                                                                          <hr/>
                                                                                          <h3 className="heading">
                                                                                                <p>4) &nbsp; {label[NameConstants.CancellingBooking]}</p>
                                                                                          </h3>
                                                                                          <p>We understand that you'll need to cancel from time to time, these things happen. {siteText} members can cancel up to 3 bookings per year and continue to be a member.</p>
                                                                                          <p>The correct process to do this is to visit our website and use the contact form to inform us that you can no longer attend. You must give us at least 3 hours notice so that we can reallocate the tickets to someone else and fill the seat for the event organiser.</p>
                                                                                          <p>By clicking 'I agree' you are confirming that you understand how many cancellations you can make each year and how to make them.*</p>
                                                                                    </label>
                                                                                    <div className="checkclass">
                                                                                          <div>
                                                                                                <button className={["col-lg-4 col-sm-12 col-md-5 btn btn-xl-lg btn-primary fw-bold mt-2",state[NameConstants.CancellingBooking].value ? "buttonClicked"  : ""].join(" ")} onClick={changeValueHandler} name={NameConstants.CancellingBooking} value={state[NameConstants.CancellingBooking].value} disabled={isLoading}>
                                                                                                      {state[NameConstants.CancellingBooking].value ? "accepted" :"click to accept"}
                                                                                                </button>
                                                                                          </div>
                                                                                          {state[NameConstants.CancellingBooking].error && 
                                                                                          <div className="form-label-error">
                                                                                                {state[NameConstants.CancellingBooking].errorMessage}
                                                                                          </div>}
                                                                                    </div>
                                                                              </div>
                                                                              <div className={["form-group","col-md-12 col-sm-12 col-xs-12"].join(" ")}>
                                                                                    <label className="control-label">
                                                                                          <hr/>
                                                                                          <h3 className="heading">
                                                                                                <p>5) &nbsp; {label[NameConstants.Punctuality]}:</p>
                                                                                          </h3>
                                                                                          <p>Collecting tickets on time is very important. In the last 30 minutes before the show begins, the Box Office are busy dealing with customers who have purchased full price tickets. That's why we ask you to collect at least 30 minutes in advance.</p>
                                                                                          <p>By clickiing 'I accept' you confirm that you have understood that you must collect tickets at least 30 minutes in advance.*</p>
                                                                                    </label>
                                                                                    <div className="checkclass">
                                                                                          <div>
                                                                                                <button className={["col-lg-4 col-sm-12 col-md-5 btn btn-xl-lg btn-primary fw-bold mt-2",state[NameConstants.Punctuality].value ? "buttonClicked" : ""].join(" ")} onClick={changeValueHandler} name={NameConstants.Punctuality} value={state[NameConstants.Punctuality].value} disabled={isLoading}>
                                                                                                      {state[NameConstants.Punctuality].value ? "accepted" :"click to accept"}
                                                                                                </button>
                                                                                          </div>
                                                                                          {state[NameConstants.Punctuality].error && 
                                                                                          <div className="form-label-error">
                                                                                                {state[NameConstants.Punctuality].errorMessage}
                                                                                          </div>}
                                                                                    </div>
                                                                              </div>
                                                                              <div className={["form-group","col-md-12 col-sm-12 col-xs-12"].join(" ")}>
                                                                                    <label htmlFor="inputName" className="control-label">
                                                                                          <hr/>
                                                                                          <h3 className="heading">
                                                                                                <p>6) &nbsp; {label[NameConstants.Etiquette]}:</p>
                                                                                          </h3>
                                                                                          <p>Event organisers give you the opportunity to attend their events at a low cost on the condition that you accept the seats you are given without complaint and do not make a fuss or be rude to their staff.</p>
                                                                                          <p>{siteText} members are not paying guests of the venue. You are a member of special group who gets access to these perks in return for providing a service.</p>
                                                                                          <p>Your role is to be discreet, friendly and grateful for whatever seats you are given and to enjoy the show with your guests.</p>
                                                                                          <p>By clicking "I accept" you are confirming that you will not ask for different seats and that you will be friendly and pleasant to all staff at the venues at all times*</p>
                                                                                    </label>
                                                                                    <div className="checkclass">
                                                                                          <div>
                                                                                                <button className={["col-lg-4 col-sm-12 col-md-5 btn btn-xl-lg btn-primary fw-bold mt-2",state[NameConstants.Etiquette].value ? "buttonClicked" : ""].join(" ")} onClick={changeValueHandler} name={NameConstants.Etiquette} value={state[NameConstants.Etiquette].value} disabled={isLoading}>
                                                                                                      {state[NameConstants.Etiquette].value ? "accepted" :"click to accept"}
                                                                                                </button>
                                                                                          </div>

                                                                                          {state[NameConstants.Etiquette].error && 
                                                                                          <div className="form-label-error">
                                                                                                {state[NameConstants.Etiquette].errorMessage}
                                                                                          </div>}
                                                                                    </div>
                                                                              </div>
                                                                              <div className={["form-group","col-md-12 col-sm-12 col-xs-12"].join(" ")}>
                                                                                    <label htmlFor="inputName" className="control-label">
                                                                                          <hr/>
                                                                                          <h3 className="heading"><p>7) &nbsp; {label[NameConstants.TicketOffers]}:</p></h3>
                                                                                          <p>
                                                                                                We regularly waive the admin fee on some events to provide free tickets to our members. The only condition of these free tickets is that you always attend and do not cancel them.
                                                                                          </p>
                                                                                          <p>
                                                                                                We are offering these tickets free of admin fee in return for you providing a service. If you cancel or do not attend after booking these tickets after receiving this warning, your account will be permanently closed immediately.
                                                                                          </p>
                                                                                          <p>
                                                                                                Please always bear in mind that event organisers are relying upon us and we are relying upon you to provide a service. By clicking "I accept" you agree to always attend and never cancel any tickets that have been made free of charge.
                                                                                          </p>
                                                                                    </label>
                                                                                    <div className="checkclass">
                                                                                          <div >
                                                                                                <button className={["col-lg-4 col-sm-12 col-md-5 btn btn-xl-lg btn-primary fw-bold mt-2",state[NameConstants.TicketOffers].value ? "buttonClicked" : ""].join(" ")} onClick={changeValueHandler} name={NameConstants.TicketOffers} value={state[NameConstants.TicketOffers].value} disabled={isLoading}>
                                                                                                      {state[NameConstants.TicketOffers].value ? "accepted" :"click to accept"}
                                                                                                </button>
                                                                                          </div>

                                                                                          {state[NameConstants.TicketOffers].error && 
                                                                                          <div  className="form-label-error">
                                                                                                {state[NameConstants.TicketOffers].errorMessage}
                                                                                          </div>}
                                                                                         
                                                                                    </div>
                                                                                    <div className={["form-group","col-lg-6 col-md-8 col-sm-8 col-xs-8"].join(" ")}>
                                                                                          <br/><br/>
                                                                                          <button type="submit" className={["col-sm-12 col-md-8 btn btn-xl-lg btn-primary fw-bold mt-2"].join(" ")} onClick={submitFormHandler} disabled={isLoading}>
                                                                                                Submit {false ===true && <i style={{"marginLeft":"10px"}} className="fa fa-spinner fa-spin"></i>}
                                                                                          </button>
                                                                                    </div>
                                                                              </div>
                                                                        </form>

                                                                  </div>
                                                                  {/* <!-- /.content__body --> */}

                                                            </div>
                                                      </div>
                                                </div>
                                    </div>
                              </section>
                        </main>
                        <Footer/>  
                  </div>      
            </>
            
      );
};

export default ReactiveForm;
