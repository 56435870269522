import React, { useState, useRef, useEffect } from 'react';


// external css

// small class
import ticketFlexiGreen1 from "../../assets/images/tickets/ticket-uptake-venues-green1.svg";

import ticketFlexiRed from "../../assets/images/tickets/ticket-faq-big-heading1-red.svg";
import ticketFlexiAquamarineSm from "../../assets/images/tickets/ticket-uptake-venues-aquamarine-sm.svg";

import ticketFlexiOrange1 from "../../assets/images/tickets/ticket-faq-big-heading1-orange.svg";
import ticketFlexiBlue1 from "../../assets/images/tickets/ticket-my-bookings-big-heading1-blue.svg";

// redux and react hooks
import { useNavigate  } from 'react-router-dom';
import { useDispatch, useSelector
 } from 'react-redux';
// child component
import Backdrop from '../Backdrop';

// packages
import { Transition } from 'react-transition-group';

// Actions & RTK query or mutations
import { useGetLogoutMutation } from '../../Services/modules/users'
import { useUpdateProfileMutation } from '../../Services/modules/settings';
import { logout, updateNameIfIsShort } from '../../Store/User'
import { reset as resetCurrentBookings } from '../../Store/CurruntBookings'
import { reset as resetBookingModule} from '../../Store/BookingModule'
import { reset as  resetRegistration} from '../../Store/Register'
import { reset as resetWallet } from "../../Store/Wallet"
import { setAlert } from '../../Store/UI';

// Constants
import { api } from '../../Services/Api';
import { registerValidationRules } from '../../Services';
import { label, messages, errors } from '../../resources/en';
import { NameConstants } from '../../resources/NameConstants';
import { routesName } from '../../resources/RoutesName';

const UpdateNameModal = (props) => {

      const [state,setState]= useState({
            [NameConstants.FirstName]:{value:"",error:false,errorMessage:""},
            [NameConstants.LastName]:{value:"",error:false,errorMessage:""},
      })

      const user = useSelector(state=>state.user)

      const [getLogout, { data, isSuccess, isLoading, isFetching, error }] = useGetLogoutMutation();
      const [updateProfile,{ data : updateData, isSuccess : updateIsSuccess, isLoading : updateIsLoading, isFetching : updateIsFetching, error : updateError }]  =    useUpdateProfileMutation()

      const nodeRef = useRef(null)
      const dispatch    = useDispatch()
      const navigate = useNavigate()

      useEffect(()=>{
            if(user.ct_name_length_short){

                  let fname = ""
                  let lname = ""
                  if(user.ct_access_name){
                        const nameArray = user.ct_access_name.split(" ")
                        fname = nameArray[0] || ""
                        lname = nameArray[1] || ""
                  }

                  setState(prevState=>{

                        return {
                              ...prevState,
                              [NameConstants.FirstName]:{...prevState[NameConstants.FirstName], value : fname},
                              [NameConstants.LastName]:{...prevState[NameConstants.LastName], value : lname}
                        }
                  })
            }

      },[user.ct_name_length_short, user.ct_access_name])

      // Handle logout API response
      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1"){
                        console.log("logout using update modal")
                        dispatch(api.util.resetApiState());
                        dispatch(logout(data.data));
                        navigate(routesName.Home)
                        // reset all redux slices
                        dispatch(resetCurrentBookings())
                        dispatch(resetBookingModule())
                        dispatch(resetRegistration())
                        dispatch(resetWallet())
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || errors.NotFound404}))
            }

      },[data, isSuccess, isLoading, isFetching, error, dispatch, navigate])

      // Handle Update Profile API response
      useEffect(()=>{
            if(updateIsSuccess){
                  if(updateData.status === "1"){
                        dispatch(updateNameIfIsShort({
                              fname :state[NameConstants.FirstName].value,
                              lname : state[NameConstants.LastName].value,
                              ct_name_length_short : false
                        }))
                        dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message: updateData.error || updateData.message ||  errors.NotFound404}))
                  } 
                  else if(updateData.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message: updateData.error || updateData.message ||  errors.NotFound404}))
                  }
            }

            if(updateError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:updateError.error || updateError.data.error || errors.NotFound404}))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[updateData, updateIsSuccess, updateIsLoading, updateIsFetching, updateError, dispatch, navigate])

      const changeHandler = (event)=>{
           
            const name = event.target.name;
            let value = event.target.value;

            value = capitalizeFirstLetter(value).trim();
            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:{...prevState[name],value:value}
                  }
            })
      }

      function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
      }

      const onfocusHandler = ()=>{
            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.FirstName]:{...prevState[NameConstants.FirstName],error:false},
                        [NameConstants.LastName]:{...prevState[NameConstants.LastName],error:false},
                  }
            })
      }

      const onBlueNameFiledHandler = (event)=> {

            const name = event.target.name;
            let value = event.target.value;
            
            let prefixes = ["Mr", "Mrs", "Miss", "Ms", "Mx", "Dr", "Reverend", "Rev", "Revd Doctor"];
            if(prefixes.includes(value)){
                  value = ""
            }  

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:{...prevState[name],value:value}
                  }
            })
      } 

      
      const submitUpdateDetais = (event)=>{

            event.preventDefault();
            const errorStore = [];
            const copy = {
            }
            
            for(let key in state){
                  const errorCheck = registerValidationRules(state[key].value,key);

                  if(!errorCheck.isValid) {
                        errorStore.push(key);
                  }       
                  copy[key] = {...state[key],error:!errorCheck.isValid,errorMessage:errorCheck.validationMessage}
                        
            }

            if(errorStore.length > 0)
            {
                  setState(prevState=>{
                        return {
                              ...prevState,
                              ...copy
                        }
                  })
                  
            }
            else  {

                  const data = {
                        // mobilenumber : user.ct_access_mobilenumber || "",
                        fname: state.firstName.value,
                        lname: state.lastName.value,
                        // email : user.ct_access_email || ""
                  }

                  updateProfile(data)
            }
                
      }

      const closePopupHandler = ()=> {
            getLogout();
      }
            
      return (

            <Transition in={props.show} timeout={500} mountOnEnter unmountOnExit nodeRef={nodeRef}>
            {status => { 

                              return (
                                    <React.Fragment>
                                          <div className={["modal modal__registration-verification modal__update-name show fade",(props.status === "entering" || props.status === "entered") && "modalOpen",(props.status === "exiting" || props.status === "exited") && "modalClosed", props.isAjtix && "ajtix-wrapper",props.isTfm && "tfm-wrapper"].join(" ")} id="modalVerificationCode" tabIndex="-1" aria-hidden="true" style={{"display":"block"}}>
                                                <div className="modal-dialog modal-dialog-centered">
                                                      <div className="modal-content">
                                                            <div className="modal-body">
                                                                  <button type="button" className="btn-close btn-close__custom btn-close__absolute" data-bs-dismiss="modal" aria-label="Close" onClick={closePopupHandler}>
                                                                  </button>

                                                                  <div className="content">
                                                                        <div className="content__body content-flexi-modal content-fname-validation-modal">
                                                                              <h2>Update your details</h2>
                                                                              <picture>
                                                                                    <img data-scroll className="ticket ticket-blue"
                                                                                    src={ticketFlexiBlue1} alt="" />
                                                                              </picture>
                                                                              <picture>
                                                                                    <img data-scroll className="ticket ticket-green1"
                                                                                    src={ticketFlexiGreen1} alt="" />
                                                                              </picture>
                                                                              <picture>
                                                                                    <img data-scroll className="ticket ticket-AquamarineSm"
                                                                                    src={ticketFlexiAquamarineSm} alt=""/>
                                                                              </picture>
                                                                              <picture>
                                                                                    <img data-scroll="" className="ticket ticket-red d-lg-block"
                                                                                    src={ticketFlexiRed} alt=""/>
                                                                              </picture>
                                                                              <picture>
                                                                                    <img data-scroll="" className="ticket ticket-orange1 d-lg-block"
                                                                                    src={ticketFlexiOrange1} alt=""/>
                                                                              </picture>
                                                                              <form>
                                                                                    <div className="form-group text-center">
                                                                                          <label htmlFor="updateFirstName" className="form-label">
                                                                                                {label[NameConstants.FirstName]}
                                                                                          </label>
                                                                                          <input name={NameConstants.FirstName} type="text" className="form-control" onChange={changeHandler} value={state[NameConstants.FirstName].value} onFocus={onfocusHandler} onBlur={onBlueNameFiledHandler}/>
                                                                                          {state[NameConstants.FirstName].error && <div className="form-label-error">
                                                                                                {state[NameConstants.FirstName].errorMessage}
                                                                                          </div>}
                                                                                    </div>
                                                                                    <div className="form-group text-center">
                                                                                          <label htmlFor="updateFirstName" className="form-label">
                                                                                                {label[NameConstants.LastName]}
                                                                                          </label>
                                                                                          <input name={NameConstants.LastName} type="text" className="form-control" onChange={changeHandler} value={state[NameConstants.LastName].value} onFocus={onfocusHandler} onBlur={onBlueNameFiledHandler}/>
                                                                                          {state[NameConstants.LastName].error && <div className="form-label-error">
                                                                                                {state[NameConstants.LastName].errorMessage}
                                                                                          </div>}
                                                                                    </div>
                                                                                    <div className="form-group__cta d-flex justify-content-center">
                                                                                          <button type="submit" className="btn btn-xl-lg btn-primary" disabled={isLoading || updateIsLoading} onClick={submitUpdateDetais}>Update</button>
                                                                                    </div>
                                                                              </form>
                                                                        </div>
                                                                        {/* <!-- /.content__body --> */}
                                                                  </div>
                                                                  {/* <!-- /.content --> */}

                                                            </div>
                                                            {/* <!-- /.modal-body --> */}

                                                      </div>
                                                      {/* <!-- /.modal-content --> */}
                                                </div>
                                                {/* <!-- /.modal-dialog --> */}
                                          </div>
                                          {/* <!-- modal #modalVerificationCode --> */}
                                          <Backdrop />
                                    </React.Fragment>
                              
                              )

                  }
            }
            </Transition>
      )
      
}

export default UpdateNameModal;
