import React from 'react'

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'

// child component
// packages
// Actions & RTK query or mutations
import { setStep } from '../../../Store/ContactUs';

// Constants
import { ContactUsObject } from '../../../resources/ContactUsObject';

const ContentBodyFaq = () => {

      const contactUs   =     useSelector(state=>state.contactUs);
      const dispatch    =     useDispatch();

      const continueOnFaqHandler = (event)=> {
            event.preventDefault()
            let jump_on = ContactUsObject[contactUs.step].jump_on
            dispatch(setStep({ step : jump_on }))
      }     

      const backOnFaqHandler = (event)=> {
            event.preventDefault()
            
            let back_on = ContactUsObject[contactUs.step].back_on
            dispatch(setStep({ step : back_on }))
      }

      
      
      return (
            <div className="content__body">

                  <div className="content content__contact-us-faq mx-auto">

                        <div className="content__header">
                              <h3 className="heading">How can we help you today?</h3>
                        </div>
                        {/* <!-- .content__header --> */}

                        <div className="content__body">

                              <div className="accordion accordion-line-separated" id="accordionContactUsFAQ">

                                    {/* <!-- item 1 --> */}
                                    <div className="accordion-item">
                                          <h2 className="accordion-header" id="contactUsFAQHeading1">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#contactUsFAQCollapse1" aria-expanded="false" aria-controls="contactUsFAQCollapse1">
                                                      <span className="btn__text">
                                                            I’d like to cancel or amend my booking / ask about a refund
                                                      </span>
                                                </button>
                                          </h2>
                                          <div id="contactUsFAQCollapse1" className="accordion-collapse collapse" aria-labelledby="contactUsFAQHeading1" data-bs-parent="#accordionContactUsFAQ">
                                                <div className="accordion-body">
                                                      <p>
                                                            To cancel or amend a booking, simply login to your account and navigate to Current Bookings, within My Account (or Bookings if you’re using the app). If you’ve booked flexi-tickets you’ll be able to amend or cancel those from within your account and any eligible credits will be paid into your wallet.
                                                      </p>
                                                      <p>
                                                            <Link to="/" onClick={continueOnFaqHandler}>Click here</Link> if you would still like to contact a member of our team
                                                      </p>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.accordion-item --> */}

                                    {/* <!-- item 2 --> */}
                                    <div className="accordion-item">
                                          <h2 className="accordion-header" id="contactUsFAQHeading2">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#contactUsFAQCollapse2" aria-expanded="false" aria-controls="contactUsFAQCollapse2">
                                                      <span className="btn__text">
                                                            I can’t find my booking confirmation
                                                      </span>
                                                </button>
                                          </h2>
                                          <div id="contactUsFAQCollapse2" className="accordion-collapse collapse" aria-labelledby="contactUsFAQHeading2" data-bs-parent="#accordionContactUsFAQ">
                                                <div className="accordion-body">
                                                      <p>
                                                            Booking confirmations for shows can be found in My Account (or Bookings within our app).  If the venue is issuing eTickets, click on collection instructions for guidance.  If after following those, you still need our help, do get in touch and we’ll see what we can do.
                                                      </p>
                                                      
                                                      <p>
                                                            <Link to="/" onClick={continueOnFaqHandler}>Click here</Link> if you would still like to contact a member of our team
                                                      </p>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.accordion-item --> */}

                                    {/* <!-- item 3 --> */}
                                    <div className="accordion-item">
                                          <h2 className="accordion-header" id="contactUsFAQHeading3">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#contactUsFAQCollapse3" aria-expanded="false" aria-controls="contactUsFAQCollapse3">
                                                      <span className="btn__text">I’d like to join the waiting list for a fully booked event</span>
                                                </button>
                                          </h2>
                                          <div id="contactUsFAQCollapse3" className="accordion-collapse collapse" aria-labelledby="contactUsFAQHeading3" data-bs-parent="#accordionContactUsFAQ">
                                                <div className="accordion-body">
                                                      <p>
                                                            If an event is showing as fully booked, it means that our current inventory is sold out. Any tickets that become available will be added to the listings on site for you to book.
                                                      </p>
                                                      <p>
                                                            Once all performances of an event are fully booked, you can join the waiting list. If more tickets become available, you will be automatically notified by email. Our team do not have the ability to manually add you to the waiting list unfortunately.
                                                      </p>
                                                      <p><Link to="/" onClick={continueOnFaqHandler}>Click here</Link> if you would still like to contact a member of our team</p>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.accordion-item --> */}

                                    {/* <!-- item 4 --> */}
                                    <div className="accordion-item">
                                          <h2 className="accordion-header" id="contactUsFAQHeading4">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#contactUsFAQCollapse4" aria-expanded="false" aria-controls="contactUsFAQCollapse4">
                                                      <span className="btn__text">If I book tickets, will we be sat together?</span>
                                                </button>
                                          </h2>
                                          <div id="contactUsFAQCollapse4" className="accordion-collapse collapse" aria-labelledby="contactUsFAQHeading4" data-bs-parent="#accordionContactUsFAQ">
                                                <div className="accordion-body">
                                                      <p>
                                                            Yes, you will be seated together if you buy more than one ticket. If you make more than one booking, we will automatically group the tickets as long as all tickets are booked via one member account.
                                                      </p>
                                                      <p>
                                                            <Link to="/" onClick={continueOnFaqHandler}>Click here</Link> if you would still like to contact a member of our team
                                                      </p>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.accordion-item --> */}

                                    {/* <!-- item 5 --> */}
                                    <div className="accordion-item">
                                          <h2 className="accordion-header" id="contactUsFAQHeading5">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#contactUsFAQCollapse5" aria-expanded="false" aria-controls="contactUsFAQCollapse5">
                                                      <span className="btn__text">Can I buy more tickets for an existing booking?</span>
                                                </button>
                                          </h2>
                                          <div id="contactUsFAQCollapse5" className="accordion-collapse collapse" aria-labelledby="contactUsFAQHeading5" data-bs-parent="#accordionContactUsFAQ">
                                                <div className="accordion-body">
                                                      <p>
                                                            Yes, and whilst it’ll show as two separate bookings within your account, we’ll automatically group them into one booking when we send the information to the venue so that you’ll be sat together.
                                                      </p>
                                                      <p>
                                                            <Link to="/" onClick={continueOnFaqHandler}>Click here</Link> if you would still like to contact a member of our team
                                                      </p>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.accordion-item --> */}

                                    {/* <!-- item 6 --> */}
                                    <div className="accordion-item">
                                          <h2 className="accordion-header" id="contactUsFAQHeading6">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#contactUsFAQCollapse6" aria-expanded="false" aria-controls="contactUsFAQCollapse6">
                                                      <span className="btn__text">Where/when should I collect my tickets?</span>
                                                </button>
                                          </h2>
                                          <div id="contactUsFAQCollapse6" className="accordion-collapse collapse" aria-labelledby="contactUsFAQHeading6" data-bs-parent="#accordionContactUsFAQ">
                                                <div className="accordion-body">
                                                      <p>
                                                            On the listing we explain if we, or the venue are issuing eTickets and we explain when and how those will be released.  Please refer to the collection instructions on the ticket for specific instructions for each event (because they can differ).
                                                      </p>
                                                      <p>
                                                            The collection instructions are given on every listing and they can also be found within your account under bookings. Please note that Collect from the Box Office always refers to the address of the venue and the address is always given on the PDF confirmation.
                                                      </p>
                                                      <p>
                                                            You should always collect tickets during the times stated on your confirmation. Do not arrive any more than 1 hour ahead of the show, unless we specifically ask you to. Your tickets will not be available for collection.
                                                      </p>
                                                      <p>
                                                            <Link to="/" onClick={continueOnFaqHandler}>Click here</Link> if you would still like to contact a member of our team
                                                      </p>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.accordion-item --> */}

                                    {/* <!-- item 7 --> */}
                                    <div className="accordion-item">
                                          <h2 className="accordion-header" id="contactUsFAQHeading7">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#contactUsFAQCollapse7" aria-expanded="false" aria-controls="contactUsFAQCollapse7">
                                                      <span className="btn__text">Will my account close if I don’t book within 12 months?</span>
                                                </button>
                                          </h2>
                                          <div id="contactUsFAQCollapse7" className="accordion-collapse collapse" aria-labelledby="contactUsFAQHeading7" data-bs-parent="#accordionContactUsFAQ">
                                                <div className="accordion-body">
                                                      <p>
                                                            If you are a regional member your account will not close because there is less choice.  The accounts of those people based in London will close if a booking is not made within 12 months, but not charges will be applied to the account. 
                                                      </p>
                                                      <p>
                                                            <Link to="/" onClick={continueOnFaqHandler}>Click here</Link> if you would still like to contact a member of our team
                                                      </p>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.accordion-item --> */}

                                    {/* <!-- item 8 --> */}
                                    <div className="accordion-item">
                                          <h2 className="accordion-header" id="contactUsFAQHeading8">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#contactUsFAQCollapse8" aria-expanded="false" aria-controls="contactUsFAQCollapse8">
                                                      <span className="btn__text">How can I find shows in my area?</span>
                                                </button>
                                          </h2>
                                          <div id="contactUsFAQCollapse8" className="accordion-collapse collapse" aria-labelledby="contactUsFAQHeading8" data-bs-parent="#accordionContactUsFAQ">
                                                <div className="accordion-body">
                                                      <p>
                                                            At this time, the majority of shows we work with are in London, but we do get regular regional events. We advise our regional members to stick around for a year to get a feel for what’s available as regional shows do vary throughout the seasons.  There are also online shows which are suitable for everyone.
                                                      </p>
                                                      <p>
                                                            To filter shows by your area, click on the advanced search filter and choose the regions you'd like to search.
                                                      </p>
                                                      <p>
                                                            <Link to="/" onClick={continueOnFaqHandler}>Click here</Link> if you would still like to contact a member of our team
                                                      </p>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.accordion-item --> */}

                                    {/* <!-- item 9 --> */}
                                    <div className="accordion-item">
                                          <h2 className="accordion-header" id="contactUsFAQHeading9">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#contactUsFAQCollapse9" aria-expanded="false" aria-controls="contactUsFAQCollapse9">
                                                      <span className="btn__text">
                                                            How can I let you know about my access needs?
                                                      </span>
                                                </button>
                                          </h2>
                                          <div id="contactUsFAQCollapse9" className="accordion-collapse collapse" aria-labelledby="contactUsFAQHeading9" data-bs-parent="#accordionContactUsFAQ">
                                                <div className="accordion-body">
                                                      <p style={{"fontWeight":"600"}}>
                                                            Access Bookings for Seat-Filling Opportunities
                                                      </p>
                                                      <p>
                                                            For seat-filling bookings all we  ask is that you get in touch with us to make us aware of your needs once, so that we can work with venues on your behalf to make sure they can accommodate you for all future shows that you book. Some older venues have limited access and we wouldn't want you to have a wasted journey.
                                                      </p>
                                                      <p style={{"fontWeight":"600"}}>
                                                            Access Bookings for West End Promotions (external promotions)
                                                      </p>
                                                      <p>
                                                            We promote West End tickets which we are fulfilled by third-parties and we have less control over those bookings.  What we suggest is that you visit Official London Theatre’s website to access their <a href="https://res.cloudinary.com/solt/image/upload/v1654707495/Venue_access_guide_2022_z1hdmt.pdf" target="_blank" rel='noreferrer'>Venue Guide</a>.  There’s also a really helpful <a href="https://officiallondontheatre.com/access/access-venue-contact-information/" target="_blank" rel='noreferrer'>Contact</a> List to liaise with venues directly to check your specific needs can be meet.  Once you have that information, you can continue to book tickets via the links we provide and choose the seats that will best meet your needs.
                                                      </p>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.accordion-item --> */}
                                    {/* <!-- item 10 --> */}
                                    <div className="accordion-item">
                                          <h2 className="accordion-header" id="contactUsFAQHeading10">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#contactUsFAQCollapse10" aria-expanded="false" aria-controls="contactUsFAQCollapse10">
                                                      <span className="btn__text">
                                                            Where can I update my details, or delete my account?
                                                      </span>
                                                </button>
                                          </h2>
                                          <div id="contactUsFAQCollapse10" className="accordion-collapse collapse" aria-labelledby="contactUsFAQHeading10" data-bs-parent="#accordionContactUsFAQ">
                                                <div className="accordion-body">
                                                      <p>
                                                            If you login into your account and click on My Account, you can edit your email preferences, update your password, or change your email address.
                                                      </p>
                                                      <p>
                                                            From there you can choose to opt out of all emails or reduce the number you receive, or close your account altogether.  Please note, we will not close your account if you opt-out of emails.
                                                      </p>
                                                      <p>
                                                            <Link to="/" onClick={continueOnFaqHandler}>Click here</Link> if you would still like to contact a member of our team
                                                      </p>
                                                </div>
                                          </div>
                                    </div>
                                    {/* <!-- /.accordion-item --> */}

                              </div>
                              {/* <!-- .accordion --> */}
                        </div>
                        {/* <!-- .content__body --> */}

                        <div className="content__footer">
                              <p className="p-accordion-btn">
                                    Something else? <Link className="blue" to="/" onClick={continueOnFaqHandler}>Click here</Link> to send us a message
                              </p>
                              <div className="buttons-wrapper flex-wrap d-md-flex">
                                    <Link to="/" className="btn btn-primary btn-xl-lg" onClick={backOnFaqHandler}>Back</Link>
                              </div>
                        </div>
                        {/* <!-- .content__footer --> */}

                  </div>
                  {/* <!-- .content__contact-us-faq --> */}

            </div>
      )
}

export default ContentBodyFaq
