import React from 'react'

const EventDetailListGroupItemDummy = () => {
      return (
            <div className="list-group-item list-group__time list-group-dummyItem">
                  <div className="icon-wrapper-dummy">
                  </div>
                  <p className="dummyP">
                  </p>
            </div>
      )
}

export default EventDetailListGroupItemDummy
