import React, { useState, useEffect } from 'react'

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
import { BackArrow } from "./index";

// packages
// Actions & RTK query or mutations
import { useContactWebMutation } from '../../../Services/modules/ContactUs';
import { setAlert } from '../../../Store/UI';
import { reset, setModalStatus } from '../../../Store/ContactUs';

// Constants
import { dateFormathandler, timeFormatHandler } from '../../../resources/DateFormatForCurrBookings';
import { actions, label, errors, messages } from '../../../resources/en';
import { NameConstants } from '../../../resources/NameConstants';
import { contactUsValidationRules } from "../../../Services"


const intialState = {
      [NameConstants.Problem] : { value : "", error : false, errorMessage : ""},
      [NameConstants.IdealSolution] : { value : "", error : false, errorMessage : ""}
}


const ContentBodyEventDetails = () => {

      const [state,setState]        =     useState(intialState);
      const contactUs               =     useSelector(state=>state.contactUs);
      const user                    =     useSelector(state=>state.user);
      const dispatch                =     useDispatch();

      const [contactWeb, { data, isSuccess, error, isLoading }] = useContactWebMutation();

      useEffect(()=>{

            if(isSuccess) {
                  if(data.status === "1"){
                        // dispatch(setAlert({model:true,title:messages.modalSuccessTitle, message : data.message || data.error || errors.NotFound404}));
                        dispatch(reset())
                        dispatch(setModalStatus({status : "messageReceived"}))
                        
                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.errors || data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
            
      },[data, isSuccess, isLoading, error, dispatch])

      const changeTextHandler = (event)=>{
            const { name, value} = event.target;
            setState(prevState=>{
                  return {
                        ...prevState,
                        [name] : {...prevState[name],value:value}
                  }
            })
      }

      const onFocusHandler = ()=> {
            setState(prevState=>{
                  return {
                        [NameConstants.Problem] : { ...prevState[NameConstants.Problem], error : false, errorMessage : ""},
                        [NameConstants.IdealSolution] : { ...prevState[NameConstants.IdealSolution], error : false, errorMessage : ""}
                  }
            })
      }

      const submitMemberShowDetailsMessageHandler = (event)=> {
            event.preventDefault();

            let request             =     false;
            const checkedObject     =     {};
            const requestAllow      =     [];
                  
            for(let i in state){
                        
                  const checkValidation   =       contactUsValidationRules(state[i].value,i); 
                  checkedObject[i]        =       {...state[i],error:!checkValidation.isValid,errorMessage:checkValidation.validationMessage}
                  requestAllow.push(!checkValidation.isValid); 
            }

            setState(prevState=>{
                  return {
                        ...checkedObject
                  }
            });

            request   =       requestAllow.includes(true);
            if(!request) {
                  const data = {
                        email:user.ct_access_email,
                        name: user.ct_access_name,
                        mobile: user.ct_access_mobilenumber,  
                        type:"booking",
                        booking_id :contactUs.showDetails ? contactUs.showDetails.booking_id : "",
                        event_title:contactUs.showDetails.event_name,
                        performance_date_time:dateFormathandler(contactUs.showDetails.start_date),
                        problem:state[NameConstants.Problem].value,
                        solution:state[NameConstants.IdealSolution].value,
                        booking_ref : contactUs.showDetails.BookingRef
                  }
                  contactWeb(data);
            }
      }

      let startDateArray = [];
      if(contactUs.showDetails.start_date){
            startDateArray = contactUs.showDetails.start_date.split("-");
      }
      const startDate =  startDateArray[1] + "-"+startDateArray[0] +"-" + startDateArray[2]

      return (
                  <div className="content__body p-0 d-flex flex-column flex-lg-row">

                        <div className="col__left border-bottom border-bottom-lg-0 border-right-lg d-none d-lg-block">

                              <div className="content content__event-details">
                                    <div className="content__body">
                                          <div className="card card__event flex-md-row flex-lg-column">
                                                <div className="card__event-image">
                                                      <div className={contactUs.showDetails.vendor === "TTG" ? "ttg-image-wrapper" :" "} style={{backgroundImage : contactUs.showDetails.vendor === "TTG" ? `Url(${contactUs.showDetails.blur_event_image})` : ""}}> 
                                                            <img className="event-image" srcSet={contactUs.showDetails.event_image} src={contactUs.showDetails.event_image} alt=""/>
                                                      </div>
                                                </div>
                                                <div className="card-body ps-md-3 ps-lg-0">
                                                      <h5 className="card-title fw-bold">
                                                            {contactUs.showDetails.event_name}
                                                      </h5>
                                                      <div className="list-group list-group__event-details list-group-flat">
                                                            <div className="list-group-item">
                                                                  <p className="mb-0">{contactUs.showDetails.tickets} {contactUs.showDetails.tickets > 1  ? "Tickets" : "Ticket"}</p>
                                                            </div>
                                                            <div className="list-group-item">
                                                                  <p className="mb-0">{dateFormathandler(startDate)  +' – '+ timeFormatHandler(contactUs.showDetails.start_time) }</p>
                                                            </div>
                                                            <div className="list-group-item">
                                                                  <p className="mb-0">{contactUs.showDetails.Location}</p>
                                                            </div>
                                                      </div>
                                                </div>
                                                {/* <!-- /.card-body --> */}
                                          </div>

                                    </div>
                                    {/* <!-- .content__body --> */}
                              </div>
                              {/* <!-- .content --> */}

                        </div>
                        {/* <!-- .col__left --> */}

                        <div className="col__right">
                              <div className="content content__contact-form content__contact-form--problem">
                                    <div className="content__body">
                                          <BackArrow/>
                                          <form>
                                                <div className="form-group">
                                                      <label htmlFor="eventOrgContactProblem" className="form-label">{label[NameConstants.Problem]}</label>
                                                      <textarea className="form-control" id="eventOrgContactProblem" 
                                                            name={NameConstants.Problem}
                                                            onChange={changeTextHandler} 
                                                            onFocus={onFocusHandler}
                                                            value={state[NameConstants.Problem].value}
                                                            disabled={isLoading}
                                                            >

                                                      </textarea>
                                                      {state[NameConstants.Problem].error && <label className="form-label form-label-error">{state[NameConstants.Problem].errorMessage}</label>}
                                                </div>
                                                {/* <!-- /.form-group --> */}

                                                <div className="form-group">
                                                      <label htmlFor="eventOrgContactIdealSolution" className="form-label">
                                                            {label[NameConstants.IdealSolution]}
                                                      </label>
                                                      <textarea className="form-control" id="eventOrgContactIdealSolution" 
                                                            name={NameConstants.IdealSolution}
                                                            onChange={changeTextHandler} 
                                                            onFocus={onFocusHandler}
                                                            value={state[NameConstants.IdealSolution].value}
                                                            disabled={isLoading}
                                                            >
                                                      </textarea>
                                                      {state[NameConstants.IdealSolution].error && <label className="form-label form-label-error">{state[NameConstants.IdealSolution].errorMessage}</label>}
                                                </div>
                                                {/* <!-- /.form-group --> */}

                                                <div className="form-group form-group__cta d-flex flex-column flex-sm-row">
                                                      <button type="button" className="btn btn-xl-lg btn-primary align-self-md-start" onClick={submitMemberShowDetailsMessageHandler} disabled={isLoading}>
                                                            {actions.sendMessage}
                                                      </button>
                                                </div>
                                                {/* <!-- /.form-group --> */}
                                          </form>

                                    </div>
                                    {/* <!-- .content__body --> */}
                              </div>
                              {/* <!-- .content --> */}

                        </div>
                        {/* <!-- .col__right --> */}
                  </div>
      )
}

export default ContentBodyEventDetails
