export const walletWebhookConfirmation =  build =>
      build.mutation({
            query: (body) =>  ({
                  url: '/wallet_webhook_confirmation',
                  method: 'POST',
                  body: body
            }),
            invalidatesTags: ['getHistory']
      })

      // Changed with new one
      

     