/* eslint-disable */
import React, { useState, useEffect } from 'react'

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux'

// child component
// packages
// Actions & RTK query or mutations
import { useUpdateProfileMutation } from '../../../Services/modules/settings'
import { setOtpModalStatus, setCtChangingMobileNumber, setAlert, setOtpEntityId } from '../../../Store/UI'
import { setMobileNumber } from '../../../Store/User'

// Constants
import { NameConstants } from '../../../resources/NameConstants'
import { label, actions, title, placeholders , errors, messages} from '../../../resources/en'
import { settingsChangeMobileNumberValidation } from "../../../Services"

const intialstate = {
      [NameConstants.SavedMobileNumber]:{value:"",error:false,errorMessage:""},
      [NameConstants.ChangeMobileNumber]:{value:"",error:false,errorMessage:""}
}

const ChangeMobileNumberForm = () => {

      const [state,setState]                    =     useState(intialstate);
      const user                                =     useSelector(state=>state.user)
      const ct_otp_modal_status                 =     useSelector(state=>state.ui.ct_otp_modal_status)
      const dispatch                            =     useDispatch()
      // const [fname, lname] = user.ct_access_name.split(" ");

      const [updateProfile,{ data, isSuccess, isLoading, isFetching, error }]  =    useUpdateProfileMutation()

      useEffect(()=>{
            if(ct_otp_modal_status=== "mobile_verified"){

                  dispatch(setMobileNumber({ct_access_mobilenumber : state[NameConstants.ChangeMobileNumber].value}))
                  dispatch(setOtpModalStatus({status : ""}))
                  setState(prevState=>{
                        return {
                              ...prevState,
                              [NameConstants.ChangeMobileNumber]:{value:"",error:false,errorMessage:""}
                        }
                  })
                  
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[ct_otp_modal_status, dispatch])

      useEffect(()=>{

            setState(prevState=>{
                  return {
                        ...prevState,
                        [NameConstants.SavedMobileNumber] : {...state[NameConstants.SavedMobileNumber],value:user.ct_access_mobilenumber}
                  }
            })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[user.ct_access_mobilenumber]);

      useEffect(()=>{

            if(isSuccess){
                  if(data.status === "1"){
                        dispatch(setOtpEntityId({ otp_entity_id : data?.data?.otp_entity_id || ""}))
                        dispatch(setOtpModalStatus({status : "otp"}))
                        dispatch(setCtChangingMobileNumber({ct_changing_mobile_number : state[NameConstants.ChangeMobileNumber].value}))

                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error, dispatch,])

      const onChangeHandler = (event)=>{

            const type = event.target.name;
            const value = event.target.value.replace(/\D/g, '');

            setState(prevState=>{
                  return {
                        ...prevState,
                        [type]:{...prevState[type],value:value}
                  }
            })
      }

      const onFocusHandler = ()=>{

            setState(prevState=>{
                  return {
                        ...prevState,
                        [NameConstants.ChangeMobileNumber]: {...prevState[NameConstants.ChangeMobileNumber],error:false,errorMessage:""},
                  }
            })
      }

      const submitHandler = (event)=>{
            event.preventDefault();

            const errorsArray = [];
            const coppyObject = {...state};

            for(let key in state){

                  const res = settingsChangeMobileNumberValidation(state[key].value,key,state[NameConstants.SavedMobileNumber].value)
                  if(!res.isValid){
                        errorsArray.push(key);
                        coppyObject[key] = {...coppyObject[key],error:!res.isValid,errorMessage:res.validationMessage}
                  }
            }

            if(errorsArray.length > 0) {

                  setState(prevState=>{
                        return {
                              ...prevState,
                              ...coppyObject
                        }
                  })

            } else {
                  
                  const names = user.ct_access_name.split(" ")
                  updateProfile({
                        mobilenumber : state[NameConstants.ChangeMobileNumber].value,
                        // fname : names[0],
                        // lname : names[1],
                        // email : user.ct_access_email
                  })
            }
            
      }


      return (
            <div className="content change-mobileNumber-content">
                  <h4 className="heading">{title.yourMobileNumber}</h4>
                  <div className="form-group">
                        <label className="form-label">{label[NameConstants.SavedMobileNumber]}</label>
                        <input type="text" className="form-control fc__mobileNumberOnRecord" name={NameConstants.SavedMobileNumber} value={state[NameConstants.SavedMobileNumber].value} disabled  readOnly/>
                  </div>
                  <h4 className="heading heading__change-mobile-num">{label[NameConstants.ChangeMobileNumber]}</h4>
                  <form>
                        <div className="form-group">
                              <input type="text" className="form-control fc__mobileNumberOnRecord" id="myAccntChangeMobileNumInputMobileNum" name={NameConstants.ChangeMobileNumber} value={state[NameConstants.ChangeMobileNumber].value} placeholder={placeholders[NameConstants.ChangeMobileNumber]} onChange={onChangeHandler} onFocus={onFocusHandler}
                              disabled={isLoading}/>
                              {state[NameConstants.ChangeMobileNumber].error && <p className='form-label-error'>{state[NameConstants.ChangeMobileNumber].errorMessage}</p> }
                        </div>
                        <div className="form-group form-group__cta">
                              <div className="form-group form-group__cta">
                                    <button type="button" className="btn btn-xl-lg btn-primary" onClick={submitHandler} data-bs-target="#modalMyAccountVerificationCode" disabled={isLoading}>
                                          {actions.sendConfirmationCode}
                                    </button>
                              </div>
                        </div>
                  </form>
            </div>

            
      )
}

export default ChangeMobileNumberForm;
