import React from 'react';

// external css
// redux and react hooks
// child component
import { ShowTickerItem } from './../../Components';

// packages 
import OwlCarousel from 'react-owl-carousel';

// Actions & RTK query or mutations

// Constants
import { ShowStickersOptions } from '../../resources/OwlCarouselOptions';
// import { URL } from '../../resources/Url';

const ShowsTicker = () => {

      const showTickerData = [
            {title:"Comedy",srcSet:`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/comedy_slider.jpg`,src:`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/comedy_slider.jpg`},
            {title:"Theatre",srcSet:`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/theator_slider.jpg`,src:`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/theator_slider.jpg`},
            {title:"Music",srcSet:`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/music_slider.jpg`,src:`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/music_slider.jpg`},
            {title:"Performance",srcSet:`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/performance_slider.jpg`,src:`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/performance_slider.jpg`},
            {title:"Drama",srcSet:`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/drma_slider.jpg`,src:`https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets/drma_slider.jpg`},
      ]

      return (

            <div data-scroll-section className="border-bottom">
                  <OwlCarousel className='owl-carousel owl-carousel__showsTicker owl-theme' loop {...ShowStickersOptions}>
                  {/* <div className="owl-carousel owl-carousel__showsTicker owl-theme"> */}

                        {showTickerData.map((item,index)=>{
                              return <ShowTickerItem key={`${item.title}+${index}`} title={item.title} srcSet={item.srcSet} src={item.src}/>
                        })}

                  {/* </div> */}
                  </OwlCarousel>
                  {/* <!-- /.ticker --> */}
            </div>
                             
      )
}

export default ShowsTicker;
