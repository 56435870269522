import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
      ct_reset_password_code : "",
      ct_otp_modal_status:"",
      ct_changing_mobile_number : "",
      otp_entity_id : "",
      locoScroll:{change:false,type:''},
      alert:{
            model:false,message:'',title :"",type:{value:"alert",status:false}
      },
      timer:null,
      getWalletBalance : false,
      spinner:"",
      browserDetect:"",
      deleteCardModal:false,
      scrollTo : "",
      showLoginPopup : false,
      hideWalletTopUpCopy : "",
      redirectToTemPausedPage : false,
      refreshListing : false,
      resetLoginPage : false
}

const slice = createSlice({
      name: 'ui',
      initialState: initialState,
      reducers: {
            updateLocoScroll(state, { payload }){
                  state.locoScroll = payload.locoScroll
            },
            setOtpModalStatus(state, { payload }){
                  state.ct_otp_modal_status = payload.status
            },
            setAlert(state,action) {
                  state.alert.message=action.payload.message;
                  state.alert.title=action.payload.title || "";
                  state.alert.model=action.payload.model;
                  state.alert.type= action.payload.type || {value:"alert",status:false};
            },
            confirmMessage (state, { payload }) {
                  state.alert.type = payload.type;
            },
            setBrowserDetect (state, { payload }) {
                  state.browserDetect = payload.browserDetect;
            },
            setDeteleCardModal (state, {payload}) {
                  state.deleteCardModal = payload.card;
            },
            setTimer(state,{ payload }){
                  state.timer = payload.timer;
            },
            setSpinner(state,{ payload }){
                  state.spinner = payload.status;
            },
            setGetWalletBalance(state, { payload }) {
                  state.getWalletBalance = payload.getWalletBalance;
            },
            setResetPasswordCode(state, { payload }) {
                  state.ct_reset_password_code = payload.ct_reset_password_code
            },
            setScrollToTarget(state, {payload}) {
                  state.scrollTo = payload.scrollTo
            },
            setShowLoginPopup(state, { payload }){
                  state.showLoginPopup = payload.showLoginPopup      
            },
            setHideWalletTopUpCopy(state, { payload })   {
                  state.hideWalletTopUpCopy = payload.hideWalletTopUpCopy
            },
            setRedirectToTemPausedPage(state, { payload }) {
                  state.redirectToTemPausedPage = payload.redirectToTemPausedPage
            },
            setRefreshListing(state, { payload }) {
                  state.refreshListing = payload.refreshListing
            },
            setCtChangingMobileNumber(state, { payload }) {
                  state.ct_changing_mobile_number = payload.ct_changing_mobile_number
            }, 
            setOtpEntityId (state, { payload }) {
                  state.otp_entity_id = payload.otp_entity_id || ""
            },
            setResetLoginPage(state, { payload }) {
                  state.resetLoginPage = payload.resetLoginPage
            },
            reset: ()=> initialState
           
      },
      
})



export const { updateLocoScroll, setOtpModalStatus, setAlert, confirmMessage, setBrowserDetect, setDeteleCardModal, setSecure3d, setTimer, setSpinner, setGetWalletBalance, setResetPasswordCode, setScrollToTarget, setShowLoginPopup, setHideWalletTopUpCopy, setRedirectToTemPausedPage, setRefreshListing, setCtChangingMobileNumber, setOtpEntityId, setResetLoginPage, reset } = slice.actions

export default slice.reducer
