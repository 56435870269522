import React, { useEffect } from 'react'

// external css
// redux and react hooks
import { useSelector } from 'react-redux';

// child component
import { ImageComponent } from '../../Components';
// import Footer from '../../Layouts/Footer/Footer';

// packages
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
// Constants
import { URL } from '../../resources/Url';
import { MetaData, MetaDataAj, MetaDataTfm } from '../../resources/MetaData';
import { Config } from '../../Config';

const NoWorkingPage = () => {

      const domain = useSelector(state=>state.user.domain)

      useEffect(()=>{
            document.title = "No working page";
      },[])

      let siteBaseURl = URL.SITE_BASE_URL
      let metaDataSite = MetaData
      if(domain === Config.DOMAIN_CONSTANT_TFM){
            siteBaseURl = URL.TFM_SITE_BASE_URL
            metaDataSite = MetaDataTfm
      } else if(domain === Config.DOMAIN_CONSTANT_AJTIX){
            siteBaseURl = URL.AJ_SITE_BASE_URL
            metaDataSite = MetaDataAj
      }
      
      return (
            <>

                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <div className="scroll-animations position-relative" data-scroll-container>
                        <Helmet>
                              <title>{metaDataSite.Error.title}</title>
                              <meta name="description" content={metaDataSite.Error.description} />
                              <meta name="keywords" content={metaDataSite.Error.keywords} />
                        </Helmet>
                        <main className="main__no-working-page align-items-center">

                              <section data-scroll-section className="section__no-working-page py-0 my-auto">
                                    {/* <!--
                                          NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                                    --> */}
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__no-working-page mx-auto">
                                                            <div className="content__header text-center overflow-hidden">
                                                                  <div className="big-heading big-heading-emoji">
                                                                        <h1 className="mb-0">
                                                                              {/* Sorry, our website <br className="d-none d-md-block"/>
                                                                              is not compatible with <br className="d-none d-md-block"/>
                                                                              Internet Explorer */}
                                                                              Something doesn't look right.  Use our contact form to get in touch.
                                                                        </h1>
                                                                        {domain === "" && <>
                                                                        <ImageComponent dataScroll className="emoji emoji-red" src="emoji-sad-no-working-page-red.svg" type="tickets"/>

                                                                        <ImageComponent dataScroll className="emoji emoji-aquamarine" src="emoji-sad-no-working-page-aquamarine.svg" type="tickets"/>

                                                                        <ImageComponent dataScroll className="emoji emoji-yellow" src="emoji-sad-no-working-page-yellow.svg" type="tickets"/>

                                                                        <ImageComponent dataScroll className="emoji emoji-blue" src="emoji-sad-no-working-page-blue.svg" type="tickets"/>

                                                                        <ImageComponent dataScroll className="emoji emoji-green" src="emoji-sad-no-working-page-green.svg" type="tickets"/>

                                                                        <ImageComponent dataScroll className="emoji emoji-orange" src="emoji-sad-no-working-page-orange.svg" type="tickets"/>
                                                                        </>}
                                                                  </div>
                                                                  <div className="browser-alternatives-block">
                                                                        {/* <p className="mb-0">Please use:</p> */}
                                                                        <p className="mb-0">
                                                                              <a className="blue text-decoration-none" href={`${siteBaseURl}contact-us`} target="_blank" rel='noreferrer'>Contact us</a>
                                                                        </p>
                                                                       
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                      </div>
                                                      {/* <!-- /.content.content__no-working-page --> */}
                                                </div>
                                                {/* <!-- /.col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /.section__no-working-page --> */}

                        </main>

                        {/* <Footer/> */}

                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}
            </>
      )
}

export default NoWorkingPage
