import { api } from "./../../../Services/Api";
import { contactWeb } from "./contactWeb";


export const ContactUsApi = api.injectEndpoints({
      endpoints: build => ({
            contactWeb:contactWeb(build),
      }),
      overrideExisting: true
})

export const { useContactWebMutation } = ContactUsApi;