import { validation } from "../resources/en";
import { NameConstants } from "../resources/NameConstants";

const cardValidationPayment=(value, type) =>{
      let isValid = true;
      let validatingMessage = "";
      if (!type) {
          return true;
      }

      
      if(type === NameConstants.CTNameOnCard){
            
            let ct_name_on_card = value.trim();
            if(ct_name_on_card.length === 0){
                  isValid = false;
                  validatingMessage = validation[type].required
            }
            else if(ct_name_on_card.length > 4){

                  let firstName = ct_name_on_card.split(' ').slice(0, -1).join(' ');
                  let lastName = ct_name_on_card.split(' ').slice(-1).join(' ');
                  let ct_fname_on_card_length=firstName.trim().length;
                  let ct_lname_on_card_length=lastName.trim().length;
      
                  if(ct_fname_on_card_length <1 || ct_lname_on_card_length<2)
                  {
                      isValid = false;
                      validatingMessage = validation[type].invalid
                  }
            }
            else {
                  isValid = false;
                  validatingMessage =validation[type].invalid
            }
      }

      if(type === NameConstants.CTCardNumber){
            if(value.trim() ==='') 
            {
                  isValid = false;
                  validatingMessage = validation[type].required;
            }

            var cardno = /^(?:3[47][0-9]{13})$/;
            if(value.match(cardno)) {
                  isValid = false;
                  validatingMessage = "Sorry, we don't accept AMEX at this time."
            }
           
      }

      if(type === NameConstants.CTExp){
            if(value.trim() ==='') 
            {
                  isValid = false;
                  validatingMessage = validation[type].required;
            }
      }

      if(type === NameConstants.CTCvc){
            if(value.trim() ==='') 
            {
                  isValid = false;
                  validatingMessage = validation[type].required;
            }
      }

      return {isValid,validatingMessage};
}

export default cardValidationPayment;
