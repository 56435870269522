
export const  ClassChangeFunction=(pathname,locoScrollProgress) =>{

      if(pathname.includes("about-us")){
      
            document.querySelector('.spy-scrollbar__progress').style.height = locoScrollProgress + "%";

            // append bg color class to <main> when a section is in-view
            if (document.querySelector('[data-inview="section__NHSCT"]').classList.contains('is-inview')) {
                  document.querySelectorAll('#spyScrollbarNav .nav-link').forEach(box => {
                        box.classList.remove('active');
                      
                  });
                  document.querySelector('[href="#sectionNHSCT"]').classList.add('active');
                  document.querySelector('main').classList.add('bg-white');
                  document.querySelector('main').classList.remove('bg-mint');
                  document.querySelector('main').classList.remove('bg-yellow');
            }

            if (document.querySelector('[data-inview="section__about-us"]').classList.contains('is-inview')) {
                  // document.querySelector('#spyScrollbarNav .nav-link').classList.remove('active');
                  document.querySelectorAll('#spyScrollbarNav .nav-link').forEach(box => {
                        box.classList.remove('active');
                  });

                  document.querySelector('[href="#sectionAboutUs"]').classList.add('active');
                  document.querySelector('main').classList.add('bg-white');
                  document.querySelector('main').classList.remove('bg-mint');
                  document.querySelector('main').classList.remove('bg-yellow');
            }

            if (document.querySelector('[data-inview="section__ecologi"]').classList.contains('is-inview')) {
                  // document.querySelector('#spyScrollbarNav .nav-link').classList.remove('active');
                  document.querySelectorAll('#spyScrollbarNav .nav-link').forEach(box => {
                        box.classList.remove('active');
                  });
                  document.querySelector('[href="#sectionEcologi"]').classList.add('active');
                  document.querySelector('main').classList.add('bg-mint');
                  document.querySelector('main').classList.remove('bg-white');
                  document.querySelector('main').classList.remove('bg-yellow');
            }

            if (document.querySelector('[data-inview="section__our-values"]').classList.contains('is-inview')) {
                  // document.querySelector('#spyScrollbarNav .nav-link').classList.remove('active');
                  document.querySelectorAll('#spyScrollbarNav .nav-link').forEach(box => {
                        box.classList.remove('active');
                  });
                  document.querySelector('[href="#sectionOurValues"]').classList.add('active');
                  document.querySelector('main').classList.add('bg-white');
                  document.querySelector('main').classList.remove('bg-mint');
                  document.querySelector('main').classList.remove('bg-yellow');
            }
            

            if (document.querySelector('[data-inview="section__our-numbers"]').classList.contains('is-inview')) {
                  // document.querySelector('#spyScrollbarNav .nav-link').classList.remove('active');
                  document.querySelectorAll('#spyScrollbarNav .nav-link').forEach(box => {
                        box.classList.remove('active');
                  });

                  document.querySelector('[href="#sectionOurNumbers"]').classList.add('active');
                  document.querySelector('main').classList.add('bg-white');
                  document.querySelector('main').classList.remove('bg-mint');
                  document.querySelector('main').classList.remove('bg-yellow');
            }

           

            if (document.querySelector('[data-inview="section__listing-show-steps"]').classList.contains('is-inview')) {
                  // document.querySelector('#spyScrollbarNav .nav-link').classList.remove('active');
                  document.querySelectorAll('#spyScrollbarNav .nav-link').forEach(box => {
                        box.classList.remove('active');
                  });

                  document.querySelector('[href="#sectionListingShowSteps"]').classList.add('active');
                  document.querySelector('main').classList.add('bg-yellow');
                  document.querySelector('main').classList.remove('bg-mint');
            }

            if (document.querySelector('[data-inview="section__thanks-to-supporters"]').classList.contains('is-inview')) {
                  // document.querySelector('#spyScrollbarNav .nav-link').classList.remove('active');
                  document.querySelectorAll('#spyScrollbarNav .nav-link').forEach(box => {
                        box.classList.remove('active');
                  });
                  document.querySelector('[href="#sectionThanks"]').classList.add('active');
            }

            
      } 
      else if (pathname.includes("membership-guidelines")) {
            // Membership Guidelines section
            if (document.querySelector('[data-inview="mgSection1"]').classList.contains('is-inview')) {
                  document.querySelector('main').classList.add('bg-white');
                  document.querySelector('main').classList.remove('bg-red');
            }

            if (document.querySelector('[data-inview="mgSection2"]').classList.contains('is-inview')) {
                  document.querySelector('main').classList.add('bg-white');
                  document.querySelector('main').classList.remove('bg-red');
            }

            if (document.querySelector('[data-inview="mgSection3"]').classList.contains('is-inview')) {
                  document.querySelector('main').classList.add('bg-red');
                  document.querySelector('main').classList.remove('bg-white');
            }

            if (document.querySelector('[data-inview="mgSection4"]').classList.contains('is-inview')) {
                  document.querySelector('main').classList.add('bg-white');
                  document.querySelector('main').classList.remove('bg-red');
            }

            if (document.querySelector('[data-inview="mgSection5"]').classList.contains('is-inview')) {
                  document.querySelector('main').classList.add('bg-red');
                  document.querySelector('main').classList.remove('bg-white');
            }

            if (document.querySelector('[data-inview="mgSection6"]').classList.contains('is-inview')) {
                  document.querySelector('main').classList.add('bg-white');
                  document.querySelector('main').classList.remove('bg-red');
            }

            if (document.querySelector('[data-inview="mgSection7"]').classList.contains('is-inview')) {
                  document.querySelector('main').classList.add('bg-white');
                  document.querySelector('main').classList.remove('bg-red');
            }

      }
}

