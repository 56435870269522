import React, { useEffect, useState } from  'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
// child component
// packages
// Actions & RTK query or mutations
import { useCardIdentifyMutation } from '../../../../Services/modules/BookingModule';
import { setPaymentMethod, setBookingStep, setGetBookingDetails, resetForAnimation } from '../../../../Store/BookingModule';
import { setBookingHandler } from '../../../../Store/EventDetail';
import { setMKey, setSessionBlockId, 
      // setWebViewSession 
} from '../../../../Store/User';
import { setSpinner, setAlert } from '../../../../Store/UI';

// Constants
import { dateAndTimeFormatHandler } from "../../../../resources/DateHandler";
import { NameConstants } from '../../../../resources/NameConstants';
// import { setWalletBalance } from '../../../../Store/User';
import { errors, messages } from '../../../../resources/en';


const OrderSummaryPay = (props) => {

      const bookingModule = useSelector(state=>state.bookingModule);
      const eventDetail = useSelector(state=>state.eventDetail);
      const user = useSelector(state=>state.user);
      const [disbaleSubmit,setDisbaleSubmit]    =     useState(false);

      const [cardIdentify, { data, isSuccess, isLoading, isFetching, error }]  =    useCardIdentifyMutation();

      const dispatch = useDispatch();

      useEffect(()=>{
            if(isSuccess){
                  props.closeTimerHandler();
                  dispatch(setMKey({mKey : ""}));
                  dispatch(setSessionBlockId({ct_session_block_id : ""}));
                  if(data.status === "1"){
                        dispatch(resetForAnimation());
                        dispatch(setGetBookingDetails({getBookingDetails: data }))
                        // comment this code because using firebase to refetch wallet balance
                        // dispatch(setGetWalletBalance({getWalletBalance:true}));

                        if(user.ct_web_view_session && window.ReactNativeWebView){
                              // dispatch(setWebViewSession({ct_web_view_session : ""}))
                              window.ReactNativeWebView.postMessage(JSON.stringify({
                                    "stay": true,
                                    "redirect": false,
                                    "redirectTo": "",
                                    "status": 1,
                                    "message": messages.SuccessTicketBooking
                              }));
                        }
                        dispatch(setBookingStep({step: NameConstants.bookingModuleSteps.bookingSuccess }))
                  } 
                  else if(data.status === "0") {
                        dispatch(resetForAnimation());
                        dispatch(setBookingHandler({isBooking : false}));
                        dispatch(setSpinner({status:""}));
                        setDisbaleSubmit(false)
                        if(user.ct_web_view_session && window.ReactNativeWebView){
                              // dispatch(setWebViewSession({ct_web_view_session : ""}))
                              window.ReactNativeWebView.postMessage(JSON.stringify({
                                    "stay": true,
                                    "redirect": false,
                                    "redirectTo": "",
                                    "status": 0,
                                    "message": messages.FailureTicketBooking
                              }));
                        } else {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404 }))
                        }
                  }
            }

            if(error){
                  props.resumeTimerHandler()
                  setDisbaleSubmit(false)
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error, dispatch])


      const backOnOrderSummuryPay = ()=> {

            // while click on back button wallet balance got increased
            
            // let walletBalance = user.ct_wallet_balance;
            // if(bookingModule.payment.paymentMethod === NameConstants.wallet || bookingModule.payment.paymentMethod === NameConstants.walletPlusCard || bookingModule.payment.paymentMethod === NameConstants.walletPlusApplePay) {

            //       walletBalance = parseFloat(parseFloat(bookingModule.payment.useWalletCredit) + parseFloat(user.ct_wallet_balance ));
            //       dispatch(setWalletBalance({ ct_wallet_balance : walletBalance }));
            // }
            dispatch(setPaymentMethod({paymentMethod : "", useWalletCredit : 0, useCardCredit : 0, useApplePayCredit : 0}));
            dispatch(setBookingStep({step : NameConstants.bookingModuleSteps.paymentMethod }));
      }

      const continueOnOrderSummuryPay = () => {
            setDisbaleSubmit(true)
            if(bookingModule.payment.paymentMethod === NameConstants.card || bookingModule.payment.paymentMethod === NameConstants.walletPlusCard) {
                  dispatch(setBookingStep({step: NameConstants.bookingModuleSteps.paymentViaCard }))
            } else if(bookingModule.payment.paymentMethod === NameConstants.wallet) {
                  props.pauseTimerHandler()
                  let ticket_type_master_id = bookingModule.performanceInfo.ticket_type_master_id || ""
                  let ticket_type_id = bookingModule.performanceInfo.ticket_type_id

                  const date = dateAndTimeFormatHandler(bookingModule.performanceInfo.date_time,bookingModule.performanceInfo.show_time).split(" ");

                  // here connect final booking API

                
                  dispatch(setSpinner({status:"request"}));
                  const data = {
                        event_id: bookingModule.event_id,
                        token: user.ct_access_token,
                        client: user.ct_web_view_session ? "app" : "web",
                        request_client : user.ct_web_view_session ? "app" : "web",
                        coupan_code_id : bookingModule.bookingDetails.promocode.coupan_code_id || "",
                        promocode: bookingModule.bookingDetails.promocode.promocode,
                        performance_id: bookingModule.bookingDetails.showId,
                        discount_amount: bookingModule.bookingDetails.promocode.discount,
                        price: bookingModule.bookingDetails.price,
                        wallet_balance: parseFloat(user.ct_wallet_balance),
                        amount_to_be_paid_by_card: parseFloat(bookingModule.payment.useCardCredit),
                        wallet_balance_used: parseFloat(bookingModule.payment.useWalletCredit),
                        pennies_amount: bookingModule.bookingDetails.charity ? NameConstants.charity : 0,
                        totalAmount:  parseFloat(parseFloat(bookingModule.payment.useWalletCredit) + parseFloat(bookingModule.payment.useCardCredit)),
                        flexi_price: bookingModule.bookingDetails.flexAmount,
                        is_flexi: bookingModule.bookingDetails.isFlexi,
                        tickets: bookingModule.bookingDetails.quantity,
                        group_availablity: bookingModule.group_availablity,
                        ticket_type_master_id: ticket_type_master_id,
                        ticket_type_id: ticket_type_id,
                        block_id: user.ct_session_block_id,
                        event_name: bookingModule.title,
                        address: eventDetail.eventInfo.address,
                        performance_date_time: bookingModule.performanceInfo.show_time,
                        merchantSessionKey: user.ct_mKey,
                        card_identifier: "",
                        reusable_payment: "false",
                        reusable: "false",
                        get_current_id: "order_summary_view",
                        get_next_id: "card_details_view",
                        apple_pay_id: "",
                        raw_date: date[0],
                        start_time: date[2],
                        ticket_type_name: "",
                        payment_method: bookingModule.payment.paymentMethod,
                        timer: "",
                        session_id : user.ct_web_view_session ? user.ct_web_view_session : "",
                        
                  }
                  cardIdentify(data)
            }
      }

      let ticketTypes = "";
      let subTotal = 0;
      // let displayTotalOnOrderSummury = bookingModule.bookingDetails.subTotal.toFixed(2);
      if(bookingModule.ticketTypes.length > 0) {
            if(bookingModule.group_availablity !==2){
                  ticketTypes = bookingModule.ticketTypes[0];
            }else if (bookingModule.group_availablity ===2){
                  bookingModule.ticketTypes.forEach(el=>{
                        if(el.selected){
                              ticketTypes = el.name;
                        }
                  })
            }

            subTotal = parseFloat(bookingModule.bookingDetails.price*bookingModule.bookingDetails.quantity);
      }
      
      return (
            <div className="bookTicketsStep5OrderSummaryPayViaWalletPlusCard__wrapper">
                  <div id="bookTicketsStep5OrderSummaryPayViaWalletPlusCard" className="content content__order-summary content__pay-with-card-and-credit d-flex">
                        <div className="content__body">
                              <h4 className="event__name red">{bookingModule.title}</h4>
                              <p className="event__date">{dateAndTimeFormatHandler(bookingModule.performanceInfo.date_time,bookingModule.performanceInfo.show_time)}</p>
                              <table className="table table-black-theme table__items border-top border-bottom">
                                    <tbody>
                                          <tr>
                                                <td className="td-item">{bookingModule.bookingDetails.quantity} {ticketTypes}</td>
                                                <td className="td-amount">£{subTotal.toFixed(2)}</td>
                                          </tr>
                                          {bookingModule.bookingDetails.isFlexi && 
                                          <tr>
                                                <td className="td-item">{bookingModule.bookingDetails.quantity} Flexi Tickets</td>
                                                <td className="td-amount">£{parseFloat(bookingModule.bookingDetails.flexAmount).toFixed(2)}</td>
                                          </tr>}
                                          {bookingModule.bookingDetails.promocode.status === "valid" &&
                                          <tr>
                                                <td className="td-item">{bookingModule.bookingDetails.promocode.promocode} (Voucher)</td>
                                                <td className="td-amount">-£{parseFloat(bookingModule.bookingDetails.promocode.discount).toFixed(2)}</td>
                                          </tr>}
                                          {bookingModule.bookingDetails.charity &&
                                          <tr>
                                                <td className="td-item">Donation</td>
                                                <td className="td-amount">£{parseFloat(NameConstants.charity).toFixed(2)}</td>
                                          </tr> }
                                    </tbody>
                              </table>
                        </div>
                        {/* <!-- /.content__body --> */}
                        
                       
                  </div>

                  <div className="content__footer__wrapper">
                        <div className="content__body content__body--total border-top">
                              {((bookingModule.payment.paymentMethod === NameConstants.wallet || bookingModule.payment.paymentMethod === NameConstants.walletPlusCard) && bookingModule.payment.useWalletCredit > 0) &&
                              <div className="subtotal-block">
                                    <div>Paying with credit</div>
                                    <div className="total-amount">£{parseFloat(bookingModule.payment.useWalletCredit).toFixed(2)}</div>
                              </div>}

                              {((bookingModule.payment.paymentMethod === NameConstants.card || bookingModule.payment.paymentMethod === NameConstants.walletPlusCard) && bookingModule.payment.useCardCredit > 0) &&
                              <div className="subtotal-block">
                                    <div>Paying with card</div>
                                    <div className="total-amount">£{parseFloat(bookingModule.payment.useCardCredit).toFixed(2)}</div>
                              </div>}
                        </div>
                        {/* <!-- /.content__body --> */}
                        <div className="content__footer border-top">
                              <div className="buttons-wrapper gap-0">
                                    <button type="button" className="btn__back btn btn-outline-primary w-50"  onClick={backOnOrderSummuryPay}>
                                          Back
                                    </button>
                                    <button type="button" className="btn btn-primary w-50" onClick={continueOnOrderSummuryPay} disabled={disbaleSubmit}>
                                          Pay now
                                    </button>
                              </div>
                        </div>
                        {/* <!-- /.content__footer --> */}
                  </div>
            </div>
      )
}

export default OrderSummaryPay;
