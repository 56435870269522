import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
      isTopUpOpen:false,
      step:'step1',
      paymentMethod:'',
      topUpAmount:0,
      termsModal : false,
      refetchHistory : false
}

const slice = createSlice({
      name: 'wallet',
      initialState: initialState,
      reducers: {
            setWalletParams : (state, { payload })=>{
                  state.isTopUpOpen = payload.isTopUpOpen
                  state.step = payload.step
                  state.paymentMethod = payload.paymentMethod
                  state.topUpAmount = payload.topUpAmount
            },
            setTermsModal : (state, { payload }) => {
                  state.termsModal = payload.termsModal
            },
            setRefetchHistory : (state,  { payload })=> {
                  state.refetchHistory = payload.refetchHistory
            },
            reset: ()=> initialState

      },
      
})

export const { setWalletParams, setTermsModal, setRefetchHistory, reset } = slice.actions

export default slice.reducer
