import React, { useState, useEffect } from 'react';

// external css
// redux and react hooks
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// child component
import { BackArrow } from "./index";

// packages
// Actions & RTK query or mutations
import { useGetLoginMutation, useForgotPasswordMutation } from '../../../Services/modules/users';
import { setInfoPartialRegistration } from '../../../Store/User';
import { updateRegistrationState } from '../../../Store/Register';
import { login } from '../../../Store/User';
import { setWalletBalance, setIsChangePassword } from '../../../Store/User';
import { setAlert, setOtpEntityId } from '../../../Store/UI';
import { setStep } from '../../../Store/ContactUs';

// Constants
import { loginValidationRules } from '../../../Services';
import { actions, label, placeholders, errors, messages } from '../../../resources/en';
import { NameConstants } from '../../../resources/NameConstants';
import { routesName } from '../../../resources/RoutesName';

const ContactLogin = () => {

      const [state , setState] = useState({
            [NameConstants.Email] : {value:"",error:false,errorMessage:""},
            [NameConstants.PassWord] : {value:"",error:false,errorMessage:""},
      });
      const [forgotPassword,setForgotPassword] = useState(false);

      const [getLogin, { data, isSuccess, isLoading, isFetching, error }] = useGetLoginMutation();
      const [forgotPasswordAPI, { data : forgorPassData, isSuccess : forgorPassIsSuccess, isLoading : forgorPassIsLoading, isFetching : forgorPassIsFetching, error : forgorPassError }] = useForgotPasswordMutation();

      const dispatch = useDispatch();
      const navigate = useNavigate()

      useEffect(()=>{

            if(isSuccess){
                  if(data.status === "1"){

                        if(Object.values(data.data.userInfo.registration_step).indexOf(false) > -1){
                              const registration_step = data.data.userInfo.registration_step;
                              let ct_otp_modal_status
                              if(!registration_step.otp_verify){
                                    ct_otp_modal_status = "otp"
                              } else if(!registration_step.otp_verify_email){
                                    ct_otp_modal_status = "otpEmail"
                              } else if(!registration_step.otp_verify && !registration_step.otp_verify_email){
                                    ct_otp_modal_status = "otp"
                              }

                              dispatch(setOtpEntityId({ otp_entity_id : data?.data?.otp_entity_id}))


                              dispatch(setInfoPartialRegistration({
                                    auth : false,
                                    ct_access_token : data.data.token,
                                    ct_register_token : "",
                                    ct_access_name : data.data.userInfo.fname +" "+ data.data.userInfo.lname,
                                    ct_access_email : data.data.userInfo.email,
                                    ct_access_mobilenumber : data.data.userInfo.mobilenumber,
                                    ct_is_verified : data.data.userInfo.is_verified,
                                    ct_firebase_key : data.data.userInfo.firebase_key,
                                    ct_reactive : data.data.userInfo.reactive,
                                    ct_is_top_member : data.data.userInfo.ct_is_top_member,
                                    ct_wallet_balance : parseFloat(data.data.userInfo.wallet_balance) || 0
                              }))

                              // dispatch(changeToken({ct_access_token : data.data.token}))
                              dispatch(setIsChangePassword({ct_is_change_password : data.data.userInfo.is_change_password || false}))
                              dispatch(updateRegistrationState({ct_otp_modal_status : ct_otp_modal_status,step : "step2",registration_step : registration_step}))
                              navigate(routesName.Register)

                        } else {
                              const walletBalance = parseFloat(data.data.userInfo.wallet_balance) || 0;
                              dispatch(setIsChangePassword({ct_is_change_password : data.data.userInfo.is_change_password || false}))
                              dispatch(login(data.data));
                              dispatch(setWalletBalance({ct_wallet_balance:walletBalance}))
                              dispatch(setStep({ step : NameConstants.MemberSelectShow}))
                              if(data.data.userInfo.is_change_password){
                                    navigate(routesName.MyAccount)
                              }
                        }

                  } else if(data.status === "0"){
                        identifyErrorFromAPI(data.message)
                  }
            }     

            if(error){

                  if(!error.error) {
                        identifyErrorFromAPI(error.data.message || error.data.error)
                  } else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message: error.error || error.data.error || errors.NotFound404}))
                  }
            }


      },[data,isSuccess,isLoading,isFetching,error,dispatch,navigate])

      useEffect(()=>{

            if(forgorPassData){
                  if(forgorPassData.status === "1"){
                        dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:forgorPassData.message || errors.NotFound404}))
                  } else if(forgorPassData.status === "0"){
                        identifyErrorFromAPI(forgorPassData.data.message)
                  }
                  
            }     
           
            if(forgorPassError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:forgorPassError.error || forgorPassError.data.error || forgorPassError.data.message || errors.NotFound404}))
            }

      },[forgorPassData,forgorPassIsSuccess,forgorPassIsLoading,forgorPassIsFetching,forgorPassError,dispatch])

      const handleChange = (e) => {

            const {name , value} = e.target 
            
            setState(prevState => ({
                        ...prevState,
                        [name] : {...prevState[name],value:value}
                  })
            )
      }

      const onfocusHandler = ()=>{

            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.Email]:{...prevState.email,error:false,errorMessage:""},
                        [NameConstants.PassWord]:{...prevState.password,error:false,errorMessage:""}
                  }
            })
      }

      const forgotPasswordHandler = (event)=>{
            event.preventDefault();
            onfocusHandler();
            setForgotPassword(!forgotPassword);

      }

      const handleSubmitClick = (e) => 
      {
            e.preventDefault();
           
            let payload = null;

            const errorStore = [];
            const copy = {
            }

            for(let key in state)
            {
               
                  if(forgotPassword && key === NameConstants.PassWord){
                        continue;
                  }
                  const errorCheck = loginValidationRules(state[key].value,key);
                  if(!errorCheck.isValid)
                  {
                        errorStore.push(key);
                  }       
                  copy[key] = {...state[key],error:!errorCheck.isValid,errorMessage:errorCheck.validationMessage}
                        
            }

            if(forgotPassword) {
                  payload={
                        "email":state.email.value
                  }
            } else {
                  payload={
                        "email":state.email.value,
                        "password":state.password.value,
                  }
            }

            if(errorStore.length > 0){
                  setState({...copy})
            } 
            else {
                  if(forgotPassword){
                        forgotPasswordAPI(payload)
                  } else {
                        getLogin(payload);
                  }
            }
                
      }

      const identifyErrorFromAPI = (message)=>{

            if(message.includes('email')){
                  setState(prevState=>{
                        return {
                              ...prevState,
                              [NameConstants.Email] : {...prevState.email,error:true,errorMessage:message}
                        }
                  })

            } else if(message.includes('password')) {
                  setState(prevState=>{
                        return {
                              ...prevState,
                              [NameConstants.PassWord] : {...prevState.password,error:true,errorMessage:message}
                        }
                  })
            }
      }

      return (
                  <div className="col__right">

                        <div className="content content__contact-form">
                              <div className="content__body">
                                    <BackArrow />

                                    <h3 className="heading fw-bold">
                                          Login to select the booking you need help with
                                    </h3>

                                    <form className="form__contact-us-member-login">
                                          <div className="form-group">
                                                <label htmlFor="memberLoginInputEmail" className="form-label">
                                                      {label[NameConstants.Email]}
                                                </label>
                                                <input type="email" className="form-control"
                                                id="memberLoginInputEmail" 
                                                value={state[NameConstants.Email].value}
                                                onChange={handleChange}
                                                name={NameConstants.Email}
                                                onFocus={onfocusHandler}
                                                placeholder={placeholders[NameConstants.Email]}
                                                disabled={isLoading || forgorPassIsLoading}/>
                                                {state[NameConstants.Email].error && <label className="form-label fw-normal form-label-error"   dangerouslySetInnerHTML={{__html: state.email.errorMessage}}></label>}
                                          </div>
                                          {/* <!-- /.form-group --> */}

                                          {!forgotPassword &&
                                          <div className="form-group">
                                                <label htmlFor="memberLoginInputPassword" className="form-label">{label[NameConstants.PassWord]}</label>
                                                <input type="password" className="form-control" id="memberLoginInputPassword"
                                                name={NameConstants.PassWord}
                                                value={state[NameConstants.PassWord].value}
                                                onChange={handleChange}
                                                onFocus={onfocusHandler}
                                                placeholder={placeholders.password}
                                                disabled={isLoading || forgorPassIsLoading}
                                                />
                                                {state[NameConstants.PassWord].error && <label className="form-label fw-normal form-label-error"  dangerouslySetInnerHTML={{__html: state.password.errorMessage}}></label>}
                                          </div>}
                                          {/* <!-- /.form-group --> */}

                                          <div className="form-group form-group__cta d-flex flex-column flex-sm-row">
                                                <button type="submit" className="btn btn-xl-lg btn-primary align-self-start mb-0" onClick={handleSubmitClick} disabled={isLoading || forgorPassIsLoading}>
                                                      {!forgotPassword ? actions.submit : actions.sendLink}
                                                </button>
                                          </div>
                                          {/* <!-- /.form-group --> */}
                                    </form>
                                    <p className="p-notice p-forgot-password mb-0">
                                          {!forgotPassword ? "Forgot your password? Click " : "Back to login? Click " } <Link to="/" onClick={forgotPasswordHandler}>here</Link>
                                    </p>

                              </div>
                              {/* <!-- .content__body --> */}
                        </div>
                        {/* <!-- .content --> */}

                  </div>
      )
}

export default ContactLogin
