import React from 'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// child component
// packages
// Actions & RTK query or mutations
import { setActionType, setTicketsHandler } from '../../../../Store/CurruntBookings';

// Constants
import { ticketTypes } from "../../../../resources/ticketTypes";
import { actions } from '../../../../resources/en';

const Step1 = () => {

      const curruntBookings = useSelector(state=>state.curruntBookings);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      
      const buyMoreTicketsHandler = ()=> {
            navigate(`/event-details/${curruntBookings.event_id}`,{ state: "buyMoreTickets" });
      }

      const reduceTicketHandler = ()=> {
            if(curruntBookings.eventType==="2" || curruntBookings.eventType===2) {
                  dispatch(setTicketsHandler({tickets : 0}))
            }
            dispatch(setActionType({actionType:'reduce',step:'step2'}));
      }     

      const cancelBookingHandler = ()=> {
            dispatch(setActionType({actionType:'cancel',step:'step3'}));
      }

      const otherRequestHandler = ()=> {
            dispatch(setActionType({actionType:'other',step:'step4'}));
      }

      let allowReducTickets = false;
      if(curruntBookings){

            if(ticketTypes.reduceTickets.includes(curruntBookings.eventType.toString())){
                  allowReducTickets = true
            }
      }

      return (

            <div id="manageBookingChooseOption" className="content content__manage-booking-options border-bottom">
                  <div className="content__body">
                        <h4 className="heading">Choose an option:</h4>
                        <button type="button" className="btn btn__payment-method w-100" onClick={buyMoreTicketsHandler}>
                              <span className="button__text">
                                    <span className="heading">{actions.buyMoreTickets}</span>
                              </span>
                              <span className="button__arrow button__arrow--right">
                              </span>
                        </button>
                        {allowReducTickets &&
                        <button id="btnManageBookingReduceTickets" type="button" className="btn btn__payment-method w-100" onClick={reduceTicketHandler}>
                              <span className="button__text">
                              <span className="heading">{actions.reduceTickets}</span>
                              </span>
                              <span className="button__arrow button__arrow--right"></span>
                        </button>}
                        <button id="btnManageBookingCancelBooking" type="button" className="btn btn__payment-method w-100" onClick={cancelBookingHandler}>
                              <span className="button__text">
                              <span className="heading">{actions.cancelBooking}</span>
                              </span>
                              <span className="button__arrow button__arrow--right"></span>
                        </button>
                        <button id="btnManageBookinOtherRequests" type="button" className="btn btn__payment-method w-100"  onClick={otherRequestHandler}>
                              <span className="button__text">
                              <span className="heading">{actions.otherRequests}</span>
                              </span>
                              <span className="button__arrow button__arrow--right"></span>
                        </button>
                  </div>
                  {/* <!-- /.content__body --> */}
            </div> 
      )
}

export default Step1;
