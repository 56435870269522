import React from 'react';
import "./UptakeDummy.css"

const UptakeImageDummy = () => {

      return (
            <div  className="event-image uptake-dummy__image"></div>
      )
}

export default UptakeImageDummy;
