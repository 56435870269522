import { Config } from "./../Config/index";

import { createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { isMobile, browserName, browserVersion, isTablet, isDesktop } from "react-device-detect";
import { Mutex } from 'async-mutex'
import { logout, changeCtAcessRefreshToken } from "../Store/User";
import { reset as RegisterReset } from "../Store/Register"
// import CryptoJS from "crypto-js"; install crypto-js for this

const mutex = new Mutex()
const baseQuery = fetchBaseQuery({
      baseUrl: Config.API_URL,
      prepareHeaders: async (headers, { getState }, args) => {
            
            const data =(getState());
            var token = null;
            if (data !== undefined && data.user !== undefined && 
                  (data.user.ct_access_token !== null || data.user.ct_register_token !== null ) && 
                  (data.user.ct_access_token !== undefined || data.user.ct_register_token !== undefined)
            ) {
                  token = data.user.ct_access_token || data.user.ct_register_token;
            }
           
            // If we have a token set in state, let's assume that we should be passing it.
            // if(token != null)
            // {
            //       headers.set('token', `${token}`)
            // }

            headers.set('vendor', '7a45347b-5863-11ee-976c-4c034fd24c68')
            if(data.user.domain === Config.DOMAIN_CONSTANT_TFM) {
                  headers.set('vendor', 'eb4d564c-e73d-11ee-a83f-4c034fd24c68')
            } 

            if(data.user.domain === Config.DOMAIN_CONSTANT_AJTIX) {
                  headers.set('vendor', 'd1c37ed5-e73d-11ee-a83f-4c034fd24c68')
            } 

            if (token != null) {
                  headers.set("Authorization", `Bearer ${token}`);
            }

            // detect Device and Pass to headers
            let device = ''
            let client = data?.user?.ct_web_view_session ? "app" : "web"
            if(isMobile) {
                  device = 'mobile';
            } else if (isTablet) {
                  device = 'tablet';
            } else if (isDesktop) {
                  device = 'desktop';
            }
            headers.set('client', client)
            headers.set('platform',device)
            headers.set('browser_info',`${browserName} ${browserVersion}`)

            return headers
      }
  })
  

const baseQueryWithInterceptor = async (args, api, extraOptions) => {
      //console.log("args: " + JSON.stringify(args));
      //console.log("api: " + JSON.stringify(api));
      //console.log("extraOptions: " + JSON.stringify(extraOptions));
      if(api.endpoint === "getUserWalletBalance" && !api.getState().user.ct_access_token){
            return;
      }
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()

      let { method, url, body } = args;
      url = Config.API_URL.includes("frontend") ? "/frontend" + url : url
      body = body ? body : ""
      // const timestamp = 1730984809; // Get the current timestamp
      const timestamp = Math.floor(Date.now() / 1000); // Get the current timestamp
      const secretKey = process.env.REACT_APP_SECRET_KEY
      // Generate the signature
      const signature = await generateSignature(method, url, timestamp, body, secretKey);
      // console.log(signature, "for main")
      args.headers = {
            ...args.headers,
            "X-Signature": signature,
            'X-Timestamp': timestamp,
      };

      let result = await baseQuery(args, api, extraOptions)

      if (result.error && result.error.status === 401) {

                 // checking whether the mutex is locked
                 if (!mutex.isLocked()) {
                       const release = await mutex.acquire()
                       const data =(api.getState());
                       let refreshToken = data.user.ct_refresh_token ? data.user.ct_refresh_token : data.user.ct_access_token
                        refreshToken = refreshToken ? refreshToken : ""

                       const timestampRef = Math.floor(Date.now() / 1000); // Get the current timestamp
                       const secretKeyRef = process.env.REACT_APP_SECRET_KEY
                       let urlRef = Config.API_URL.includes("frontend") ? "/frontend/refreshToken" : "/refreshToken"

                       const signatureRef = await generateSignature("POST", urlRef, timestampRef, { RefreshToken : refreshToken }, secretKeyRef);
                        try {
                              const refreshResult = await baseQuery(
                                    { url: '/refreshToken', method: 'POST', body : { RefreshToken : refreshToken },
                                    headers: {
                                          'X-Signature': signatureRef, 
                                          'X-Timestamp': timestampRef,
                                        },
                                    },
                                    api,
                                    extraOptions)
                              if (refreshResult.data) {
                                    const refresh_token = refreshResult.data.data.refresh_token
                                    const token = refreshResult.data.data.token
                                    api.dispatch(changeCtAcessRefreshToken({ ct_access_token : token, ct_refresh_token : refresh_token}))
                                    // retry the initial query
                                    result = await baseQuery(args, api, extraOptions)
                              } else {
                                    console.log("logout in API js 90")
                                    api.dispatch(logout())
                                    api.dispatch(RegisterReset())
                              }
                        } finally {
                             // release must be called once the mutex should be released again.
                             release()
                        }
                  }
                  else {
                       // wait until the mutex is available without locking it
                       await mutex.waitForUnlock()
                       result = await baseQuery(args, api, extraOptions)
                  }
      }     
      
      return parseApiResponse(result)
}

export const api = createApi({
      baseQuery: baseQueryWithInterceptor,
      // global configuration for the api
      refetchOnFocus: false,
      endpoints: () => ({}),
})


function parseApiResponse(result) {

      if (result.error && result.error.status === 401) {
          // here you can deal with 401 error
      }

      // if (result.error && result.error.status === 400) {
      //       // here you can deal with 401 error
      //       console.log("Result" + JSON.stringify(result));
      // }
  
      return result;
}
    

const generateSignature = async (method, url, timestamp, body, secretKey) => {

      let bodyGenerate = body ? JSON.stringify(body) : body
      const dataToSign = method +  url + timestamp + bodyGenerate;

      const encoder = new TextEncoder();
      const keyData = encoder.encode(secretKey);
      const data = encoder.encode(dataToSign);
      // Import the secret key for HMAC
      const cryptoKey = await crypto.subtle.importKey(
            'raw',
            keyData,
            { name: 'HMAC', hash: { name: 'SHA-256' } },
            false,
            ['sign']
      );

      // Generate the HMAC signature
      const signatureBuffer = await crypto.subtle.sign('HMAC', cryptoKey, data);
  
      // Convert ArrayBuffer to hex string (to match PHP output format)
      const signatureArray = Array.from(new Uint8Array(signatureBuffer));
      const signatureHex = signatureArray
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');

      return signatureHex;
};

// const generateSignature = async (method, url, timestamp, body, secretKey) => {

//       const dataToSign = method + url + timestamp + JSON.stringify(body);

//       const computedSignature = CryptoJS.HmacSHA256(dataToSign, secretKey).toString(CryptoJS.enc.Hex);

//       return computedSignature;
// };
    

    
    
    
    