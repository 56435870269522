export const cancelBooking =  build =>
      build.mutation({
            query: (body) =>  ({
                  url: '/booking/cancelBooking',
                  method: 'POST',
                  body: body
            }),
            invalidatesTags: ['MemberBookings']
      })

      // Changed with new one