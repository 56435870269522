import { validation } from "../resources/en";
import { NameConstants } from "../resources/NameConstants";
export const joinValidationRules=(value, type,email) =>{

      // console.log(value,type)
      const textMessage = validation;

      let isValid = true;
      let validationMessage = "";
      if (!type) {
          return true;
      }

      if(type === NameConstants.FirstName){

            if(value.trim() ==="")
            {
                  isValid = false;
                  validationMessage = textMessage[type].required;
            }
            else if(value.length <2)
            {
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type === NameConstants.LastName){
            if(value.trim() === "")
            {
                  isValid = false;
                  validationMessage = textMessage[type].required
            }
            else if(value.length <2)
            {
                  isValid = false;
                  validationMessage = textMessage[type].invalid
            }
      }

      if(type === NameConstants.PassWord){

            if(value.trim()=== "")
            {
                  isValid =false;
                  validationMessage = textMessage[type].required;
            }
            else if(value.length < 6)
            {
                  isValid=false;
                  validationMessage = textMessage[type].minLength;
            }
      }

      if(type=== NameConstants.NHSTrust){

            if(value.trim()==='0' || value.trim()==='')
            {
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }
      
      if(type === "organisation"){

            validationMessage = "Please select Organisation";
            if(value.trim()==='0' || value.trim()==='')
            {
                  isValid = false;
                  validationMessage = "Please select Organisation";
            }
      }

      if(type === NameConstants.PhoneNumber){

            let isnum = /^\d+$/.test(value);
            if(value.trim() === "" || !value) {
                  isValid =false;
                  validationMessage =  textMessage[type].required;
                  return {isValid,validationMessage};
            }
            if(value.trim()=== "07"){
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
            if(value.length !== 11 || !isnum ){
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
            if(value.substring(0, 3) === "070") {
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
             
      }
      
      if(type=== NameConstants.Comments){
            if(value.trim()==='')
            {
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }


      if(type=== NameConstants.WorkIDCard){
            if(value==='')
            {
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type=== NameConstants.Role){

            if(value.trim()==='')
            {
                  isValid = false;
                  validationMessage = textMessage[type].required;;

            } 
            else if(value.length <2)
            {
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type=== NameConstants.NearestCity){

            if(value.trim()==='' || value.trim() === "0")
            {
                  isValid = false;
                  validationMessage = textMessage[type].required;
            }
      }

      if(type === NameConstants.NHSEmail){

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!value){
                  isValid = false;
                  validationMessage = textMessage[type].required;
            }
            else if(!re.test(String(value).toLowerCase()) || !value){
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
            else if((value.indexOf("@nhs.net") === -1) && (value.indexOf("nhs.uk") === -1) && (value.indexOf("nhs.scot") === -1)){
                  isValid = false;
                  validationMessage = textMessage[type].invalidNHS;
            }
      }

      if(type === NameConstants.EmailTfm){

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!value){
                  isValid = false;
                  validationMessage =  textMessage[type].required;
            }
            else if(!re.test(String(value).toLowerCase()) || !value){
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type ===  NameConstants.EmailConfitmTfm){

            if(!value){
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }

            if(value !== email){

                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }


      if(type === NameConstants.NHSEmailConfirm){

            if(!value){
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }

            if(value !== email){

                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type=== NameConstants.CheckReceiveAlerts){
            if(value === false){

                  isValid=false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type=== NameConstants.CheckAgreeTOS){
            if(value === false){

                  isValid=false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      return {isValid,validationMessage};
}

