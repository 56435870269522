export const deleteCard =  build =>
      build.mutation({
            query: (body) =>  ({
                  url: '/card/delete',
                  method: 'POST',
                  body: body
            }),
            invalidatesTags: ['getCard']
      })

      // Changed with new one