export const getTicketTypeBookingInfo =  build =>
      build.mutation({
            query: (body) =>  ({
                  url: '/tickettype/GetTicketTypeBookingInfo',
                  method: 'POST',
                  body: body
            }),
      })

      // Changed with new one

      
