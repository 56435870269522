export const downloadTickets =  build =>
      build.query({
            query: (body) =>  ({
                  url: '/booking/download_tickets',
                  method: 'GET',
                  params: body
            }),
      })

      // Changed with new one
