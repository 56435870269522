import React, { useState, useEffect } from "react";

// external css
// redux and react hooks
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// child component
import Footer from "../../Layouts/Footer/Footer";
import { TickerText } from "../../Components";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";

// packages
import { Helmet } from "react-helmet";

// Actions & RTK query or mutations
import { usePositiveReviewSystemMutation } from "../../Services/modules/ReviewSystem";
import { updateLocoScroll, setAlert } from "../../Store/UI";
import { setCtSurveyRedirect } from "../../Store/User"

// Constants
import { Member_Satisfaction_Survey } from "../../resources/TextRollerConstants";
import { messages, errors } from "../../resources/en";
import { routesName } from "../../resources/RoutesName";
import { URL } from "../../resources/Url";
// import { routesName } from "../../resources/RoutesName";
import { MetaData, MetaDataAj, MetaDataTfm } from "../../resources/MetaData";
import { Config } from "../../Config";

const FeedbackSurvey = () => {

      const [step, setSteps] = useState('step1')
      const [rating, setRating] = useState('')

      const domain      =     useSelector(state=>state.user.domain)

      const dispatch = useDispatch()
      const navigate = useNavigate()

      const [submitPositiveReview, { data, isSuccess, isLoading, isFetching, error }] = usePositiveReviewSystemMutation();

      useEffect(()=>{

            if(isSuccess){
                  if(data.status === "1"){
                        
                        if(rating >=9) {
                              setSteps('step3')
                        }
                        else {
                              navigate(routesName.CurrentListing)
                              dispatch(setAlert({model:true,title:"Thanks!",message: data.message || errors.NotFound404}))
                        }
                        dispatch(setCtSurveyRedirect({ct_surevy_redirect : ''}))

                  } else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message: data.message || data.error || data.errors || errors.NotFound404}))
                  } 
            }     

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message: error.error || error.data.error || errors.NotFound404}))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data,isSuccess,isLoading,isFetching,error,dispatch ])


      useEffect(()=>{
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
      },[step, dispatch])

      const afterLoadHandler = ()=> {
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
      }

      const stepsChangeHandler = (stp) => {
            setSteps(stp)
      }

      const ratingsChangeHandler = (rting)=> {
            if(rting >=10) {
                  submitDataHandler("",rting)
            } else {
                  setSteps('step4')
            }
            setRating(rting)
      }

      const submitDataHandler = (message,rting)=> {

            if(isLoading) {
                  return
            }

            const data = {
                  rating : rating || rting,
                  message : message || ""
            }

            submitPositiveReview(data)
      }

      const submitToTrusPilotHandler = ()=>{
            window.open(URL.Trust_Pilot_URL, '_blank');
      }

      let metaDataSite = MetaData
      if(domain === Config.DOMAIN_CONSTANT_TFM){
            metaDataSite = MetaDataTfm
      } else if(domain === Config.DOMAIN_CONSTANT_AJTIX){
            metaDataSite = MetaDataAj
      }

      return (
            <div className="scroll-animations position-relative" data-scroll-container>
                  <Helmet>
                        <title>{metaDataSite.FeedbackSurvey.title}</title>
                        <meta name="description" content={metaDataSite.FeedbackSurvey.description} />
                        <meta name="keywords" content={metaDataSite.FeedbackSurvey.keywords} />
                  </Helmet>
                  <main>
                        <section data-scroll-section className="section__leave-feedback section__survey py-0">
                              <div className="border-bottom">
                                    <TickerText id="tickerYourFeedback" textData={Member_Satisfaction_Survey} />
                              </div>
                              <div className="container-fluid container__block container__leave-feedback">
                                    <div className="row">
                                          <div className="col-md-12">
                                                <div className="content content__leave-feedback mx-auto">
                                                      <div className="content__body text-center mx-auto content__body_servy">
                                                            <div className="card card__event p-5">
                                                                  {step === "step1" && <Step1 stepChange={stepsChangeHandler} afterLoadHandler={afterLoadHandler}/>}
                                                                  {step === "step2" && <Step2 rating={rating} ratingChange={ratingsChangeHandler} isLoading={isLoading}/>}
                                                                  {step === "step3" && <Step3 stepChange={stepsChangeHandler} onSubmitToTrustPilot={submitToTrusPilotHandler}/>}
                                                                  {step === "step4" && <Step4 rating={rating} submitHandler={submitDataHandler} isLoading={isLoading}/>}
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>

                        </section>
                  </main>

                  <Footer />
            </div>
      );
};

export default FeedbackSurvey;
