import { api } from "./../../../Services/Api";
import { getHistory } from "./getHistory";
import { getUserWalletBalance } from "./getUserWalletBalance";
import { addUserWalletBalance } from "./addUserWalletBalance";
import { fetchApplePayIntent } from "./fetchApplePayIntent";
import { walletWebhookConfirmation } from "./walletWebhookConfirmation";

export const walletApi = api.injectEndpoints({
            endpoints: build => ({
                  getHistory :getHistory(build),
                  getUserWalletBalance : getUserWalletBalance(build),
                  addUserWalletBalance : addUserWalletBalance(build),
                  fetchApplePayIntent : fetchApplePayIntent(build),
                  walletWebhookConfirmation : walletWebhookConfirmation(build)
      }),
      overrideExisting: true
})

export const { useLazyGetHistoryQuery, useGetUserWalletBalanceQuery, useAddUserWalletBalanceMutation, useFetchApplePayIntentMutation, useWalletWebhookConfirmationMutation } = walletApi