export const dateFormathandler = (start_date)=>{

      // replace format 05-11-2023 to 05/11/2023 for firefox on 19-05-2023 because suresh getting issue in firefox
      var convertedDate = start_date.replace(/-/g, "/");

      const date = new Date(convertedDate);
     

      const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];

      let day = weekday[date.getDay()];
      let date1 = date.getDate() + nth(date.getDate());
      let month = date.toLocaleString('default', { month: 'long' })
      let year = date.getFullYear();
      return day+' '+date1+' '+month+' '+year;
}

const nth = (d)=> {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
            case 1:  return "st";
            case 2:  return "nd";
            case 3:  return "rd";
            default: return "th";
      }
}

export const timeFormatHandler = (time)=>{
      const timeArr= time.split(":");
      return (timeArr[0] > 12 ? timeArr[0] - 12 :timeArr[0]) +":"+ timeArr[1] +""+(timeArr[0] > 12 ? "pm" :"am")
}