import React from 'react'

const UptakeInstructionDummy = () => {
      return (
            <div className="p-important-wrapper d-flex flex-row align-items-center uptake-instruction__dummy">
                  <p className="mb-0"></p>
            </div>
      )
}

export default UptakeInstructionDummy
