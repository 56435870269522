import { createSlice } from "@reduxjs/toolkit";

const payment = {
      paymentMethod : "",
      useWalletCredit : 0,
      useCardCredit : 0,
      useApplePayCredit : 0
}

// const promocode = {
//       amount_type: "",
//       discount: 0,
//       discount_session: 0,
//       discount_type: "",
//       mainAmount: 0,
//       main_discount: 0,
//       off: "",
//       promocode: "",
//       totalAmount: 0,
//       status:"none",
//       message:""
// }

const promocode = {
      remove_flexi: false,
      amount_type : "",
      off: "",
      discount: 0,
      discount_type : "",
      main_discount: "0",
      coupan_code_id: "",
      promocode: "",
      totalAmount: 0,
      totalAmount_without_flexi: 0,
      mainAmount: 0
}


const bookingDetails = {
      showId:null,
      isFlexi:false,
      charity:false,
      flexAmount:0,
      price: 0,
      subTotal: 0,
      quantity: 0,
      ticketValidationMsg: "",
      scheduleTimeTitle: "",
      max_tickets:"",
      promocode:promocode,
      checkBox:false
}

const initialState = {
      event_id:"",
      title:"",
      step:"",
      group_availablity:0,
      performanceInfo:null,
      ticketTypes:[],
      getBookingDetails:null,
      payment : payment,
      bookingDetails:bookingDetails,
      secureData:null,
}

const BookingModuleSlice = createSlice({
      name:'bookingmodule',
      initialState:initialState,
      reducers:{
            setBookingStep(state, { payload }){
                  state.step = payload.step;
            },
            changeCheckBox(state,{ payload }){
                  state.bookingDetails.checkBox = payload.checkBox;
            },
            setSecureData(state,{ payload }){
                  state.secureData = payload.data;
            },
            setEventId(state, { payload }){
                  state.event_id = payload.event_id;
                  state.title = payload.title;
            },
            setPaymentMethod (state, { payload }){
                  state.payment.paymentMethod = payload.paymentMethod;
                  state.payment.useWalletCredit = payload.useWalletCredit;
                  state.payment.useCardCredit = payload.useCardCredit;
                  state.payment.useApplePayCredit = payload.useApplePayCredit;
            },
            setGroupAvailability (state,  { payload }) {
                  state.group_availablity = payload.group_availablity;
                  state.step = payload.step;
                  state.ticketTypes = payload.ticketTypes;
            },
            setTicketTypes (state, { payload }) {
                  state.ticketTypes = payload.ticketTypes;
            },
            setShowId(state, { payload }){
                  state.bookingDetails.showId = payload.showId
            },
            setPerformanceInfo(state, { payload }){
                  state.performanceInfo = payload.data;
            },
            setValidatingMessage(state, { payload }){
                  state.bookingDetails.ticketValidationMsg = payload.validatingMessage;
            },
            initialPriceQuantity(state,{ payload }){
                  state.bookingDetails.price = payload.price;
                  state.bookingDetails.quantity = payload.quantity;
                  state.bookingDetails.subTotal = payload.subTotal;
                  state.bookingDetails.max_tickets = payload.max_tickets;
                  state.bookingDetails.ticketValidationMsg = "";
            },
            ticketQuantityHandler(state, { payload }){
                  state.bookingDetails.quantity = payload.newQuantity;
                  state.bookingDetails.subTotal = payload.subTotal;
                  state.bookingDetails.isFlexi = payload.isFlexi;
                  state.bookingDetails.flexAmount = payload.flexAmount;
            },
            setFlexiHandler (state, { payload }) {
                  state.bookingDetails.isFlexi = payload.isFlexi;
                  state.bookingDetails.flexAmount = payload.flexAmount;
                  state.bookingDetails.subTotal = payload.subTotal;
            },
            promoCodeSet(state, { payload }){
                  state.bookingDetails.promocode = payload.promocode;
            },
            setCharityHandler(state, { payload }) {
                  state.bookingDetails.charity = payload.charity;
                  state.bookingDetails.subTotal = payload.subTotal;
            },
            removePromoCode(state,{ payload }){
                  state.bookingDetails.promocode = promocode;
            },
            setValueForPromoCodes(state, { payload }){
                  state.bookingDetails.promocode.totalAmount = payload.totalAmount;
                  state.bookingDetails.promocode.discount = payload.discount;
                  state.bookingDetails.promocode.mainAmount = payload.mainAmount;
            },
            setGetBookingDetails (state, { payload }){
                  state.getBookingDetails = payload.getBookingDetails;
            },
            resetBooking (state, { payload }){
                  state.bookingDetails = bookingDetails;
                  state.payment = payment;
                  state.performanceInfo = null;
            },
            resetForAnimation (state, { payload }) {
                  // state.event_id=""
                  // state.title = ""
                  state.step ="chooseDate"
                  // state.group_availablity = 0;
                  state.performanceInfo = null
                  // state.ticketTypes = [];
                  state.getBookingDetails = null
                  state.payment = payment
                  state.bookingDetails = bookingDetails
                  state.secureData = null
            },
            reset: ()=> initialState
                
      }
})

export const { 
      setSecureData, setEventId, setGroupAvailability, setPerformanceInfo, 
      setShowId, setBookingStep, setValidatingMessage,  setTicketTypes,
      initialPriceQuantity, ticketQuantityHandler, setFlexiHandler, 
      promoCodeSet, removePromoCode, setValueForPromoCodes,
      changeCheckBox, setCharityHandler, setPaymentMethod,
      setGetBookingDetails, 
      reset, resetBooking, resetForAnimation 
} = BookingModuleSlice.actions
export default BookingModuleSlice.reducer;

