import React from 'react';

// external css
// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';

// child component
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from "./Step3"
import Loader from '../../../Ui/BookingModuleLoader';

// packages
// Actions & RTK query or mutations
import { setWalletParams } from '../../../Store/Wallet';

// Constants

const WalletTopUp = ({ webView }) => {

      const wallet      =     useSelector(state => state.wallet)
      const ui          =     useSelector(state => state.ui)
      const dispatch    =     useDispatch();

      const topUpHandler = ()=> {
            dispatch(setWalletParams({
                  isTopUpOpen : false,
                  step : 'step1',
                  paymentMethod : "",
                  topUpAmount : 0
            }))
      }

      let headerShow = true;

      if(!webView && wallet.step === "step3") {
            headerShow = false
      }

      return (
            <div className="slidein-wrapper show">
                  <div className="slidein slidein__wallet-topup">

                        <div className="content__slidein content-scrollable">

                              <form>

                                    <div className="content content__checkout content__wallet-topup">

                                          {headerShow &&
                                          <div className="content__header border-bottom position-relative">
                                                <button type="button" className="btn-close btn-close__custom btn-close__absolute" aria-label="Close" onClick={topUpHandler}>

                                                </button>
                                                <div className="content my-auto">
                                                      <h4 className="content__title my-auto mb-0">Top up your wallet</h4>
                                                </div>
                                          </div> }
                                          {/* <!-- /.content__header --> */}

                                          {/* <!-- STEP 1: Enter top up amount --> */}
                                          {wallet.step === "step1" && <Step1 />}
                                          {/* <!-- /#walletTopupStep1 --> */}

                                          {/* <!-- STEP 2: Top up Payment Via Card --> */}
                                          {wallet.step === "step2" && <Step2 />}
                                          {/* <!-- /#walletTopupStep2 --> */}

                                          {/* <!-- LAST STEP :  Top up Success --> */}
                                          {wallet.step === "step3"&& <Step3 webView={webView}/>}
                                          {/* <!-- /.content__wallet-topup-success --> */}

                                          <Loader show={ui.spinner === "request"}/>

                                    </div>
                                    {/* <!-- /.content__wallet-topup --> */}

                              </form>

                        </div>
                        {/* <!-- /.content__slidein --> */}

                  </div>
                  {/* <!-- /.slidein --> */}
            </div>

      )
}

export default WalletTopUp;
