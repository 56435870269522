const text = {
      yourFeedbackText:["Your Feedback","Your Feedback","Your Feedback","Your Feedback","Your Feedback"],
      catsOneText : ["Theatre","Music","Walks & Talks","Performance","Movies","Comedy","Classical Music","Cabaret"],
      catsTwoText :["Theatre","Music","Walks & Talks","Performance","Movies","Comedy","Classical Music","Cabaret"],
      locText : ["Birmingham","London","Liverpool","Brighton","Manchester","Cambridge","Oxford"],
      currBookingText : ["Current Bookings", "Current Bookings", "Current Bookings", "Current Bookings", "Current Bookings"],
      pastBookingText : ["Past Bookings", "Past Bookings", "Past Bookings", "Past Bookings", "Past Bookings"],
      likedShowsText : [ "SHOWS YOU'VE LIKED", "SHOWS YOU'VE LIKED", "SHOWS YOU'VE LIKED", "SHOWS YOU'VE LIKED", "SHOWS YOU'VE LIKED", "SHOWS YOU'VE LIKED"],
      Member_Satisfaction_Survey : ["MEMBER SATISFACTION SURVEY"]
}

export const { yourFeedbackText, catsOneText, catsTwoText, locText, currBookingText, pastBookingText, likedShowsText, Member_Satisfaction_Survey } = text;
