import React, { useState, useEffect } from 'react'

// external css

// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';
import {  useLocation } from 'react-router-dom';


// child component
import { HeadingPictureComponent } from '../../Components';
import Footer from '../../Layouts/Footer/Footer';

// packages
import { Helmet } from 'react-helmet';
// Actions & RTK query or mutations
import { useLazyTermsQuery } from '../../Services/modules/Others';
import { setAlert, updateLocoScroll, setScrollToTarget } from '../../Store/UI';

// Constants
import { errors, messages } from '../../resources/en';
import { MetaData, MetaDataAj, MetaDataTfm } from '../../resources/MetaData';
import { Config } from '../../Config';

const Terms = () => {

      const [scroll,setScroll]                  =     useState(false)
      const dispatch                            =     useDispatch();
      const location                            =     useLocation();
      const domain                              =     useSelector(state=>state.user.domain)
      const [getPrivacy, { data, isSuccess, error, isLoading }] = useLazyTermsQuery();

      useEffect(()=>{
            document.title = "Terms & Conditions";
            getPrivacy();

      },[getPrivacy]);
      

      useEffect(()=>{
            if(isSuccess) {
                  if(data.status=== "1"){
                        setScroll(true);
                  }
                  else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
      
      },[data, isSuccess, error, isLoading, dispatch])

      useEffect(()=>{
            if(scroll){
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))

                  if(location.search.includes('wallet') || location.search.includes("giftVoucher")){

                        let oldSpan = location.search.includes('wallet') ? document.getElementById("wallet") : document.getElementById("giftVoucher"); // select the span element by ID
                        let newSpan = document.createElement("span"); // create a new "span" element
                        if(location.search.includes('wallet')){
                              newSpan.setAttribute("id", "wallet"); // set the "id" attribute
                              newSpan.innerHTML = '<i class="fa fa-arrow-right blink" aria-hidden="true"></i> Credit Wallet'; // set the innerHTML content
                        } else if(location.search.includes("giftVoucher")){
                              newSpan.setAttribute("id", "giftVoucher");
                              newSpan.innerHTML = '<i class="fa fa-arrow-right blink" aria-hidden="true"></i> Gift Voucher Credit'; // set the innerHTML content
                        }
      
                        if(oldSpan){
                              oldSpan.replaceWith(newSpan); // replace the old span element with the
                              if(location.search.includes('wallet')){
                                    dispatch(setScrollToTarget({scrollTo : "wallet"}))
                              } else if(location.search.includes('giftVoucher')) {
                                    dispatch(setScrollToTarget({scrollTo : "giftVoucher"}))
                              }
                              // oldSpan.scrollIntoView({ behavior: "smooth" })
                        }
                  }
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[scroll, dispatch])

      let metaDataSite = MetaData
      if(domain === Config.DOMAIN_CONSTANT_TFM){
            metaDataSite = MetaDataTfm
      } else if(domain === Config.DOMAIN_CONSTANT_AJTIX){
            metaDataSite = MetaDataAj
      }


      return (
            <>
                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <div className={["scroll-animations position-relative"].join(" ")} data-scroll-container>
                        <Helmet>
                              <title>{metaDataSite.TermsAndConditions.title}</title>
                              <meta name="description" content={metaDataSite.TermsAndConditions.description} />
                              <meta name="keywords" content={metaDataSite.TermsAndConditions.keywords} />
                        </Helmet>
                        <main>
                              <section data-scroll-section className="section__faq section__terms pt-0">
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12 terms_page">
                                                      <div className="content content__faq mx-auto">
                                                            <div className="content__header text-center overflow-hidden">
                                                                  <div className="big-heading">
                                                                        <h1 className="mb-0">Terms & Conditions </h1>
                                                                        {domain === "" &&
                                                                        <>
                                                                        <HeadingPictureComponent dataScroll className="ticket-orange" srcSet="ticket-faq-big-heading1-orange.svg" src="ticket-faq-big-heading1-green-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-blue" srcSet="ticket-faq-big-heading1-blue.svg" src="ticket-faq-big-heading1-blue-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-aquamarine" srcSet="ticket-faq-big-heading1-aquamarine.svg" src="ticket-faq-big-heading1-orange-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-yellow" srcSet="ticket-faq-big-heading1-yellow.svg" src="ticket-faq-big-heading1-aquamarine-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-red" srcSet="ticket-faq-big-heading1-red.svg" src="ticket-faq-big-heading1-red-sm.svg"/>
                                                                        </>}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="content__body" style={{"minHeight": "40vh"}}>
                                                                  {data && <div dangerouslySetInnerHTML={{ __html: data.data }}></div> }
                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content.content__faq --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /.section__faq --> */}
                        </main>

                        <Footer/>

                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}
            </>
      )
}

export default Terms;
