import React from 'react'

const AccordianItem = ({ question, id, collapseId,  content }) => {

      
      return (
            <div className="accordion-item">
                  <h2 className="accordion-header" id={id}>
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${collapseId}`} aria-expanded="false" aria-controls={collapseId}>
                              <span className="btn__text">
                                    {question}
                              </span>
                        </button>
                  </h2>
                  <div id={collapseId} className="accordion-collapse collapse" aria-labelledby={id} data-bs-parent="#accordionFAQ">
                        <div className="accordion-body">
                              <p dangerouslySetInnerHTML={{__html: content}}>
                              </p>
                        </div>
                  </div>
            </div>
      )
}

export default AccordianItem
