import React, { useState, useEffect, useRef } from 'react';
// external css
// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';

// child component
// packages
import { osName  } from "react-device-detect";

// Actions & RTK query or mutations
import { useLazyGetCardQuery, useLazyWebInitiateSessionQuery } from '../../Services/modules/Card';
import { setPaymentParams, setSpinner } from '../../Store/WebViewPayment';
import { useAddUserVoucherMutation } from '../../Services/modules/GiftVoucher';
import  { setSecureData  } from "./../../Store/BookingModule"
import { setMKey } from '../../Store/User';
import  { setAlert } from "./../../Store/UI"
// Constants
import { cardValidationPayment } from '../../Services';
import { NameConstants } from '../../resources/NameConstants';
import { errors, messages, placeholders, actions } from '../../resources/en';
import { api } from '../../Services/Api';

const intialState = {
      [NameConstants.CTNameOnCard]:{value:"",error:false,errorMessage:""},
      [NameConstants.CTCardNumber]:{value:"",error:false,errorMessage:""},
      [NameConstants.CTExp]:{value:"",error:false,errorMessage:""},
      expirey_date:{value:"",error:false,errorMessage:""},
      [NameConstants.CTCvc]:{value:"",error:false,errorMessage:""}
}

const Step2 = (props) => {

      const webViewPayment                      =     useSelector(state => state.webViewPayment);
      const user                                =     useSelector(state => state.user);
      const spinner                             =     useSelector(state => state.ui.spinner);
      const secureData                          =     useSelector(state=>state.bookingModule.secureData)

      const [state,setState]                    =     useState(intialState);
      const [cvv,setCvv]                        =     useState({value:"",error:false,errorMessage:""});
      const [useSavedCard,setUseSavedCard]      =     useState(false);
      const [saveCardFuture,setSaveCardFuture]  =     useState(false);

      const [disbaleSubmit,setDisbaleSubmit]    =     useState(false);

      const ref                                 =     useRef();
      const dispatch                            =     useDispatch();

      const [getCard, { data, isSuccess, error, isLoading }] = useLazyGetCardQuery()
      const [WebInitiateSession, { data : WebData, isSuccess : WebIsSuccess, error : WebError, isLoading : WebIsLoading  }] = useLazyWebInitiateSessionQuery();
      const [addUserVoucher, { data : voucherData, isSuccess : voucherIsSuccess, error : voucherError  }]  = useAddUserVoucherMutation()


      useEffect(()=>{
            if(user.ct_is_top_member === 1) {
                  dispatch(setSpinner({status:"request"}));
                  getCard()
            }

      },[getCard, dispatch, user.ct_is_top_member])


      // Handler for getCard API fetch saved card for user 
      useEffect(()=>{
            if(isSuccess) {
                  if(data.status === "1") {

                  } else if(data.status === "0") {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
                  dispatch(setSpinner({status:""}));
            }

            if(error) {
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
      },[data, isSuccess, error, isLoading, dispatch])


      useEffect(()=>{
            if(WebIsSuccess) {
                  if(WebData.status === "success"){
                        if(useSavedCard){
                              console.log(WebData.data)
                              sagePayRequestReusable(WebData.data.merchantSessionKey);
                        } else {
                              sagePayRequest(WebData.data.merchantSessionKey);
                        }
                        dispatch(setMKey({mKey:WebData.data.merchantSessionKey}))
                  } else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:WebData.error || WebData.message || errors.NotFound404}));
                  }
            }
            if(WebError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:WebError.error || WebError.data.error || WebError.data.message || errors.NotFound404}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[WebData, WebIsSuccess, WebError, WebIsLoading, dispatch])

      //Handler for add user voucher API response
      useEffect(()=>{
            if(voucherData) {
                  dispatch(setMKey({mKey:''}))
                  dispatch(api.util.resetApiState('cardApi'));
                  // dispatch(setRefetchHistory({refetchHistory : true}))
                  if(voucherData.status === "3DAuth"){
                        dispatch(setSecureData({data : voucherData}));
                  }
                  if(voucherData.status === "1"){
                        dispatch(setPaymentParams({
                              step : "step3",
                              paymentMethod : "",
                              amount : "",
                              sessionId : ""
                        }))
                        dispatch(setSpinner({status:""}));

                  } else if(voucherData.status === "0") {
                        dispatch(setPaymentParams({
                              step : "step3",
                              paymentMethod : "",
                              amount : "",
                              sessionId : ""
                        }))

                        dispatch(setSpinner({status:""}));
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:voucherData.error || voucherData.message || errors.NotFound404}));
                  }
            }

            if(voucherError) {
                  dispatch(setSpinner({status:""}));
                  dispatch(setMKey({mKey:''}))
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:voucherError.error || voucherError.data.error || voucherError.data.message || errors.NotFound404}));
            }

      },[voucherData, voucherError, voucherIsSuccess, dispatch])

      // Submit Form While 3D Secure took place 
      useEffect(()=>{
            if(secureData !== null ) {
                  if(secureData.status === "3DAuth"){
                        dispatch(setSecureData({data:null}));
                        ref.current.submit();
                  }
            }

      },[secureData, dispatch])

      const backHandler = ()=> {
            dispatch(setPaymentParams({
                  step : "step1",
                  paymentMethod : "",
                  amount : webViewPayment.amount,
                  sessionId : webViewPayment.sessionId
            }))
      }

      const onChangeCheckBox = (event)=> {
            setUseSavedCard(event.target.checked);
      }

      const checkBoxHandler = (event) => {
            setSaveCardFuture(event.target.checked);
      }

      const changeValueHandler = (event)=>{
            const type = event.target.name;
            let val  = event.target.value;
            
            if(type === NameConstants.CTExp) {
                  var str = val;
                  if(str.length > 5){
                        return;
                  }
                  const actual_val = str.replace(/\//g, '');
                  // eslint-disable-next-line no-useless-escape
                  const display_val = val.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '')

                  // const checkValidation = cardValidationPayment(val,type); 
                  setState(prevState=>{
                        return {
                              ...prevState,
                              // [type]:{...prevState[type],value:display_val,error:!checkValidation.isValid,message:checkValidation.validatingMessage},
                              [type]:{...prevState[type],value:display_val},
                              expirey_date:{...prevState.expirey_date,value:actual_val}
                        }
                  })

            } else{

                  let actual_val = val;
                  if(type === NameConstants.CTCvc){
                        actual_val = val.replace(/[^0-9]/g, '');;
                  }

                  if(type === NameConstants.CTCardNumber){
                        actual_val = val.replace(/[^0-9]/g, '');;
                  }

                  // const checkValidation = cardValidationPayment(actual_val,type); 
                  setState(prevState=>{
                        return {
                              ...prevState,
                              // [type]:{...prevState[type],value:actual_val,error:!checkValidation.isValid,errorMessage:checkValidation.validatingMessage}
                              [type]:{...prevState[type],value:actual_val}
                        }
                  })
            }
            
      }

      const changeCvvHandler = (event)=>{
            const actual_val = event.target.value.replace(/[^0-9]/g, '');;
            
            setCvv(prevState=>{
                  return {
                        ...prevState,
                        value:actual_val
                  }
            })
      }

      const clearErrorHandler = ()=>{
            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.CTNameOnCard]:{...prevState[NameConstants.CTNameOnCard],error:false,errorMessage:""},
                        [NameConstants.CTCardNumber]:{...prevState[NameConstants.CTCardNumber],error:false,errorMessage:""},
                        [NameConstants.CTExp]:{...prevState[NameConstants.CTExp],error:false,errorMessage:""},
                        expirey_date:{...prevState.expirey_date,error:false,errorMessage:""},
                        [NameConstants.CTCvc]:{...prevState[NameConstants.CTCvc],error:false,errorMessage:""}
                  }
            })
      }

      const clearCvvErrorHandler = ()=> {
            setCvv(prevState=>{
                  return {
                        ...prevState,
                        error:false,
                        errorMessage:""
                  }
            })
      }

      const SendPaymentRequestHandler = (event)=> {

            event.preventDefault()
            setDisbaleSubmit(true)
            if(useSavedCard) {
                  const checkValidation   =       cardValidationPayment(cvv.value,NameConstants.CTCvc); 
                  if(checkValidation.isValid) {
                        WebInitiateSession();
                        dispatch(setSpinner({status:"request"}));
                  } else {
                        setDisbaleSubmit(false)
                        setCvv(prevState=>{
                              return {
                                    ...prevState,
                                    error:!checkValidation.isValid,
                                    errorMessage :checkValidation.validatingMessage
                              }
                        })
                  }
            } else {
                  let request             =     false;
                  const checkedObject     =     {};
                  const requestAllow      =     [];
                  
                  for(let i in state){
                        
                        const checkValidation   =       cardValidationPayment(state[i].value,i); 
                        checkedObject[i]        =       {...state[i],error:!checkValidation.isValid,errorMessage:checkValidation.validatingMessage}
                        requestAllow.push(!checkValidation.isValid); 
                  }

                  setState(prevState=>{
                        return {
                              ...checkedObject
                        }
                  });

                  request   =       requestAllow.includes(true);
                  if(!request) {
                        dispatch(setSpinner({status:"request"}));
                        WebInitiateSession();
                  } else {
                        setDisbaleSubmit(false)
                  }
            }
      }

      const sagePayRequest = (merchantSessionKey)=>{
               
            window.sagepayOwnForm({ merchantSessionKey: merchantSessionKey })
            .tokeniseCardDetails({
                  cardDetails: {
                        cardholderName: state[NameConstants.CTNameOnCard].value,
                        cardNumber: state[NameConstants.CTCardNumber].value,
                        expiryDate: state.expirey_date.value,
                        securityCode: state[NameConstants.CTCvc].value
                  },
                  onTokenised: function(result) {
                              if (result.success) {
                                    const card_identifier = result.cardIdentifier;
                                    
                                    addUserVoucher({
                                          voucher_amount : webViewPayment.amount,
                                          wallet_balance_used: false,
                                          reusable_payment : false,
                                          reusable : saveCardFuture,
                                          card_identifier : card_identifier,
                                          mKey : merchantSessionKey,
                                          session_id : '',
                                          client: webViewPayment.sessionId ? "app" : "web",
                                          request_client : webViewPayment.sessionId ? "app" : "web",
                                          voucher_session_id: webViewPayment.sessionId
                                    })

                              } else{
                                    dispatch(setSpinner({status:""}));
                                    setDisbaleSubmit(false)
                                    const errorsSag = result.errors;
                                    let ct_card_number = false;
                                    let ct_exp = false;
                                    let ct_cvc = false;
                                    let ct_name_on_card = false;

                                    errorsSag.forEach(error => {
                                          let strng       = error.message;
                                          let incNumber   = strng.includes("number");
                                          let incExp      = strng.includes("date");
                                          let incHold     = strng.includes("cardholder");
                                          let incCvv      = strng.includes("code");
                                          if (incNumber) { ct_card_number = true; }
                                          if (incExp) { ct_exp = true; }
                                          if (incHold) { ct_name_on_card = true; }
                                          if (incCvv) { ct_cvc = true; }
                                    }) 

                                    if (ct_name_on_card) {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTNameOnCard]:{...prevState[NameConstants.CTNameOnCard],error:true,errorMessage:errors.SavedCard[NameConstants.CTNameOnCard]}
                                                }
                                          })
                                    }

                                    if (ct_card_number){	
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTCardNumber]:{...prevState[NameConstants.CTCardNumber],error:true,errorMessage:errors.SavedCard[NameConstants.CTCardNumber]}
                                                }
                                          })							
                                    }

                                    if (ct_exp) {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTExp]:{...prevState[NameConstants.CTExp],error:true,errorMessage:errors.SavedCard[NameConstants.CTExp]}
                                                }
                                          })
                                    }

                                    if(ct_cvc) {
                                          setState(prevState=>{
                                                return {
                                                      ...prevState,
                                                      [NameConstants.CTCvc]:{...prevState[NameConstants.CTCvc],error:true,errorMessage:errors.SavedCard[NameConstants.CTCvc]}
                                                }
                                          })
                                    }

                                    if(!ct_name_on_card && !ct_card_number && !ct_exp && !ct_cvc){
                                          dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:errorsSag[0].message || errors[0].message || errors.sagePayError}))
                                    }
                              }
                    }
            });
      }

      const sagePayRequestReusable = (merchantSessionKey)=>{

            window.sagepayOwnForm({ merchantSessionKey: merchantSessionKey })
            .activateReusableCardIdentifier({
                  reusableCardIdentifier: data.data.card.identifier,
                  securityCode: cvv.value,
                  onActivated: function(result) {
                        if (result.success) {
                             
                              addUserVoucher({
                                    voucher_amount : webViewPayment.amount,
                                    wallet_balance_used: false,
                                    reusable_payment : true,
                                    reusable : saveCardFuture,
                                    card_identifier : data.data.card.identifier,
                                    mKey : merchantSessionKey,
                                    session_id : '',
                                    client: webViewPayment.sessionId ? "app" : "web",
                                    request_client : webViewPayment.sessionId ? "app" : "web",
                                    voucher_session_id: webViewPayment.sessionId
                              })
                              console.log('success using reusable card ')
                        } else{
                              // dispatch(setSpinner({status:""}));
                              setDisbaleSubmit(false)
                              const errors = result.errors;
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:errors[0].message|| errors.NotFound404}));
                        }
                  }
            });
      }
      
      
      let hasCard = false
      if(data) {
            hasCard =data.data.has_card;
      }

      return (
                  <>
                  {secureData &&
                        <form  ref={ref}  method="POST" style={{"visibility": "hidden","height":"0px"}} action={secureData && secureData.acsUrl}>
                              <input type="hidden" name="creq" value={secureData && secureData.cReq}/>
                              <input type="hidden" name="acsTransID" value={secureData && secureData.acsTransId}/>
                              <input type="hidden" name="threeDSSessionData" value={secureData && secureData.threeDSSessionData}/>
                              <input type="hidden" name="ThreeDSNotificationURL " value={secureData && secureData.ThreeDSNotificationURL} />
                              <button type="submit" style={{"visibility": "hidden"}}></button>
                        </form>
                  }
                  <div id="walletTopupStep2" className="content content__payment-options border-bottom d-flex">
                        <div className={["content__body",osName ==="iOS"  ? "ios-body-padding" : "general-body-padding"].join(" ")}>
                              {(hasCard && user.ct_is_top_member === 1) &&
                              <>
                                    <h4 className="heading">
                                          Use your saved card
                                    </h4>
                                    <div className='content__body reusecard__wrapper'>
                                          <div className="input-group input-group__as-form-control input-group__as-checkbox">
                                                <span className="input-group-text">* * * *&nbsp;&nbsp;* * * *&nbsp;&nbsp;* * * *&nbsp;&nbsp;{1234}</span>
                                                <div className="custom-control custom-checkbox custom-checkbox__check" style={{top:0,marginTop:0}}>
                                                      <input type="checkbox" className="custom-control-input " id="walletTopupUseSavedCard" checked={useSavedCard} onChange={onChangeCheckBox}/>
                                                      <label className="custom-control-label" htmlFor="walletTopupUseSavedCard">
                                                      </label>
                                                </div>
                                          </div>
                                          <div className="form-group form-group__cvc input-group__as-CVC">
                                                      <input type="text" className="form-control" 
                                                      id="walletTopupReuseCardCVC"
                                                      name={NameConstants.CTCvc} 
                                                      placeholder={placeholders[NameConstants.CTCvc]} 
                                                      onFocus={clearCvvErrorHandler}
                                                      onChange={changeCvvHandler} 
                                                      value={cvv.value}
                                                />
                                                {cvv.error && <p className="form-label-error">{cvv.errorMessage}</p> }
                                          </div>
                                    </div>
                                    
                                    <hr/>
                              </>}
                              <h4 className="heading heading__use-new-card">
                                    Use a new card
                              </h4>
                              <div className="form-groups__use-new-card" style={{opacity : useSavedCard ? "0.5": ""}}>
                                    <div className="form-group">
                                          <input type="text" className="form-control" id="walletTopupInputCardName" 
                                                name={NameConstants.CTNameOnCard} 
                                                placeholder={placeholders[NameConstants.CTNameOnCard]} 
                                                onFocus={clearErrorHandler} 
                                                onChange={changeValueHandler} 
                                                value={state[NameConstants.CTNameOnCard].value}
                                                disabled={useSavedCard}
                                          />
                                          {state[NameConstants.CTNameOnCard].error && <p className="form-label-error">{state[NameConstants.CTNameOnCard].errorMessage}</p>}
                                    </div>
                                    <div className="form-group">
                                          <input type="text" className="form-control" id="walletTopupInputCardNum" 
                                                name={NameConstants.CTCardNumber}  
                                                placeholder={placeholders[NameConstants.CTCardNumber]} 
                                                onFocus={clearErrorHandler} 
                                                onChange={changeValueHandler} 
                                                value={state[NameConstants.CTCardNumber].value}
                                                disabled={useSavedCard}
                                          />
                                          {state[NameConstants.CTCardNumber].error && <p className="form-label-error">{state[NameConstants.CTCardNumber].errorMessage}</p>}
                                    </div>
                                    <div className="form-groups__expiry-cvc d-flex">
                                          <div className="form-group form-group__card-expiry">
                                                <input type="text" className="form-control fc__CardExpiry" 
                                                      id="walletTopupInputCardExpiry"
                                                      name={NameConstants.CTExp} 
                                                      placeholder={placeholders[NameConstants.CTExp]} 
                                                      onFocus={clearErrorHandler} 
                                                      onChange={changeValueHandler} 
                                                      value={state[NameConstants.CTExp].value}
                                                      disabled={useSavedCard}
                                                />
                                                {state[NameConstants.CTExp].error && <p  className="form-label-error">{state[NameConstants.CTExp].errorMessage}</p>}
                                          </div>
                                          <div className="form-group form-group__cvc">
                                                <input type="text" className="form-control fc__CVC" 
                                                      id="walletTopupInputCardCVC"
                                                      name={NameConstants.CTCvc} 
                                                      placeholder={placeholders[NameConstants.CTCvc]} 
                                                      onFocus={clearErrorHandler} 
                                                      onChange={changeValueHandler} 
                                                      value={state[NameConstants.CTCvc].value}
                                                      disabled={useSavedCard}
                                                />
                                                {state[NameConstants.CTCvc].error && <p className="form-label-error">{state[NameConstants.CTCvc].errorMessage}</p>}
                                          </div>
                                    </div>
                                    {/* <!-- /.form-groups__expiry-cvc --> */}
                                    {(!hasCard && user.ct_is_top_member === 1) &&
                                    <div className="checkbox-wrapper">
                                          <div className="custom-control custom-control-sm-label custom-checkbox custom-checkbox__check">
                                                <input type="checkbox" className="custom-control-input" id="walletTopupSaveCard" onChange={checkBoxHandler} checked={saveCardFuture}/>
                                                <label className="custom-control-label" htmlFor="walletTopupSaveCard">
                                                      Save card for future purchases
                                                </label>
                                          </div>
                                    </div>}
                              </div>
                              {/* <!-- /.form-groups__use-new-card --> */}
                              <br className="d-none d-xxl-block"/>
                        </div>
                        {/* <!-- /.content__body --> */}
                        <div className="giftVoucher-lower-footer-body">

                              <div className="content__body content__body--total border-top">
                                    <div className="subtotal-block">
                                          <div className="fw-bold">Total (VAT inc.):</div>
                                          <div className="total-amount">£{parseFloat(webViewPayment.amount).toFixed(2)}</div>
                                    </div>
                              </div>
                              <div className="content__footer border-top">
                                    <div className="buttons-wrapper gap-0">
                                          <button id="btnBackToStep1" type="button" className="btn__back btn btn-outline-primary w-50" disabled={spinner === "request"} onClick={backHandler}>{actions.back}</button>
                                          <button id="btnWalletTopUp" type="button" className="btn btn-primary w-50" disabled={disbaleSubmit} onClick={SendPaymentRequestHandler} >{actions.submit}</button>
                                    </div>
                              </div>
                        </div>
                  </div>
                  </>
      )
}

export default Step2;
