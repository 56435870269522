/* eslint-disable */
import React, { useEffect } from 'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

// child component
import Footer from "../../Layouts/Footer/Footer";
import { ImageComponent } from '../../Components';
import { UptakeImageDummy, 
      UptakeTitleDummy, UptakeHeadingPer, 
      UptakeProgressBarDummy, UptakeMemberFeedback, 
      UptakeListStatDummy, UptakeStatusWrapperDummy,
      UptakeInstructionDummy,
      UptakeCardDummy, UptakeCardCarouselDummy
} from "./DummyComponents/UptakePage";

// packages
import OwlCarousel from 'react-owl-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Helmet } from 'react-helmet';
import { Tooltip } from 'react-tooltip'

// Actions & RTK query or mutations
import { useLazyGetUptakeQuery } from "../../Services/modules/Uptake";
import { setAlert, updateLocoScroll } from '../../Store/UI';

// Constants
import { errors, messages } from '../../resources/en';
// import { dateFormatHadler  } from "./../../resources/DateHandler";
import { UptakePageOptions, UptakePageBannerOptions } from '../../resources/OwlCarouselOptions';
import { URL } from '../../resources/Url';
import { routesName } from '../../resources/RoutesName';
import { MetaData, MetaDataAj, MetaDataTfm } from '../../resources/MetaData';
import { Config } from '../../Config';

const UptakePpageV2 = () => {

      const browserDetect = useSelector(state=>state.ui.browserDetect);
      const domain        = useSelector(state=>state.user.domain)

      const { id } = useParams();
      const dispatch = useDispatch();

      const [getUptake, { data, isSuccess, error, isLoading }] = useLazyGetUptakeQuery();


      useEffect(()=>{
            getUptake({event_id:id});
      },[getUptake,id])

      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1"){
                        // console.log(data)
                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }

      },[data, isSuccess, error, isLoading, dispatch])

      const afterLoadHandler = ()=>{
            // console.log("after Load Image handler..")
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
      }

      const getDateForEcologiLogo = ()=>{
            const date = Date.now()
            return `ecology_logo_red.png?v=${date}`
      }


      // let color_class = "";
      let percentage = null;
      if(data) {
            // color_class = color_class + data.data.color_class;
            percentage = data.data.percentage ;
      }

      let ecologiLogoName = "ecology_logo_red.png?v=1";
      ecologiLogoName = getDateForEcologiLogo()
      let Get_Uptake_Feedback_report_URL = URL.Get_Uptake_Feedback_report_URL
      let Uptake_Export_Button_URL = URL.Uptake_Export_Button_URL

      let metaDataSite = MetaData
      if(domain === Config.DOMAIN_CONSTANT_TFM){
            metaDataSite = MetaDataTfm
            Get_Uptake_Feedback_report_URL = URL.Get_Uptake_Feedback_report_URL_TFM
            Uptake_Export_Button_URL = URL.Uptake_Export_Button_URL_TFM
      } else if(domain === Config.DOMAIN_CONSTANT_AJTIX){
            metaDataSite = MetaDataAj
            Get_Uptake_Feedback_report_URL = URL.Get_Uptake_Feedback_report_URL_AJ
            Uptake_Export_Button_URL = URL.Uptake_Export_Button_URL_AJ
      }
     
      return (
            
            <React.Fragment>
                  
                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <div className="scroll-animations position-relative" data-scroll-container>
                        <Helmet>
                              <title>{metaDataSite.Uptake.title}</title>
                              <meta name="description" content={metaDataSite.Uptake.description} />
                              <meta name="keywords" content={metaDataSite.Uptake.keywords} />
                        </Helmet>
                        <main className="main__uptake">
                              <section data-scroll-section className="section__uptake">
                                    {/* <!--
                                          NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                                    --> */}
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__uptake mx-auto">
                                                            <div className="content__body">
                                                                  
                                                                  {data ? (
                                                                        <OwlCarousel loop {...UptakePageBannerOptions}>
                                                                        <div className='card card__uptake card__uptake--thank-you border-0 bg-primary border-radius-10' >
                                                                              <div className="card-body text-center">
                                                                                    <h5 className="card-title fw-bold">
                                                                                          Thank you! Together we<br className="d-md-none"/> are helping to support <span className="yellow2">230 NHS Charities</span>
                                                                                    </h5>
                                                                              </div>
                                                                        </div>
                                                                        <div className='card card__uptake card__uptake--thank-you border-0 bg-primary border-radius-10' >
                                                                              <Link to={routesName.AboutUs} target='_blank' rel='noreferrer' style={{"textDecoration":"none"}}>
                                                                              <div className="card-body text-center ecologi-card bg-mint">
                                                                                    <h5 className="card-title fw-bold" style={{"color":"var(--bs-dark)"}}>
                                                                                          and investing into carbon avoidance projects <span style={{"color":"var(--bs-primary) "}}>via Ecologi</span>
                                                                                    </h5>
                                                                              </div>
                                                                              </Link>
                                                                        </div>
                                                                        </OwlCarousel>) 
                                                                        : <UptakeCardDummy />}
                                                                  {/* <!-- /.card__uptake --> */}

                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content.content__uptake --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}

                                          <div className="row">
                                                <div className="col-md-12">

                                                      <div className="content content__uptake content__uptake-event mx-auto">
                                                            <div className="content__body">

                                                                  <div className="card card__event border-1 border-radius-10 flex-lg-row mb-0">
                                                                        <div className="card__event-image flex-shrink-0">
                                                                              {/* <ImageComponent className="event-image" srcSet="@2x.png" src="" type="images"/> */}
                                                                              { data ? <LazyLoadImage className="event-image"srcSet={data.data.image} src={data.data.image} afterLoad={afterLoadHandler} /> : <UptakeImageDummy />}
                                                                              
                                                                        </div>
                                                                        <div className="card-body">

                                                                              <div className="card-body__row row-event-title-status">
                                                                                    {data ?  <h5 className="card-title fw-bold">{data && data.data.title}</h5> : <UptakeTitleDummy/>}
                                                                                    {data ? <div className="status-wrapper d-flex flex-shrink-0 align-items-center">
                                                                                          <span className="heading">Status:</span>
                                                                                          <span className="status">{data && data.data.eventstatus}</span>
                                                                                    </div> : <UptakeStatusWrapperDummy />}

                                                                              </div>
                                                                              {/* <!-- /.card-body__row --> */}

                                                                              <div className="card-body__row row-uptake-list-progress">
                                                                                    <div className="col col__left order-md-2">
                                                                                          
                                                                                          {data ? <ul className="list__uptake-stat list-unstyled">
                                                                                                <li style={{marginRight:browserDetect?"30px":""}}>
                                                                                                      <div className="stat">
                                                                                                            <h4 className="stat__number fw-bold">{data && data.data.tickets}</h4>
                                                                                                            <div className="stat__text">Allocated</div>
                                                                                                      </div>
                                                                                                </li>
                                                                                                <li style={{marginRight:browserDetect?"30px":""}}>
                                                                                                      <div className="stat">
                                                                                                            <h4 className="stat__number fw-bold">{data && data.data.purchased}</h4>
                                                                                                            <div className="stat__text">Booked</div>
                                                                                                      </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                      <div className="stat">
                                                                                                            <h4 className="stat__number fw-bold">{data && data.data.waiting_list_count}</h4>
                                                                                                            <div className="stat__text">Waiting list</div>
                                                                                                      </div>
                                                                                                </li>
                                                                                          </ul> : <UptakeListStatDummy />}

                                                                                    </div>
                                                                                    {/* <!-- /.col --> */}

                                                                                    <div className="col col__right order-md-1 flex-shrink-0">

                                                                                          <div className="uptake-progress-wrapper">
                                                                                                {data ? <h5 className="heading">Uptake %</h5> : <UptakeHeadingPer />}
                                                                                                
                                                                                                {data ? <div className="progress progress-bar-overlay">
                                                                                                      <div className="progress-value" style={{color : percentage > 57 && "white"}}>{percentage}%</div>
                                                                                                      <div className={["progress-bar"].join(" ")} role="progressbar" aria-label="Uptake %" style={{"width": percentage + '%'}} aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100">
                                                                                                      </div>
                                                                                                </div> : <UptakeProgressBarDummy />}
                                                                                          </div>
                                                                                          {/* <!-- /.uptake-progress-wrapper" --> */}

                                                                                    </div>
                                                                                    {/* <!-- /.col --> */}
                                                                              </div>
                                                                              {/* <!-- /.card-body__row --> */}

                                                                              <div className="card-body__row row-notice-buttons">
                                                                                    <div className="col col__left order-md-2">

                                                                                          
                                                                                          {data ? <div className="p-important-wrapper d-flex flex-row align-items-center">
                                                                                                <p className="red mb-0">
                                                                                                      Important - these are provisional guest list(s), meaning that members may still cancel or amend their bookings. We always send the final guest list on the day of the event containing the definitive list of bookings. This email will also detail any cancelled bookings.
                                                                                                </p>
                                                                                          </div> :<UptakeInstructionDummy />}

                                                                                    </div>
                                                                                    {/* <!-- /.col --> */}
                                                                                    <div className="col col__right order-md-1 d-lg-flex flex-shrink-0">
                                                                                          
                                                                                          {data ?  <div className="buttons-wrapper mt-lg-auto">
                                                                                                <a className="btn btn-primary" href={`${Get_Uptake_Feedback_report_URL}${id}`}target="_blank"  rel='noreferrer'>Member Feedback</a>
                                                                                          </div> : <UptakeMemberFeedback />}
                                                                                    </div>
                                                                                    {/* <!-- /.col --> */}
                                                                              </div>
                                                                              {/* <!-- /.card-body__row --> */}

                                                                        </div>
                                                                        {/* <!-- /.card-body --> */}
                                                                  </div>
                                                                  {/* <!-- /.card__event --> */}

                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}

                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__uptake content__uptake-details-carousel mx-auto">
                                                            <div className="content__body p-0 d-flex flex-column flex-lg-row">
                                                                  <div className="col__left order-lg-2" style={{marginLeft:browserDetect ? "26px" : ""}}>
                                                                        {!data && (<><UptakeCardDummy margin="26px"/>
                                                                        <UptakeCardDummy margin="26px"/>
                                                                        <UptakeCardDummy margin="26px"/></>)}
                                                                        {(data &&  data.data.performances) && data.data.performances.map((pef,index)=>{
                                                                              // const dateHandler = dateFormatHadler(pef.start_date,pef.start_time);
                                                                        return (<div className="card card__uptake card__uptake--details border-1 border-radius-10" key={pef.id}>
                                                                                    <div className="card-body d-flex flex-column flex-sm-row align-items-sm-center">
                                                                                          <ul className="list__uptake-details list-unstyled mb-0  d-flex flex-column flex-md-row flex-wrap">
                                                                                                <li className="list-item__date">
                                                                                                      <div className="item">
                                                                                                            <span className="heading">Date:</span>
                                                                                                            <span>{pef.performance_time}</span>
                                                                                                      </div>
                                                                                                </li>
                                                                                                <li className="list-item__status">
                                                                                                      <div className="item">
                                                                                                            <span className="heading">Status:</span>
                                                                                                            <span>{pef.is_held_back === 1 ? "Pending" :pef.status}</span>
                                                                                                            {pef.is_held_back === 1 ?  <>
                                                                                                                  &nbsp;
                                                                                                                  <span className="icon-tooltip-info" data-tooltip-id="my-tooltip" data-tooltip-content="We are strategically staggering listing this performance to prioritise and boost sales on earlier dates">i</span>
                                                                                                                  <Tooltip id="my-tooltip" style={{ left : 1, fontSize: "15px", width : "250px"}} />
                                                                                                            </>
                                                                                                           : null }
                                                                                                      </div>
                                                                                                </li>
                                                                                                <li className="list-item__allocated">
                                                                                                      <div className="item">
                                                                                                            <span className="heading">Allocated:</span>
                                                                                                            <span>{pef.tickets}</span>
                                                                                                      </div>
                                                                                                </li>
                                                                                                <li className="list-item__booked">
                                                                                                      <div className="item">
                                                                                                            <span className="heading">Booked:</span>
                                                                                                            <span>{pef.purchased}</span>
                                                                                                      </div>
                                                                                                </li>
                                                                                          </ul>
                                                                                          <div className="buttons-wrapper align-items-center">
                                                                                                <a className="btn btn-primary" href={`${pef.export_file}`} target="_blank" rel="noreferrer">Export</a>
                                                                                          </div>
                                                                                    </div>
                                                                                    {/* <!-- /.card-body  --> */}
                                                                              </div>)

                                                                        })}
                                                                        {/* <!-- /.card__uptake--details  --> */}

                                                                  </div>
                                                                  {/* <!-- /.col__left --> */}
                                                                  <div className="col__right order-lg-1">
                                                                        {data ? <div className="card card__uptake card__uptake--carousel border-1 border-radius-10 p-0">

                                                                              <OwlCarousel className="owl-carousel owl-carousel__uptake owl-theme h-100" loop {...UptakePageOptions}>
                                                                                    <div className="item">
                                                                                          <div className="item__image">
                                                                                                <ImageComponent className="carousel-image object-fit" 
                                                                                                src={`${URL.Image_Base_Url}iStock-542088116.jpg`} />
                                                                                          </div>
                                                                                    </div>
                                                                                    <div className="item">
                                                                                          <div className="item__image">
                                                                                                <ImageComponent className="carousel-image object-fit" 
                                                                                                src={`${URL.Image_Base_Url}iStock-1212167952.jpg`}/>
                                                                                                
                                                                                          </div>
                                                                                    </div>
                                                                                    <div className="item">
                                                                                          <div className="item__image">
                                                                                                <ImageComponent className="carousel-image object-fit" 
                                                                                                srcSet={`${URL.Image_Base_Url}iStock-465180779.jpg`}
                                                                                                src={`${URL.Image_Base_Url}iStock-465180779.jpg`} />
                                                                                          </div>
                                                                                    </div>
                                                                                    <div className="item">
                                                                                          <div className="item__image">
                                                                                                <ImageComponent className="carousel-image object-fit" 
                                                                                                srcSet={`${URL.Image_Base_Url}iStock-914628584.jpg`}
                                                                                                src={`${URL.Image_Base_Url}iStock-914628584.jpg`}
                                                                                                />
                                                                                          </div>
                                                                                    </div>
                                                                                    <div className="item">
                                                                                          <div className="item__image">
                                                                                                <ImageComponent className="carousel-image object-fit" 
                                                                                                srcSet={`${URL.Image_Base_Url}iStock-911008782.jpg`}
                                                                                                src={`${URL.Image_Base_Url}iStock-911008782.jpg`}
                                                                                                />
                                                                                                
                                                                                          </div>
                                                                                    </div>
                                                                              </OwlCarousel>
                                                                              {/* <!-- /.owl-carousel__uptake --> */}

                                                                              <div className="card-body text-center">
                                                                                    <p className="p-donation fw-bold">
                                                                                          {/* Together we’re raising funds in support of <span className="blue">NHS Charities Together</span> to help the NHS go further. */}
                                                                                          We work in support of <span className="blue">NHS Charities Together</span> and we invest in carbon avoidance projects for every ticket booked to attend your show.
                                                                                    </p>
                                                                                    <div className="buttons-wrapper justify-content-center">
                                                                                          <Link className="btn btn-primary align-self-start mx-auto" to={routesName.AboutUs}>Learn more</Link>
                                                                                    </div>
                                                                                    <div className="logos-container">
                                                                                          <div className="logos-wrapper d-flex justify-content-center">
                                                                                                <a href="https://nhscharitiestogether.co.uk/" rel="noreferrer" target="_blank">
                                                                                                      <ImageComponent className="image logo-nhs-charities" srcSet="logo-nhs-charities-together-blue-174@2x.png" src="logo-nhs-charities-together-blue-174.png" type="images" style={{padding:browserDetect ? "0 20px" : ""}}/>
                                                                                                </a>
                                                                                                {domain === Config.DOMAIN_CONSTANT_AJTIX ? null :
                                                                                                <a href="https://ecologi.com/centralticketslimited" rel="noreferrer" target="_blank" className='logo__ecologi__custom-text-uptake'>
                                                                                                      {/* <span className="custom-text"><span className="trees-count">4.05k</span> trees</span>
                                                                                                      <ImageComponent className="image logo-ecologi" srcSet="dynamic_text_ecologi_logo_from_gio@2x.png" src="dynamic_text_ecologi_logo_from_gio.png" type="images" style={{padding:browserDetect ? "0 20px" : ""}}/> */}
                                                                                                      
                                                                                                      <img className="image logo-ecologi"  style={{padding:browserDetect ? "0 20px" : ""}}
                                                                                                            srcSet={`${URL.Image_Base_Url}ecology_logo/${ecologiLogoName} 2x`} 
                                                                                                            src={`${URL.Image_Base_Url}ecology_logo/${ecologiLogoName}`} alt="" 
                                                                                                      />
                                                                                                </a>}
                                                                                          </div>
                                                                                          <p className="p-registered text-start mb-0">Registered charity no. 1186569</p>
                                                                                    </div>
                                                                              </div>
                                                                              {/* <!-- /.card-body --> */}
                                                                        </div> :  <UptakeCardCarouselDummy />}
                                                                        {/* <!-- /.card__uptake --> */}

                                                                  </div>
                                                                  {/* <!-- /.col__right --> */}
                                                            </div>
                                                            {/* <!-- /.content__body --> */}
                                                      </div>
                                                      {/* <!-- /.content.content__uptake --> */}

                                                </div>
                                                {/* <!-- /.col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /.section__uptake --> */}
                        </main>

                        <Footer />
                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}

            </React.Fragment>
      )
}

export default UptakePpageV2;
