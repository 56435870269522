import React, { useState, useEffect } from 'react'

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// child component
// packages

// Actions & RTK query or mutations
import { useGetLogoutMutation } from "./../../../Services/modules/users"
import { setWalletParams, reset as resetWallet } from '../../../Store/Wallet';
import { logout } from '../../../Store/User';
import { setAlert } from '../../../Store/UI';
import { reset as resetCurrentBookings } from '../../../Store/CurruntBookings';
import { reset as resetBookingModule } from "../../../Store/BookingModule"
import { reset as resetRegistration } from "../../../Store/Register"
import { persistor } from '../../../Store';

// Constants
import { routesName } from '../../../resources/RoutesName';
import { messages, errors } from '../../../resources/en';
import { api } from '../../../Services/Api';
import { Config } from '../../../Config';

const AuthenticatedMenus = () => {

      const [showTopUp,setShowTopUp]      =     useState(false);
      const user                          =     useSelector(state=>state.user)
      const  { pathname }                 =     useLocation();
      const dispatch                      =     useDispatch();
      const navigate                      =     useNavigate()

      const [getLogout, { data, isSuccess, isLoading, isFetching, error }] = useGetLogoutMutation();


      useEffect(()=>{
            setShowTopUp(false);

      },[pathname])

      // Handle logout API response
      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1"){
                        console.log("logout using Auth Menus")
                        dispatch(api.util.resetApiState());
                        dispatch(logout(data.data));
                        navigate(routesName.Home)
                        // reset all redux slices
                        dispatch(resetCurrentBookings())
                        dispatch(resetBookingModule())
                        dispatch(resetRegistration())
                        dispatch(resetWallet())

                        persistor.purge(); // Clear the persisted state
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || errors.NotFound404}))
            }

      },[data, isSuccess, isLoading, isFetching, error, dispatch, navigate])

      const showOptionsHandler = (e)=>{
            e.preventDefault();
            setShowTopUp(!showTopUp)
      }

      const topUpHandler = ()=> {
            if(pathname === routesName.MyWalletTopUp) {
                  dispatch(setWalletParams({isTopUpOpen : true,step : 'step1',paymentMethod : "",topUpAmount : 0}))
            }
      }

      const logOutHandler = ()=> {
            getLogout()
      }

      return (
                 <React.Fragment>

                        <li className="nav-item">
                              <Link className="nav-link" to={routesName.CurrentListing}>
                                    Shows
                              </Link>
                        </li>
                        <li className="nav-item">
                              <a className="nav-link"  href={user.domain === Config.DOMAIN_CONSTANT_AJTIX ? "https://ajtickets.tixculture.com/" : "https://centraltickets.tixculture.com/"} target="_blank" rel='noreferrer'>
                                    West End Offers
                              </a>
                        </li>
                        <li className="nav-item">
                              <Link className="nav-link" to={routesName.MyAccountHome}>
                                    My Account
                              </Link>
                        </li>
                        <li className="nav-item">
                              <Link className="nav-link" to={routesName.ReferFriend}>
                                    Refer Friends
                              </Link>
                        </li>
                        <li className="nav-item">
                              <Link className="nav-link" to={routesName.ContactUs}>
                                    Contact Us
                              </Link>
                        </li>
                        <li className="nav-item dropdown border-bottom-0">
                              <h4 className="nav-link__heading d-xl-none">Credit</h4>
                              <button className={["nav-link dropdown-toggle nav-link__price has-icon btn btn-primary",showTopUp && "show"].join(" ")} id="navbarDropdownCheckout" onClick={showOptionsHandler}>
                                    <span className="icon-bg icon-wallet"></span>
                                    <span className="nav-link__text">£{parseFloat(user.ct_wallet_balance).toFixed(2)}</span>
                              </button>
                              <ul className={["dropdown-menu dropdown-menu__wallet dropdown-menu-end text-xl-center",showTopUp && "show"].join(" ")} aria-labelledby="navbarDropdownCheckout">
                                    <li>
                                          <Link className="dropdown-item" onClick={topUpHandler} to={routesName.MyWalletTopUp}>
                                                Top up
                                          </Link>
                                    </li>
                                    <li>
                                          <Link className="dropdown-item" to={routesName.MyWallet}>
                                                History
                                          </Link>
                                    </li>
                                    <li>
                                          <Link className="dropdown-item" to={routesName.GiftVouchers}>
                                                Gift Vouchers 
                                          </Link>
                                    </li>
                                    <li>
                                          <div className="dropdown-item" onClick={logOutHandler} style={{cursor :"pointer"}}>
                                                Log Out
                                          </div>
                                    </li>
                              </ul>
                        </li>
                 </React.Fragment>
      )
}

export default AuthenticatedMenus;
