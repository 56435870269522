import { api } from "./../../../Services/Api";
import { referralCode } from "./referralCode"

export const referralApi = api.injectEndpoints({
            endpoints: build => ({
                  referralCode :referralCode(build),
      }),
      overrideExisting: true
})

export const { useReferralCodeMutation } = referralApi