
import { validation } from "./../resources/en";
import { NameConstants } from "../resources/NameConstants";

const otpVerificationValidationRules=(value, type) =>{

      const textMessage = validation;

      let isValid = true;
      let validationMessage = "";

      if (!type) {
          return true;
      }

      if(type === NameConstants.PhoneNumber){

            let isnum = /^\d+$/.test(value);
            if(value.trim()=== "07"){
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
            if(value.length !== 11 || !isnum ){
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
            if(value.substring(0, 3) === "070") {
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
             
      }

      if(type === NameConstants.OTP){

            
            if(!value.trim() || value.trim()=== "")
            {
                  isValid = false;
                  validationMessage = textMessage[type].required;;
                  return {isValid,validationMessage};
            }
            else if(value.trim().length > 5 || value.trim().length < 5)
            {
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
      }

      if(type === NameConstants.OTPEmail || type === NameConstants.OTPForgotPassword){
            if(!value.trim() || value.trim()=== "")
            {
                  isValid = false;
                  validationMessage = textMessage[type].required;;
                  return {isValid,validationMessage};
            }
            else if(value.trim().length > 5 || value.trim().length < 5)
            {
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
      }

      return {isValid,validationMessage};
}

export default otpVerificationValidationRules;