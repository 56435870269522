export const addUserWalletBalance =  build =>
      build.mutation({
            query: (body) =>  ({
                  url: '/wallet/add_user_wallet_balance',
                  method: 'POST',
                  body: body
            }),
      })

      // Changed with new one

     