import React, { useEffect } from  'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
import { HeadingPictureComponent } from "./../../../../Components";

// packages
// Actions & RTK query or mutations
import { useGetBookingDetailsMutation } from '../../../../Services/modules/BookingModule';
import { resetForAnimation, setTicketTypes } from "../../../../Store/BookingModule";
import { setBookingHandler } from "../../../../Store/EventDetail"
import { setAlert, setSpinner } from '../../../../Store/UI';

// Constants
import { errors, messages } from '../../../../resources/en';

const BookingSuccess = () => {

      const bookingModule     =     useSelector(state=>state.bookingModule);
      const user              =     useSelector(state=>state.user);
      const [getBookingDetails, { data, isSuccess, isLoading, isFetching, error }]  =    useGetBookingDetailsMutation();
      const dispatch = useDispatch();

      useEffect(()=>{

            dispatch(setSpinner({status:"request"}));
            getBookingDetails({booking_id : bookingModule.getBookingDetails.booking_id})

      },[getBookingDetails,bookingModule.getBookingDetails.booking_id, dispatch])

      // Handle Fetch getBookingDetails Data API Request
      useEffect(()=>{
            if(isSuccess){
                  dispatch(setSpinner({status:""}));
                  if(data.status === "1"){
                        
                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}))
                  }
            }
            
            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || errors.NotFound404}))
                  dispatch(setSpinner({status:""}));
            }     

      },[data, isSuccess, isLoading, isFetching, error,dispatch])

      const closeBookingHandler = ()=> {
            dispatch(setBookingHandler({isBooking:false}))
            dispatch(resetForAnimation())
            if(!user.ct_web_view_session){
                  dispatch(setTicketTypes({ticketTypes : []}))
            }
      }


      const collectionInstructionHandler = (event)=>{
            event.preventDefault();
            if(!data){
                  return;
            }
            dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.data.collection_instruction}))
      }
     
      return (

            <div id="bookTicketsBookingSuccess" className="content content__booking-success tickets-drop-down text-center border-bottom d-flex">
                  {!user.ct_web_view_session &&<button type="button" className="btn-close btn-close__checkout btn-close__custom btn-close__absolute" aria-label="Close" onClick={closeBookingHandler}>
                  </button>}
                  <div className="content__header">
                        <div className="big-heading">
                              <h1 className="mb-0">You’re<br/>all<br/><span className="d-none d-xl-inline">&nbsp;</span>booked</h1>
                              {user.domain === "" && <>
                              <HeadingPictureComponent media="(min-width: 768px)" 
                              className="ticket-blue"
                              dataScroll
                              srcSet="ticket-booking-success-blue.svg"
                              src="ticket-booking-success-blue-sm.svg"
                              />

                              <HeadingPictureComponent media="(min-width: 768px)" 
                              className="ticket-red"
                              dataScroll
                              srcSet="ticket-booking-success-red.svg"
                              src="ticket-booking-success-red-sm.svg"
                              />

                              <HeadingPictureComponent media="(min-width: 768px)" 
                              className="ticket-yellow"
                              dataScroll
                              srcSet="ticket-booking-success-yellow.svg"
                              src="ticket-booking-success-yellow-sm.svg"
                              />

                              <HeadingPictureComponent media="(min-width: 768px)" 
                              className="ticket-green"
                              dataScroll
                              srcSet="ticket-booking-success-green.svg"
                              src="ticket-booking-success-green-sm.svg"
                              />

                              <HeadingPictureComponent media="(min-width: 768px)" 
                              className="ticket-orange"
                              dataScroll
                              srcSet="ticket-booking-success-orange.svg"
                              src="ticket-booking-success-orange-sm.svg"
                              />

                              <HeadingPictureComponent media="(min-width: 768px)" 
                              className="ticket-aquamarine"
                              dataScroll
                              srcSet="ticket-booking-success-aquamarine.svg"
                              src="ticket-booking-success-aquamarine-sm.svg"
                              />
                              </>}
                        </div>
                  </div>
                  <div className="content__body min-height-0">
                        <h4 className="event__name red">{data && data.data.tickets+'x'} {data && data.data.event_name}</h4>
                        <p className="event__date">{data && data.data.start_date+' - '}  {data && data.data.start_time}</p>
                        <div className="buttons-wrapper">
                              <button className="btn btn-primary mx-auto" onClick={collectionInstructionHandler}>Read collection instructions</button>
                        </div>
                  </div>
                  {/* <!-- /.content__body --> */}
            </div>
                                          
      )
}

export default BookingSuccess;
