import React from 'react';

// external css
// redux and react hooks
import { useDispatch } from 'react-redux';

// child component
import { HeadingPictureComponent } from '../../../Components';

// packages
// Actions & RTK query or mutations
import { setWalletParams } from '../../../Store/Wallet';

// Constants

const Step3 = ({ webView }) => {

      const dispatch = useDispatch();

      const backHandler = ()=> {
            dispatch(setWalletParams({isTopUpOpen : false,step : 'step1',paymentMethod : "",topUpAmount : 0}))
      }
      
      return (
            <div id="walletTopupSuccess" className="content content__wallet-topup-success tickets-drop-down text-center d-flex">

                  {!webView && <button type="button" className="btn-close btn-close__custom btn-close__absolute" aria-label="Close" onClick={backHandler}>
                  </button>}

                  <div className="content__header">
                        <div className="big-heading">
                              <h1 className="mb-0">
                                    Credit<br/>Topped<br/>&nbsp;Up
                              </h1>
                              <HeadingPictureComponent media="(min-width: 768px)" className="ticket-blue"
                              dataScroll
                              srcSet="ticket-topup-success-blue.svg"
                              src="ticket-topup-success-blue-sm.svg"
                              />

                              <HeadingPictureComponent media="(min-width: 768px)" className="ticket-red"
                              dataScroll
                              srcSet="ticket-topup-success-red.svg"
                              src="ticket-topup-success-red-sm.svg"
                              />


                              <HeadingPictureComponent media="(min-width: 768px)" className="ticket-yellow"
                              dataScroll
                              srcSet="ticket-topup-success-yellow.svg"
                              src="ticket-topup-success-yellow-sm.svg"
                              />

                              <HeadingPictureComponent media="(min-width: 768px)" className="ticket-green"
                              dataScroll
                              srcSet="ticket-topup-success-green.svg"
                              src="ticket-topup-success-green-sm.svg"
                              />

                              <HeadingPictureComponent media="(min-width: 768px)" className="ticket-orange"
                              dataScroll
                              srcSet="ticket-topup-success-orange.svg"
                              src="ticket-topup-success-orange-sm.svg"
                              />

                              <HeadingPictureComponent media="(min-width: 768px)" className="ticket-aquamarine"
                              dataScroll
                              srcSet="ticket-topup-success-aquamarine.svg"
                              src="ticket-topup-success-aquamarine-sm.svg"
                              />

                        </div>
                  </div>
                  <div className="content__body min-height-0">
                        <br/>
                        <div className="buttons-wrapper">
                              <button type="button" id="btnBackToStep2" className="btn btn-primary mx-auto" onClick={backHandler}>Go back</button>
                        </div>
                  </div>
                  {/* <!-- /.content__body --> */}
            </div>
      )
}

export default Step3
