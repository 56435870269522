export const fetchApplePayIntent =  build =>
      build.mutation({
            query: (body) =>  ({
                  url: '/create_payment_intent',
                  method: 'POST',
                  body: body
            }),
      })

      
      // Changed with new one

     