
import { validation } from "../resources/en";
import { NameConstants } from "../resources/NameConstants";

const settingsChangeEmailValidation=(value,type,oldEmail,newEmail) =>{
      let isValid = true;
      let validationMessage = "";
      
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if(type === NameConstants.OldEmail){
            if(value.trim()=== "") {
                  isValid =false;
                  validationMessage = validation.email.required;
            }
            else if(!re.test(String(value).toLowerCase())) {
                  isValid=false;
                  validationMessage = validation.email.invalid;
            }
      }
 
      if(type === NameConstants.NewEmail){
            if(value.trim()=== "" || !value) {
                  isValid =false;
                  validationMessage = validation[type].required;
            }
            else if(!re.test(String(value).toLowerCase())) {
                  isValid=false;
                  validationMessage = validation[type].invalid;
            } else if ( value === oldEmail){
                  isValid=false;
                  validationMessage = validation[type].diffEmail;
            }
      }

      if(type === NameConstants.RepeatNewEmail){
            if(value.trim()=== "" || !value) {
                  isValid =false;
                  validationMessage = validation[type].required;
            } else if(value !== newEmail) {
                  isValid =false;
                  validationMessage = validation[type].invalid;
            }
             
      }

      return {isValid,validationMessage};
}

export default settingsChangeEmailValidation;