import { api } from "./../../../Services/Api";
import { submitRegisterData } from "./submitRegisterData"
import { getLinkResponse } from "./getLinkResponse";
import { updateEmailStatus } from "./updateEmailStatus";

export const registerApi = api.injectEndpoints({
            endpoints: build => ({
            submitRegisterData :submitRegisterData(build),
            getLinkResponse : getLinkResponse(build),
            updateEmailStatus : updateEmailStatus(build)

      }),
      overrideExisting: true
})

export const { useSubmitRegisterDataMutation, useGetLinkResponseMutation, useUpdateEmailStatusMutation } = registerApi