import { api } from "./../../../Services/Api";
import { positiveReviewSystem } from "./positiveReviewSystem"

export const reviewApi = api.injectEndpoints({
            endpoints: build => ({
            positiveReviewSystem : positiveReviewSystem(build),

      }),
      overrideExisting: true
})

export const {  usePositiveReviewSystemMutation } = reviewApi