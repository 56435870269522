/* eslint-disable */
import React, { useEffect, useState } from 'react'

// external css
import info from "./../../assets/images/info_icon.svg"
import ageRestriction from "./../../assets/images/age_restriction_icon.svg"
import "./ETickets.css"

// redux and react hooks
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// child component
import Footer from '../../Layouts/Footer/Footer'
import QRModal from '../../Ui/Modal/QRModal';

// packages
import { RWebShare } from "react-web-share";
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { setAlert, updateLocoScroll } from '../../Store/UI';
import { useLazyBookingReleaseSeatsQuery } from '../../Services/modules/Others'

// Constants
import { errors, messages } from '../../resources/en';
import { Config } from '../../Config';
import { routesName } from '../../resources/RoutesName';
import { MetaData, MetaDataAj, MetaDataTfm } from '../../resources/MetaData';


const ETickets = () => {
      
    const [btnPopup, setBtnPopup]           =     useState(false)
    const [scroll,setScroll]                =     useState(false);
    const [qrModal, setqrModal]             =     useState("")

    const [singleTicket, setSingleTicket]   =     useState(false)

    const { ticketId, serviceId }           =     useParams();
    const dispatch                          =     useDispatch()
    const domain                            =     useSelector(state=>state.user.domain)

    const [bookingReleaseSeats , { data, isSuccess, error}] = useLazyBookingReleaseSeatsQuery()

    useEffect(()=>{
        if(serviceId) {
            setSingleTicket(true)
        } else {
            setSingleTicket(false)
        }
        bookingReleaseSeats({entity_id : ticketId, service_id : serviceId || ""})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[bookingReleaseSeats, ticketId])

    useEffect(()=>{
        if(scroll){
                dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
                setScroll(false)
        }
    },[scroll, dispatch])


    useEffect(()=>{

        if(isSuccess){
                if(data.status === "1") {
                    setScroll(true)
                } else {
                    setScroll(true)
                    dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.message || data.error || errors.NotFound404 }))
                }
        } 
        
        if(error){
            dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data, error, isSuccess])

    const downloadTicketFromScannerHandler = (pdfURL)=> {
        let download_link = `${pdfURL}`
        window.open(download_link, "_blank") || window.location.replace(download_link);
    }

    const downloadTicketHandler = ()=>{

        let download_url = data?.data?.download_url
        window.open(download_url, "_blank") || window.location.replace(download_url);
        setBtnPopup(!btnPopup)

    }

    let metaDataSite = MetaData
    if(domain === Config.DOMAIN_CONSTANT_TFM){
          metaDataSite = MetaDataTfm
    } else if(domain === Config.DOMAIN_CONSTANT_AJTIX){
          metaDataSite = MetaDataAj
    }

      return (
            <div className="scroll-animations position-relative" data-scroll-container>
                    {qrModal && <QRModal qrModal={qrModal} closeQRModal={()=>setqrModal("")}/>}
                  <main>
                        <section data-scroll-section className="section__feedback-report section__etickets py-0">
                            <Helmet>
                                <title>{metaDataSite.ETickets.title}</title>
                                <meta name="description" content={metaDataSite.ETickets.description} />
                                <meta name="keywords" content={metaDataSite.ETickets.keywords} />
                            </Helmet>
                             
                              <div className="container-fluid container__block container__feedback-report">
                                    <div className="row">
                                          <div className="col-md-12">
                                                {isSuccess && !data.data ? <>
                                                    <div className="content content__feedback-report mx-auto">
                                                        <div className="card card__event border-radius-10 flex-lg-row mb-0" style={{"minHeight":"45vh","display":"flex","justifyContent":"center","alignItems":"center"}}>
                                                            <div className="" style={{"text-align": "center","color":"var(--bs-red)"}}>
                                                                    <h2 className='no-uptake-event' style={{"margin":"0px"}}>Currently, there are no tickets found </h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : 
                                                <div className="content content__feedback-report mx-auto">
                                                      <div className="content__header content__header--template text-center overflow-hidden">
                                                            {data  ? <h4>Order: <strong>{data?.data?.vendor}-{data?.data?.booking_reference_id}</strong></h4> :  
                                                            <h4 className='dummy-order-title'></h4>}
                                                      </div>

                                                    <div className="main_body">
                                                        <div className="report_section">

                                                            <div className="ticket_container">
                                                                <div className="header_container">
                                                                    <div className="image_container">
                                                                        {data ? <img src={data?.data?.event_image} alt={data?.data?.event_title}/> :
                                                                        <div className='dummy-event-image'></div> }
                                                                    </div>
                                                                    <div className="title_container">
                                                                        {data ? <h4>{data?.data?.event_title}</h4> : 
                                                                        <h4 className='dummy-event-title'></h4>}
                                                                        {data ? <p>{data?.data?.time}</p> : <p className='dummy-event-content'></p>}
                                                                        {data  ? <p>{data?.data?.venue_name}</p> : <p className='dummy-event-content'></p>}
                                                                        {data ? <p>{data?.data?.tickets} {data?.data?.tickets > 1 ? "Tickets" : "Ticket"}</p> : <p className='dummy-event-content'></p> }
                                                                    </div>
                                                                </div>
                                                                 
                                                                <div className="banner_container">
                                                                    {data ? 
                                                                    <div  className="special_notes">
                                                                        {/* <div id="icon">
                                                                            <img src={info} alt='Info' />
                                                                        </div> */}
                                                                        <div className="text_container">
                                                                            <h6 className="sc-pFZIQ gbgfMs">Collection Instructions</h6>
                                                                            <div>
                                                                                <p>
                                                                                    {/* <strong> */}
                                                                                        {data?.data?.collection_instruction}
                                                                                    {/* </strong> */}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div> : <div className='special_notes dummy-special_notes'></div> }
                                                                    {(data?.data?.is_show_important_message === 1 && data) && 
                                                                    <div className='special_notes age_restrictions'>
                                                                        {/* <div id="icon">
                                                                            <img src={ageRestriction} alt=''/>
                                                                        </div> */}
                                                                        <div className="text_container">
                                                                            <h6 className="sc-pFZIQ gbgfMs" style={{color: "var(--bs-red)"}}>IMPORTANT</h6>
                                                                            <div>
                                                                                <p style={{color: "var(--bs-red)"}}>
                                                                                    {data?.data?.important_message}
                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                    </div>} 
                                                                    {!data && <div className='special_notes age_restrictions dummy-special_notes dummy-age_restrictions'></div> }
                                                                </div> 
                                                            </div>
                                                            <div className="qr_container">
                                                                <div className='download_btn_container'>
                                                                    <div className='btn-group'>
                                                                    
                                                                        {data ? <button className='btn btn-xl-lg btn-primary download-ticket-btn dropdown-toggle' onClick={downloadTicketHandler}>
                                                                            Download Tickets
                                                                            {/* <img src={arrowDown} alt='' /> */}

                                                                        </button> : <button className='btn btn-xl-lg btn-primary download-ticket-btn dummy-btn'></button> }
                                                                    </div>

                                                                </div>
                                                                <div className="button-card-wrapper">
                                                                    <div className={["row row-cols-1 row-cols-sm-2 row-cols-xl-2 gx-2 gy-2", singleTicket ? "" : "justify-content-left"].join(" ")}>
                                                                    {data ? data?.data?.scanners.map(el=>(

                                                                        <div className="col dummy-single_ticket_container" key={el.id}>
                                                                            <div className="action-cards">
                                                                                <h2>
                                                                                    {(el.seat_information) && <h6>{el.seat_information || ""} </h6>}
                                                                                </h2>
                                                                                <div className="action-cards-btns">
                                                                                    {/* <button className="actionbtn" onClick={()=>setqrModal(el.scanner)}>
                                                                                        <img src="https://etickets.londontheatredirect.com/lib/static/media/btn-apple.f3be1c3c.svg" alt="Apple Wallet"/>
                                                                                    </button>
                                                                                    <button className="actionbtn">
                                                                                        <img src="https://etickets.londontheatredirect.com/lib/static/media/btn-google.6d19b064.svg" alt="Google Pay" />
                                                                                    </button> */}
                                                                                    <button className="btn btn-xl-lg btn-primary actionbtn" onClick={()=>downloadTicketFromScannerHandler(el.pdf_url)}>
                                                                                        {/* <img src="https://etickets.londontheatredirect.com/lib/static/media/btn-pdf.ecfb6a0c.svg" onClick={()=>downloadTicketFromScannerHandler(el.pdf_url)} alt="Pdf"/> */}
                                                                                        Download PDF
                                                                                    </button>
                                                                                </div>
                                                                                <div className="qr-blk">
                                                                                    <img src={el.scanner} alt="qr"/>
                                                                                </div>
                                                                                <RWebShare
                                                                                    data={{
                                                                                    // text: `${el.level} ${el.seat_no}`,
                                                                                    url: `${el.share_url}`,
                                                                                    title: `${el.level} ${el.seat_no}`,
                                                                                    }}
                                                                                    onClick={() => console.log("shared successfully!")}
                                                                                >
                                                                                    <button className="sharebtn">
                                                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M13.12 17.023l-4.199-2.29a4 4 0 1 1 0-5.465l4.2-2.29a4 4 0 1 1 .959 1.755l-4.2 2.29a4.008 4.008 0 0 1 0 1.954l4.199 2.29a4 4 0 1 1-.959 1.755zM6 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm11-6a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path></g></svg>
                                                                                    </button>
                                                                                </RWebShare>
                                                                            </div>
                                                                        </div>
                                                                    )) : (
                                                                        <div className="col">
                                                                            <div className="action-cards dummy-action-cards">
                                                                                <h2>
                                                                                    
                                                                                </h2>
                                                                                <div className="action-cards-btns">
                                                                                    <button className="btn btn-xl-lg btn-primary actionbtn">
                                                                                    </button>
                                                                                </div>
                                                                                <div className="qr-blk">
                                                                                </div>
                                                                                <button className="sharebtn">
                                                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M13.12 17.023l-4.199-2.29a4 4 0 1 1 0-5.465l4.2-2.29a4 4 0 1 1 .959 1.755l-4.2 2.29a4.008 4.008 0 0 1 0 1.954l4.199 2.29a4 4 0 1 1-.959 1.755zM6 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm11-6a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path></g></svg>

                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                    )}
                                                                    </div>
                                                                </ div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="help-link-block">
                                                        <Link to={routesName.ContactUs} className="help-link">Need help with your tickets?</Link>
                                                    </div>
                                                     
                                                </div>}
                                          </div>
                                    </div>
                              </div>

                        </section>
                  </main>

                  <Footer />
            </div>
      )
}

export default ETickets