import React from 'react'

const ArticalQuestionsDummy = () => {


      return (
            <article className="report_article main_q1 dummy">
                  <div className="ct_report_title">
                        <div className="ct_report_sub_title">
                              <h2 className="ct_rt_text"><strong></strong></h2>
                              <h3 className="ct_rt_sub_text"><span className="count_q1"> </span><span></span></h3>
                        </div>
                  </div>
                  <div className="ct-chart">
                        <div className="ct-chart-section setval_q1">
                            <div className="ct_bar">
                                <div className="ct_bar_section"></div>
                                <mark className="ct_report_question"></mark>
                                <div className="ct_percentage">
                                        <span className="per_q1_A"></span> 
                                        <mark className="ct_report_question_sub">
                                                <span className="total_review  review_q1_A"></span>
                                        </mark>
                                </div>
                                <div className="ct_progress">
                                        <div className="ct_progress_sub progress_q1_A">
                                        </div>
                                </div>      
                            </div>
                            <div className="ct_bar">
                                <div className="ct_bar_section"></div>
                                <mark className="ct_report_question"></mark>
                                <div className="ct_percentage">
                                        <span className="per_q1_A"></span> 
                                        <mark className="ct_report_question_sub">
                                                <span className="total_review  review_q1_A"></span>
                                        </mark>
                                </div>
                                <div className="ct_progress">
                                        <div className="ct_progress_sub progress_q1_A">
                                        </div>
                                </div>      
                            </div>
                            <div className="ct_bar">
                                <div className="ct_bar_section"></div>
                                <mark className="ct_report_question"></mark>
                                <div className="ct_percentage">
                                        <span className="per_q1_A"></span> 
                                        <mark className="ct_report_question_sub">
                                                <span className="total_review  review_q1_A"></span>
                                        </mark>
                                </div>
                                <div className="ct_progress">
                                        <div className="ct_progress_sub progress_q1_A">
                                        </div>
                                </div>      
                            </div>
                            <div className="ct_bar">
                                <div className="ct_bar_section"></div>
                                <mark className="ct_report_question"></mark>
                                <div className="ct_percentage">
                                        <span className="per_q1_A"></span> 
                                        <mark className="ct_report_question_sub">
                                                <span className="total_review  review_q1_A"></span>
                                        </mark>
                                </div>
                                <div className="ct_progress">
                                        <div className="ct_progress_sub progress_q1_A">
                                        </div>
                                </div>      
                            </div>
                        </div>
                  </div>
            </article>
      )
}

export default ArticalQuestionsDummy
