import React from 'react';
import Backdrop from '../Backdrop';

const QRModal = (props) => {

    const closeHandler = ()=> {
        props.closeQRModal()
    }

      return (
                  <React.Fragment>
                        <div className={["modal modal__form-modal modal__almost-done modal__QRModal show fade",(props.status === "entering" || props.status === "entered") && "modalOpen",(props.status === "exiting" || props.status === "exited") && "modalClosed"].join(" ")} id="modalAlmostDone" style={{"display":"block"}} tabIndex="-1" aria-hidden="true" onClick={closeHandler}>
                              <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                          <div className="modal-body d-flex">
                                                <div className="info-card-wrapper bg-light-blue">
                                                    <div className="info-card-icon">
                                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" color="#009EC9" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{"color": "rgb(0, 158, 201);"}}><path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
                                                    </div>
                                                    <div className="info-card-content">
                                                        <p>Simply scan the QR code with your iPhone. Please note that each ticket must be added separately.</p>
                                                    </div>
                                                </div>
                                                <div className="qr-blk mt-3">
                                                    <img src={props.qrModal} alt="qr" />
                                                </div>
                                                <div className="modal-btn d-flex justify-content-center mt-4">
                                                    <button className="actionbtn">
                                                        <img src="https://etickets.londontheatredirect.com/lib/static/media/btn-apple.f3be1c3c.svg" alt="Apple Wallet"/>
                                                    </button>
                                                </div>
                                          </div>
                                          {/* <!-- /.modal-body --> */}
                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>
                        <Backdrop />
                  </React.Fragment>
      )
}

export default QRModal;
