const options = {
      AboutUsOptions:{
            center: true,
            autoplay: true,
            // autoheight: true,
            loop: true,
            dots: false,
            autoplayTimeout:2500,
            responsive:{
                  0:{
                        autoWidth: true,
                        // autoWidth sets a max-width for .card__testimonial at screen size min 1400px
                        items: 3
                  },
                  1000:{
                        // autoWidth: true,
                        // autoWidth sets a max-width for .card__testimonial at screen size min 1400px
                        items: 2
                  },
                  1200:{
                        autoWidth: true,
                        items: 5
                  },
            }

      },
      CurrentListingOptions :{
            autoplay: true,
            autoplayTimeout: 4000,
            center: true,
            loop: true,
            dots: false,
            margin: 20,
            autoWidth: true,
            responsive:{
                  0:{
                        items: 3
                  },
                  1200:{
                        items: 5
                  },
            }
      },
      SectionHomeHearoOptions :{
            autoplayTimeout: 10000,
            animateOut: 'fadeOut',
            // autoplayHoverPause :true,
            dots: false,
            // nav: true,
            nav: false,
            navContainer: '#owlHomeHeroNav',
            loop: true,
            responsive:{
                  0:{
                        items: 1
                  }
            }
      },
      ShowStickersOptions : {
            autoplay: true,
            center: true,
            dots: false,
            nav: false,
            loop: true,
            autoplayTimeout:2500,
            responsive:{
                  0:{
                        autoWidth:true,
                        items: 1
                  },
                  992:{
                        autoWidth:true,
                        items: 2
                  },
            }
      },
      UptakePageOptions : {
            autoplay: true,
            autoheight: true.toString(),
            loop: true,
            dots: false,
            responsive:{
                  0:{
                        items: 2
                  },
                  768:{
                        autoWidth: true,
                        center: true,
                        items: 3
                  },
                  1200:{
                        autoWidth: true,
                        center: true,
                        items: 4
                  },
            }
      },
      UptakePageBannerOptions : {
            autoplay: true,
            autoheight: true.toString(),
            loop: true,
            dots: false,
            margin: 10,
            items :1,
            autoplayHoverPause :true,
            autoplayTimeout : 6000
      },
      landingPageMobileOptions : {
            // autoplay: true,
            autoheight: true.toString(),
            // loop: true,
            dots: true,
            margin: 10,
            items :1,
            // autoplayHoverPause :true,
            // autoplayTimeout : 6000
      },
      SectionHomeCarousel :{
            autoplayTimeout: 10000,
            animateOut: 'fadeOut',
            dots: false,
            nav: false,
            margin: 0,
            loop: true,
            responsive:{
                  0:{
                        items: 1.25
                  },
                  391 : {
                        items : 1.25
                  },
                  767 : {
                        items : 2.20
                  },
                  820 : {
                        items : 2.30
                  },
                  991 : {
                        items : 2.10
                  },
                  1200 : {
                        items : 4.2
                  }
                  
            }
      },
      SectionHomeCarouselExternal :{
            autoplayTimeout: 10000,
            animateOut: 'fadeOut',
            dots: false,
            nav: false,
            margin: 0,
            loop: true,
            responsive:{
                  0:{
                        items: 2.5
                  },
                  391 : {
                        items : 2.5
                  },
                  767 : {
                        items : 3.75
                  },
                  1200 : {
                        items : 6
                  }
                  
            }
      },

}

export const { AboutUsOptions, CurrentListingOptions, SectionHomeHearoOptions, ShowStickersOptions, UptakePageOptions, UptakePageBannerOptions,landingPageMobileOptions, SectionHomeCarousel, SectionHomeCarouselExternal } = options;