import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import EmailPreferences from './EmailPreferences';
import SavedCards from "./SavedCards"
import ChangeEmail from './ChangeEmail';
import ChangeMobileNumber from './ChangeMobileNumber';
import ChangePassword from './ChangePassword';
import DeleteAccount from './DeleteAccount';

import { updateLocoScroll } from "./../../../Store/UI";
import { title } from '../../../resources/en';

const AccountSettingsDesktop = (props) => {

      const {ct_is_top_member, ct_is_change_password } = useSelector(state=>state.user)
      const [settings,setSettings] = useState(props.page || "ChangePassword")

      const dispatch = useDispatch();

      const chnageSettingsHandler = (event)=>{
            if(!ct_is_change_password){
                  setSettings(event.target.name)
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
            }
      } 

      return (
                  <div className="tab__my-account d-flex align-items-md-stretch mx-auto d-none d-md-flex">
                        <ul className="nav nav-tabs flex-column" id="myAccountTab" role="tablist">
                              <li className="nav-item" role="presentation">
                                    <button className={["nav-link",settings === "ChangePassword" && "active"].join(" ")} id="myAccntChangePassword-tab" name="ChangePassword" type="button" onClick={chnageSettingsHandler}>{title.changePassword}</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                    <button className={["nav-link",settings === "ChangeEmail" && "active"].join(" ")} id="myAccntChangeEmail-tab" type="button" name="ChangeEmail" onClick={chnageSettingsHandler}>{title.changeEmail}</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                    <button className={["nav-link",settings === "ChangeMobileNumber" && "active"].join(" ")} id="myAccntChangeMobileNum-tab" type="button" name="ChangeMobileNumber" onClick={chnageSettingsHandler}>{title.changeMobileNUmber}</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                    <button className={["nav-link",settings === "EmailPreferences" && "active"].join(" ")} id="myAccntEmailPreferences-tab" type="button" name="EmailPreferences" onClick={chnageSettingsHandler}>{title.emailPrefrences}</button>
                              </li>
                              {ct_is_top_member === 1 &&
                              <li className="nav-item" role="presentation">
                                    <button className={["nav-link",settings === "SavedCards" && "active"].join(" ")} id="myAccntSavedCards-tab" type="button" name="SavedCards" onClick={chnageSettingsHandler}>{title.savedCards}</button>
                              </li> }
                              <li className="nav-item" role="presentation">
                                    <button className={["nav-link",settings === "DeleteAccount" && "active"].join(" ")} id="myAccntDeleteAccount-tab" type="button" name="DeleteAccount" onClick={chnageSettingsHandler}>{title.deleteAccount}</button>
                              </li>
                        </ul>

                        <div className="tab-content w-100" id="myAccountTabContent">
                              {settings === "ChangePassword" && 
                              <ChangePassword/>}

                              {settings === "ChangeEmail" && 
                              <ChangeEmail/>}

                              {settings === "ChangeMobileNumber" &&
                              <ChangeMobileNumber/>}
                              
                              {settings === "EmailPreferences" &&
                              <EmailPreferences/>}

                              {settings === "SavedCards" &&
                              <SavedCards/>}

                              {settings === "DeleteAccount" &&
                              <DeleteAccount/>}
                        </div>
                        {/* <!-- /.tab-content --> */}

                  </div>
      )
}

export default AccountSettingsDesktop;
