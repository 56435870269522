import React from 'react';

// external css
// redux and react hooks
// child component
import BigHeading from "./BigHeading";
import ForVenues from "./ForVenues";
import ForMembers from "./ForMembers";
import ContainerBigHeading from "./ContainerBigHeading";

// packages
// Actions & RTK query or mutations
// Constants

const SectionWhatWeDo = () => {

      return (
          
            <section data-scroll-section className="section__what-we-do border-bottom">

                  <BigHeading/>
                  {/* <!-- /.container-fluid --> */}

                  <ForVenues/>
                  {/* <!-- /.container-fluid.container__for-venues  --> */}

                  <ForMembers/>
                  {/* <!-- /.container-fluid.container__for-members  --> */}

                  <ContainerBigHeading/>
                  {/* <!-- /.container-fluid  --> */}

            </section>
      )
}

export default SectionWhatWeDo;
