import React, { useEffect, useState } from 'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
// packages
// Actions & RTK query or mutations
import { useContactWebBookingsMutation } from "../../../../Services/modules/Bookings";
import { reset, cancelBookingSuccess } from '../../../../Store/CurruntBookings';
import { setAlert } from '../../../../Store/UI';

// Constants
import { checkValidationCancelBookings } from "./../../../../Services";
import { errors, actions, messages } from "../../../../resources/en";
import { NameConstants } from '../../../../resources/NameConstants';

const Step4 = () => {

      const user = useSelector(state=>state.user)
      const curruntBookings = useSelector(state=>state.curruntBookings);
      const [state,setState] = useState({value:"",error:false,errorMessage:""});
      const [contactWebBookings, { data, isSuccess, isLoading, isFetching, error}] = useContactWebBookingsMutation();

      const dispatch = useDispatch();

      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1"){
                        // dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:data.error || data.message }))
                        dispatch(cancelBookingSuccess({step : 'step5'}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
                  dispatch(reset())
            }

      },[data, isSuccess, isLoading, isFetching, error, dispatch])

      const changeHandler = (event)=> {
            setState(prevState=>{
                  return {
                        ...prevState,
                        value:event.target.value
                  }
            });
      }

      const submitRequestHandler = (e)=> {
            e.preventDefault();

            let copyObject = {...state};
            const error = checkValidationCancelBookings(state.value,NameConstants.bookingRequestMessage);

            if(!error.isValid){
                  copyObject = {...copyObject,error:!error.isValid,errorMessage:error.validatingMessage}
                  setState(copyObject);
                  return;
            }

            const data = { 
                  email:user.ct_access_email, 
                  name:user.ct_access_name, 
                  mobile:user.ct_access_mobilenumber, 
                  message:state.value,
                  RefId:curruntBookings.bookingRef,
                  event_title:curruntBookings.event_title,
                  start_date:curruntBookings.start_date,
                  start_time:curruntBookings.start_time 
            }

            contactWebBookings(data)
      }

      const clearErrorHandler = ()=> {
            setState(prevState=>{
                  return {
                        ...prevState,
                        error:false,
                        errorMessage:""
                  }
            })
      }

      return (

            <div id="manageBookingOtherRequests" className="content content__manage-booking-other-requests border-bottom d-flex">
                  <div className="content__body">
                        <h4 className="heading">
                              {messages.cancelBookingOtherRequest}
                        </h4>
                        <div className="form-group">
                              <textarea className="form-control" onChange={changeHandler} value={state.value} onFocus={clearErrorHandler} disabled={isLoading}></textarea>
                              {state.error && <p className="form-label-error">{state.errorMessage}</p>}
                        </div>
                        <div className="buttons-wrapper">
                              <button id="btnManageBookingSendRequest" type="button" className="btn btn-primary" disabled={isLoading} onClick={submitRequestHandler}>
                                    {actions.sendRequest}
                              </button>
                        </div>
                  </div>
                  {/* <!-- /.content__body --> */}
            </div>
      )
}

export default Step4;
