import React from 'react'

const PictureComponent = (props) => {

      return (
            <picture className={["ticket",props.className].join(" ")}>
                  {/* <source media="(min-width: 992px)" srcSet="assets/images/tickets/ticket-home-carousel-item1-blue.svg"/> */}
                  <source media="(min-width: 992px)" srcSet={require(`../assets/images/tickets/${props.src}`)}/>
                  <img src={require(`../assets/images/tickets/${props.src}`)} alt=""/>
                  {/* <img src="assets/images/tickets/ticket-home-carousel-item1-blue-sm.svg" alt=""/> */}
            </picture>
      )
}

export default PictureComponent
