
import { validation } from "./../resources/en";
import { NameConstants } from "../resources/NameConstants";

const registerValidationRules=(value, type,password) =>{

      const textMessage = validation;

      let isValid = true;
      let validationMessage = "";

      if (!type) {
          return true;
      }
      

      if(type === NameConstants.FirstName){
            if(value.trim() ==="")
            {
                  isValid = false;
                  validationMessage = textMessage[type].required;
            }
            else if(value.length <2)
            {
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type=== NameConstants.LastName){

            if(value.trim() === ""){
                  isValid = false;
                  validationMessage = textMessage[type].required;
            }
            else if(value.length <2)
            {
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }


      if(type === NameConstants.Email){

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!value){
                  isValid = false;
                  validationMessage = textMessage[type].required;
            }
            else if(!re.test(String(value).toLowerCase()) || !value){
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type === NameConstants.PhoneNumber){

            let isnum = /^\d+$/.test(value);
            if(value.trim() === "" || !value) {
                  isValid =false;
                  validationMessage =  textMessage[type].required;
                  return {isValid,validationMessage};
            }
            if(value.trim()=== "07"){
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
            if(value.length !== 11 || !isnum ){
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
            if(value.substring(0, 3) === "070") {
                  isValid =false;
                  validationMessage =  textMessage[type].invalid;
                  return {isValid,validationMessage};
            }
             
      }

      if(type === NameConstants.PassWord){
           
            if(value.trim()=== ""){
                  isValid =false;
                  validationMessage = textMessage[type].required;
            }
            else if(value.length < 6){
                  isValid=false;
                  validationMessage = textMessage[type].minLength;
            }
            
      }

      if(type === NameConstants.ConfirmPassword){
            if(!value){
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
            if(value !== password){
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type=== NameConstants.CheckReceiveAlerts){
            if(value === false){

                  isValid=false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type=== NameConstants.CheckAgreeTOS){

            if(value === false){
                  isValid=false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      if(type=== NameConstants.CheckAgreeMG){
            if(value === false){
                  isValid=false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      return {isValid,validationMessage};
}



export default registerValidationRules;