import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Step1 from './Steps/Step1';
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";

import { reset } from '../../../Store/CurruntBookings';

const ManageBooking = () => {

      const curruntBookings = useSelector(state=>state.curruntBookings);
      const dispatch = useDispatch();
      
      const closeCancleBookingHandler = ()=> {
            dispatch(reset());
      }     

      return (
                  <div className={["slidein-wrapper show"].join(" ")}>
                        <div className="slidein slidein__manage-booking">

                              <div className="content__slidein content-scrollable">

                                    <form>

                                          <div className="content content__checkout content__manage-booking">

                                                <div className="content__header border-bottom position-relative">
                                                      <button type="button" className="btn-close btn-close__custom btn-close__absolute" aria-label="Close" onClick={closeCancleBookingHandler}>
                                                      </button>
                                                      <div className="content my-auto">
                                                            <h4 className="content__title my-auto mb-0">Manage your booking</h4>
                                                      </div>
                                                </div>
                                                {/* <!-- /.content__header --> */}

                                                {/* <!-- SCREEN 1: Choose from options --> */}
                                                {curruntBookings.step === "step1" &&
                                                <Step1/>}

                                                {/* <!-- SCREEN 2: Manage booking reduce tickets --> */}
                                                {curruntBookings.step === "step2" &&
                                                <Step2/>}

                                                {/* <!-- SCREEN 3: Manage booking cancel booking --> */}
                                                {curruntBookings.step === "step3" &&
                                                <Step3/>}

                                                {/* <!-- SCREEN 4: Manage booking other requests --> */}
                                                {curruntBookings.step === "step4" &&
                                                <Step4/>}

                                                {/* <!-- LAST SCREEN :  Other requests received --> */}
                                                {curruntBookings.step === "step5" &&
                                                <Step5/>}

                                          </div>
                                          {/* <!-- /.content__content__manage-booking --> */}

                                    </form>

                              </div>
                              {/* <!-- /.content__slidein --> */}

                        </div>
                        {/* <!-- /.slidein --> */}

                  </div>
      )
}

export default ManageBooking;
