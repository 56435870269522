
export const WhiteThemeAboutUs=(pathname,locoScroll) =>{


      if(pathname.includes("about-us")) {

            const contentNHSCTHeight = document.querySelector("#sectionNHSCT .content__NHSCT").offsetHeight

            locoScroll.on('scroll', (position) =>  {
                  if(document.querySelectorAll('.main__about-us').length > 0) {
                        if ((position.scroll.y) > contentNHSCTHeight) {
                              document.querySelector('.main__about-us .spy-scrollbar').classList.remove('theme-white');
                        }else {
                              document.querySelector('.main__about-us .spy-scrollbar').classList.add('theme-white');
                        }
                  }
            });
      }

}

