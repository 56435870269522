import { api } from "./../../../Services/Api";
import { fetchEventDetail } from "./fetchEventDetail";

export const eventDetailApi = api.injectEndpoints({
            endpoints: build => ({
            fetchEventDetail :fetchEventDetail(build),

      }),
      overrideExisting: true
})

export const { useFetchEventDetailMutation } = eventDetailApi