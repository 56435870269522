import { api } from "./../../../Services/Api";
import { getCurrentListing } from "./getCurrentListing";
import { eventNotifymeWeb } from "./eventNotifyWeb";
import { removeEventNotifymeWeb } from "./removeEventNotifyWeb"

export const currentListingApi = api.injectEndpoints({
            endpoints: build => ({
            getCurrentListing :getCurrentListing(build),
            eventNotifymeWeb:eventNotifymeWeb(build),
            removeEventNotifymeWeb:removeEventNotifymeWeb(build),
      }),
      overrideExisting: true
})

export const { useGetCurrentListingMutation, useEventNotifymeWebMutation, useRemoveEventNotifymeWebMutation } = currentListingApi