import React from 'react'

const UptakeCardDummy = (props) => {
      return (
            <div className="card card__uptake card__uptake--thank-you border-0 bg-primary border-radius-10 uptake-card__dummy" style={{marginTop:props.margin}}>
           
            </div>
      )
}

export default UptakeCardDummy
