import React from 'react'

const HeroBody = (props) => {
      return (
            <div className="hero-body">
                  <p className="lead">
                        {props.children}
                  </p>
            </div>
      )
}

export default HeroBody
