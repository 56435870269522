import React from "react";
import ChangeEmailForm from "../Components/ChangeEmailForm";

const ChangeEmail = ()=>{


      return (
                  <div className="tab-pane tab-pane__ChangeEmail fade show active" id="myAccntChangeEmail" role="tabpanel" aria-labelledby="myAccntChangeEmail-tab">
                                          
                        <div className="tab-pane__body">

                              <div className="content">
                                    <h4 className="heading">Change Email</h4>
                                    <ChangeEmailForm />
                              </div>
                              {/* <!-- /.content --> */}

                        </div>
                        {/* <!-- /.tab-pane__body --> */}
                  </div>
      )
}

export default ChangeEmail;