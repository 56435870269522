import { api } from "./../../../Services/Api";
import { getHistory } from "./getHistory";
import { addUserVoucher } from "./addUserVoucher";
import { widgetSession  } from "./widgetSession";
import { getWidgetSession } from "./getWidgetSession";

export const giftVoucherApi = api.injectEndpoints({
      endpoints: build => ({
            getVoucherHistory : getHistory(build),
            addUserVoucher : addUserVoucher(build),
            widgetSession: widgetSession(build),
            getWidgetSession : getWidgetSession(build)
      }),
      overrideExisting: true
})

export const {  useLazyGetVoucherHistoryQuery, useAddUserVoucherMutation, useWidgetSessionMutation, useLazyGetWidgetSessionQuery } = giftVoucherApi;