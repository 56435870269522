import { NameConstants } from "./NameConstants"

//  Saved Card Page
export const ContactUsObject = {
            [NameConstants.UserCat]:{
                  "step": NameConstants.UserCat,
                  "question": "How can we help you?",
                  "back_on":"1",
                  "options": [
                        {
                              "option_id": "2",
                              "content": "I'm interested in listing a show",
                              "jump_on": NameConstants.OrganizerShowQue
                        },
                        {
                              "option_id": "1",
                              "content": "anything else",
                              "jump_on": NameConstants.MemberBookingQue,
                        }
                  ],
            },
            [NameConstants.MemberBookingQue]:{
                  "step": NameConstants.memberBookingQue,
                  "question": "Do you have a question about a booking?",
                  "back_on": NameConstants.UserCat,
                  "options": [
                        {
                              "option_id": "1",
                              "content": "Yes",
                              "auth_jump_on": NameConstants.MemberSelectShow,
                              "jump_on":NameConstants.MemberLogin,
                        }, 
                        {
                              "option_id": "2",
                              "content": "No",
                              "jump_on": NameConstants.MemberFaq,
                        }
                  ],
            },
            [NameConstants.MemberLogin]:{
                  "step": NameConstants.MemberLogin,
                  "back_on": NameConstants.MemberBookingQue,
                  "jump_on": NameConstants.MemberSelectShow,
            },
            [NameConstants.MemberSelectShow]:{
                  "step": NameConstants.MemberSelectShow,
                  "back_on": NameConstants.MemberLogin,
                  "jump_on": NameConstants.MemberEventDetail,
            },
            [NameConstants.MemberEventDetail]:{
                  "step": NameConstants.MemberEventDetail,
                  "back_on": NameConstants.MemberSelectShow,
                  "jump_on": NameConstants.UserCat,
            },
            [NameConstants.MemberFaq]:{
                  "step": NameConstants.MemberFaq,
                  "back_on": NameConstants.MemberBookingQue,
                  "jump_on": NameConstants.MemberContactForm,
            },
            [NameConstants.MemberContactForm]:{
                  "step": NameConstants.MemberContactForm,
                  "back_on": NameConstants.MemberFaq,
                  "jump_on": NameConstants.UserCat,
            },
            [NameConstants.OrganizerShowQue]:{
                  "step": NameConstants.OrganizerShowQue,
                  "question": "Do you have a show in mind?",
                  "back_on": NameConstants.UserCat,
                  "options": [
                        {
                              "option_id": "1",
                              "content": "Yes",
                              "jump_on": NameConstants.OrganizerShowForm,
                        }, 
                        {
                              "option_id": "2",
                              "content": "No",
                              "jump_on": NameConstants.OrganizerSimpleForm,
                        }
                  ],
            },
            [NameConstants.OrganizerShowForm]:{
                  "step": NameConstants.OrganizerShowForm,
                  "back_on": NameConstants.OrganizerShowQue,
                  "jump_on": NameConstants.UserCat,
            },
            [NameConstants.OrganizerSimpleForm]:{
                  "step": NameConstants.OrganizerSimpleForm,
                  "back_on":NameConstants.OrganizerShowQue,
                  "jump_on": NameConstants.UserCat,
            },
            
}


export const ContentUserCatArray = [
      NameConstants.UserCat,
      NameConstants.MemberBookingQue,
      NameConstants.MemberLogin,
      NameConstants.OrganizerShowQue,
      NameConstants.OrganizerShowForm,
      NameConstants.OrganizerSimpleForm

]

export const ContentContactCluesArray = [
      NameConstants.UserCat,
      NameConstants.MemberBookingQue,
      NameConstants.OrganizerShowQue,

]

export const ContentBodyQueCatArray = [
      NameConstants.UserCat,
      NameConstants.MemberBookingQue,
      NameConstants.MemberLogin,
      NameConstants.OrganizerShowQue,
      NameConstants.OrganizerShowForm,
      NameConstants.OrganizerSimpleForm
]

export const userCatTextArray = [
      NameConstants.OrganizerShowQue,
      NameConstants.OrganizerSimpleForm,
      NameConstants.OrganizerShowForm
]

export const backButtonShowArray = [
      NameConstants.MemberBookingQue,
      NameConstants.OrganizerShowQue
]

