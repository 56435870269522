import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
      step:'step1',
      paymentMethod:'',
      amount: "",
      spinner : "request",
      sessionId : "",
      GiftVoucherTerms : false
}

const slice = createSlice({
      name: 'webViewPayment',
      initialState: initialState,
      reducers: {
            setPaymentParams : (state, { payload })=>{
                  state.step = payload.step
                  state.paymentMethod = payload.paymentMethod
                  state.amount = payload.amount
                  state.sessionId = payload.sessionId
            },
            setSpinner : (state, { payload }) => {
                  state.spinner = payload.status
            },
            setGiftVoucherTerms : (state, { payload })=> {
                  state.GiftVoucherTerms = payload.GiftVoucherTerms
            },
            reset: ()=> initialState
      },
      
})

export const { setPaymentParams, setSpinner, setGiftVoucherTerms, reset } = slice.actions

export default slice.reducer
