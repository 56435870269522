import { api } from "./../../../Services/Api";
import { appHomeConfig } from "./appHomeConfig";

export const homeApi = api.injectEndpoints({
            endpoints: build => ({
                  appHomeConfig : appHomeConfig(build)
      }),
      overrideExisting: true
})

export const {  useLazyAppHomeConfigQuery } = homeApi