import React from 'react';
import { Route } from 'react-router-dom';
import { routesName } from './RoutesName';

import { 
      SaveCard,
      WalletTopUp,
      BookingModule,
      MembershipGuidelines,
      WebViewPayment
} from './../Pages';

export default <>
                  <Route path={routesName.VoucherForGift} element={<WebViewPayment/>}/>
                  <Route path={routesName.SuccessVoucherForGift} element={<WebViewPayment/>}/>
                  <Route path={routesName.FailureVoucherForGift} element={<WebViewPayment/>}/>

                  <Route path={routesName.SavedCard} element={<SaveCard/>}/>
                  <Route path={routesName.SuccessCardStoreWebView} element={<SaveCard/>}/>
                  <Route path={routesName.FailureCardStoreWebView} element={<SaveCard/>}/>
                  
                  <Route path={routesName.WalletTopUp} element={<WalletTopUp/>}/>
                  <Route path={routesName.SuccessAddBalanceWebView} element={<WalletTopUp/>}/>
                  <Route path={routesName.FailureAddBalanceWebview} element={<WalletTopUp/>}/>

                  <Route path={routesName.BookingModule} element={<BookingModule/>}/>
                  <Route path={routesName.SuccessBookingWebView} element={<BookingModule/>}/>
                  <Route path={routesName.FailureBookingWebView} element={<BookingModule/>}/>

                  <Route path={routesName.WebMembershipGuidlines} element={<MembershipGuidelines/>}/>
            </>

