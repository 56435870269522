import React from 'react'

const HeadingPictureComponent = (props) => {


      return (
            <picture>
                  <source media={props.media ? props.media : "(min-width: 992px)"} srcSet={require(`./../assets/images/tickets/${props.srcSet}`)}/>
                  <img data-scroll={props.dataScroll} className={[props.type === "emoji" ? "" : "ticket",props.className].join(" ")} src={require(`./../assets/images/tickets/${props.src}`)} alt=""/>
            </picture>
      )
}

export default HeadingPictureComponent;

