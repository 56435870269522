import { api } from "./../../../Services/Api";
import { aboutUsCount } from "./aboutUsCount";

export const aboutUsApi = api.injectEndpoints({
      endpoints: build => ({
            aboutUsCount:aboutUsCount(build),
      }),
      overrideExisting: true
})

export const {  useLazyAboutUsCountQuery } = aboutUsApi