import { createSlice } from '@reduxjs/toolkit'

            
const initialState = {
      isBooking:false,
      eventInfo:null,
      scheduleInfo:null,
      // values:null,
      venueDetails:null
}
const eventDetailSlice = createSlice({
      name: 'eventDetail',
      initialState:initialState,
      reducers: {
            setEventsData(state, { payload }) {
                  state.eventInfo = payload.eventInfo
                  state.scheduleInfo = payload.scheduleInfo
                  // state.values = payload.values
                  state.venueDetails = payload.venueDetails
            },
            setEventNotifyResponse(state,action) {
                  state.eventInfo.is_waiting_joined = action.payload.is_waiting_joined
            },
            setBookingHandler (state, { payload }) {
                  state.isBooking = payload.isBooking;
            },
            setScheduleInfo(state,{ payload }){
                  state.scheduleInfo = payload.scheduleInfo
            },
            reset: ()=> initialState
      },
      
})


export const { setEventsData, setEventNotifyResponse, setBookingHandler, setScheduleInfo, reset } = eventDetailSlice.actions

export default eventDetailSlice.reducer
