import React, { useState, useEffect, useRef } from 'react'

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

// child component
import AccountSettingsDesktop from './AccountSettingsDesktop/AccountSettingsDesktop';
import AccountSettingsMobile from './AccountSettingsMobile/AccountSettingsMobile';
import Footer from '../../Layouts/Footer/Footer'
import CardDeletedModal from '../../Ui/Modal/CardDeletedModal';

// packages
import { Transition } from 'react-transition-group';
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { setAlert, setDeteleCardModal } from '../../Store/UI';

// Constants
import { routesName } from '../../resources/RoutesName';
import { messages } from '../../resources/en';
import { MetaData, MetaDataAj, MetaDataTfm } from '../../resources/MetaData';
import { Config } from '../../Config';

const MyAccount = () => {

      const { deleteCardModal }           =     useSelector(state=>state.ui)
      const domain                        =     useSelector(state=>state.user.domain)
      
      const [device,setDevice]            =     useState('');
      const [settingPage,setSettingPage]  =     useState('');

      const dispatch                      =     useDispatch();
      const navigate                      =     useNavigate()
      // const { state }                     =     useLocation();
      const location                      =     useLocation();
      const nodeRef                       =     useRef(null);

      let width                           =     window.innerWidth
      
      useEffect(()=>{
            
            if(width > 767){
                  setDevice('desktop')
            } else {
                  setDevice('mobile')
            }
      },[width])
      
      useEffect(()=>{
            document.title = "My Account";
      },[])

      useEffect(()=>{
            
            if(location.pathname.includes(routesName.SuccessCardStore) || location.pathname.includes(routesName.FailureCardStore)){
                  setSettingPage('SavedCards')
                  const searchParams = new URLSearchParams(location.search);
                  const client = searchParams.get('client');
                  const bookingStatus = location.pathname.includes(routesName.SuccessCardStore) ? "success" : "failure";
                  const message = bookingStatus === "success" ?  messages.SuccessCardStore : messages.FailureCardStore;

                  console.log(client,bookingStatus, message )

                  if(client !== "app" && client !== null){
                        if(bookingStatus === "success"){
                              dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message: messages.SuccessCardStore}))
                        } 
                        else {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message: messages.FailureCardStore}))
                        }
                        
                  }
                  navigate(routesName.MyAccount, { replace: true });
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[dispatch, navigate])

      const deleteCardModalHandler = ()=> {
            dispatch(setDeteleCardModal({card:false}))
      }

      let metaDataSite = MetaData
      if(domain === Config.DOMAIN_CONSTANT_TFM){
            metaDataSite = MetaDataTfm
      } else if(domain === Config.DOMAIN_CONSTANT_AJTIX){
            metaDataSite = MetaDataAj
      }

      return (
                  <React.Fragment>
                        {/* <!-- REQUIRED wrapper div for parallax --> */}
                        <div className={["scroll-animations position-relative"].join(" ")} data-scroll-container>
                              <Helmet>
                                    <title>{metaDataSite.Account.title}</title>
                                    <meta name="description" content={metaDataSite.Account.description} />
                                    <meta name="keywords" content={metaDataSite.Account.keywords} />
                              </Helmet>
                              <main>

                                    <section data-scroll-section className="section__my-account pt-0">
                                          <div className="container-fluid container__block">
                                                <div className="row">
                                                      <div className="col-md-12">
                                                            <div className="content content__my-account mx-auto">
                                                                  <div className="content__header">
                                                                        <div className="big-heading">
                                                                              <h1 className="h4 mb-0">Account settings</h1>
                                                                              <p className="text-muted">Manage your account and profile settings</p>
                                                                        </div>
                                                                  </div>
                                                                  {/* <!-- .content__header --> */}
                                                                  <div className="content__body">

                                                                        {/* <!--
                                                                        IMPORTANT
                                                                        - the desktop layout code for the Account Settings can not be styled to matched hte mobile layout
                                                                        - the desktop Account Settings are in tabs
                                                                        - the mobile Account Settings are designed as collapsible divs
                                                                        - so we are using a different code for desktop thatt is visible from iPad portrait and hidden below 768px screen size
                                                                        - the collapsible Account Settings are hidden in desktop and visible in screens size less than 768px
                                                                        --> */}

                                                                        {device === "desktop" && <AccountSettingsDesktop page={settingPage}/>}

                                                                        {/* <!-- BEGIN ACCOUNT SETTINGS MOBILE --> */}
                                                                        {device === "mobile" && <AccountSettingsMobile/>}
                                                                        {/* <!-- IMPORTANT: note that the form fields and buttons on both desktop and mobile forms use the same IDs --> */}
                                                                        {/* <AccountSettingsMobile/> */}
                                                                        {/* <!-- /. --> */}
                                                                        {/* <!-- END ACCOUNT SETTINGS MOBILE --> */}

                                                                  </div>
                                                                  {/* <!-- .content__body --> */}
                                                            </div>
                                                            {/* <!-- .content__my-account --> */}
                                                      </div>
                                                      {/* <!-- .col-md-12 --> */}
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </div>
                                          {/* <!-- /.container-fluid  --> */}
                                    </section>
                                    {/* <!-- /.section__my-account --> */}

                              </main>

                              <Footer/>

                        </div>

                        {/* <!-- /div[data-scroll-container] --> */}
                        <Transition in={deleteCardModal} timeout={500} mountOnEnter unmountOnExit nodeRef={nodeRef}>
                              {status => {
                                          return <CardDeletedModal status={status} deleteCardModalHandler={deleteCardModalHandler}/> 
                                    }
                              }
                        </Transition>
                        
                  </React.Fragment>
      )
}

export default MyAccount;



