import { api } from "../../Api";
import { fetchMemberPreference } from "./fetchMemberPreference";
import { updatePrefrences } from "./updateMemberPreference";

export const preferenceApi = api.injectEndpoints({
            endpoints: build => ({
            fetchMemberPreference :fetchMemberPreference(build),
            updateMemberPreference:updatePrefrences(build)
      }),
      overrideExisting: true
})

export const { useLazyFetchMemberPreferenceQuery, useUpdateMemberPreferenceMutation } = preferenceApi