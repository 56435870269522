import React from 'react'

// external css
import ticketContactBigHeading1Aquamarine from "../../../assets/images/tickets/ticket-contact-big-heading1-aquamarine.svg";
import ticketContactBigHeading1AquamarineSm from "../../../assets/images/tickets/ticket-contact-big-heading1-aquamarine-sm.svg";
import ticketContactBigHeading1Blue from "../../../assets/images/tickets/ticket-contact-big-heading1-blue.svg";
import ticketContactBigHeading1BlueSm from "../../../assets/images/tickets/ticket-contact-big-heading1-blue-sm.svg";
import ticketContactBigHeading1Yellow from "../../../assets/images/tickets/ticket-contact-big-heading1-yellow.svg";
import ticketContactBigHeading1YellowSm from "../../../assets/images/tickets/ticket-contact-big-heading1-yellow-sm.svg";

// redux and react hooks
import { useSelector } from 'react-redux';

// child component
// packages
// Actions & RTK query or mutations
// Constants

const ContentHeader = () => {

      const domain = useSelector(state=>state.user.domain)
      
      return (
            <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                  <div className="big-heading">
                        <h1 className="h2 mb-0">Contact us</h1>
                        {domain === "" &&<>
                        <picture>
                              <source media="(min-width: 992px)" srcSet={ticketContactBigHeading1Aquamarine}/>
                              <img data-scroll="" className="ticket ticket-aquamarine" src={ticketContactBigHeading1AquamarineSm} alt=""/>
                        </picture>
                        <picture>
                              <source media="(min-width: 992px)" srcSet={ticketContactBigHeading1Blue}/>
                              <img data-scroll="" className="ticket ticket-blue" src={ticketContactBigHeading1BlueSm} alt=""/>
                        </picture>
                        <picture>
                              <source media="(min-width: 992px)" srcSet={ticketContactBigHeading1Yellow}/>
                              <img data-scroll="" className="ticket ticket-yellow" src={ticketContactBigHeading1YellowSm} alt=""/>
                        </picture>
                        </>}
                  </div>
            </div>
      )
}

export default ContentHeader
