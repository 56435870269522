import React from 'react';
import Backdrop from '../Backdrop';

import { messages } from '../../resources/en';

const CardDeletedModal = (props) => {

      return (
                  <React.Fragment>
                        <div className={["modal modal__my-account-delete-card show fade",(props.status === "entering" || props.status === "entered") && "modalOpen",(props.status === "exiting" || props.status === "exited") && "modalClosed"].join(" ")} style={{"display":'block'}} id="modalMyAccountDeleteCard" tabIndex="-1">
                              <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                          <div className="modal-body d-flex align-items-center justify-content-center">
                                                <button type="button" className="btn-close btn-close__custom btn-close__absolute" onClick={props.deleteCardModalHandler}>
                                                </button>
                                                <div className="content">
                                                      <div className="content__body text-center">
                                                            <h2 dangerouslySetInnerHTML={{__html: messages.deleteCard}}></h2>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}
                                          </div>
                                          {/* <!-- /.modal-body --> */}
                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>

                        <Backdrop/>
                  </React.Fragment>
      )
}

export default CardDeletedModal;
