import { api } from "./../../../Services/Api";
import { favUnFav } from "./favUnFav";

export const favouriteListingApi = api.injectEndpoints({
            endpoints: build => ({
            favUnFav:favUnFav(build),
      }),
      overrideExisting: true
})

export const { useFavUnFavMutation } = favouriteListingApi