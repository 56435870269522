import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
      isTopUpOpen:false,
      step:'step1',
      paymentMethod:'',
      giftAmount: "",
      spinner : "",
      refetchHistory :false,
      voucherSessionId : ""
}

const slice = createSlice({
      name: 'giftVoucher',
      initialState: initialState,
      reducers: {
            setGiftParams : (state, { payload })=>{
                  state.isTopUpOpen = payload.isTopUpOpen
                  state.step = payload.step
                  state.paymentMethod = payload.paymentMethod
                  state.giftAmount = payload.giftAmount
                  state.voucherSessionId = payload.voucherSessionId
            },
            setSpinner : (state, { payload }) => {
                  state.spinner = payload.status
            },
            setRefetchHistory: (state, { payload }) => {
                  state.refetchHistory = payload.refetchHistory
            },
            reset: ()=> initialState

      },
      
})

export const { setGiftParams, setSpinner, setRefetchHistory, reset } = slice.actions

export default slice.reducer
