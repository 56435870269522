import React from 'react';
import { ImageComponent } from './index';

const ShowItem = (props) => {
      
      // const imageSrcset = require(`../assets/images/${props.srcSet}`)
      // const imageSrc = require(`../assets/images/${props.src}`)

      return (
            <div className="item">
                  <div className="show">
                        <div className="show__cat">{props.title}</div>
                        <div className="show__image">
                              {/* <img className="carousel-image object-fit" srcSet={`${imageSrcset} 2x`} src={imageSrc} alt=""/> */}
                              <ImageComponent className="carousel-image object-fit" srcSet={props.srcSet} src={props.src}/>
                        </div>
                  </div>
            </div>
      )
}

export default ShowItem
