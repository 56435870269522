import { validation } from "./../resources/en";
import { NameConstants } from "../resources/NameConstants"

 const surveyValidationRules=(value, type) =>{

      const textMessage = validation;

      let isValid = true;
      let validationMessage = "";

      if (!type) {
            return true;
      }
      
      if(type === NameConstants.SurveyAnswer){

            if(value.trim()==='') {
                  
                  isValid = false;
                  validationMessage = textMessage[type].required;
                  
            } 
            else if(value.length < 3) {
                  isValid=false;
                  validationMessage = textMessage[type].invalid;
            }
      }
     
      return {isValid,validationMessage};
}

export default surveyValidationRules;
