import React from 'react'

const UptakeListStatDummy = () => {
      return (
            <ul className="list__uptake-stat list-unstyled uptake-liststat__dummy">
                  
            </ul>
      )
}

export default UptakeListStatDummy
