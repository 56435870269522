export const submitRestoreAccessTerms =  build =>
      build.mutation({
            query: (body) =>  ({
                  url: '/submit_restore_access_terms',
                  method: 'POST',
                  body: body
            }),
      })



      // remove it 