import React, { useEffect } from 'react'
// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
// packages
// Actions & RTK query or mutations
import { useLazyGetSessionKeyDetailsQuery } from '../../../Services/modules/BookingModule';
import { setSpinner, setGiftParams, setRefetchHistory } from '../../../Store/GiftVoucher'; 
import { useAddUserVoucherMutation } from '../../../Services/modules/GiftVoucher';
import { setAlert } from '../../../Store/UI';

// Constants
import { routesName } from '../../../resources/RoutesName';
import { messages, errors } from '../../../resources/en';


const Step2 = (props) => {

      const giftVoucher                         =     useSelector(state=>state.giftVoucher)
      const walletBalance                       =     useSelector(state=>state.user.ct_wallet_balance)

      const dispatch                            =     useDispatch()


      const [getSessionKeyDetails, { data, error }] = useLazyGetSessionKeyDetailsQuery()

      const [addUserVoucher, { data : voucherData , error : voucherError , isLoading : voucherIsLoading }] = useAddUserVoucherMutation();

      useEffect(()=>{
            getSessionKeyDetails({session_id: giftVoucher.voucherSessionId})
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[getSessionKeyDetails])

      useEffect(()=>{
            if(data) {
                  if(data.status === "1") {      
                        dispatch(setGiftParams({
                              isTopUpOpen: true,
                              step : "step2",
                              paymentMethod : "",
                              giftAmount : data.data.voucher_amount && data.data.voucher_amount.toString(),
                              voucherSessionId : giftVoucher.voucherSessionId
                        }))
                  } else {
                        dispatch(setGiftParams({
                              isTopUpOpen: true,
                              step : "step1",
                              paymentMethod : "",
                              giftAmount : giftVoucher.giftAmount,
                              voucherSessionId : ""
                        }))
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404 }))
                  }
            }

            if(error) {
                  console.log(error)
                  // dispatch(setGiftParams({
                  //       isTopUpOpen: true,
                  //       step : "step1",
                  //       paymentMethod : "",
                  //       giftAmount : giftVoucher.giftAmount,
                  //       voucherSessionId : ""
                  // }))
                  // dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.data.error || data.data.message || errors.NotFound404 }))
                  // console.log(error)
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, error])

      //Handler addUserVoucher using wallet response
      useEffect(()=>{
            if(voucherData) {
                  dispatch(setSpinner({status : ""}))
                  if(voucherData.status === "1") {    
                        dispatch(setRefetchHistory({refetchHistory : true}))
                        dispatch(setGiftParams({
                              isTopUpOpen: true,
                              step : "step4",
                              paymentMethod : "",
                              giftAmount : "",
                              voucherSessionId : ""
                        }))

                  } else if(voucherData.status === "0") {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:voucherData.error || voucherData.message || errors.NotFound404 }))
                  }
            }

            if(voucherError) {
                  dispatch(setSpinner({status : ""}))
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:voucherError.error || voucherError.data.error || voucherError.data.message || errors.NotFound404 }))
            }

      },[voucherData, voucherError, dispatch])

      const paymnetUsingWalletOrCardHandler = (method)=> {
            if(method ==="wallet" && walletBalance >= giftVoucher.giftAmount) {
                  console.log("here")
                  dispatch(setSpinner({status : "request"}))
                  addUserVoucher({
                        voucher_session_id: giftVoucher.voucherSessionId,
                        wallet_balance_used: true
                  })
                 
            } else {

                  dispatch(setGiftParams({
                        isTopUpOpen: true,
                        step : "step3",
                        paymentMethod : "",
                        giftAmount : giftVoucher.giftAmount,
                        voucherSessionId : giftVoucher.voucherSessionId
                  }))
            }
      }

      const termsHandler = ()=> {
           
            // navigate(`${routesName.Terms}?wallet`, { target: '_blank', myData: "wallet" });
            window.open(`${routesName.Terms}?myData=giftVoucher`, "_blank");
      }

      return (
            <div id="walletTopupStep1" className="content content__payment-options content__topup-options border-bottom">
                  <div className="content__body" style={{ paddingBottom : "180px"}} >
                        <h4 className="heading">Use your wallet</h4>
                        <button id="btnPaymentMethodWallet" type="button"  className="btn btn__payment-method w-100" onClick={()=>paymnetUsingWalletOrCardHandler("wallet")} disabled={(walletBalance < giftVoucher.giftAmount) || voucherIsLoading}>
                              <span className="button__icon button__icon--wallet"></span>
                              <span className="button__text">
                                    <span className="sub-heading__upper">Your current credit</span>
                                    <span className="heading">£ {walletBalance ? walletBalance.toFixed(2) : '0.00' }</span>
                              </span>
                              <span className="button__arrow button__arrow--right">
                              </span>
                        </button>
                        <h4 className="heading">Other payment methods</h4>
                        <button id="btnPaymentMethodDebitCredit" type="button" className="btn btn__payment-method w-100" disabled={voucherIsLoading} onClick={()=>paymnetUsingWalletOrCardHandler("card")}>
                              <span className="button__icon button__icon--debitcredit"></span>
                              <span className="button__text">
                                    <span className="heading">Debit or Credit Card</span>
                              </span>
                              <span className="button__arrow button__arrow--right"></span>
                        </button>
                        <div className='space-for-apple-pay-button'></div>
                        <span className="bottom-text">
                        1) Account credit can be used against the cost of any show listed via our <a href={`/`} target="_blank" rel='noreferrer'>Shows</a> tab. 2) It cannot be used for shows found on the West End Offers tab of our website which are fulfilled by third-party ticketing partners <a href="https://centraltickets.tixculture.com/" target="_blank" rel='noreferrer'>(tixculture.com)</a> or <a href="https://centraltickets.ticketswitch.com/" target="_blank" rel='noreferrer'>(ticketswitch.com)</a>. 3) Any credit added is non-refundable and cannot be exchanged for cash in part or full.&nbsp;<span style={{"color":"red"}}><span to={`${routesName.Terms}?wallet`} onClick={termsHandler} style={{"color":"red","textDecoration":"underline","cursor":"pointer"}} rel='noreferrer' target="__blank">Full Terms &amp; Conditions.</span></span>
                        </span>
                        <div className="custom-control custom-checkbox custom-checkbox-sm custom-checkbox__check custom-checkbox-hide-checkbox" >
                              <label className="custom-control-label custom-control-label-hide">
                                    By making payment you agree to our Terms & Conditions
                              </label>
                        </div>
                  </div>
                  {/* <!-- /.content__body --> */}
                  <div className="giftVoucher-lower-footer-body">
                        <div className="content__body content__body--total border-top">
                              <div className="subtotal-block">
                                    <div className="fw-bold">Total (VAT inc.):</div>
                                    <div className="total-amount">£{parseFloat(giftVoucher.giftAmount).toFixed(2)}</div>
                              </div>
                        </div>
                  </div>
                  {/* <!-- /.content__body --> */}
            </div>
      )
}

export default Step2
