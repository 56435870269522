import React, { useState, useEffect } from 'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// child component
import { CurrentListingDummyImage } from "./../Components"
import iconLocationPinBlack from "./../assets/images/icon-location-pin-black.svg";
import iconClockBlack from "./../assets/images/icon-clock-black.svg";
import iconPaymentCardBlack from "./../assets/images/icon-payment-card-black.svg";
// import alterImage from "./../assets/images/alterImage.png"
// packages
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Actions & RTK query or mutations
import { useFavUnFavMutation } from "./../Services/modules/favourites";
import { useEventNotifymeWebMutation } from '../Services/modules/currentlisting';
import { setAlert } from '../Store/UI';

// Constants
import { onLineTicketTypes } from '../resources/ticketTypes';
import { errors, messages } from '../resources/en';


const CurrentListingItem = (props) => {

      
      const [isFavourite,setIsFavourite]  =     useState(props.is_favorite)
      const [imageLoaded,setImageLoaded]  =     useState(true)
      const [displayLazy,setDisplayLazy]  =     useState(true)
      const browserDetect                 =     useSelector(state=>state.ui.browserDetect);
      const dispatch                      =     useDispatch();
      const navigate                      =     useNavigate();

      const [favUnFav, { isSuccess : favUnFavIsSuccess}]        =    useFavUnFavMutation();
      const [eventNotifyWeb, { data , isSuccess, isLoading, isFetching, error}] = useEventNotifymeWebMutation();
      let online = false;
      let isNew = false
      if (onLineTicketTypes.includes(props.tickets_type)){
            online = true; 
      }

      if(props.is_new === "1"){
            isNew = true
      }
      
      // useEffect(()=>{

      //       // not showing any message for this api call because this is not much crirtical
      //       console.log(data, "data")
      //       console.log(error)
      // },[data, isSuccess, isLoading, isFetching, error])
      

      useEffect(()=>{
            if(favUnFavIsSuccess){
                  if(props.reFetchFavourites) {
                        props.reFetchFavourites()
                  }
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[favUnFavIsSuccess])

      useEffect(()=>{
            if(isSuccess){
                  dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:data.error || data.errors || data.message}))
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      },[ data, isSuccess, isLoading, isFetching, error, dispatch])

      useEffect(()=>{
            // props.afterLoad()

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[isFavourite,displayLazy])

      useEffect(()=>{

            if(!imageLoaded){
                  props.afterLoad()
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[imageLoaded])
    
      // const afterLoadHandler = ()=> {
      //       setImageLoaded(false)
      //       props.afterLoad()
      // }

      const favUnFavHandler = (e)=>{
            e.preventDefault();
            // if(props.type === "fav") {
            //       props.reFetchFavourites();
            // }
            favUnFav(props.id);
            setIsFavourite(!isFavourite);
      }

      const joinWaitingListHandler = (e)=>{
            e.preventDefault();
            if(props.is_soldout !== "1") {
                  navigate(`/event-details/${props.id}`)
            }
            else if(props.is_soldout === "1"){
                  const event_id  = props.id;
                  const data      = {event_id:event_id};

                  // .................call API Join Waiting List...............//
                  eventNotifyWeb(data);
            }
      }

      const onLoadHandler = ()=> {
            // commment from here and added in useEffect which dependency is imageLoaded
            // props.afterLoad()
            setImageLoaded(false)
      }

      return (
            <div className="item" id={props.id}>
                  <div className="card card__event">
                        <div className="card__event-image">
                              {isNew && <div className="badge badge-upper">
                                          New
                                    </div>}
                              {online &&
                                    <div className="badge badge-upper">
                                          Online
                                    </div>}

                              {props.is_soldout ==="1" ?
                              <div className="badge badge__ticket-sold-out">
                                    Sold out!
                              </div> :
                              <ul className="list__event-tags">
                                   
                                    {/* {props.leftTickets < 4 &&
                                    <li>
                                          <div className="badge badge-red">{`${props.leftTickets} ${props.leftTickets > 1 ? "tickets" : "ticket"} left `}</div>
                                    </li> } */}
                                    {/* {props.categories.split(",").map((el,index)=>{

                                          return (    
                                                      <li key={el + index}>
                                                            <div className="badge">{el}</div>
                                                      </li>
                                                )
                                    })} */}
                                    {props.labels && props.labels.map((el,index)=>{

                                          return (    
                                                <li key={el.displayText + index}>
                                                      <div className="badge" style={{"backgroundColor":el.bgColor,"color":el.fgColor}}>{el.displayText}</div>
                                                </li>
                                          )
                                    })}
                                    
                              </ul>}
                              <Link to={`/event-details/${props.id}`} style={{position :"relative"}}>
                                    <CurrentListingDummyImage/>
                                    {displayLazy && <LazyLoadImage className={["event-image event-image-listing",props.is_soldout ==="1" ? "current_listings__soldout__event-image" : ""].join(" ")} src={props.event_image} srcSet={`${props.event_image} 2x`} 
                                    afterLoad={onLoadHandler} 
                                    // visibleByDefault={true}
                                    // delayTime={5000}
                                    // placeholder={<img className="event-image" src={alterImage} effect="opacity" alt=''/>}
                                    onError={() => {
                                          // Handle image load failure
                                          setImageLoaded(true)
                                          setDisplayLazy(false)
                                          // commment from here and added in useEffect which dependency is imageLoaded
                                          // props.afterLoad()
                                    }}
                                    />}

                                    {/* {!imageLoaded &&<img  className="event-image"  srcSet={`${props.event_image} 2x`} src={props.event_image} alt=""/>} */}
                              </Link>
                        </div>
                        <div className="card-body">
                              <h5 className="card-title fw-bold">
                                    <Link to={`/event-details/${props.id}`}>{props.event_name}</Link>
                              </h5>
                              <div className="list-group list-group__event-details list-group-flat">
                                    <div className="list-group-item list-group__location">
                                          <div className="icon-wrapper" style={{marginRight:browserDetect ? "10px":""}}>
                                                <img className="icon__location-pin" src={iconLocationPinBlack} alt=""/>
                                          </div>
                                          <p className="mb-0">{props.address}</p>
                                    </div>
                                    <div className="list-group-item list-group__time">
                                          <div className="icon-wrapper" style={{marginRight:browserDetect ? "10px":""}}>
                                                <img className="icon__clock" src={iconClockBlack} alt=""/>
                                          </div>
                                          <p className="mb-0">{props.event_date_time}</p>
                                    </div>
                                    <div className="list-group-item list-group__price">
                                          <div className="icon-wrapper" style={{marginRight:browserDetect ? "10px":""}}>
                                                <img className="icon__payment-card" src={iconPaymentCardBlack} alt=""/>
                                          </div>
                                          {/* <p className="mb-0">{props.admin_fees}</p> */}
                                          {parseFloat(props.admin_fees) <= 6.5 && 
                                          <p className="mb-0">£0 Ticket + £{props.admin_fees} Admin Fee</p>}
                                          {parseFloat(props.admin_fees) >= 6.51 && 
                                          <p className="mb-0">£{props.admin_fees} Ticket </p>}

                                    </div>
                              </div>
                        </div>
                        {/* <!-- /.card-body --> */}
                        <div className="card-footer">
                              <div className="buttons-wrapper d-flex align-items-center justify-content-between">
                                    <button className="btn btn-outline-primary hover-primary" onClick={joinWaitingListHandler} >
                                          {props.is_soldout === "1" ? "Join waiting list" :
                                          "Book now"}
                                    </button>
                                    <Link className={["btn-heart",isFavourite ? "active" :" "].join(" ")} to="/" onClick={favUnFavHandler}></Link>
                              </div>
                        </div>
                        {/* <!-- /.card-footer --> */}
                  </div>
                  {/* <!-- /.card --> */}
            </div>
      )
}
export default CurrentListingItem
