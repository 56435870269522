import React, { useEffect, useLayoutEffect, useState } from 'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// child component
import Footer from "../../Layouts/Footer/Footer";
import { ImageComponent } from '../../Components';
import { UptakeImageDummy, 
      UptakeListStatDummy,
      UptakeTitleDummy,
      UptakeCardDummy, UptakeCardCarouselDummy
} from "../UptakePage/DummyComponents/UptakePage";

// packages
import OwlCarousel from 'react-owl-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { useLazyUnderrepresentedCommunitiesQuery } from "../../Services/modules/Others"
import { setAlert, updateLocoScroll } from '../../Store/UI';

// Constants
import { errors, messages } from '../../resources/en';
// import { dateFormatHadler  } from "./../../resources/DateHandler";
import { UptakePageOptions, UptakePageBannerOptions } from '../../resources/OwlCarouselOptions';
import { URL } from '../../resources/Url';
import { routesName } from '../../resources/RoutesName';
import { MetaData } from '../../resources/MetaData';
import { Config } from '../../Config';

const UnderRepresentedCommunity = () => {

      const browserDetect = useSelector(state=>state.ui.browserDetect);
      const domain        = useSelector(state=>state.user.domain)
      const [id, setId] = useState('')

      const dispatch = useDispatch();
      const navigate = useNavigate();

      const [getUndData, { data, isSuccess, error, isLoading }] = useLazyUnderrepresentedCommunitiesQuery();


      useLayoutEffect(()=>{
            const url = window.location;
            const searchParams = new URLSearchParams(url.search);
            const keyValuePairs = {};
            
            for (const [key, value] of searchParams) {
                 keyValuePairs[key] = value;
            }
            setId(keyValuePairs.id)
            getUndData(keyValuePairs)
      },[getUndData])

      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1"){
                        // console.log(data.data.display_name)
                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                        navigate('/')
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, error, isLoading, dispatch])

      const afterLoadHandler = ()=>{
            // console.log("after Load Image handler..")
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
      }

      const getDateForEcologiLogo = ()=>{
            const date = Date.now()
            return `ecology_logo_red.png?v=${date}`
      }


      let ecologiLogoName = "ecology_logo_red.png?v=1";
      ecologiLogoName = getDateForEcologiLogo()

      let displayName = [];

      const options = {
            autoplay: true,
            // autoplayTimeout: 2000,
            center: true,
            loop: true,
            dots: false,
            nav: true,
            //EXTERNAL nav container
            navContainer: '#owlCarouselEventTagsNav',
            autoWidth: true,
            animateIn:true,
            animateOut:true,
            responsive:{
                  0:{
                        items: 3,
                        margin: 12,
                  },
                  1200:{
                        items: 3,
                        margin: 25,
                  },
            }
      }

      if(data && data.data.display_name) {
            displayName = data.data.display_name.split(",")
      }
      
     
      return (
            
            <React.Fragment>
                  
                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <div className="scroll-animations position-relative" data-scroll-container>
                        <Helmet>
                              <title>{MetaData.Uptake.title}</title>
                              <meta name="description" content={MetaData.Uptake.description} />
                              <meta name="keywords" content={MetaData.Uptake.keywords} />
                        </Helmet>
                        <main className="main__uptake main__underrepresented_communities">
                              <section data-scroll-section className="section__uptake">
                                    {/* <!--
                                          NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                                    --> */}
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__uptake mx-auto">
                                                            <div className="content__body">
                                                                  
                                                                  {data ? (
                                                                        <OwlCarousel loop {...UptakePageBannerOptions}>
                                                                        <div className='card card__uptake card__uptake--thank-you border-0 bg-primary border-radius-10' >
                                                                              <div className="card-body text-center">
                                                                                    <h5 className="card-title fw-bold">
                                                                                          Thank you! Together we<br className="d-md-none"/> are helping to support <span className="yellow2">230 NHS Charities</span>
                                                                                    </h5>
                                                                              </div>
                                                                        </div>
                                                                        <div className='card card__uptake card__uptake--thank-you border-0 bg-primary border-radius-10' >
                                                                              <Link to={routesName.AboutUs} target='_blank' rel='noreferrer' style={{"textDecoration":"none"}}>
                                                                              <div className="card-body text-center ecologi-card bg-mint">
                                                                                    <h5 className="card-title fw-bold" style={{"color":"var(--bs-dark)"}}>
                                                                                          and tackling climate change <span style={{"color":"var(--bs-primary)"}}>via Ecologi</span>
                                                                                    </h5>
                                                                              </div>
                                                                              </Link>
                                                                        </div>
                                                                        </OwlCarousel>) 
                                                                        : <UptakeCardDummy />}
                                                                  {/* <!-- /.card__uptake --> */}

                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content.content__uptake --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}

                                          <div className="row">
                                                <div className="col-md-12">

                                                      <div className="content content__uptake content__uptake-event mx-auto">
                                                            <div className="content__body">

                                                                  <div className="card card__event border-1 border-radius-10 flex-lg-row mb-0">
                                                                        <div className="card__event-image flex-shrink-0">
                                                                              { data ? <LazyLoadImage className="event-image"srcSet={data.data.image} src={data.data.image} afterLoad={afterLoadHandler} /> : <UptakeImageDummy />}
                                                                              
                                                                        </div>
                                                                        <div className="card-body">

                                                                              <div className="card-body__row row-event-title-status">
                                                                                    <div className='header-title col-12 col-md-6 col-xl-8'>
                                                                                    {data ?  <h5 className="card-title fw-bold">{data && data.data.title}</h5> : <UptakeTitleDummy/>}
                                                                                    </div>
                                                                                    <div className='header-date col-12 col-md-6 col-xl-4'>
                                                                                          <h5 className="card-title fw-bold col-12">Date Range:</h5> 
                                                                                          <h5 className="card-title col-12 date-range">{data && data.data.start_date} - {data && data.data.end_date}</h5> 
                                                                                    </div>
                                                                              </div>
                                                                              {/* <!-- /.card-body__row --> */}

                                                                              <div className="card-body__row row-uptake-list-progress">
                                                                                    <div className="col col__left">
                                                                                          
                                                                                          {data ? <ul className="list__uptake-stat list-unstyled">
                                                                                                <li style={{marginRight:browserDetect?"30px":""}}>
                                                                                                      <div className="stat">
                                                                                                            {/* <h4 className="stat__number fw-bold">{data && data.data.total_attendy}</h4> */}
                                                                                                            <h4 className="stat__number fw-bold">{data && data.data.total_shows}</h4>
                                                                                                            <div className="stat__text">events</div>
                                                                                                      </div>
                                                                                                </li>
                                                                                                <li style={{marginRight:browserDetect?"30px":""}}>
                                                                                                      <div className="stat">
                                                                                                            <h4 className="stat__number fw-bold">{data && data.data.total_allocated}</h4>
                                                                                                            <div className="stat__text">tickets allocated</div>
                                                                                                      </div>
                                                                                                </li>
                                                                                                <li style={{marginRight:browserDetect?"30px":""}}>
                                                                                                      <div className="stat">
                                                                                                            <h4 className="stat__number fw-bold">{data && data.data.total_attendy}</h4>
                                                                                                            <div className="stat__text">people attended</div>
                                                                                                      </div>
                                                                                                </li>
                                                                                                
                                                                                          </ul> : <UptakeListStatDummy />}
                                                                                          {data && <a className="btn btn-primary report_download_button" href={`https://upload.centraltickets.co//download_underserved_community_data?id=${id}`}target="_blank"  rel='noreferrer'>Download Report</a> }
                                                                                    </div>
                                                                                    
                                                                                    {/* <!-- /.col --> */}
                                                                              </div>
                                                                              {/* <!-- /.card-body__row --> */}

                                                                             

                                                                        </div>
                                                                        {/* <!-- /.card-body --> */}
                                                                  </div>
                                                                  {/* <!-- /.card__event --> */}

                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                          {displayName.length > 0 && 
                                          <div className='row events_slider_wrapper_row'>
                                                <div className='col-12'>
                                                      <div className='content content__uptake content__uptake-event mx-auto'>
                                                            <div className="events_slider_wrapper">
                                                                  <div className="list__event-tags-wrapper">
                                                                        <OwlCarousel className='list__event-tags owl-carousel owl-carousel__event-tags' 
                                                                        loop {...options}
                                                                        >
                                                                              {displayName.map((el,index)=>{

                                                                                    return (
                                                                                          <div className="item" key={`underrepresented${index}`}>
                                                                                                <span className={["badge badge-outline badge-xl-lg"].join(" ")} dataid={index} crindex={index}>
                                                                                                      {el}
                                                                                                </span>
                                                                                          </div>
                                                                                    )
                                                                              })}

                                                                        </OwlCarousel>
                                                                        {/* <!-- /.list__event-tags --> */}

                                                                        {/* <!-- EXTERNAL nav for .owl-carousel__event-tags  --> */}
                                                                        <div id="owlCarouselEventTagsNav" className="owl-nav"></div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>}


                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__uptake content__uptake-details-carousel mx-auto">
                                                            <div className="content__body p-0 d-flex flex-column flex-lg-row">

                                                                  <div className="col__left order-lg-2" style={{marginLeft:browserDetect ? "26px" : ""}}>
                                                                        <div className='row underrepresented-list-items'>

                                                                        {data && <h5 className='card-title'>Your event(s) helped the connect the communities below with the arts:</h5>}
                                                                        {!data && (<><UptakeCardDummy margin="26px"/>
                                                                        <UptakeCardDummy margin="26px"/>
                                                                        <UptakeCardDummy margin="26px"/></>)}
                                                                        
                                                                                    
                                                                                          {(data &&  data.data.communities_data) && data.data.communities_data.map((pef,index)=>{   
                                                                                                return (
                                                                                                      <div className="card-underrepresented-list-item col-12 col-xl-4" key={pef.id}>
                                                                                                                  <ul className="list-underrepresented-detail" >
                                                                                                                        <li className="list-item">
                                                                                                                              <div className="item">
                                                                                                                                    <span className="heading">{pef.filed_name}: </span>
                                                                                                                              </div>
                                                                                                                        </li>
                                                                                                                        <li className="list-item" >
                                                                                                                              <div className="item">
                                                                                                                                    <span>{pef.filed_value}% </span>
                                                                                                                              </div>
                                                                                                                        </li>
                                                                                                                  </ul>
                                                                                                      </div>
                                                                                                )
                                                                                          })}
                                                                        </div>

                                                                  </div>
                                                                  {/* <!-- /.col__left --> */}
                                                                  <div className="col__right order-lg-1">
                                                                        {data ? <div className="card card__uptake card__uptake--carousel border-1 border-radius-10 p-0">

                                                                              <OwlCarousel className="owl-carousel owl-carousel__uptake owl-theme h-100" loop {...UptakePageOptions}>
                                                                                    <div className="item">
                                                                                          <div className="item__image">
                                                                                                <ImageComponent className="carousel-image object-fit" 
                                                                                                src={`${URL.Image_Base_Url}iStock-542088116.jpg`} />
                                                                                          </div>
                                                                                    </div>
                                                                                    <div className="item">
                                                                                          <div className="item__image">
                                                                                                <ImageComponent className="carousel-image object-fit" 
                                                                                                src={`${URL.Image_Base_Url}iStock-1212167952.jpg`}/>
                                                                                                
                                                                                          </div>
                                                                                    </div>
                                                                                    <div className="item">
                                                                                          <div className="item__image">
                                                                                                <ImageComponent className="carousel-image object-fit" 
                                                                                                srcSet={`${URL.Image_Base_Url}iStock-465180779.jpg`}
                                                                                                src={`${URL.Image_Base_Url}iStock-465180779.jpg`} />
                                                                                          </div>
                                                                                    </div>
                                                                                    <div className="item">
                                                                                          <div className="item__image">
                                                                                                <ImageComponent className="carousel-image object-fit" 
                                                                                                srcSet={`${URL.Image_Base_Url}iStock-914628584.jpg`}
                                                                                                src={`${URL.Image_Base_Url}iStock-914628584.jpg`}
                                                                                                />
                                                                                          </div>
                                                                                    </div>
                                                                                    <div className="item">
                                                                                          <div className="item__image">
                                                                                                <ImageComponent className="carousel-image object-fit" 
                                                                                                srcSet={`${URL.Image_Base_Url}iStock-911008782.jpg`}
                                                                                                src={`${URL.Image_Base_Url}iStock-911008782.jpg`}
                                                                                                />
                                                                                                
                                                                                          </div>
                                                                                    </div>
                                                                              </OwlCarousel>
                                                                              {/* <!-- /.owl-carousel__uptake --> */}

                                                                              <div className="card-body text-center">
                                                                                    <p className="p-donation fw-bold">
                                                                                          {/* Together we’re raising funds in support of <span className="blue">NHS Charities Together</span> to help the NHS go further. */}
                                                                                          We work in support of <span className="blue">NHS Charities Together</span> and we invest in carbon avoidance projects for every ticket booked to attend your show.
                                                                                    </p>
                                                                                    <div className="buttons-wrapper justify-content-center">
                                                                                          <Link className="btn btn-primary align-self-start mx-auto" to={routesName.AboutUs}>Learn more</Link>
                                                                                    </div>
                                                                                    <div className="logos-container">
                                                                                          <div className="logos-wrapper d-flex justify-content-center">
                                                                                                <a href="https://nhscharitiestogether.co.uk/" rel="noreferrer" target="_blank">
                                                                                                      <ImageComponent className="image logo-nhs-charities" srcSet="logo-nhs-charities-together-blue-174@2x.png" src="logo-nhs-charities-together-blue-174.png" type="images" style={{padding:browserDetect ? "0 20px" : ""}}/>
                                                                                                </a>
                                                                                                {domain === Config.DOMAIN_CONSTANT_AJTIX ? null : 
                                                                                                <a href="https://ecologi.com/centralticketslimited" rel="noreferrer" target="_blank" className='logo__ecologi__custom-text-uptake'>
                                                                                                      {/* <span className="custom-text"><span className="trees-count">4.05k</span> trees</span>
                                                                                                      <ImageComponent className="image logo-ecologi" srcSet="dynamic_text_ecologi_logo_from_gio@2x.png" src="dynamic_text_ecologi_logo_from_gio.png" type="images" style={{padding:browserDetect ? "0 20px" : ""}}/> */}

                                                                                                      <img className="image logo-ecologi"  style={{padding:browserDetect ? "0 20px" : ""}}
                                                                                                            srcSet={`${URL.Image_Base_Url}ecology_logo/${ecologiLogoName} 2x`} 
                                                                                                            src={`${URL.Image_Base_Url}ecology_logo/${ecologiLogoName}`} alt="" 
                                                                                                      />
                                                                                                </a>}
                                                                                          </div>
                                                                                          <p className="p-registered text-start mb-0">Registered charity no. 1186569</p>
                                                                                    </div>
                                                                              </div>
                                                                              {/* <!-- /.card-body --> */}
                                                                        </div> :  <UptakeCardCarouselDummy />}
                                                                        {/* <!-- /.card__uptake --> */}

                                                                  </div>
                                                                  {/* <!-- /.col__right --> */}
                                                            </div>
                                                            {/* <!-- /.content__body --> */}
                                                      </div>
                                                      {/* <!-- /.content.content__uptake --> */}

                                                </div>
                                                {/* <!-- /.col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /.section__uptake --> */}
                        </main>

                        <Footer />
                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}

            </React.Fragment>
      )
}

export default UnderRepresentedCommunity;
