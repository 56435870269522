/* eslint-disable */
import React, { useState, useEffect, useRef } from  'react';

// external css
import "react-datepicker/dist/react-datepicker.css";
import "./../../../ReactDatepicker.css";

// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// child component
import { SelectComponent } from '../../../../Components';
// packages
import DatePicker from "react-datepicker";
import TomSelect from 'tom-select';
// Actions & RTK query or mutations
import { useGetPerformanceInfoMutation, useApplyPromocodeMutation, 
      useTicketBlockMutation, useFindTicketTypeMutation, 
      useGetTicketTypePerformanceMutation, useGetDetailsOfTicketTypePerfMutation
} from '../../../../Services/modules/BookingModule';
import { 
            setShowId, setPerformanceInfo, changeCheckBox,
            setValidatingMessage, initialPriceQuantity, ticketQuantityHandler, 
            setFlexiHandler, promoCodeSet, removePromoCode, setValueForPromoCodes,
            setBookingStep, setTicketTypes, setGroupAvailability,
            resetForAnimation 
      } from '../../../../Store/BookingModule';
import { setBookingHandler, setScheduleInfo } from '../../../../Store/EventDetail';
import { setAlert } from '../../../../Store/UI';
import { setSessionBlockId } from '../../../../Store/User';
import { setSpinner } from '../../../../Store/UI';

// Constants
import { errors, messages } from '../../../../resources/en';
import { routesName } from '../../../../resources/RoutesName';
import { NameConstants } from '../../../../resources/NameConstants';
import { checkValidationPromoCode } from '../../../../Services';
import { flexiHandler } from '../../../../Shared';

const ChooseDate = (props) => {

      const [isCalOpen,setIsCalOpen]            =     useState(false)
      const [promoCode,setPromoCode]            =     useState({value:"",error:false,errorMessage:""});
      const [selectedDate, setSelectedDate]     =     useState(null);
      const [listSchedule, setListSchedule]     =     useState([]);
      const [getDates, setGetDates]             =     useState(false);
      const bookingModule = useSelector(state=>state.bookingModule);
      const eventDetail = useSelector(state=>state.eventDetail);
      const ct_web_view_session = useSelector(state=>state.user.ct_web_view_session);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const selectTicketRef     = useRef(null);

      const [getPerformanceInfo, { data, isSuccess, isLoading, isFetching, error }]  =    useGetPerformanceInfoMutation();
      const [applyPrmocode, { data : promoData, isSuccess : promoIsSuccess, isLoading : promoIsLoading, isFetching : promoIsFetching, error : promoError }]  =    useApplyPromocodeMutation();
      const [ticketBlock, { data : blockData, isSuccess : blockIsSuccess, isLoading : blockIsLoading, isFetching : blockIsFetching, error : blockError }]  =    useTicketBlockMutation();
      const [findTicketType, { data : ticketData, isSuccess : ticketIsSuccess, isLoading : ticketIsLoading, isFetching : ticketIsFetching, error : ticketError }]  =    useFindTicketTypeMutation();
      const [getTicketTypePerformance, { data : getDatesData, isSuccess : getDatesIsSuccess, isLoading : getDatesIsLoading, isFetching : getDatesIsFetching, error : getDatesError }]  =    useGetTicketTypePerformanceMutation();
      const [getDetailsTicPer, { data : getTicPerData, isSuccess : getTicPerIsSuccess, isLoading : getTicPerIsLoading, isFetching : getTicPerIsFetching, error : getTicPerError }]  =    useGetDetailsOfTicketTypePerfMutation();

      useEffect(()=>{
            
            // if(!isCalOpen && bookingModule.ticketTypes.length === 0){
            if(!isCalOpen && bookingModule.ticketTypes.length === 0 && eventDetail.isBooking){

                  let ticketTypes = eventDetail.eventInfo.group_availablity !==2 ? [eventDetail.eventInfo.tickets_type] : [];
                  dispatch(setGroupAvailability({group_availablity : eventDetail.eventInfo.group_availablity, step : NameConstants.bookingModuleSteps.chooseDate, ticketTypes : ticketTypes}))
      
                  if(eventDetail.eventInfo.group_availablity ===2 && bookingModule.event_id){
                        findTicketType({event_id : bookingModule.event_id});
                        dispatch(setSpinner({status:"request"}))
                  }
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[ findTicketType, bookingModule.event_id, isCalOpen ])

      useEffect(()=>{
            if(getDates){
                 
                  setGetDates(false)
                  let ticket_type_id = "";
                  bookingModule.ticketTypes.forEach(el => {
                        if(el.selected) {
                              ticket_type_id = el.id
                        }
                  });

                  if(ticket_type_id){
                        dispatch(setSpinner({status:"request"}))
                        getTicketTypePerformance({event_id : bookingModule.event_id, ticket_type_id :ticket_type_id})
                  }

                  // clear performance info showId and selected date while change ticket type
                  setSelectedDate(null);
                  dispatch(setPerformanceInfo({data:null}));
                  dispatch(setShowId({showId:null}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[getDates, getTicketTypePerformance ])
      
      useEffect(()=>{
            if(isSuccess){
                  if(data.status === "1"){
                        const resdata = data.data;

                        // To show message to the user that they have already booked tickets in same schedule
                        if(resdata.booking_alert) {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:messages.ticAlertMessage.replace('{{x}}',resdata.total_tickets),type:{value:"ticAlert",status:false}}))
                        }
                        setIsCalOpen(false);
                        dispatch(setPerformanceInfo({data:resdata}));
                        initialPriceQuantityHandler(resdata.groups);
                        
                        dispatch(setSpinner({status:""}));

                  } else if(data.status === "0") {
                        dispatch(setSpinner({status:""}));
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.errors || data.message}))
                  }
            }

            if(error){
                  dispatch(setShowId({showId:null}));
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error, dispatch])

      useEffect(()=>{
            if(promoIsSuccess){
                  let promocode;
                  if(promoData.status ==="1"){
                        promocode = {...promoData.data,status:"valid",message:promoData.message}
                  }
                  else if(promoData.status ==="0"){
                        promocode = {
                              remove_flexi: false,
                              amount_type : "",
                              off: "",
                              discount: 0,
                              discount_type : "",
                              main_discount: "0",
                              coupan_code_id: "",
                              promocode: "",
                              totalAmount: 0,
                              totalAmount_without_flexi: 0,
                              mainAmount: 0,
                              status: "invalid",
                              message: promoData.message || promoData.error
                        }
                  }

                  dispatch(promoCodeSet({promocode:promocode}));
                  dispatch(setSpinner({status:""}))
            }

            if(promoError){
                  dispatch(setSpinner({status:""}))
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:promoError.error || promoError.data.error || promoError.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[promoData, promoIsSuccess, promoIsLoading, promoIsFetching, promoError, dispatch])

      // Blcok Tickets for normal and ticket type event
      useEffect(()=>{
            if(blockIsSuccess){
                  if(blockData.status ==="1"){
                        dispatch(setSessionBlockId({ct_session_block_id:blockData.data.session_block_id}))
                        props.startTimer();
                        dispatch(setBookingStep({step: NameConstants.bookingModuleSteps.orderSummary}));
                  } else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:blockData.error || blockData.errors || blockData.message || errors.NotFound404 }))
                  }
                  dispatch(setSpinner({status:""}))
            }

            if(blockError){
                  dispatch(setSpinner({status:""}))
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:blockError.error || blockError.data.error || blockError.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[blockData, blockIsSuccess, blockIsLoading, blockIsFetching, blockError, dispatch])

      // Find Ticket Type
      useEffect(()=>{
            
            if(ticketIsSuccess){
                  if(ticketData.status === "1") {
                        const data = ticketData.data.ticket_type_data;
                        const updateData =  data.map(el=>{
                              return {...el,selected:false}
                        })
                        dispatch(setTicketTypes({ticketTypes:updateData || []}))
                  } else if(ticketData.status === "2"){
                        navigate(routesName.CurrentListing)

                  }
                  dispatch(setSpinner({status:""}))
            }

            if(ticketError){
                  dispatch(setSpinner({status:""}))
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:ticketError.error || ticketError.data.error || ticketError.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[ticketData, ticketIsSuccess, ticketIsLoading, ticketIsFetching, ticketError, dispatch])

      // Get Available dates for ticket type
      useEffect(()=>{
            
            if(getDatesIsSuccess){
                  if( getDatesData.status === "1") {
                        // console.log(getDatesData)
                       dispatch(setScheduleInfo({scheduleInfo : getDatesData.data}))
                  }
                  
                  dispatch(setSpinner({status:""}))
            }

            if(ticketError){
                  dispatch(setSpinner({status:""}))
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:ticketError.error || ticketError.data.error || ticketError.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[ getDatesData,  getDatesIsSuccess,  getDatesIsLoading,  getDatesIsFetching,  getDatesError, dispatch])

      // Get Details of Ticket Type Performance
      useEffect(()=>{
            if(getTicPerIsSuccess){
                  if(getTicPerData.status === "1") {
                        setIsCalOpen(false);
                        dispatch(setPerformanceInfo({data:getTicPerData.data}));
                        initialPriceQuantityHandler(getTicPerData.data.groups,getTicPerData.data.price);
                  }
                  else {
                        dispatch(setShowId({showId:null}));
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:getTicPerData.error || getTicPerData.message || errors.NotFound404 }))
                  }
                  dispatch(setSpinner({status:""}))
            }

            if(getTicPerError){
                  dispatch(setShowId({showId:null}));
                  dispatch(setSpinner({status:""}))
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:getTicPerError.error || getTicPerError.data.error || getTicPerError.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[ getTicPerData,  getTicPerIsSuccess,  getTicPerIsLoading,  getTicPerIsFetching,  getTicPerError, dispatch])

      // Select Ticket Tom Select Configurations
      useEffect(()=>{

            if(selectTicketRef.current) {
                  const selectSort = new TomSelect(selectTicketRef.current,{
                        allowEmptyOption : true
      
                  });
                  selectTicketRef.current = selectSort;


                  if(bookingModule.group_availablity ===2){
                        selectTicketRef.current.setValue(null)
                  }
            }
           
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[bookingModule.ticketTypes.length])

      const calenderHandler = ()=>{
            // event.PreventDefault();
            let ticketTypeSelected = false;
            if(bookingModule.group_availablity ===2){
                  bookingModule.ticketTypes.forEach(el=>{
                        if(el.selected){
                              ticketTypeSelected = true;
                        }
                  })
                  if(!ticketTypeSelected){
                        return;
                  }
            }
            if(isCalOpen){
                       
                  if(bookingModule.group_availablity ===2){ 
                        dispatch(setTicketTypes({ticketTypes:[]}))
                  }
                  dispatch(resetForAnimation())
                  setSelectedDate(null);
                  // dispatch(setPerformanceInfo({data:null}));
                  // dispatch(setShowId({showId:null}));
            }
            setIsCalOpen(!isCalOpen)
      };

      const filterAvailableDates = (date) => {

            const date2Digit = "0"+date.getDate();
            const date1 =date2Digit.slice(-2) +"-" +("0" + (date.getMonth() + 1)).slice(-2) +"-" +date.getFullYear();
           
            if (eventDetail.scheduleInfo) {
                  if(eventDetail.scheduleInfo.avalability.hasOwnProperty(date1)){
                        let ctAlertCheck = "";
                        ctAlertCheck = eventDetail.scheduleInfo.avalability[date1].color_class;
                        return ctAlertCheck === "ct_alert" ? false : true;
                        // return true;
                  } 
                  else {
                        return false;
                  }

            } else {

                  return false;
            }
      };

      const getClassNamesForAvailableDates = (date)=>{

            const date2Digit = "0"+date.getDate();
            const date1 =date2Digit.slice(-2) +"-" +("0" + (date.getMonth() + 1)).slice(-2) +"-" +date.getFullYear();

            if (eventDetail.scheduleInfo) {
                  if(eventDetail.scheduleInfo.avalability.hasOwnProperty(date1)) {
                        return eventDetail.scheduleInfo.avalability[date1].color_class;
                  } 
                  else {
                        return undefined;
                  }

            } else {
                  return undefined;
            }
      };

      const onClickDateHandler = (value) => {
            
            if(isLoading){
                  return;
            }
            
            dispatch(setPerformanceInfo({data:null}));
            dispatch(setShowId({showId:null}));
            

            const date1 = getDateInddmmyyyyFormat(value);
            if (eventDetail.scheduleInfo.avalability.hasOwnProperty(date1)) {
                  const shows = eventDetail.scheduleInfo.avalability[date1].shows;
                  setListSchedule(shows);
                  setSelectedDate(value);
            }
      };

      const getDateInddmmyyyyFormat = (value)=>{

            const date2Digit = "0"+value.getDate();
            const date1 =date2Digit.slice(-2) +"-" +("0" + (value.getMonth() + 1)).slice(-2) +"-" +value.getFullYear();
            return date1;
      };

      const formatOpeningDateHandler = ()=>{
            const date1Array = Object.keys(eventDetail.scheduleInfo.avalability)[0].split("-");
            const finalDateFormat = date1Array[2]+"/"+date1Array[1]+"/"+date1Array[0];
            return new Date(finalDateFormat)
      };

      const formatDateInUKFormatHandler = (date)=>{

            const dat = getDateInddmmyyyyFormat(date);
            // old code which not working in android imulator
            // return dat.replaceAll("-","/");

            return dat.replace(/-/g, "/")
            // return '10/04/2023';
      };

      const setShowIdHandler = (showId,colorClass) => {
            
            if(isLoading){
                  return;
            }
            if(colorClass === 'ct_alert'){
                  return;
            }

            dispatch(resetForAnimation())
            // dispatch(setSecureData({data:null}));      
            // dispatch(setPerformanceInfo({data:null}));
            dispatch(setShowId({showId:showId}));

            dispatch(setSpinner({status:"request"}));

            let ticket_type_id = ""
            if(bookingModule.group_availablity ===2) {
                  bookingModule.ticketTypes.forEach(el=>{
                        if(el.selected){
                              ticket_type_id = el.id;
                        }
                  })
            }

            // getPerformanceInfo({"performance_id":showId});  :- widget api fisrtstly decided to use then revoke decision
            bookingModule.group_availablity===2 ? 
                  getDetailsTicPer({"show_id":showId,group_availablity : bookingModule.group_availablity, ticket_type_id :ticket_type_id}) : 
                  getPerformanceInfo({"show_id":showId});

      };

      const initialPriceQuantityHandler = (groups,price)=> {
           
            let Updateprice = 0;
            let quantity = 0;
            let subTotal = 0;
            if(bookingModule.group_availablity===2) {
                  Updateprice       = parseFloat(price);
                  quantity          = groups[0] || 0;
                  subTotal          = price*quantity;
            } else {
                 
                 
                  Updateprice       = parseFloat(eventDetail.eventInfo.price);
                  quantity          = groups[0] || 0;
                  subTotal          = parseFloat(Updateprice * quantity);
            }

            dispatch(initialPriceQuantity({price:Updateprice,quantity:quantity,subTotal:subTotal,max_tickets:eventDetail.eventInfo.max_tickets}))

      }

      const changeTicketsHandler =(event) => {
            dispatch(setValidatingMessage({validatingMessage:""}));
            const updateTickets = parseInt(event.target.value) || 0;
            
            if(updateTickets > 0) {

                  if(bookingModule.group_availablity!==2){
                        if (updateTickets > bookingModule.performanceInfo.user_remain) {

                              dispatch(setValidatingMessage({validatingMessage:bookingModule.performanceInfo.user_remain  + " tickets allowed (max)"}));
                        }
                        else if (updateTickets > bookingModule.performanceInfo.total_remain) {
      
                              const message = bookingModule.performanceInfo.validation_message ? bookingModule.performanceInfo.validation_message : "only "+bookingModule.performanceInfo.total_remain+" ticket left";
                              dispatch(setValidatingMessage({validatingMessage:message}));
      
                        }
                  }

                  const subTotal = bookingModule.bookingDetails.price * updateTickets;
                  // ---------------Code For PromoCode-----------------------//
                  if(bookingModule.bookingDetails.promocode.status === "valid"){
                        let ticket_type_master_id = bookingModule.performanceInfo.ticket_type_master_id

                        const data = {
                              show_id:bookingModule.bookingDetails.showId,
                              event_id:bookingModule.event_id,
                              promocode:promoCode.value,
                              tickets:updateTickets,
                              ticket_type_master_id:ticket_type_master_id,
                              group_availablity:bookingModule.group_availablity,
                        }

                        dispatch(setSpinner({status:"request"}))
                        applyPrmocode(data)

                  }
                  const flexAmount = bookingModule.bookingDetails.isFlexi ? flexiHandler(bookingModule.bookingDetails.price,updateTickets) : 0;
                  dispatch(ticketQuantityHandler({newQuantity:updateTickets,subTotal:subTotal + flexAmount,isFlexi:bookingModule.bookingDetails.isFlexi,flexAmount:flexAmount}));
            }   

      }

      const flexiChangeHandler = (event)=> {
            const isFlexi = event.target.checked;
            let flexAmount = 0;
            let subTotal = bookingModule.bookingDetails.price * bookingModule.bookingDetails.quantity;
            flexAmount = isFlexi ? flexiHandler(bookingModule.bookingDetails.price,bookingModule.bookingDetails.quantity) : 0;
            dispatch(setFlexiHandler({isFlexi:isFlexi,flexAmount:flexAmount,subTotal:subTotal + flexAmount}));
      }

      const promocodeChangeHandler = (event)=> {
            const promocodeValue = event.target.value.toUpperCase();
            setPromoCode(prevState=>{
                  return {
                        ...prevState,
                        value:promocodeValue
                  }
            });
      }

      const clearPromocodeErrorHandler = ()=> {

            if(bookingModule.bookingDetails.promocode.status === "invalid"){
                  dispatch(removePromoCode())
            }
            setPromoCode(prevState=>{
                  return {
                        ...prevState,
                        error:false,
                        errorMessage:""
                  }
            });
      }

      const promoCodeApplyHandler = ()=> {

            if(bookingModule.bookingDetails.promocode.status !== "valid" && bookingModule.bookingDetails.subTotal > 0){

                  const checkValidation = checkValidationPromoCode(promoCode.value,NameConstants.promoCode);
                  if(checkValidation.isValid){

                        let ticket_type_master_id = bookingModule.performanceInfo.ticket_type_master_id

                        const data = {
                              show_id:bookingModule.bookingDetails.showId,
                              event_id:bookingModule.event_id,
                              promocode:promoCode.value,
                              tickets:bookingModule.bookingDetails.quantity,
                              ticket_type_master_id:ticket_type_master_id,
                              group_availablity:bookingModule.group_availablity,
                        }

                        dispatch(setSpinner({status:"request"}))
                        applyPrmocode(data)

                  } else {
                        setPromoCode(prevState=>{
                              return {
                                    ...prevState,
                                    error:true,
                                    errorMessage:checkValidation.validatingMessage
                              }
                        });
                  }
              
            }
            
      }

      const removePromoCodeHandler = ()=>{
            
            setPromoCode(prevState=>{
                  return {
                        value:"",
                        error:false,
                        errorMessage:""
                  }
            });
            dispatch(removePromoCode())

      }

      const promoCodeCalculatorHandler = (subTotal,updateTickets)=>{

            let discount=0;
            let totalAmount=0;
            if(bookingModule.bookingDetails.promocode.discount_type === "%"){
                  discount = ((bookingModule.bookingDetails.promocode.main_discount/100)*subTotal).toFixed(2);
                  totalAmount = (subTotal - discount);
            }
            else if(bookingModule.bookingDetails.promocode.discount_type === "rs" && bookingModule.bookingDetails.promocode.amount_type === "O"){

                  discount = bookingModule.bookingDetails.promocode.main_discount;
                  totalAmount = (subTotal - discount);
            }
            else if(bookingModule.bookingDetails.promocode.discount_type === "rs" && bookingModule.bookingDetails.promocode.amount_type === "T"){

                  discount = bookingModule.bookingDetails.promocode.main_discount*updateTickets;
                  totalAmount = (subTotal - discount) >0 ? (subTotal - discount) : 0;
            }

            dispatch(setValueForPromoCodes({totalAmount:parseFloat(totalAmount),discount:parseFloat(discount),mainAmount:parseFloat(subTotal)}))
      }

      const changeCheckBoxHandler = (event)=> {
            dispatch(changeCheckBox({checkBox:event.target.checked}));
      }

      const setCollectionInstructionHandler = (event) => {
            event.preventDefault();
            let message = eventDetail.eventInfo.collection_instruction;
            if(bookingModule.group_availablity ===2 && bookingModule.performanceInfo){
                  message = bookingModule.performanceInfo.collection_instruction;
            }

            dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:message}))
      };

      const ContinueAddingTicketsHandler = (event)=> {
            event.preventDefault()

            if(bookingModule.bookingDetails.quantity > 0) {

                  if(bookingModule.group_availablity !== 2){

                        if(bookingModule.bookingDetails.quantity > bookingModule.performanceInfo.total_remain){
            
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:"There aren't enough available tickets to fulfil your order. Some tickets are mid-checkout, and may be released back into circulation. Refresh the page and try again"}));
                              dispatch(setBookingHandler({isBooking:false}));
                              dispatch(resetForAnimation());
                              return;
                        }
                  }
      
                  if (bookingModule.bookingDetails.checkBox) {
                        dispatch(setSpinner({status:"request"}));

                              let ticket_type_master_id = bookingModule.performanceInfo.ticket_type_master_id

                              const data = {
                                    performance_id : bookingModule.bookingDetails.showId,
                                    tickets : bookingModule.bookingDetails.quantity,
                                    group_availablity:bookingModule.group_availablity,
                                    ticket_type_master_id : ticket_type_master_id
                              }
                              ticketBlock(data);

                  }
                  else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:"Please check that you have understood the collection instructions"}))
                  }
            }
            
      }

      const changeTypeSelectedHandler = (event)=>{
            
            event.preventDefault();
            dispatch(resetForAnimation())
            if(bookingModule.group_availablity ===2) {
                  const value             =     event.target.value;
                  let copyArray           =     [];

                  copyArray = bookingModule.ticketTypes.map(el=>{
                        return {...el,selected:false}
                  })

                  const findIndex         =     copyArray.findIndex(el=>el.name===value);
                  let updateElement       =     {...copyArray[findIndex]};
                  updateElement           =     {...updateElement,selected:true}
                  copyArray[findIndex]    =       updateElement;
                  dispatch(setTicketTypes({ticketTypes:copyArray}))
                  setGetDates(true);
            } 
      }

      const flexiModalHandler = ()=> {

            if(ct_web_view_session) {
                  props.flexiModalHandler();
                  return;
            }  

            window.open(routesName.FlexiTerms, '_blank');
      }


      let items = [];
      let disablePromoCode = false;
      let disableBookNow = false;
      let displaySubtotalOnChooseDate = parseFloat(bookingModule.bookingDetails.subTotal).toFixed(2);
      let displaySelectTicketType = false;
      let isAllowCalander = false;
      let flexiPerTicket;
      let windowInnerWidth = window.innerWidth

      if(bookingModule.group_availablity !==2) {
            items = bookingModule.ticketTypes;
            disablePromoCode = bookingModule.bookingDetails.promocode.status === "valid";
            disablePromoCode = bookingModule.bookingDetails.subTotal === 0
            disableBookNow = bookingModule.bookingDetails.quantity > 0;
            if(bookingModule.ticketTypes.length > 0){
                  displaySelectTicketType = true;
            }
            isAllowCalander = true;

            flexiPerTicket = flexiHandler(bookingModule.bookingDetails.price,1).toFixed(2)
      }

      if(bookingModule.group_availablity ===2) {

            disablePromoCode = bookingModule.bookingDetails.subTotal === 0
            disableBookNow = bookingModule.bookingDetails.quantity > 0;
            if(bookingModule.ticketTypes.length > 0){
                  displaySelectTicketType = true;
            }
            bookingModule.ticketTypes.forEach(el=>{
                  if(el.selected){
                        isAllowCalander = true;
                        items.push(el.name)
                  }
            })
            flexiPerTicket = flexiHandler(bookingModule.bookingDetails.price,1).toFixed(2)
      }
      
      if(bookingModule.bookingDetails.promocode.status === "valid") {
            displaySubtotalOnChooseDate = parseFloat(bookingModule.bookingDetails.promocode.totalAmount + bookingModule.bookingDetails.flexAmount).toFixed(2);
      }

      return (
            <div className={["bookTicketsStep1__wrapper"].join(" ")}>
                  <div id="bookTicketsStep1" className={["content content__choose-date d-flex"].join(" ")}>
                        <div className="content__body">

                              <h4 id="contentTitle" className="event__name webview__content__title">
                                    {bookingModule.title}
                              </h4>
                              {/* {displaySelectTicketType && 
                              <SelectComponent id="selectTicketType" onChange={changeTypeSelectedHandler} placeholder="Select Ticket Type" items={items} update={false}
                              divClass={(displaySelectTicketType && bookingModule.group_availablity !==2) ? "hideDropdownArrow" :""} disabled={bookingModule.group_availablity !==2}>
                                    {(bookingModule.ticketTypes.length > 0 && bookingModule.group_availablity !==2) && bookingModule.ticketTypes.map((el,index)=>{
                                          return <option key={`tickettype${index}`}  value={el}>{el}</option>
                                    })} 
                                    {(bookingModule.ticketTypes.length > 0 && bookingModule.group_availablity ===2) && bookingModule.ticketTypes.map((el,index)=>{
                                          return <option key={`${el.id}${index}`} value={el.name}>{el.name}</option>
                                    })}   

                              </SelectComponent>} */}
                              {displaySelectTicketType && 
                              <div className={["form-group",(displaySelectTicketType && bookingModule.group_availablity !==2) ? "hideDropdownArrow" :"",items.length>0 ? "selectedTickets" :""].join(" ")}>
                                    <select id="selectTicketType" className="form-control tomselect tomselect-xl-lg" autoComplete="off" onChange={changeTypeSelectedHandler} name={props.name}  placeholder="Select Ticket Type" disabled={bookingModule.group_availablity !==2} items={items} update={false} ref={selectTicketRef}>
                                          {(bookingModule.ticketTypes.length > 0 && bookingModule.group_availablity !==2) && bookingModule.ticketTypes.map((el,index)=>{
                                                return <option key={`tickettype${index}`}  value={el}>{el}</option>
                                          })} 
                                          {(bookingModule.ticketTypes.length > 0 && bookingModule.group_availablity ===2) && bookingModule.ticketTypes.map((el,index)=>{
                                                return <option key={`${el.id}${index}`} value={el.name}>{el.name}</option>
                                          })} 
                                    </select>
                              </div>}
                              
                              {/* <!-- /.form-group --> */}
                              <div className="form-group">
                                    <div className="content__select-time--wrapper">
                                          {/* <input id="selectEventDate" className="form-control__datepicker datepicker-xl-lg" placeholder="Select Date"> */}
                                          {/* <button id="btnSelectEventDate" className="btn datepicker__trigger" onClick={calenderHandler}>{selectedDate ? formatDateInUKFormatHandler(selectedDate) : "Select Date"}</button> */}
                                          <div id="btnSelectEventDate" className="btn datepicker__trigger" onClick={calenderHandler}>{selectedDate ? formatDateInUKFormatHandler(selectedDate) : "Select Date"}</div>
                                    
                                          {isCalOpen && <div className={["content__select-time as-dropdown d-flex flex-column flex-md-row",ct_web_view_session && "new_content__select-time"].join(" ")}>
                                                {/* This code is only for app */}
                                                {ct_web_view_session && <><p className="p-label new_checkour_date_title">Select Date</p>
                                                <h4 id="contentTitle" className="event__name webview__content__title new_checkout_event_title webview__content__hide-title">
                                                      {bookingModule.title}
                                                </h4></>}
                                                {/* This code is only for app */}
                                                <div className="col__left">
                                                      <div id="selectEventDate" className="datepicker-container">
                                                            {isAllowCalander && 
                                                            <DatePicker className="form-control__datepicker datepicker-xl-lg" 
                                                            dateFormat="dd-MM-yyyy" 
                                                            placeholderText="Select Date" 
                                                            minDate={new Date()} 
                                                            selected={selectedDate}
                                                            calendarStartDay={1} 
                                                            filterDate={(date)=>filterAvailableDates(date)}
                                                            dayClassName={(date) =>getClassNamesForAvailableDates(date)}
                                                            inline
                                                            onChange={(date:Date) => onClickDateHandler(date)}
                                                            openToDate={formatOpeningDateHandler()}
                                                            />}
                                                            
                                                      </div>
                                                </div>
                                                {/* <!-- /.col__left --> */}
                                                <div className="col__right">
                                                      {!ct_web_view_session &&<p className="p-label">Select date</p>}
                                                      {(!ct_web_view_session  && windowInnerWidth > 767) && <div id="contentSelectEventTime" className="d-block">
                                                            <p className="p-label mb-0">Selected date:</p>
                                                            {/* <!-- set #chooseShowTime__value from date picked --> */}
                                                            {selectedDate && 
                                                            <>
                                                                  <p className="p-value">
                                                                        <span id="eventDateValue">
                                                                              {formatDateInUKFormatHandler(selectedDate)}
                                                                        </span>
                                                                  </p>
                                                                  <p className="p-label">Select time</p>
                                                                  {listSchedule.length > 0 && listSchedule.map((el,index)=>{
                                                                        let colorClass = "";
                                                                        bookingModule.group_availablity ===2 ? colorClass = el.color_class.class ? el.color_class.class : el.color_class : colorClass = el.color_class;

                                                                        return (
                                                                              <div className={["btn-check-wrapper",colorClass].join(" ")} key={el.show_id} disabled={isLoading}>
                                                                                    <input id={`radioSelectEventTime${index}`} className="form-check-input btn-check btn-check__select-time" type="radio" name="radioSelectEventTime" value={el.start_time}/>
                                                                                    <label className="btn btn-outline-primary" htmlFor={`radioSelectEventTime${index}`}
                                                                                    onClick={()=>setShowIdHandler(el.show_id,colorClass)}>
                                                                                          {el.start_time}
                                                                                    </label>
                                                                              </div>
                                                                        )
                                                                  })}
                                                            </>}
                                                      </div>}
                                                      {(ct_web_view_session  || windowInnerWidth <= 767) && <div id="contentSelectEventTime" className="d-block mobile_view_style web__view_style">
                                                            <div className='selected-date'>
                                                                  <p className="p-label">Selected date:</p>
                                                                  {selectedDate && <p className="p-value">
                                                                        <span id="eventDateValue">
                                                                              {formatDateInUKFormatHandler(selectedDate)}
                                                                        </span>
                                                                  </p>}
                                                            </div>
                                                            {selectedDate && 
                                                            <div className='selected-time'>
                                                                  <p className="p-label">Select time</p>
                                                                  
                                                                        {listSchedule.length > 0 && listSchedule.map((el,index)=>{
                                                                        let colorClass = "";
                                                                        bookingModule.group_availablity ===2 ? colorClass = el.color_class.class : colorClass = el.color_class;

                                                                              return (
                                                                                    <div className={["btn-check-wrapper",colorClass].join(" ")} key={el.show_id} disabled={isLoading}>
                                                                                          <input id={`radioSelectEventTime${index}`} className="form-check-input btn-check btn-check__select-time" type="radio" name="radioSelectEventTime" value={el.start_time}/>
                                                                                          <label className="btn btn-outline-primary" htmlFor={`radioSelectEventTime${index}`}
                                                                                          onClick={()=>setShowIdHandler(el.show_id,colorClass)}>
                                                                                                {el.start_time}
                                                                                          </label>
                                                                                    </div>
                                                                              )
                                                                        })}
                                                            </div>}
                                                            {/* <!-- set #chooseShowTime__value from date picked --> */}
                                                      </div>}
                                                      {/* <!-- /#contentSelectEventTime --> */}
                                                </div>
                                                {/* <!-- /.col__right --> */}
                                                <button type="button" className={["btn-close btn-close__custom",ct_web_view_session ? "new_checkout_close__button" : ""].join(" ")} onClick={calenderHandler}>
                                                </button>
                                          </div>}
                                          {/* <!-- /.content__select-time --> */}
            
                                    </div>
                                    {/* <!-- /.form-datepicker_btn-triggered --> */}
            
                              </div>
                              {/* <!-- /.form-group --> */}

                              {bookingModule.performanceInfo && <SelectComponent id="selectTicketsTotal" 
                                    onChange={changeTicketsHandler} 
                                    placeholder="Select Ticket Number" 
                                    items={[bookingModule.bookingDetails.quantity || 0]}
                                    update={false}
                                    >
                                    {(bookingModule.performanceInfo && bookingModule.performanceInfo.groups.length>0) && bookingModule.performanceInfo.groups.map((el,index)=>{
                                          return <option key={`selecttickets${index}`} value={el}>{el}</option>
                                    })}
                              </SelectComponent>}
                              {/* <!-- /.form-group --> */}

                              {bookingModule.performanceInfo &&
                              <>
                                    <div className="checkbox-wrapper checkbox-wrapper__flexiticket justify-content-between" style={{"opacity":bookingModule.performanceInfo.is_flexi_booking !==1 && "0.5"}}>
                                          <div className="custom-control custom-checkbox custom-checkbox__check">
                                                <input type="checkbox" className="custom-control-input" id="checkFlexiTicket" 
                                                      checked={bookingModule.bookingDetails.isFlexi} 
                                                      onChange={flexiChangeHandler} disabled={bookingModule.performanceInfo.is_flexi_booking !==1}/>
                                                <label className="custom-control-label" htmlFor="checkFlexiTicket">
                                                      Make your tickets <span className="fw-bold red">FLEXI</span> (£{flexiPerTicket} per ticket)
                                                </label>
                                          </div>
                                          <div className="flexi-price">+ £{bookingModule.bookingDetails.flexAmount.toFixed(2)}</div>
                                    </div>
                                    <p className="small">
                                          Peace of mind to transfer or cancel your booking if you can <br className="d-none d-md-block"/>no longer attend. (<span className="anchor-style" style={{"color":"var(--bs-red)","cursor":"pointer"}} onClick={flexiModalHandler} >T&C’s</span> apply).
                                    </p>
                              </>}
                              {bookingModule.performanceInfo &&
                              <div className="promo-code-block">
                                    <div className="promo-code__form-group d-flex align-items-center" style={{marginBottom : (promoCode.error || bookingModule.bookingDetails.promocode.status === "invalid") ? "0px" : ""}}>
                                          <div className="form-group mb-0">
                                                <label htmlFor="inputPromoCode" className="form-label visually-hidden" >
                                                      Apply a promotional code
                                                </label>
                                                <input type="text" className="form-control" id="inputPromoCode" placeholder="Apply a promotional code"
                                                      onChange={promocodeChangeHandler} value={promoCode.value} onFocus={clearPromocodeErrorHandler} disabled={disablePromoCode}/>
                                                      
                                          </div>
                                          
                                          <button className="btn btn-outline-primary btn__plus-to-x" type="button" aria-label="Apply promo" onClick={promoCodeApplyHandler}  disabled={disablePromoCode}>
                                                <span className="icon__plus"></span>
                                          </button>
                                          <br></br>
                                    
                                    </div>
                                    {(promoCode.error || bookingModule.bookingDetails.promocode.status === "invalid" ) && <label htmlFor="inputPromoCode" className="form-label form-label-error" >
                                          {promoCode.errorMessage || bookingModule.bookingDetails.promocode.message}
                                    </label>}
                                    
                                    {bookingModule.bookingDetails.promocode.status === "valid" &&
                                    
                                    <div className="promo-code__item">
                                          <div className="promo-code">{bookingModule.bookingDetails.promocode.promocode}</div>
                                          <div className="promo-code-value-wrapper">- <span className="promo-code-value">£{parseFloat(bookingModule.bookingDetails.promocode.discount).toFixed(2) || bookingModule.bookingDetails.promocode.discount}</span></div>
                                          <button type="button" className="btn btn-circle-x ms-2" aria-label="Remove promo" onClick={removePromoCodeHandler}>
                                                <span className="icon__plus"></span>
                                          </button>
                                    </div>}
                              </div>}
                              {/* <!-- /.promo-code-block --> */}

                        </div>
                        {/* <!-- /.content__body --> */}

                        
                        {/* <!-- /.content__body --> */}
                       
                       
                  </div>

                  <div className="content__footer__wrapper">
                        <div className="content__body content__body--total border-top">
                              <div className="subtotal-block">
                                    <div className="label">Total (VAT inc.):</div>
                                    <div className="total-amount">£{displaySubtotalOnChooseDate}</div>
                              </div>

                              <div className="custom-control custom-checkbox custom-checkbox-sm custom-checkbox__check">
                                    <input type="checkbox" className="custom-control-input" id="checkUnderstoodCollectionInstructions" checked={bookingModule.bookingDetails.checkBox} onChange={changeCheckBoxHandler} required/>
                                    <label className="custom-control-label" htmlFor="checkUnderstoodCollectionInstructions">
                                          Click to confirm that you have understood the <a className="text-decoration-underline" href="/" onClick={setCollectionInstructionHandler} >collection instructions</a> for this
                                          event.
                                    </label>
                              </div>
                        </div>
                        <div className="content__footer border-top">
                              <div className="buttons-wrapper gap-0">
                                    <button id="btnContinueStep2" type="button" className="btn btn-primary w-100" onClick={ContinueAddingTicketsHandler} style={{"cursor": !disableBookNow ? "not-allowed" : "" }}>
                                          Book now
                                    </button>
                              </div>
                        </div>
                  </div>
                  {/* <!-- /.content__footer --> */}
            </div>
      )
}

export default ChooseDate;
