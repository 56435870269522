import React, { useEffect } from 'react'

// external css

// small class
import ticketFlexiGreen1 from "./../../../../assets/images/tickets/ticket-uptake-venues-green1.svg";

import ticketFlexiRed from "./../../../../assets/images/tickets/ticket-faq-big-heading1-red.svg";
import ticketFlexiAquamarineSm from "./../../../../assets/images/tickets/ticket-uptake-venues-aquamarine-sm.svg";

import ticketFlexiOrange1 from "./../../../../assets/images/tickets/ticket-faq-big-heading1-orange.svg";
import ticketFlexiBlue1 from "./../../../../assets/images/tickets/ticket-my-bookings-big-heading1-blue.svg";

// redux and react hooks
import { useDispatch } from 'react-redux';
// child component
// packages
// Actions & RTK query or mutations
import { useLazyGetFlexiQuery } from '../../../../Services/modules/Others';
import { setAlert } from '../../../../Store/UI';

// Constants
import { errors, messages } from '../../../../resources/en';

const FlexiTerms = ({closeFlexiModal}) => {

      const dispatch    =     useDispatch();
      const [getFlexi, { data, isSuccess, error, isLoading }] = useLazyGetFlexiQuery();


      useEffect(()=>{
            document.title = "Flexi Ticket Terms and Conditions";
            getFlexi();

      },[getFlexi]);

      useEffect(()=>{
            if(isSuccess) {
                  if(data.status=== "1"){
                  }
                  else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
      
      },[data, isSuccess, error, isLoading, dispatch])

      return (

                  <React.Fragment>
                        <div className="modal modal__fw modal__terms-and-conditions slideInDown show" id="modalFlexiTermsAndConditions" data-bs-backdrop="static" tabIndex="-1" aria-hidden="false">
                              <div className="modal-dialog">
                                    <div className="modal-content">
                                          <div className="modal-body">
                                                <button type="button" className="btn-close btn-close__custom btn-close__absolute" 
                                                // data-bs-dismiss="modal" aria-label="Close" 
                                                onClick={closeFlexiModal}>
                                                </button>
                                                <div className="content d-flex flex-column flex-xl-row"  data-scroll-container>
                                                      {/* <!-- /.content__body --> */}
                                                      <div className="content__body content-flexi-modal">
                                                            <h2 className="heading" style={{textAlign : "center"}}>Flexi Ticket Terms <br />and Conditions</h2>
                                                            <picture>
                                                                  <img data-scroll className="ticket ticket-blue"
                                                                  src={ticketFlexiBlue1} alt=""/>
                                                            </picture>
                                                            <picture>
                                                                  <img data-scroll className="ticket ticket-green1"
                                                                        src={ticketFlexiGreen1} alt="" />
                                                            </picture>
                                                            <picture>
                                                                  <img data-scroll className="ticket ticket-AquamarineSm"
                                                                  src={ticketFlexiAquamarineSm} alt="" />
                                                            </picture>
                                                            <picture>
                                                                  <img data-scroll="" className="ticket ticket-red d-lg-block"
                                                                  src={ticketFlexiRed} alt="" />
                                                            </picture>
                                                            <picture>
                                                                  <img data-scroll="" className="ticket ticket-orange1 d-lg-block"
                                                                  src={ticketFlexiOrange1} alt="" />
                                                            </picture>

                                                            {data && <div dangerouslySetInnerHTML={{ __html: data.data }}></div> }
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}

                                          </div>
                                          {/* <!-- /.modal-body --> */}
                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>
                        {/* <!-- modal #modalTermsAndConditions --> */}
                        <div className="modal-backdrop fade show" style={{"top": "91px"}}></div>
                  </React.Fragment>
      )
}

export default FlexiTerms;
