
import { validation } from "./../resources/en";
import { NameConstants } from "../resources/NameConstants"

const loginValidationRules=(value, type) =>{

      const textMessage = validation;

      let isValid = true;
      let validationMessage = "";

      if (!type) {
            return true;
      }
      
      if(type === NameConstants.PassWord){
            if(value.trim()=== "")
            {
                  isValid =false;
                  validationMessage = textMessage[type].required;
            }
            // else if(value.length < 7)
            // {
            //       isValid=false;
            //       validationMessage = textMessage[type].minLength;
            // }
            
      }
      
      if(type === NameConstants.Email){
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!value || value.trim() === "")
            {
                  isValid = false;
                  validationMessage = textMessage[type].required;
            }
            else if(!re.test(String(value).toLowerCase()) || !value)
            {
                  isValid = false;
                  validationMessage = textMessage[type].invalid;
            }
      }

      return {isValid,validationMessage};
}

export default loginValidationRules;