import React, { useState, useEffect } from 'react'

// external css

// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';


// child component
import { HeadingPictureComponent } from '../../Components';
import { AccordianItem } from '../../Components';
import Footer from '../../Layouts/Footer/Footer';

// packages
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { useLazyGetFaqQuery } from '../../Services/modules/Others';
import { setAlert, updateLocoScroll } from '../../Store/UI';
import { setScrollToTarget } from '../../Store/UI';

// Constants
import { errors, messages } from '../../resources/en';
import { MetaData, MetaDataAj, MetaDataTfm } from '../../resources/MetaData';
import { Config } from '../../Config';

const Faq = () => {

      const [scroll,setScroll] = useState(false);
      const [getFaq, { data, isSuccess, error, isLoading }] = useLazyGetFaqQuery();

      const dispatch                            =     useDispatch();
      const location                            =     useLocation();
      const domain                              =     useSelector(state=>state.user.domain)

      useEffect(()=>{
            document.title = "FAQ";
            getFaq();
      },[getFaq])

      useEffect(()=>{
            if(isSuccess) {
                  if(data.status === "1"){
                        setScroll(true);

                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.errors || data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
      
      },[data, isSuccess, error, isLoading, dispatch])

      useEffect(()=>{
            if(scroll) {
                  setScroll(false)
                  dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
            }

            let newStr = location.search.replace(/\?/g, "");

            if(newStr){

                  let parentElement  = document.querySelector(`#${newStr}.accordion-header`); // select the span element by ID

                  if(parentElement){

                        if(!parentElement.querySelector("i")){
                              let buttonElement = parentElement.querySelector(".accordion-button")
                              buttonElement.setAttribute("aria-expanded", "true");
                              var nextElement = parentElement.nextElementSibling;
                              if(nextElement){
                                    nextElement.classList.add("show");
                              }

                              let newTag  = '<i class="fa fa-arrow-right blink" aria-hidden="true"></i>'; // create a new "span" element
                              buttonElement.insertAdjacentHTML("afterbegin", newTag);

                              dispatch(setScrollToTarget({scrollTo : newStr}))
                        }

                  }

            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[scroll,dispatch])


      let newStr =  location.search ? location.search.replace(/\?/g, "") : "";
      let metaDataSite = MetaData
      if(domain === Config.DOMAIN_CONSTANT_TFM){
            metaDataSite = MetaDataTfm
      } else if(domain === Config.DOMAIN_CONSTANT_AJTIX){
            metaDataSite = MetaDataAj
      }
      

      return (
            <>
                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <div className={["scroll-animations position-relative"].join(" ")} data-scroll-container>
                        <Helmet>
                              <title>{metaDataSite.Faqs.title}</title>
                              <meta name="description" content={metaDataSite.Faqs.description} />
                              <meta name="keywords" content={metaDataSite.Faqs.keywords} />
                        </Helmet>
                        <main>
                              <section data-scroll-section className="section__faq pt-0">
                                    <div className="container-fluid container__block">
                                          <div className="row">
                                                <div className="col-md-12">
                                                      <div className="content content__faq mx-auto">
                                                            <div className="content__header text-center overflow-hidden">
                                                                  <div className="big-heading">
                                                                        <h1 className="mb-0">Our frequently<br/>asked questions</h1>
                                                                        {domain === "" &&
                                                                        <>
                                                                        <HeadingPictureComponent dataScroll className="ticket-orange" srcSet="ticket-faq-big-heading1-orange.svg" src="ticket-faq-big-heading1-green-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-blue" srcSet="ticket-faq-big-heading1-blue.svg" src="ticket-faq-big-heading1-blue-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-aquamarine" srcSet="ticket-faq-big-heading1-aquamarine.svg" src="ticket-faq-big-heading1-orange-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-yellow" srcSet="ticket-faq-big-heading1-yellow.svg" src="ticket-faq-big-heading1-aquamarine-sm.svg"/>

                                                                        <HeadingPictureComponent dataScroll className="ticket-red" srcSet="ticket-faq-big-heading1-red.svg" src="ticket-faq-big-heading1-red-sm.svg"/>
                                                                        </>}

                                                                  </div>
                                                            </div>
                                                            {/* <!-- .content__header --> */}
                                                            <div className="content__body">

                                                                  <div className="accordion accordion-line-separated" id="accordionFAQ">

                                                                        {data && data.data.map((item)=>{

                                                                              return <AccordianItem key={`${item.question}+${item.id}`} {...item} id={`faqHeading${item.id}`} collapseId={`faqCollapse${item.id}`} showContent={newStr === `faqHeading${item.id}`}/>
                                                                               
                                                                        })}
                                                                        {/* <!-- /.accordion-item --> */}

                                                                  </div>
                                                                  {/* <!-- .accordion --> */}

                                                            </div>
                                                            {/* <!-- .content__body --> */}
                                                      </div>
                                                      {/* <!-- .content.content__faq --> */}

                                                </div>
                                                {/* <!-- .col-md-12 --> */}
                                          </div>
                                          {/* <!-- /.row --> */}
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              {/* <!-- /.section__faq --> */}
                        </main>

                        <Footer/>

                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}
            </>
      )
}

export default Faq;
