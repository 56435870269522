import React from 'react'

// external css
// redux and react hooks
import { useDispatch } from 'react-redux';

// child component
// packages
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Actions & RTK query or mutations
import { setStep, setShowDetails } from '../../../Store/ContactUs';

// Constants
import { dateFormathandler, timeFormatHandler } from "../../../resources/DateFormatForCurrBookings";
import { NameConstants } from '../../../resources/NameConstants';

const SingleListBooking = (props) => {

      const { afterLoad, ...rest} = props;
      const dispatch = useDispatch();

      const onSelectShowHandler = ()=>{
            dispatch(setShowDetails({showDetails : rest}))
            dispatch(setStep({step : NameConstants.MemberEventDetail }));
      }

      const startDateArray = rest.start_date.split("-");
      const startDate =  startDateArray[1] + "-"+startDateArray[0] +"-" + startDateArray[2]

      return (
            <li>
                  <div className="card card__event flex-row flex-wrap mx-auto">
                        <div className="card__event-image">
                              {/* replace div with tag a */}
                              <div className={props.vendor === "TTG" ? "ttg-image-wrapper" : ""} style={{backgroundImage : props.vendor === "TTG" ? `Url(${props.blur_event_image})` : ""}}>
                              <div>
                                    {/* <img className="event-image" srcSet={props.event_image} src={props.event_image} alt=""/> */}
                                    <LazyLoadImage className="event-image" src={rest.event_image} srcSet={rest.event_image} afterLoad={afterLoad} effect={props.vendor === "TTG" ? "blur" : ""}/>
                              </div>
                              </div>
                        </div>
                        <div className="card-body d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between py-0">
                              <div className="col-left">
                                    <h5 className="card-title fw-bold">
                                          {/* replace div with tag a */}
                                          <div>{rest.event_name}</div>
                                    </h5>
                                    <ul className="list__event-details list-unstyled">
                                          <li>{rest.tickets} {rest.tickets > 1 ? "Tickets" : "Ticket"}</li>
                                          {/* <li>{dateFormathandler(rest.start_date)  +' – '+ timeFormatHandler(rest.start_time) }</li> */}
                                          <li>{dateFormathandler(startDate)  +' – '+ timeFormatHandler(rest.start_time) }</li>
                                          <li>{rest.Location}</li>
                                    </ul>
                              </div>
                              <div className="col-right">
                                    <div className="buttons-wrapper flex-wrap my-lg-auto">
                                          <button className="btn btn-primary custom_contact_button" onClick={onSelectShowHandler}>Select</button>
                                    </div>
                              </div>
                        </div>
                  </div>
            </li>
      )
}

export default SingleListBooking
