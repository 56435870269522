export const widgetSession =  build =>
      build.mutation({
            query: (body) =>  ({
                  url: '/widget_session',
                  method: 'POST',
                  body: body
            }),
            // invalidatesTags: ['getVoucherHistory']
      })


     