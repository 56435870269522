import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import TomSelect from 'tom-select';
import { updateLocoScroll } from '../Store/UI';

const SelectComponent = (props) => {

      const dispatch = useDispatch();
      useEffect(()=>{
            const selectRefId = document.getElementById(props.id)
            const selectArea =  new TomSelect(selectRefId,{
                  items:props.items && props.items,
                  onFocus:onFocusHandler,onBlur:onBlurHandler,
                  controlInput :null
            });
       
            return ()=>{
                  selectArea.destroy();
            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      const onFocusHandler =()=>{

            if(props.update === false){
                  return;
            }
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'stop'}}))
      }

      const onBlurHandler =()=>{
            if(props.update === false){
                  return;
            }
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'start'}}))
      }

      return (
            <div className={["form-group",props.divClass].join(" ")}>
                  {props.label &&
                  <label className="heading fw-bold">
                        Select Area
                  </label>}
                  <select id={props.id} className="form-control tomselect tomselect-xl-lg" autoComplete="off" onChange={props.onChange} name={props.name} onFocus={props.onFocus} placeholder={props.placeholder} disabled={props.disabled} items={props.items} >
                        {props.children}
                  </select>
                  
            </div>
      )
}

export default SelectComponent
