import React, { useEffect } from 'react'

import { TextRoller } from "./../Shared";

const TickerText = (props) => {

      useEffect(()=>{

            var TickerTextRoller = document.getElementById(props.id);
            if(TickerTextRoller){
                  var text_roller_TicketText = new TextRoller(TickerTextRoller);
                  text_roller_TicketText.start();
            }

      },[props.id])

      let count = null
      if(props.count !== undefined) {
            count = props.count
      }

      return (
            <div id={props.id} className="ticker">
                  <span className="ticker__text">
                        {props.textData.map((text,index)=>{

                                    return <React.Fragment key={`${props.textData[0]} ${index}`}><span className="dot"></span>{text} {(props.count !== undefined && props.count !== null)? `(${count})` : ""}</React.Fragment>

                              })
                        }
                  </span>
                  {/* <!-- /.ticker__text --> */}
            </div>
      )
}

export default TickerText
