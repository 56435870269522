import React from 'react'

const EventDetailNavItemDummy = (props) => {
      
      return (
            <button className={["nav-link nav-dummylink",props.active && "borderBottom"].join(" ")} id="bt-information-tab" type="button">
            </button>
      )
}

export default EventDetailNavItemDummy
