import React from 'react'

const StepComponent = (props) => {
      return (
            <div data-scroll data-scroll-repeat className="feature feature__ticket-booking-step d-flex align-items-start">
                  <div className="feature__icon flex-shrink-0 text-center">
                        <img className={["icon",props.iconClassName].join(" ")} src={require(`./../../../assets/images/${props.iconSrc}`)} alt=""/>
                  </div>
                  <div className="feature__body">
                        <div className="feature-heading d-flex">
                              <span className="step">{props.stepNumber}</span>
                              <h4 className="heading mb-0">{props.stepTitle}</h4>
                        </div>
                        <p>
                              {props.children}
                        </p>
                  </div>
            </div>
      )
}

export default StepComponent
