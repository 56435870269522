import React from 'react';
import "./UptakeVenue.css"

const PageTitle = () => {
      return (
            <div className='uptakeVenue-pageTitle__dummy'>
                  
            </div>
      )
}

export default PageTitle
