import React from  'react';

// external css
import emojiSadTimeoutYellow from "../../../../assets/images/tickets/emoji-sad-timeout-yellow.svg"
import emojiSadTimeoutYellowSm from "../../../../assets/images/tickets/emoji-sad-timeout-yellow-sm.svg"

import emojiSadTimeoutAquamarine from "../../../../assets/images/tickets/emoji-sad-timeout-aquamarine.svg"
import emojiSadTimeoutAquamarineSm from "../../../../assets/images/tickets/emoji-sad-timeout-aquamarine-sm.svg"

import emojiSadTimeoutBlue from "../../../../assets/images/tickets/emoji-sad-timeout-blue.svg"
import emojiSadTimeoutBlueSm from "../../../../assets/images/tickets/emoji-sad-timeout-blue-sm.svg"

import emojiSadTimeoutGreen from "../../../../assets/images/tickets/emoji-sad-timeout-green.svg"
import emojiSadTimeoutGreenSm from "../../../../assets/images/tickets/emoji-sad-timeout-green-sm.svg"

import emojiSadTimeoutOrange from "../../../../assets/images/tickets/emoji-sad-timeout-orange.svg"
import emojiSadTimeoutOrangeSm from "../../../../assets/images/tickets/emoji-sad-timeout-orange-sm.svg"

import emojiSadTimeoutRed from "../../../../assets/images/tickets/emoji-sad-timeout-red.svg"
import emojiSadTimeoutRedSm from "../../../../assets/images/tickets/emoji-sad-timeout-red-sm.svg"

// redux and react hooks
import { useDispatch } from 'react-redux';

// child component
import { NameConstants } from '../../../../resources/NameConstants';

// packages
import { setBookingStep, resetForAnimation } from '../../../../Store/BookingModule';
import { setBookingHandler } from "../../../../Store/EventDetail"

// Actions & RTK query or mutations

// Constants


const BookingTimeOut = () => {

      const dispatch = useDispatch();

      const restartBookingHandler = ()=>{
            dispatch(setBookingStep({step : NameConstants.bookingModuleSteps.chooseDate}));
      }

      const closeBookingHanler = ()=>{
            dispatch(resetForAnimation())
            dispatch(setBookingHandler({isBooking:false}))
      }

      return (

            <div id="bookTicketsBookingTimeout" className="content content__booking-timeout tickets-drop-down text-center border-bottom d-flex">
                  <button type="button" className="btn-close btn-close__checkout btn-close__custom btn-close__absolute" aria-label="Close" onClick={closeBookingHanler}>
                  </button>
                  <div className="content__header">
                        <div className="big-heading">
                              <h1 className="mb-0">Time<br />Out</h1>

                              <picture>
                                    <source media="(min-width: 768px)" srcset={emojiSadTimeoutYellow}/>
                                    <img data-scroll className="emoji emoji-yellow" src={emojiSadTimeoutYellowSm} alt=""/>
                              </picture>
                              <picture>
                                    <source media="(min-width: 768px)" srcset={emojiSadTimeoutAquamarine}/>
                                    <img data-scroll className="emoji emoji-aquamarine" src={emojiSadTimeoutAquamarineSm} alt=""/>
                              </picture>
                              <picture>
                                    <source media="(min-width: 768px)" srcset={emojiSadTimeoutBlue}/>
                                    <img data-scroll className="emoji emoji-blue" src={emojiSadTimeoutBlueSm} alt=""/>
                              </picture>
                              <picture>
                                    <source media="(min-width: 768px)" srcset={emojiSadTimeoutGreen}/>
                                    <img data-scroll className="emoji emoji-green" src={emojiSadTimeoutGreenSm} alt=""/>
                              </picture>
                              <picture>
                                    <source media="(min-width: 768px)" srcset={emojiSadTimeoutOrange}/>
                                    <img data-scroll className="emoji emoji-orange" src={emojiSadTimeoutOrangeSm} alt=""/>
                              </picture>
                              <picture>
                                    <source media="(min-width: 768px)" srcset={emojiSadTimeoutRed}/>
                                    <img data-scroll className="emoji emoji-red" src={emojiSadTimeoutRedSm} alt=""/>
                              </picture>
                        </div>
                  </div>

                  <div className="content__body min-height-0">
                        <h4 className="heading red black-lg">Your session has expired. Please try again.</h4>
                        <div className="buttons-wrapper">
                              <button id="btnRestartBooking" type="button" className="btn btn-primary mb-4 mx-auto" onClick={restartBookingHandler}>
                                    Restart booking
                              </button>
                        </div>
                  </div>
                  {/* <!-- /.content__body --> */}
            </div>
                                          
      )
}

export default BookingTimeOut;
