import React, { useState, useEffect } from 'react'

// external css
// redux and react hooks
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// child component
import Footer from '../../Layouts/Footer/Footer';
import { TickerText } from '../../Components';

// packages
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';

// Actions & RTK query or mutations
import { useLazyGetFeedbackQuery, usePostFeedbackMutation } from "./../../Services/modules/Feedback";
import { setAlert, updateLocoScroll } from '../../Store/UI';

// Constants
import { errors, actions, placeholders, messages } from '../../resources/en';
import { yourFeedbackText, catsOneText } from "../../resources/TextRollerConstants";
import { routesName } from '../../resources/RoutesName';
import { MetaData, MetaDataAj, MetaDataTfm } from '../../resources/MetaData';
import { Config } from '../../Config';

const LeaveFeedBack = () => {
      
      const [frontError,setFrontError]    =     useState([]);
      const [state,setState]              =     useState(null)

      const browserDetect                 =     useSelector(state=>state.ui.browserDetect);
      const domain                        =     useSelector(state=>state.user.domain)
      const [getFeedback, { data, error, isSuccess }] = useLazyGetFeedbackQuery();
      const [postFeedback, { data : postData, error : postError, isSuccess : postIsSuccess , isLoading : postIsLoading }] = usePostFeedbackMutation();
      
      const { id } = useParams();
      const params = useLocation();
      const dispatch = useDispatch();
      const navigate = useNavigate();

      useEffect(()=>{
            document.title = "Leave Feedback";
      },[])
      
      useEffect(()=>{
            getFeedback({booking_id:id});
      },[getFeedback,id])

      useEffect(()=>{

            if(isSuccess){
                  if(data.status === "1") {
                        setState(data.data)
                  } else {
                        navigate(routesName.MyBookings)
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.message || errors.NotFound404 }))
                  }
            } 

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
                  navigate(routesName.MyBookings)
            }

      },[data,error,isSuccess,dispatch,navigate])

      useEffect(()=>{

            if(postData){
                  // console.log(postData)
                  if(postData.status === "1") {
                        dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:postData.message || errors.NotFound404 }))
                        navigate(routesName.MyBookings)
                  } else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:postData.message || errors.NotFound404 }))
                        navigate(routesName.MyBookings)
                  }
            } 

            if(postError){
                  // console.log(postError)
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:postError.error || postError.data.error || postError.data.message || postError.NotFound404 }))
                  navigate(routesName.MyBookings)
            }

      },[postData,postError, postIsSuccess,dispatch,navigate])
      
      const afterLoadHandler = ()=>{
            // console.log("after Load Image handler..")
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
      }

      const changeBoxHandler = (pollIndex,bodyIndex,value,type)=>{
            clearFrontError()
            const currentArray  = [...state.poll[pollIndex].body];
            const checkForRadio = currentArray.map(el=>el.selected)
           
            if(value && !checkForRadio.includes("true") && type === 'radio'){
                  return;
            }
            else {
                  
                  let updateArray = [];
                  if(type === 'radio') {

                        updateArray = currentArray.map((el,index)=>{
                              if(index === bodyIndex) {
                                    return {
                                          ...el,selected:!value
                                    }
                              }
                              else {
                                    return {
                                          ...el,selected:false
                                    }
                              }
                        })
                  } else if (type === 'checkbox') {

                        updateArray = currentArray.map((el,index)=>{
                              if(index === bodyIndex) {
                                    return {
                                          ...el,selected:!value
                                    }
                              }
                              else {
                                    return {
                                          ...el
                                    }
                              }
                        })
                  }

                  setState(currState=>{
                        return {
                              ...currState,
                              poll:{
                                    ...currState.poll,
                                    [pollIndex] : {
                                          ...currState.poll[pollIndex],
                                          body : updateArray
                                    }
                              }
                        }
                  })
            }
            
      }

      const changeTextHandler = (event,pollIndex)=>{
            clearFrontError()
            let val = event.target.value;
            // const name = event.target.name;

            setState(currState=>{
                  return {
                        ...currState,
                        poll:{
                              ...currState.poll,
                              [pollIndex] : {
                                    ...currState.poll[pollIndex],
                                    body : val
                              }
                        }
                  }
            })
      }

      const changeNumberHandler = (event,pollIndex)=>{
            clearFrontError()
            event.preventDefault();
            let val = validate(event.target.value).input;
            setState(currState=>{
                  return {
                        ...currState,
                        poll:{
                              ...currState.poll,
                              [pollIndex] : {
                                    ...currState.poll[pollIndex],
                                    body : val
                              }
                        }
                  }
            })
      }

      const clearFrontError = ()=>{
            setFrontError([]);
      }

      const submitFeedBackHandler = (event)=>{
            
            event.preventDefault();
            const updateState = {...state.poll};
            const errorLengthArray = [];

            for(let key in updateState){

                  const selectedEle = [];
                  if(['0','1','4','5','6','8','9'].includes(key)){
                        updateState[key].body.forEach(element => {
                              if(element.selected) {
                                    selectedEle.push(element.id)
                              }
                        });

                        if(selectedEle.length === 0) { 
                              errorLengthArray.push(key)
                        }
                  } else if(['2','3'].includes(key)) {

                        if(key === "2"){

                              if(updateState[1].body[0].selected){
                                    if(updateState[key].body.length <= 1 ){
                                          errorLengthArray.push(key)
                                    }
                              }
                        }

                        if(key === "3"){
                              if(updateState[1].body[1].selected){
                                    if(updateState[key].body.length <= 1 ){
                                          errorLengthArray.push(key)
                                    }
                              }
                        }

                  } 
                  // else if(key === '7') {

                  //       if(updateState[key].body <= 0.00){
                  //             errorLengthArray.push(key);
                  //       }
                  // }
            }

            if(errorLengthArray.length > 0) {
                  setFrontError(errorLengthArray);
                  return;
            }

            if(errorLengthArray.length <= 0) {

                  const rating = state.poll[0].body.filter(el=>el.selected);
                  const likeDislikeReason = state.poll[1].body[0].selected ? state.poll[2].body : state.poll[3].body;
                  const referabilityRatting = state.poll[6].body.filter(el=>el.selected)
                  const howDidEventDiscussed = state.poll[5].body.filter(el=>el.selected)
                  const reasonsToAttendEvent = state.poll[9].body.filter(el=>el.selected)
                  const thingsDonePriorToEvent = state.poll[8].body.filter(el=>el.selected)
                  const emotionalResponseSubjestMatterRatting = state.poll[4].body.filter(el=>el.selected)


                  const ratingKey = state.poll[0].key
                  const partyAmountKey = state.poll[7].key
                  const antyhingDidntLikeKey = state.poll[1].key
                  const likeDislikeReasonKey =  state.poll[1].body[0].selected ? state.poll[2].key : state.poll[3].key;
                  const referabilityRattingKey = state.poll[6].key
                  const howDidEventDiscussedKey = state.poll[5].key
                  const reasonsToAttendEventKey = state.poll[9].key
                  const thingsDonePriorToEventKey = state.poll[8].key
                  const emotionalResponseSubjestMatterRattingKey = state.poll[4].key

                  let event_id = "";
                  if(params.state){
                        event_id = params.state.event_id ? params.state.event_id : "";
                  }

                  const submitData = {
                        booking_id:id,
                        event_id:event_id || "",
                        [ratingKey] : rating.length > 0 ? rating[0].label : "",
                        [partyAmountKey]:state.poll[7].body,
                        [antyhingDidntLikeKey]:state.poll[1].body[0].selected,
                        [likeDislikeReasonKey] : likeDislikeReason,
                        [referabilityRattingKey]:referabilityRatting.length > 0 ? referabilityRatting[0].label :'',
                        [howDidEventDiscussedKey] :howDidEventDiscussed.length > 0 ? howDidEventDiscussed[0].label : '',
                        [reasonsToAttendEventKey]:reasonsToAttendEvent.length > 0 ? reasonsToAttendEvent.map(el=>el.label) : [],
                        [thingsDonePriorToEventKey]:thingsDonePriorToEvent.length > 0 ?  thingsDonePriorToEvent.map(el=>el.label) : [],
                        [emotionalResponseSubjestMatterRattingKey]: emotionalResponseSubjestMatterRatting.length > 0 ? emotionalResponseSubjestMatterRatting[0].label : '',
                  }
                  postFeedback(submitData)
            }
            else {
                  window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                  });
            }
            
      }

      const validate = (s) => {
            var rgx = /^[0-9]*\.?[0-9]*$/;
            return s.match(rgx);
      }

      const backButtonHandler = (event)=>{
            event.preventDefault();
            navigate(-1)
      }

      let metaDataSite = MetaData
      if(domain === Config.DOMAIN_CONSTANT_TFM){
            metaDataSite = MetaDataTfm
      } else if(domain === Config.DOMAIN_CONSTANT_AJTIX){
            metaDataSite = MetaDataAj
      }

      return (
                  <>

                        {/* <!-- REQUIRED wrapper div for parallax --> */}
                        <div className="scroll-animations position-relative" data-scroll-container>
                              <Helmet>
                                    <title>{metaDataSite.LeaveFeedback.title}</title>
                                    <meta name="description" content={metaDataSite.LeaveFeedback.description} />
                                    <meta name="keywords" content={metaDataSite.LeaveFeedback.keywords} />
                              </Helmet>
                              <main>

                                    <section data-scroll-section className="section__leave-feedback py-0">
                                          <div className="border-bottom">
                                                <TickerText id="tickerYourFeedback" textData={yourFeedbackText}/>
                                          </div>
                                          <div className="container-fluid container__block container__leave-feedback">
                                                <div className="row">
                                                      <div className="col-md-12">
                                                            <div className="content content__leave-feedback mx-auto">
                                                                  <div className="content__body text-center mx-auto">

                                                                        <div className="card card__event p-0">
                                                                              <div className="card__event-image">
                                                                                    {/* <img  className="event-image" srcSet={state ? state.event_image : ""} src={state ? state.event_image : ""} alt="" /> */}
                                                                                    {state && <LazyLoadImage className="event-image w-100" srcSet={`${state.event_image} 2x`} src={state.event_image} afterLoad={afterLoadHandler} /> }
                                                                                    
                                                                              </div>
                                                                              <div className="card-body p-0">
                                                                                    <h1 className="h2 card-title fw-bold mb-0">
                                                                                          {/* {data && data.data.event_title} */}
                                                                                          {state ? state.event_name : '' }
                                                                                    </h1>
                                                                              </div>
                                                                        </div>

                                                                        <form>

                                                                              <div className="form-group">
                                                                                    <h3 className="form-group__heading">
                                                                                          {state ? state.poll[0].text : ''}
                                                                                    </h3>
                                                                                    <div id="overallEventExperienceRating" className="list__btn-checks list__btn-checks-circle justify-content-center mx-auto">
                                                                                          {state && state.poll[0].body.map((item,index)=>{

                                                                                                return (
                                                                                                      <div className="btn-check-wrapper mb-0" key={`Object0${item.id}`} style={{margin:browserDetect ? "0 20px":""}}>
                                                                                                            <input className="form-check-input btn-check" type="radio" id={`radioExperienceRating${item.id}`} onChange={()=>changeBoxHandler('0',index,item.selected,'radio')} checked={item.selected}/>
                                                                                                            <label className="btn btn-sm btn-outline-primary rounded-circle hover-primary" htmlFor={`radioExperienceRating${item.id}`}>{item.value}</label>
                                                                                                      </div>
                                                                                                )
                                                                                          })}
                                                                                          
                                                                                    </div>
                                                                                    {/* <!-- .list__btn-check-wrapper --> */}
                                                                                    <p>{state ? state.poll[0].lable_underneath : ''}</p>
                                                                                    {frontError.includes('0') && <p className='error-exists'>{errors.leaveFeedback[0]}</p>}

                                                                              </div>
                                                                              {/* <!-- .form-group --> */}

                                                                              <div className="form-group">
                                                                                    <h3 className="form-group__heading">{state ? state.poll[1].text : ''}</h3>
                                                                                    <div className="list__btn-checks flex-column flex-md-row justify-content-center mx-auto">
                                                                                          {state && state.poll[1].body.map((item,index)=>{
                                                                                                return (
                                                                                                      <div className="btn-check-wrapper mb-0" key={`Object1${item.id}`} style={{margin:browserDetect ? "0 20px":""}}>
                                                                                                            <input className="form-check-input btn-check" type="radio" id={`radioAnythingYouDidntLike${item.value}`}  onChange={()=>changeBoxHandler('1',index,item.selected,'radio')} checked={item.selected}/>
                                                                                                            <label className="btn btn-sm btn-outline-primary hover-primary w-100" htmlFor={`radioAnythingYouDidntLike${item.value}`}>{item.value}</label>
                                                                                                      </div>
                                                                                                )
                                                                                                
                                                                                          })}
                                                                                         
                                                                                    </div>

                                                                                    {frontError.includes('1') && <p className='error-exists'>{errors.leaveFeedback[1]}</p>}
                                                                                    {/* <!-- .list__btn-check-wrapper --> */}
                                                                              </div>
                                                                              {/* <!-- .form-group --> */}

                                                                              {/* also display if ratings are greter than 4 */}
                                                                              { (state && state.poll[1].body[1].selected) && <div id="formGroupShowGoodReason" className="form-group d-block">
                                                                                    <h3 className="form-group__heading">
                                                                                          {state ? state.poll[3].text : ''}
                                                                                    </h3>
                                                                                    <div className="form-group">
                                                                                          <textarea className="form-control mx-auto" id="textareaRecommendShow" placeholder={placeholders.DoLike} onChange={(event)=>changeTextHandler(event,'3')}  value={state.poll[3].body}></textarea>
                                                                                    </div>
                                                                                    {frontError.includes('3') && <p className='error-exists'>{errors.leaveFeedback[3]}</p>}
                                                                              </div>}
                                                                              {/* <!-- .form-group --> */}

                                                                              { (state && state.poll[1].body[0].selected) && <div id="formGroupShowDisliked" className="form-group d-block">
                                                                                    <h3 className="form-group__heading">
                                                                                          {state ? state.poll[2].text : ''}
                                                                                    </h3>
                                                                                    <div className="form-group">
                                                                                          <textarea className="form-control mx-auto" id="textareaDisliked" placeholder={placeholders.DidnotLike} onChange={(event)=>changeTextHandler(event,'2')} value={state.poll[2].body}></textarea>
                                                                                    </div>
                                                                                    {frontError.includes('2') && <p className='error-exists'>{errors.leaveFeedback[2]}</p>}
                                                                              </div>}
                                                                              {/* <!-- .form-group --> */}

                                                                              <div className="form-group">
                                                                                    <h3 className="form-group__heading">
                                                                                          {state ? state.poll[4].text :''}
                                                                                    </h3>
                                                                                    <div className="list__btn-checks list__btn-checks-circle justify-content-center mx-auto">
                                                                                          {state && state.poll[4].body.map((item,index)=>{
                                                                                                
                                                                                                return (
                                                                                                      <div className="btn-check-wrapper mb-0" key={`Object4${item.id}`} style={{margin:browserDetect ? "0 20px":""}}>
                                                                                                            <input className="form-check-input btn-check" type="radio" id={`radioEmotionalResponse${item.value}`} value={item.value} onChange={()=>changeBoxHandler('4',index,item.selected,'radio')} checked={item.selected}/>
                                                                                                            <label className="btn btn-sm btn-outline-primary rounded-circle hover-primary" htmlFor={`radioEmotionalResponse${item.value}`}>{item.value}</label>
                                                                                                      </div>
                                                                                                )
                                                                                                
                                                                                          })}

                                                                                    </div>
                                                                                    {/* <!-- .list__btn-check-wrapper --> */}
                                                                                    <p>{state ? state.poll[4].lable_underneath :''}</p>
                                                                                    {frontError.includes('4') && <p className='error-exists'>{errors.leaveFeedback[4]}</p>}
                                                                              </div>
                                                                              {/* <!-- .form-group --> */}

                                                                              <div className="form-group">
                                                                                    <h3 className="form-group__heading">
                                                                                          {state ? state.poll[5].text : ''}
                                                                                    </h3>
                                                                                    <div className="list__btn-checks flex-column flex-md-row justify-content-center mx-auto">
                                                                                          {state && state.poll[5].body.map((item,index)=>{

                                                                                                return (
                                                                                                      <div className="btn-check-wrapper mb-0" key={`Object5${item.id}${index}`} style={{margin:browserDetect ? "0 20px":""}}>
                                                                                                            <input className="form-check-input btn-check" type="radio" name="radioPostEventDiscuss" id={`radioPostEventDiscuss${item.value}`} value={item.value} onChange={()=>changeBoxHandler('5',index,item.selected,'radio')} checked={item.selected}/>
                                                                                                            <label className="btn btn-sm btn-outline-primary hover-primary w-100" htmlFor={`radioPostEventDiscuss${item.value}`}>{item.value}</label>
                                                                                                      </div>
                                                                                                )
                                                                                          })}
                                                                                          
                                                                                    </div>
                                                                                    {frontError.includes('5') && <p className='error-exists'>{errors.leaveFeedback[5]}</p>}
                                                                                    {/* <!-- .list__btn-check-wrapper --> */}
                                                                              </div>
                                                                              {/* <!-- .form-group --> */}

                                                                              <div className="form-group">
                                                                                    <h3 className="form-group__heading">
                                                                                          {state ?  state.poll[6].text : ''}
                                                                                    </h3>
                                                                                    <div className="list__btn-checks list__btn-checks-circle justify-content-center mx-auto">
                                                                                          {state && state.poll[6].body.map((item,index)=>{
                                                                                                return (
                                                                                                      <div className="btn-check-wrapper mb-0" key={`Object6${item.id}`} style={{margin:browserDetect ? "0 20px":""}}>
                                                                                                            <input className="form-check-input btn-check" type="radio" id={`radioLikelyToRecommend${item.value}`}  onChange={()=>changeBoxHandler('6',index,item.selected,'radio')} checked={item.selected}/>
                                                                                                            <label className="btn btn-sm btn-outline-primary rounded-circle hover-primary" htmlFor={`radioLikelyToRecommend${item.value}`}>{item.value}</label>
                                                                                                      </div>
                                                                                                )
                                                                                          })}
                                                                                          
                                                                                    </div>
                                                                                    {/* <!-- .list__btn-check-wrapper --> */}
                                                                                    <p>{state ? state.poll[6].lable_underneath : ''}</p>
                                                                                    {frontError.includes('6') && <p className='error-exists'>{errors.leaveFeedback[6]}</p>}
                                                                              </div>
                                                                              {/* <!-- .form-group --> */}


                                                                              <div className="form-group form-group__venue-spend">
                                                                                    <h3 className="form-group__heading">{state ? state.poll[7].text : ''}</h3>
                                                                                    <div className="input-group input-group__amount input-group__as-form-control mx-auto">
                                                                                          <span className="input-group-text fw-bold ms-auto ms-md-0">£</span>
                                                                                          {/* <span id="inputVenueSpendAmount" className="form-control fc__amount fw-bold me-auto me-md-0" role="textbox" contentEditable="" name="q8" onChange={changeNumberHandler} value={state.q8.value}>
                                                                                          </span> */}
                                                                                          <input type="text" id="inputVenueSpendAmount" className="form-control fc__amount fw-bold me-auto me-md-0" onChange={(event)=>changeNumberHandler(event,'7')} value={state ? state.poll[7].body : ''} placeholder={placeholders.partyAmount}/>
                                                                                    </div>
                                                                                    {frontError.includes('7') && <p className='error-exists'>{errors.leaveFeedback[7]}</p>}
                                                                              </div>
                                                                              {/* <!-- .form-group --> */}

                                                                              <div className="form-group">
                                                                                    <h3 className="form-group__heading">{state ? state.poll[8].text : ''}</h3>
                                                                                    {state && state.poll[8].body.map((item,index)=>{

                                                                                          return (
                                                                                                <div className="btn-check-wrapper mx-auto" key={`Object8${item.id}`}>
                                                                                                      <input className="form-check-input btn-check" type="checkbox" name="checkPreEventDidYou" id={`checkPreEventDidYou${item.id}`} onChange={()=>changeBoxHandler('8',index,item.selected,'checkbox')} checked={item.selected}/>
                                                                                                      <label className="btn btn-sm btn-outline-primary hover-primary w-100" htmlFor={`checkPreEventDidYou${item.id}`}>{item.value}</label>
                                                                                                </div>
                                                                                          )
                                                                                    })}
                                                                                    {frontError.includes('8') && <p className='error-exists'>{errors.leaveFeedback[8]}</p>}
                                                                              </div>
                                                                              {/* <!-- .form-group --> */}

                                                                              <div className="form-group">
                                                                                    <h3 className="form-group__heading">
                                                                                          {state ? state.poll[9].text : ''}
                                                                                    </h3>
                                                                                    {state && state.poll[9].body.map((item,index)=>{

                                                                                          return (
                                                                                                      <div className="btn-check-wrapper mx-auto" key={`Object9${item.id}`}>
                                                                                                            <input className="form-check-input btn-check" type="checkbox" name="checkReasonsForAttending" id={`checkReasonsForAttending${item.id}`} checked={item.selected} onChange={()=>changeBoxHandler('9',index,item.selected,'checkbox')}/>
                                                                                                            <label className="btn btn-sm btn-outline-primary hover-primary w-100" htmlFor={`checkReasonsForAttending${item.id}`}>{item.value}</label>
                                                                                                      </div> 
                                                                                                )
                                                                                    })}
                                                                                    {frontError.includes('9') && <p className='error-exists'>{errors.leaveFeedback[9]}</p>}
                                                                              </div>
                                                                              {/* <!-- .form-group --> */}

                                                                              <div className="form-group form-group__cta d-flex flex-column flex-sm-row justify-content-center">
                                                                                    <button className="btn btn-xl-lg btn-outline-primary" onClick={(event)=>backButtonHandler(event)} style={{margin:browserDetect ? "0 20px":""}} disabled={postIsLoading}>
                                                                                          {actions.back}
                                                                                    </button>
                                                                                    <button type="submit" className="btn btn-xl-lg btn-primary" onClick={submitFeedBackHandler} style={{margin:browserDetect ? "0 20px":""}} disabled={postIsLoading}>
                                                                                          {actions.submit}
                                                                                    </button>
                                                                              </div>

                                                                        </form>

                                                                  </div>
                                                                  {/* <!-- .content__body --> */}
                                                            </div>
                                                            {/* <!-- .content.content__leave-feedback --> */}

                                                      </div>
                                                      {/* <!-- .col-md-12 --> */}
                                                </div>
                                                {/* <!-- /.row --> */}
                                          </div>
                                          {/* <!-- /.container-fluid  --> */}
                                    </section>
                                    {/* <!-- /.section__leave-feedback --> */}


                                    <div data-scroll-section className=" border-top border-bottom">
                                          <TickerText id="tickerShowCatsOne" textData={catsOneText}/>
                                    </div>

                              </main>

                              <Footer/>

                        </div>
                        {/* <!-- /div[data-scroll-container] --> */}
                  </>
      )
}

export default LeaveFeedBack;
